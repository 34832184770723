import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/effect-fade"
import { Pagination, Autoplay, Navigation, EffectFade } from "swiper/modules";
import { useCallback, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Spinner from "../Spinner";
const Slider = () => {

  const [loaded, setLoaded] = useState({ name: "", iamgeloaded: false })

  const onLoad = useCallback((name) => {
    setLoaded({ ...loaded, name: name, iamgeloaded: true });
  }, [])

  return (
    <Swiper
      navigation={true} modules={[Autoplay, Navigation, EffectFade]}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      onSwiper={(swiper) => {
        // Referencia a la instancia de Swiper para usar en eventos de ratón
        const nextEl = swiper.navigation.nextEl;
        const prevEl = swiper.navigation.prevEl;

        // Agrega eventos de ratón para mostrar/ocultar botones
        swiper.el.addEventListener('mouseenter', () => {
          nextEl.style.opacity = 1;
          prevEl.style.opacity = 1;
        });
        swiper.el.addEventListener('mouseleave', () => {
          nextEl.style.opacity = 0;
          prevEl.style.opacity = 0;
        });
      }}
      effect="fade"
      loop={true}
      className="h-[150px] md:h-full"
    >
      {['slider1', 'slider2', 'slider3', 'slider4'].map((slider, index) => (
        <SwiperSlide className="slide overflow-hidden" key={index}>
          <div className="overflow-hidden">

            <LazyLoadImage
              className="h-[150px] md:h-full"
              alt={`No image`}
              effect="blur"
              src={`./images/slider/${slider}.webp`}
            />

          </div>
          {(!loaded.iamgeloaded && loaded.name === slider) &&
            <div className="absolute inset-0 w-full h-full">
              <div className="absolute bottom-0 left-0 right-0 top-0 md:top-24 p-10">
                <h1 className="text-white text-xl font-medium capitalize">
                </h1>
                <div>
                  <Spinner w={'35px'} h={'35px'} />
                </div>
              </div>
            </div>

          }

        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default Slider;
