import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userService = createApi({
  reducerPath: "user",
  tagTypes: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      headers.set('authorization', userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (datauser) => {
          return {
            url: "create-user",
            method: "POST",
            body: datauser,
          };
        },
        invalidatesTags: ["users"],
      }),
      updateUser: builder.mutation({
        query: (dataUser) => {
          return {
            url: `update-user/${dataUser._id}`,
            method: "PUT",
            body: dataUser,
          };
        },
        invalidatesTags: ["users"],
      }),
      deleteUser: builder.mutation({
        query: (id) => {
          return {
            url: `delete-user/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["users"],
      }),
      get: builder.query({
        query: (page) => {
          return {
            url: `users/${page}`,
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),
      getUserById: builder.query({
        query: (id) => {
          return {
            url: `get-user/${id}`,
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),

      getCurrentCashById: builder.query({
        query: (id) => {
          return {
            url: `getcurrentcash/${id}`,
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),

      updatePassword: builder.mutation({
        query: (datauser) => {
          return {
            url: `update-password/${datauser._id}`,
            method: "PUT",
            body: datauser,
          };
        },
        invalidatesTags: ["users"],
      }),

      getAllUsers: builder.query({
        query: () => {
          return {
            url: "allusers",
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),

      getAllUsersByPage: builder.query({
        query: (params) => {
          var finalquery = ''
          if(params.search === ''){
            finalquery = 'emptyquery'
          }else{
            finalquery = params.search
          }
          return {
            url: `allusersbypage/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),

      countAllUsersFilter: builder.query({
        query: () => {
          return {
            url: `countusersfilter`,
            method: "GET",
          };
        },
        providesTags: ["users"],
      }),

      randomUsers: builder.query({
        query: () => {
          return {
            url: "random-users",
            method: "GET",
          };
        },
      }),

      updateCash: builder.mutation({
        query: (cashdata) => {
          return {
            url: "updatecash",
            method: "PUT",
            body: cashdata,
          };
        },
        invalidatesTags: ["users"],
      }),

      updateLockedUser: builder.mutation({
        query: (actiondata) => {
          return {
            url: "updatelockeduser",
            method: "PUT",
            body: actiondata,
          };
        },
        invalidatesTags: ["users"],
      }),

      updateProfileInfo: builder.mutation({
        query: (data) => {
          return {
            url: "updateprofileinfo",
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["users"],
      }),

      updatePhoto: builder.mutation({
        query: (data) => {
          return {
            url: "updatephoto",
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["users"],
      }),

      updateConfiguration: builder.mutation({
        query: (data) => {
          return {
            url: "updateConfiguration",
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["configurations"],
      }),
    };
  },
});
export const {
  useCreateMutation,
  useGetQuery,
  useGetUserByIdQuery,
  useCountAllUsersFilterQuery,
  useGetCurrentCashByIdQuery,
  useUpdatePasswordMutation,
  useUpdateConfigurationMutation,
  useGetAllUsersQuery,
  useGetAllUsersByPageQuery,
  useRandomUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateCashMutation,
  useUpdateCashRechargeMutation,
  useUpdateLockedUserMutation,
  useUpdateProfileInfoMutation,
  useUpdatePhotoMutation
} = userService;
export default userService;
