import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const emailService = createApi({
    reducerPath: 'email',
    tagTypes: 'emails',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://cinedar.com:8443/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            const userToken = reducers?.authReducer?.userToken;
            const offlineUserToken = reducers?.authReducer?.offlineUserToken
            headers.set('authorization', (userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '' || offlineUserToken ? `Bearer ${offlineUserToken}` : ''));
            return headers;
        }
    }),

    endpoints: (builder) => {
        return {
            sendEmailForgotPasword: builder.mutation({
                query: (data) => {
                    return {
                        url: '/send-email-forgot-password',
                        method: 'POST',
                        body: data
                    }
                }
            }),
            sendEmailWhitInformationService: builder.mutation({
                query: (data) => {
                    return {
                        url: '/send-email-information-service',
                        method: 'POST',
                        body: data
                    }
                }
            })
        }
    }
});
export const { useSendEmailForgotPaswordMutation, useSendEmailWhitInformationServiceMutation } = emailService;
export default emailService