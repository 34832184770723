import ScreenHeader from "../../components/ScreenHeader";
import { IoAlarmSharp, IoBagCheck, IoCard, IoCashSharp, IoTime, IoTimer, IoToday } from "react-icons/io5";
import { Toaster } from "react-hot-toast";
import Wrapper from "./Wrapper";
import { useEffect, useState } from "react";
import { AgruparBalanceNew, AgruparCantidadPerfilesNew, AgruparGCSellsNew, AgruparUserRecargasNew, currencyFormat } from "../../utils/Funciones.js";
import { FaCoins, FaDollarSign, FaPaypal, FaRegUser, FaShip, FaShippingFast, FaUserCheck, FaUsers } from "react-icons/fa";
import moment from "moment";
import { BsCalendar, BsCalendar2, BsCalendar2Date, BsCalendar2DayFill, BsCalendar2X, BsCalendar3, BsCashStack } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import AnimatedNumber from "react-animated-number";
import { SiBinance, SiCashapp } from "react-icons/si";
import Spinner from "../../components/Spinner";
import GraphsLine from "../../components/GraphsLine";
import GraphsRadar from "../../components/GraphsRadar";
import GraphsBarRechargeUsers from "../../components/GraphsBarRechargeUsers";
import GraphsBarRechargeResellers from "../../components/GraphsBarRechargeResellers";
import { useGetAllCategoriesStreamingQuery, useGetAllCategoriesStreamingByRatingQuery } from "../../store/services/categoryService";
import { useGetAllUsersQuery } from "../../store/services/userService";
import { useGetAllVentasQuery, useGetDashboardQuery, useGetLastVentasQuery } from "../../store/services/ventasService";
import { useGetAllPaymentsQuery } from "../../store/services/paymentService";
import { useGetAllGiftCardsQuery } from "../../store/services/giftcardService";
import { MdAdminPanelSettings } from "react-icons/md";

const Dashboard = ({
    socket,
    adminToken,
    configuration,
    loadingconfiguration,
    categories,
    categoryIsLoading
}) => {

    const [totalventas, setTotalVentas] = useState({
        hoy: 0,
        mes: 0,
        total: 0
    })
    const [changetotal, setChangeTotal] = useState({
        value: 0,
        string: 'Total de ventas',
        type: 'total'
    })
    const [totalrecharge, setTotalRecharge] = useState({
        yape: 0,
        mercadopago: 0,
        binance: 0,
        izipay: 0,
        total: 0
    })
    const [changetotalrecharge, setChangeTotalRecharge] = useState({
        value: 0,
        string: 'Total de recargas',
        type: 'total'
    })

    const [totalusers, setTotalUsers] = useState({
        admins: 0,
        normal: 0,
        reseller: 0,
        total: 0
    })
    const [changetotalusers, setChangeTotalUsers] = useState({
        value: 0,
        string: '',
        type: ''
    })

    const { data: lastventasFromdb, isFetching: fechinglastventas } = useGetLastVentasQuery();

    const { data: dashboardData, isFetching } = useGetDashboardQuery();

    const [balanceRanking, setBalanceRanking] = useState([])
    const [last10ventas, setLast10Ventas] = useState([])
    const [top5Streaming, setTop5Streaming] = useState([])
    const [top5StreamingRating, setTop5StreamingRating] = useState([])
    const [top10Consumidores, setTop10Consumidores] = useState([])
    const [top10Resellers, setTop10Resellers] = useState([])

    const [pedidosStatus, setPedidosStatus] = useState({
        expired: 0,
        proxexpired: 0,
        pending: 0,
        expiredBool: false,
        proxexpiredBool: false,
        pendingBool: false,
        string: ""
    })

    useEffect(() => {
        if (!isFetching) {
            setChangeTotal({ ...changetotal, type: 'total', value: dashboardData?.totalVentas, string: 'Total de ventas' })
            setTotalVentas({
                ...totalventas,
                hoy: dashboardData?.totalVentasHoy,
                mes: dashboardData?.totalVentasMes,
                total: dashboardData?.totalVentas
            })
            setChangeTotalRecharge({ ...changetotalrecharge, type: 'total', value: dashboardData?.totalRecargas, string: 'Total de recargas' })
            setTotalRecharge({
                ...totalrecharge,
                yape: dashboardData?.totalRecargasYape,
                mercadopago: dashboardData?.totalRecargasMercadoPago,
                binance: dashboardData?.totalRecargasBinance,
                izipay: dashboardData?.totalRecargasIziPay,
                total: dashboardData?.totalRecargas
            })
            setChangeTotalUsers({ ...changetotalusers, type: 'total', value: dashboardData?.totalUsers, string: 'Total de usuarios' })
            setTotalUsers({
                ...totalusers,
                admins: dashboardData?.totalUsersAdmins,
                normal: dashboardData?.totalUsersConsumidores,
                reseller: dashboardData?.totalUsersResellers,
                total: dashboardData?.totalUsers
            })
            setPedidosStatus({
                ...pedidosStatus,
                expired: dashboardData?.totalPedidosExpirados,
                proxexpired: dashboardData?.totalPedidosProximosExpirar,
                pending: dashboardData?.totalPedidosPendientes,
                expiredBool: false,
                proxexpiredBool: false,
                pendingBool: true,
                string: 'Pedidos pendientes'
            })

            setLast10Ventas(dashboardData?.last10Ventas)
            setTop5Streaming(dashboardData?.top5Streaming)
            setTop5StreamingRating(dashboardData?.top5StreamingRating)
            setTop10Consumidores(dashboardData?.top10Consumidores)
            setTop10Resellers(dashboardData?.top10Resellers)
        }
    }, [dashboardData])

    const ChangeTotal = (type) => {
        if (type === 'hoy') {
            setChangeTotal({ ...changetotal, type: type, value: totalventas.hoy, string: 'Total de hoy' })
        } else if (type === 'mes') {
            setChangeTotal({ ...changetotal, type: type, value: totalventas.mes, string: 'Total del mes' })
        } else if (type === 'total') {
            setChangeTotal({ ...changetotal, type: type, value: totalventas.total, string: 'Total de ventas' })
        }
    }

    const ChangeTotalRecharge = (paytype) => {
        if (paytype === 'yape') {
            setChangeTotalRecharge({ ...changetotalrecharge, type: paytype, value: totalrecharge.yape, string: 'Recargas yape' })
        } else if (paytype === 'mercadopago') {
            setChangeTotalRecharge({ ...changetotalrecharge, type: paytype, value: totalrecharge.mercadopago, string: 'Recargas mercadopago' })
        } else if (paytype === 'izipay') {
            setChangeTotalRecharge({ ...changetotalrecharge, type: paytype, value: totalrecharge.izipay, string: 'Recargas izipay' })
        } else if (paytype === 'binance') {
            setChangeTotalRecharge({ ...changetotalrecharge, type: paytype, value: totalrecharge.binance, string: 'Recargas binance' })
        } else if (paytype === 'total') {
            setChangeTotalRecharge({ ...changetotalrecharge, type: paytype, value: totalrecharge.total, string: 'Total de recargas' })
        }
    }

    const ChangeTotalUsers = (usertype) => {
        if (usertype === 'normal') {
            setChangeTotalUsers({ ...changetotalusers, type: usertype, value: totalusers.normal, string: 'Total consumidores' })
        } else if (usertype === 'reseller') {
            setChangeTotalUsers({ ...changetotalusers, type: usertype, value: totalusers.reseller, string: 'Total resellers' })
        } else if (usertype === 'admin') {
            setChangeTotalUsers({ ...changetotalusers, type: usertype, value: totalusers.admins, string: 'Administradores' })
        } else if (usertype === 'total') {
            setChangeTotalUsers({ ...changetotalusers, type: usertype, value: totalusers.total, string: 'Total de usuarios' })
        }
    }

    const ChangePedidosStatus = (status) => {
        if (status === 'expired') {
            setPedidosStatus({ ...pedidosStatus, pendingBool: false, proxexpiredBool: false, expiredBool: true, string: 'Pedidos expirados' })
        } else if (status === 'proximexpired') {
            setPedidosStatus({ ...pedidosStatus, pendingBool: false, expiredBool: false, proxexpiredBool: true, string: 'Pedidos próximos a expirar' })
        } else if (status === 'pending') {
            setPedidosStatus({ ...pedidosStatus, expiredBool: false, proxexpiredBool: false, pendingBool: true, string: 'Pedidos pendientes' })
        }
    }

    return (
        <Wrapper socket={socket} configuration={configuration}
            loadingconfiguration={loadingconfiguration}>
            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <IoBagCheck size={32} className="mr-[5px]" /> ADMIN DASHBOARD
                    </div>
                </div>
            </ScreenHeader>

            <div className="w-full md:w-12/12 mb-5 mt-[67px]">
                <div class="">
                    <div class="">
                        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                            <div class="flex flex-wrap p-3 h-44 rounded shadow-lg bg-white dark:bg-neutral-900">
                                <div className="w-full grid grid-cols-3 self-center content-center">
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Total de hoy" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotal('hoy')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <IoToday size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Total del mes" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotal('mes')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <BsCalendar3 size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Total" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotal('total')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <FaCoins size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="w-full flex items-center justify-center mt-4">
                                    <div className="flex items-center justify-center">
                                        {changetotal.type === 'hoy' && <IoToday size={64} className="scale text-blue-500" />}
                                        {changetotal.type === 'mes' && <BsCalendar3 size={64} className="scale text-blue-500" />}
                                        {changetotal.type === 'total' && <FaCoins size={64} className="scale text-yellow-500" />}
                                    </div>
                                    <div className="pl-2 text-neutral-900 dark:text-neutral-200">
                                        {
                                            !isFetching ?
                                                <>
                                                    <div className="w-full">
                                                        <AnimatedNumber
                                                            component="text"
                                                            initialValue={0}
                                                            value={changetotal.value}
                                                            stepPrecision={0}
                                                            style={{
                                                                transition: "0.5s ease-out",
                                                                fontSize: 30,
                                                                transitionProperty: "background-color, color, opacity"
                                                            }}
                                                            duration={500}
                                                            formatValue={n => 'S/. ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                        />
                                                    </div>
                                                    <div className="w-full text-neutral-500 text-xl transition-all duration-500">
                                                        {changetotal.string}
                                                    </div>
                                                </> : <Spinner w={'55px'} h={'55px'} />
                                        }

                                    </div>
                                </div>
                            </div>


                            <div class="flex flex-wrap p-3 h-44 rounded shadow-lg bg-white dark:bg-neutral-900">
                                <div className="w-full grid grid-cols-5 self-center items-center">
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Recargas de yape" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalRecharge('yape')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <img class="rounded-full w-6 grayscale"
                                                    src={`/images/paymethods/yape.webp`} alt={`No image yape`} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Recargas de izipay" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalRecharge('izipay')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <img class="rounded-full w-6 grayscale"
                                                    src={`/images/paymethods/izipay.webp`} alt={`No image yape`} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Recargas con mercadopago" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalRecharge('mercadopago')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <img class="rounded-full w-6 grayscale"
                                                    src={`/images/paymethods/mercadopago.webp`} alt={`No image mercadopago`} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Recargas con binance" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalRecharge('binance')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <img class="rounded-full w-6 grayscale"
                                                    src={`/images/paymethods/binance.webp`} alt={`No image mercadopago`} />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Total de recargas" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalRecharge('total')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <SiCashapp size={17} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>

                                </div>
                                <div className="w-full flex items-center justify-center mt-4">
                                    <div className="flex items-center justify-center">
                                        {changetotalrecharge.type === 'yape' && <img class="rounded-full w-[64px] cursor-pointer scale" src={`/images/paymethods/yape.webp`} alt={`No image yape`} />}
                                        {changetotalrecharge.type === 'mercadopago' && <img class="rounded-full w-[64px] cursor-pointer scale" src={`/images/paymethods/mercadopago.webp`} alt={`No image mercadopago`} />}
                                        {changetotalrecharge.type === 'izipay' && <img class="rounded-full w-[64px] cursor-pointer scale" src={`/images/paymethods/izipay.webp`} alt={`No image izipay`} />}
                                        {changetotalrecharge.type === 'binance' && <img class="rounded-full w-[64px] cursor-pointer scale" src={`/images/paymethods/binance.webp`} alt={`No image binance`} />}
                                        {changetotalrecharge.type === 'total' && <SiCashapp size={64} className="scale text-green-500" />}
                                    </div>
                                    <div className="pl-2 text-neutral-900 dark:text-neutral-200">
                                        {
                                            !isFetching ?
                                                <>
                                                    <div className="w-full">
                                                        <AnimatedNumber
                                                            component="text"
                                                            initialValue={0}
                                                            value={changetotalrecharge.value}
                                                            stepPrecision={0}
                                                            style={{
                                                                transition: "0.5s ease-out",
                                                                fontSize: 30,
                                                                transitionProperty: "background-color, color, opacity"
                                                            }}
                                                            duration={500}
                                                            formatValue={n => 'S/. ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                        />
                                                    </div>
                                                    <div className="w-full text-neutral-500 text-xl transition-all duration-500">
                                                        {changetotalrecharge.string}
                                                    </div>
                                                </> : <Spinner />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-wrap p-3 h-44 rounded shadow-lg bg-white dark:bg-neutral-900">
                                <div className="w-full grid grid-cols-4 self-center items-center">
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Administradores" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalUsers('admin')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <MdAdminPanelSettings size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Usuarios consumidores" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalUsers('normal')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <FaUserCheck size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Usuarios resellers" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalUsers('reseller')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <FaDollarSign size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Total de usuarios" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangeTotalUsers('total')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <FaUsers size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="w-full flex items-center justify-center mt-4">
                                    <div className="flex items-center justify-center">
                                        {changetotalusers.type === 'normal' && <FaUserCheck size={64} className="scale text-blue-600" />}
                                        {changetotalusers.type === 'reseller' && <FaDollarSign size={64} className="scale text-green-600" />}
                                        {changetotalusers.type === 'total' && <FaUsers size={64} className="scale text-violet-600" />}
                                        {changetotalusers.type === 'admin' && <MdAdminPanelSettings size={64} className="scale text-red-600" />}
                                    </div>
                                    <div className="pl-2 text-neutral-900 dark:text-neutral-200">
                                        {
                                            !isFetching ?
                                                <>
                                                    <div className="w-full">
                                                        <AnimatedNumber
                                                            component="text"
                                                            initialValue={0}
                                                            value={changetotalusers.value}
                                                            stepPrecision={0}
                                                            style={{
                                                                transition: "0.5s ease-out",
                                                                fontSize: 30,
                                                                transitionProperty: "background-color, color, opacity"
                                                            }}
                                                            duration={500}
                                                        />
                                                    </div>
                                                    <div className="w-full text-neutral-500 text-xl transition-all duration-500">
                                                        {changetotalusers.string}
                                                    </div>
                                                </> : <Spinner />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap p-3 h-44 rounded shadow-lg bg-white dark:bg-neutral-900">
                                <div className="w-full grid grid-cols-3 self-center items-center">
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Pedidos expirados" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangePedidosStatus('expired')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <IoAlarmSharp size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Pedidos próximos a expirar" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangePedidosStatus('proximexpired')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <IoTimer size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <Tooltip title="Pedidos pendientes" arrow={true} TransitionComponent={Zoom}>
                                            <div onClick={() => ChangePedidosStatus('pending')} className="h-[40px] w-[40px] rounded-full bg-neutral-200 dark:bg-black flex items-center justify-center cursor-pointer shadow-lg shadow-black">
                                                <FaShippingFast size={22} className="text-neutral-900 dark:text-neutral-200" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="w-full flex items-center justify-center mt-4">
                                    <div className="flex items-center justify-center">
                                        {pedidosStatus.expiredBool && <IoAlarmSharp size={64} className="scale text-red-600" />}
                                        {pedidosStatus.proxexpiredBool && <IoTimer size={64} className="scale text-orange-600" />}
                                        {pedidosStatus.pendingBool && <FaShippingFast size={64} className="scale text-yellow-600" />}
                                    </div>
                                    <div className="pl-2 text-neutral-900 dark:text-neutral-200">
                                        {
                                            !isFetching ?
                                                <>
                                                    <div className="w-full">
                                                        <AnimatedNumber
                                                            component="text"
                                                            initialValue={0}
                                                            value={pedidosStatus.expiredBool ? pedidosStatus.expired : pedidosStatus.proxexpiredBool ? pedidosStatus.proxexpired : pedidosStatus.pending}
                                                            stepPrecision={0}
                                                            style={{
                                                                transition: "0.5s ease-out",
                                                                fontSize: 30,
                                                                transitionProperty: "background-color, color, opacity"
                                                            }}
                                                            duration={500}
                                                        />
                                                    </div>
                                                    <div className="w-full text-neutral-500 text-xl transition-all duration-500">
                                                        {pedidosStatus.string}
                                                    </div>
                                                </> : <Spinner />
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div className="w-full relative shadow-lg bg-white dark:bg-neutral-900">
                                <div className="w-full h-10 flex items-center justify-center pt-5 pb-2 text-xl text-neutral-900 dark:text-neutral-200">TOP 5 STREAMING</div>
                                <div className="w-full p-3 text-neutral-900 dark:text-neutral-200">
                                    <GraphsLine
                                        categoryStreamingRating={!isFetching && top5StreamingRating}
                                        text={''}
                                        textlabel1={'RATING'}
                                        textlabel2={'BALANCE'}
                                        balance={!isFetching && top5Streaming}
                                    />
                                </div>

                            </div>
                            <div className="w-full relative shadow-lg bg-white dark:bg-neutral-900 pb-4">
                                <div className="w-full h-10 flex items-center justify-center pt-5 pb-2 text-xl text-neutral-900 dark:text-neutral-200">ÚLTIMAS 10 VENTAS</div>
                                <div className="overflow-auto h-[415px] p-3">
                                    {!fechinglastventas ? lastventasFromdb?.ventas?.map((venta) => {

                                        return (
                                            <div key={venta._id} className="flex items-center mb-3 bg-neutral-200 hover:bg-neutral-300 dark:bg-neutral-950 dark:hover:bg-neutral-900 p-3 rounded cursor-pointer hover:contrast-[108%] transition-all duration-500">
                                                <div className="w-[64px] flex items-center justify-center">
                                                    <div className="w-16 h-16 rounded-full flex items-center justify-center bg-neutral-900 dark:bg-neutral-200">
                                                        <img className="w-16 h-16 rounded-full m-auto" src={`/images/logos/${venta.logocategory}`} alt={`No image ${venta.categoryname}`}></img>
                                                    </div>
                                                </div>
                                                <div className="w-[calc(100%-64px)]">
                                                    <div className="w-full flex items-center justify-center relative text-sm md:text-base text-neutral-900 dark:text-neutral-200">
                                                        El usuario {venta.useremail} ah comprado {venta.type} {venta.categoryname}
                                                    </div>
                                                    <div className="w-full flex items-center justify-center text-xs md:text-sm text-green-600 dark:text-green-400">
                                                        {moment(venta.created_at).format("LLLL")}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <Spinner w={'55px'} h={'55px'} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div className="w-full flex flex-wrap items-center justify-center relative shadow-lg bg-white dark:bg-neutral-900 rounded">
                                <div className="w-full h-10 flex items-center justify-center pt-5 pb-2 text-xl text-neutral-900 dark:text-neutral-200">TOP 10 USERS</div>
                                <div className="w-full flex justify-center p-3">
                                    <GraphsBarRechargeUsers title={'TOP 10 Users Recargas'} rechargeRanking={!isFetching && top10Consumidores} labelname={'Ranking Recargas Users'} />
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap items-center justify-center relative shadow-lg bg-white dark:bg-neutral-900 rounded">
                                <div className="w-full h-10 flex items-center justify-center pt-5 pb-2 text-xl text-neutral-900 dark:text-neutral-200">TOP 10 RESELLERS</div>
                                <div className="w-full flex justify-center p-3">
                                    <GraphsBarRechargeResellers title={'TOP 10 Resellers Recargas'} rechargeRankingResellers={!isFetching && top10Resellers} labelname={'Ranking Recargas Resellers'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Dashboard;
