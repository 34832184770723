import { Toaster } from 'react-hot-toast';
import Spinner from "../../components/Spinner";
import { IconButton, Modal, Pagination, PaginationItem, Stack, TextField, Tooltip } from "@mui/material";
import { IoBagCheck, IoBan, IoCheckmarkCircle, IoCloseCircleSharp, IoCloseSharp, IoEye, IoEyeOff, IoSearch, IoTime } from "react-icons/io5";
import AccountList from "../../components/home/AccountList";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { FaClipboard, FaGifts, FaShippingFast } from 'react-icons/fa';
import { useMemo, useState, useEffect } from 'react';
import { CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat } from '../../utils/Funciones.js';
import { BsCashStack } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import { useGetAllCategoriesGiftCardQuery } from '../../store/services/categoryService';
import { useGetAllGiftCardsSellsByUserIdQuery } from '../../store/services/giftcardService';
import Spinner2 from '../../components/Spinner2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import { useGetAllGcVentasByUserIdQuery } from '../../store/services/gcventasService';
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SpinnerData from '../../components/SpinnerData';
import CopyToClipboard from 'react-copy-to-clipboard';

const MyGiftCards = ({
   SECRET_KEY,
   socket,
   userToken,
   user,
   loadinguserbyid,
   userById,
   configuration,
   loadingconfiguration
}) => {

   const [curTime, SetCurTime] = useState(null)
   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [pedidosByState, SetPedidosByState] = useState('')
   const [infopedido, setInfopedido] = useState('')
   const [limit, setLimit] = useState(30);
   const [answerPedido, setAnswerPedido] = useState({ open: false, observation: "", namesubGC: "", data: null });
   const [verdetalles, SetVerDetalles] = useState({
      open: false,
      ventaId: "",
      categoryname: "",
      subcategoryname: "",
      subcategorylogo: "",
      gcdata: [],
      copied: false
   })

   const { data: category, isFetching: loadingcategory } = useGetAllCategoriesGiftCardQuery();
   const { data: ventas, isFetching, refetch } = useGetAllGcVentasByUserIdQuery({ id: userToken && user?.id, search: searchTerm.trim(), page: currentPage, limit: limit });

   const [editorState, setEditorState] = useState(EditorState.createEmpty());
   var htmlData = useMemo(
      () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
      [editorState]
   );

   useEffect(async () => {
      await refetch()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetch.abort();
      };
   }, [searchTerm, currentPage, limit]);


   useEffect(async () => {
      window.scrollTo(0, 0);
      const interval = setInterval(async () => {
         SetCurTime(new Date().toLocaleString());
      }, 1000);
      return () => clearInterval(interval);
   }, [])
   
   const HandleOpenDetalles = (ventaId, categoryname, subcategoryname, subcategorylogo, gcdata) => {
      SetVerDetalles({ ...verdetalles, open: !verdetalles.open, ventaId, categoryname, subcategoryname, subcategorylogo, gcdata })
   }

   const onCopy = (text) => {
      SetVerDetalles({ ...verdetalles, copied: true });
      message(text, 'success', 2)
   }

   const handleChangePage = (e, p) => {
      setCurrentPage(p);
   };

   const changeHandleSearch = (event) => {
      SetPedidosByState('')
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

   const HandleChangeFilterPedidos = async (state) => {
      setSearchTerm(state)
      SetPedidosByState(state)
      setCurrentPage(1);
   }
   return (
      <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
         loadingconfiguration={loadingconfiguration}>
         <Toaster position="top-right" reverseOrder={true} />
         <UserScreenHeader>
            <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
               <FaGifts size={32} className="mr-2" /> Mis Pedidos GiftCards <span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">{!isFetching && ventas?.totalventas}</span></div>
         </UserScreenHeader>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[106px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pt-8 pb-8">
            <div className="relative">
               <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
               </div>
               <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Escribe código de venta ..."
                  required
                  onChange={debouncedChangeHandler}
                  autoComplete="off"
               />
            </div>
         </div>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[211px] pb-5 pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950">
            <div className="w-full flex items-center justify-center md:justify-end text-neutral-800 dark:text-neutral-200 mt-0 h-10">

            </div>
         </div>
         <div className='w-full'>
            <Modal
               keepMounted
               open={verdetalles.open}
               onClose={() => SetVerDetalles({ ...verdetalles, open: !verdetalles.open, ventaId: "", categoryname: "", subcategoryname: "", subcategorylogo: "", gcdata: [] })}
               aria-labelledby="keep-mounted-modal-title"
               aria-describedby="keep-mounted-modal-description"
               sx={{ zIndex: 50 }}
            >
               <div id="authentication-modal" class="w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">
                  <div className={`m-auto relative p-5 w-full md:w-6/12 bg-neutral-200 dark:bg-neutral-900 pb-24 overflow-y-auto`}>
                     <div className="flex items-center">
                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                           <IconButton color="error" onClick={() => SetVerDetalles({ ...verdetalles, open: !verdetalles.open, ventaId: "", categoryname: "", subcategoryname: "", subcategorylogo: "", gcdata: [] })}>
                              <IoCloseSharp
                                 className="text-neutral-200"
                                 size={28}
                              />
                           </IconButton>
                        </div>
                        <div className="text-2xl ml-3 text-neutral-900 dark:text-neutral-200 uppercase font-semibold">Detalles del pedido</div>
                     </div>

                     <div className="w-full mt-16 text-neutral-900 dark:text-neutral-200">
                        <div className="grid grid-wrap w-full md:w-5/12">
                           <div className="flex inline-flex items-center">
                              <span className="absolute -mt-28 -ml-0 text-neutral-900 dark:text-neutral-200 
                          text-base rounded-lg bg-neutral-100 dark:bg-neutral-800 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center">{verdetalles.ventaId}</span>
                              <LazyLoadImage
                                 className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                                 alt={`No image ${verdetalles.subcategoryname}`}
                                 effect="blur"
                                 src={`/images/logos/${verdetalles.subcategorylogo}`}
                              />
                              <div className="grid self-center">
                                 <div className="ml-2 text-sm font-semibold">{verdetalles.categoryname}</div>
                                 <div className="ml-2 text-sm font-semibold">{verdetalles.subcategoryname}</div>
                              </div>
                           </div>
                        </div>
                        <div className='bg-neutral-50 dark:bg-neutral-950 p-3 w-full text-center mt-5'>
                           <div className='text-lg bg-neutral-300 dark:bg-neutral-800 p-5 w-full text-center flex items-center justify-center mb-2'>Gift Cards <div className='bg-neutral-200 dark:bg-neutral-900 p-2 rounded-full w-8 h-8 ml-2 flex items-center justify-center'>{verdetalles.gcdata.length}</div></div>
                           {
                              verdetalles.gcdata.map((gc, index) => {
                                 return (
                                    <div className="cursor-pointer relative flex flex-wrap items-center w-full text-sm mt-10 md:mt-0 bg-neutral-200 dark:bg-neutral-900 mb-3 p-3">
                                       <div className="w-[50px] grid">
                                          {index + 1}
                                       </div>
                                       <div className="md:w-[calc(50%-100px)] w-full grid">
                                          <div className="flex items-center justify-center">Precio unitario</div>
                                          <div className="flex items-center justify-center">{currencyFormat(gc.price)}</div>
                                       </div>
                                       <div className="md:w-6/12 w-full grid">
                                          <div className="flex items-center justify-center">Código</div>
                                          <div className="flex items-center justify-center text-green-600 dark:text-green-500 blur-sm hover:blur-none">{gc.code}</div>
                                       </div>
                                       <div className="md:w-[50px] w-full grid">
                                          <CopyToClipboard text={gc.code} onCopy={() => onCopy('Código copiado')}>
                                             <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                                          </CopyToClipboard>
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </Modal>
         </div>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[211px] pb-5 pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950">
            <div className="w-full flex items-center justify-center md:justify-end text-neutral-800 dark:text-neutral-200 mt-0 h-10">
               {
                  !isFetching ?
                     <>
                        <div className="hidden md:block">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isFetching && Number(ventas?.totalventasFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChangePage}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="large"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-neutral-200 dark:bg-neutral-900"
                                 sx={{
                                    "& .MuiPaginationItem-page": { color: "#e5e5e5", fontSize: 15, border: "2px solid #1d4ed8", fontFamily: 'Jost, sans-serif' },
                                    "& .MuiPaginationItem-icon": { color: '#334155' },
                                    "& .Mui-selected": { backgroundColor: "#1d4ed8" },
                                    "& .MuiPaginationItem-ellipsis": { color: '#e5e5e5', fontSize: 25 },
                                 }}
                              />
                           </Stack>
                        </div>
                        <div className="block md:hidden">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isFetching && Number(ventas?.totalventasFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChangePage}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="small"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-neutral-200 dark:bg-neutral-900"
                                 sx={{
                                    "& .MuiPaginationItem-page": { color: "#e5e5e5", fontSize: 15, border: "2px solid #1d4ed8", fontFamily: 'Jost, sans-serif' },
                                    "& .MuiPaginationItem-icon": { color: '#334155' },
                                    "& .Mui-selected": { backgroundColor: "#1d4ed8" },
                                    "& .MuiPaginationItem-ellipsis": { color: '#e5e5e5', fontSize: 25 },
                                 }}
                              />
                           </Stack>
                        </div>
                     </>
                     : <Spinner />
               }

            </div>
            <div className='mt-8'>

               {
                  <Tooltip title="Click aquí para filtrar pedidos pendientes" arrow={true}>
                     <div onClick={() => HandleChangeFilterPedidos("Pending")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "Pending" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-yellow-500 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><FaShippingFast className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!isFetching ? ventas?.totalventasPending : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

               {
                  <Tooltip title="Click aquí para filtrar pedidos rechazados" arrow={true}>
                     <div onClick={() => HandleChangeFilterPedidos("Rejected")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "Rejected" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-700 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><IoBan className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!isFetching ? ventas?.totalventasRejected : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

               {
                  <Tooltip title="Click aquí para filtrar todos los pedidos" arrow={true}>
                     <div onClick={() => HandleChangeFilterPedidos("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-green-500 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><IoBagCheck className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!isFetching ? ventas?.totalventas : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

            </div>
         </div>

         <div className="mt-[415px]">
            {!isFetching ? ventas?.ventas.length > 0 ? ventas?.ventas.map((venta) => {
               var categoryname = ""
               var subcategoryname = ""
               var subcategorylogo = ""

               !loadingcategory && category?.categories.find((cat) => {
                  if (cat._id === venta.categoryId) {
                     categoryname = cat.category
                     cat.subcategory.find((subcat) => {
                        if (subcat._id === venta.subcategoryId) {
                           subcategoryname = subcat.name
                           subcategorylogo = subcat.logo
                        }
                     })
                  }
               })
               return (
                  <div className="animation transition-all text-neutral-900 dark:text-neutral-200 duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer bg-neutral-200 dark:bg-neutral-900 flex flex-wrap mb-12 shadow-xl rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer" key={venta.ventaId}>
                     <div className="flex flex-wrap items-center w-full md:w-[calc(50%-150px)]">
                        <div className="flex inline-flex items-center">
                           <span className="absolute -mt-28 -ml-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-lg bg-neutral-200 dark:bg-neutral-900 pt-2 pl-2 pr-2 pb-2 flex inline-flex 
                          items-center justify-center"><div className="flex items-center ml-2 text-xs truncate ...">{venta.ventaId}</div> </span>
                           <LazyLoadImage
                              className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                              alt={`No image ${subcategoryname}`}
                              effect="blur"
                              src={`/images/logos/${subcategorylogo}`}
                           />
                        </div>
                        <div className="w-[calc(100%-56px)]">
                           <div className="ml-2 text-lg capitalize font-semibold">{categoryname}</div>
                           <div className="ml-2 text-xs truncate ...">{subcategoryname}</div>
                           <div className="ml-2 text-xs mt-2 truncate ...">Se creó hace {CalcularTiempoTranscurrido(venta.created_at)}</div>
                        </div>
                     </div>

                     <div className="relative md:flex grid grid-cols-3 md:grid-cols-1 items-center w-full md:w-[calc(50%-150px)] text-sm mt-10 md:mt-0">
                        <div className="md:w-4/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Cantidad</div>
                           <div className="flex items-center justify-center">{venta.quantity}</div>
                        </div>
                        <div className="md:w-4/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Precio</div>
                           <div className="flex items-center justify-center">{currencyFormat(venta.totalamount)}</div>
                        </div>
                        <div className="md:w-4/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Observación</div>
                           <div className="flex items-center justify-center">{venta.observations}</div>
                        </div>
                     </div>

                     <div className="w-full md:w-[150px] mt-5 md:mt-0">
                        <div className="flex items-center justify-center font-bold text-sm">Estado</div>
                        <div className="flex items-enter justify-center text-sm">

                           {venta.state === "Completed" &&
                              <div class="m-auto p-2 bg-green-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                 <span class="text-center rounded-full bg-green-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Completado</span>
                              </div>
                           }
                           {venta.state === "Processing" &&
                              <div class="m-auto p-2 bg-orange-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                 <span class="text-center rounded-full bg-orange-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Procesando</span>
                              </div>}
                           {venta.state === "Pending" &&
                              <div class="m-auto p-2 bg-yellow-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                 <span class="text-center rounded-full bg-yellow-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Pendiente</span>
                              </div>}
                           {venta.state === "Rejected" &&
                              <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                 <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Rechazado</span>
                              </div>}
                        </div>
                     </div>

                     <div className="w-[150px] mt-5 md:mt-0 flex items-center justify-center bg-neutral-50 dark:bg-neutral-950 rounded">
                        <IconButton onClick={() => HandleOpenDetalles(venta.ventaId, categoryname, subcategoryname, subcategorylogo, venta.gcdata)} color='primary' className={`w-[45px]`} disabled={venta.state === "Completed" ? false : true}>
                           <IoEye size={24} className={`${venta.state === "Completed" ? 'text-neutral-900 dark:text-neutral-200' : 'text-neutral-400 dark:text-neutral-800'}`} />
                        </IconButton>

                        <IconButton color='primary' className='w-[45px]'>
                           <MdDelete size={24} className='text-red-500' />
                        </IconButton>
                     </div>
                  </div>
               )
            }) : (<div className="alert-danger font-semibold mt-[415px]">Sin resultados en la búsqueda! o no existen ventas registrados</div>) : (<div className="mt-[415px]"><SpinnerData>Cargando ventas, por favor espere...</SpinnerData></div>)}
         </div>

      </AccountList >
   )
}
export default MyGiftCards;