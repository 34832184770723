import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const cuentaService = createApi({
    reducerPath: 'cuenta',
    tagTypes: 'cuentas',
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://cinedar.com:8443/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            const userToken = reducers?.authReducer?.userToken;
            const offlineUserToken = reducers?.authReducer?.offlineUserToken
            headers.set('authorization', (userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '' || offlineUserToken ? `Bearer ${offlineUserToken}` : ''));
            return headers;
        }
    }),

    endpoints: (builder) => {
        return {
            cCuenta: builder.mutation({
                query: (datacuenta) => {
                    return {
                        url: '/create-cuenta',
                        method: 'POST',
                        body: datacuenta
                    }
                },
                invalidatesTags: ['cuentas']
            }),
            updateCuenta: builder.mutation({
                query: datacuenta => {
                    return {
                        url: `update-cuenta/${datacuenta._id}`,
                        method: 'PATCH',
                        body: datacuenta
                    }
                },
                invalidatesTags: ['cuentas']
            }),
            deleteCuenta: builder.mutation({
                query: id => {
                    return {
                        url: `/delete/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['cuentas']
            }),
            getAllCuentas: builder.query({
                query: () => {
                    return {
                        url: `allcuentas`,
                        method: 'GET'
                    }
                },
                providesTags: ['cuentas']
            }),

            getAllStockCuentas: builder.query({
                query: () => {
                    return {
                        url: `allstockcuentas`,
                        method: 'GET'
                    }
                },
                providesTags: ['cuentas']
            }),

            getAllCuentasByPage: builder.query({
                query: (params) => {
                    var finalquery = ''
                    if (params.search === '') {
                        finalquery = 'emptyquery'
                    } else {
                        finalquery = params.search
                    }
                    return {
                        url: `cuentasbypage/${finalquery}/${params.page}/${params.limit}`,
                        method: 'GET'
                    }
                },
                providesTags: ['cuentas']
            }),
            getCuentaById: builder.query({
                query: (id) => {
                    return {
                        url: `get-cuenta/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ['cuentas']
            }),
            countAllCuentas: builder.query({
                query: () => {
                  return {
                    url: `countallcuentas`,
                    method: "GET",
                  };
                },
                providesTags: ["cuentas"],
              }),
        }
    }
})
export const { useGetAllStockCuentasQuery, useCCuentaMutation, useCountAllCuentasQuery, useDeleteCuentaMutation, useUpdateCuentaMutation, useGetAllCuentasQuery, useGetAllCuentasByPageQuery, useGetCuentaByIdQuery } = cuentaService;

export default cuentaService