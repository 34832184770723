import React, { useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const GraphsRadar = ({ rechargeRanking, labelname, title }) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const options = {
        responsive: true,
        Animation: true,
        scales: {
            r: {
                angleLines: { color: '#059669' },
                grid: { color: '#059669' },
                pointLabels: { color: '#e5e5e5' },
                ticks: { color: '#171717'}
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: title
            }
        },
    };

    const data = {
        labels: rechargeRanking && rechargeRanking.sort((a, b) => b.total_recharge - a.total_recharge).map((rech) => rech.username).slice(0, 5),
        datasets: [
            {
                label: labelname,
                data: rechargeRanking && rechargeRanking.sort((a, b) => b.total_recharge - a.total_recharge).map((rech) => rech.total_recharge).slice(0, 5),
                borderColor: '#15803ce8',
                backgroundColor: '#4bdd81a4',
                borderWidth: 1,
            },
        ],
    };
    return (
        <Radar
            options={options}
            data={data}
            width={'100%'}
            height={`${windowSize.current[0] > 640 ? '80%' : '100%'}`}
        />
    )
}
export default GraphsRadar