import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const socialService = createApi({
  reducerPath: "service",
  tagTypes: "services",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      const offlineUserToken = reducers?.authReducer?.offlineUserToken
      headers.set('authorization', (userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '' || offlineUserToken ? `Bearer ${offlineUserToken}` : ''));
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createSocialPlataform: builder.mutation({
        query: (data) => {
          return {
            url: "create-social-plataform",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["services"],
      }),

      updatePlataform: builder.mutation({
        query: (data) => {
          return {
            url: `update-plataform/${data._id}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["services"],
      }),

      updateService: builder.mutation({
        query: dataservice => {
            return {
                url: `update-service/${dataservice._id}`,
                method: 'PATCH',
                body: dataservice
            }
        },
        invalidatesTags: ['services']
    }),

      createSocialReport: builder.mutation({
        query: (data) => {
          return {
            url: "create-social-report",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["services"],
      }),

      createSocialAnswerReport: builder.mutation({
        query: (data) => {
          return {
            url: "create-social-answer-report",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["services"],
      }),

      getAllSocialPlataforms: builder.query({
        query: () => {
          return {
            url: "/get-plataforms",
            method: "GET",
          };
        },
        providesTags: ["services"],
      }),

      getServiceById: builder.query({
        query: (field) => {
          return {
            url: `get-service-id/${field.serviceId}`,
            method: "GET",
          };
        },
        providesTags: ["services"],
      }),

      createSocialOrder: builder.mutation({
        query: (data) => {
          return {
            url: "create-social-order",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["services"],
      }),

      getOrdersByUserId: builder.query({
        query: (params) => {
          var finalquery = ''
          if (params.search === '') {
            finalquery = 'emptyquery'
          } else {
            finalquery = params.search
          }
          return {
            url: `get-orders-userid/${params.id}/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["services"],
      }),

      getAllSocialOrders: builder.query({
        query: (params) => {
          var finalquery = ''
          if (params.search === '') {
            finalquery = 'emptyquery'
          } else {
            finalquery = params.search
          }
          return {
            url: `get-all-social-orders/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["services"],
      }),
    };
  },
});

export const {
  useCreateSocialReportMutation,
  useUpdatePlataformMutation,
  useUpdateServiceMutation,
  useCreateSocialAnswerReportMutation,
  useGetOrdersByUserIdQuery,
  useGetServiceByIdQuery,
  useCreateSocialPlataformMutation,
  useCreateSocialOrderMutation,
  useGetAllSocialPlataformsQuery,
  useGetAllSocialOrdersQuery,
} = socialService;
export default socialService;
