import { useState } from "react"
import AdminNav from "../../components/AdminNav";
import Sidebar from "../../components/Sidebar";
const Wrapper = ({ children, socket, configuration, loadingconfiguration }) => {
    const [side, setSide] = useState('-translate-x-full')
    const openCloseSidebar = () => {
        if (side === "-translate-x-full") {
            setSide("")
        } else {
            setSide("-translate-x-full");
        }
    }
    const closeSidebar = () => {
        setSide('-translate-x-full');
    }
    return (
        <>
            <Sidebar side={side} closeSidebar={closeSidebar} />
            <AdminNav openCloseSidebar={openCloseSidebar} socket={socket} configuration={configuration}
      loadingconfiguration={loadingconfiguration}/>
            <section className="ml-0 sm:ml-64 bg-neutral-200 dark:bg-black min-h-screen pt-20 overflow-hidden">
                <div className="bg-neutral-200 dark:bg-black px-4 py-6">
                    {children}
                </div>
            </section>

        </>
    )
}
export default Wrapper;