import { Virtual, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "../skeleton/Skeleton";
import Thumbnail from "../skeleton/Thumbnail";
import { convertirsegundos, currencyFormat, message } from "../../utils/Funciones.js";
import { BsCash, BsHandbag, BsTagFill } from "react-icons/bs";
import { IoCardSharp, IoCartSharp, IoChevronDown, IoChevronForward, IoCloseSharp, IoDesktopSharp, IoHeartOutline, IoStar, IoTimer, IoVideocamSharp } from "react-icons/io5";
import { MdConfirmationNumber, MdDevices, MdFormatListNumbered, MdPayments, MdPhoneIphone, MdSecurity } from "react-icons/md";
import { Button, Checkbox, FormControl, IconButton, MenuItem, Modal, Select, TextareaAutosize, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../Spinner";
import SpinnerImages from "../SpinnerImages";
import { FcMultipleDevices } from "react-icons/fc";
import { useGetAllStockCuentasQuery } from "../../store/services/cuentaService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Spinner2 from "../Spinner2";
import { FaAward, FaLink } from "react-icons/fa";
import debounce from 'lodash.debounce';
import { useCreateSocialOrderMutation, useGetAllSocialPlataformsQuery, useGetServiceByIdQuery } from "../../store/services/socialService";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import parse from 'html-react-parser';


const SocialServicesHome = ({ socket, userToken, user, UpdateUserById }) => {
  const [purchaseSocialService, SetPurchaseSocialService] = useState(
    {
      open: false,
      plataformId: "",
      preciofijo: 0,
      porcentajeaplciado: 0,
      speedseconds: 0,
      policy: "",
      logo: "",
      name: "",
      acept_terms: false,
      services: null,
      serviceId: "",
      servicetype: "",
      comments: "",
      link: "",
      urlexamplephone: "",
      urlexampledesktop: "",
      quantity: 0
    }
  )

  const navigate = useNavigate();

  const [service, setServiceId] = useState("")
  const [usdtToPen, setUsdtToPen] = useState(3.70);

  const { data: plataforms, isFetching } = useGetAllSocialPlataformsQuery();
  const { data: serviceId, isFetching: loadingservice, refetch } = useGetServiceByIdQuery({ serviceId: service });
  const [sendNewSocialOrder, response] = useCreateSocialOrderMutation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  var policyData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );


  useEffect(() => {
    if (socket) {
    }
  }, [])

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setUsdtToPen(data.rates.PEN);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };
    const interval = setInterval(fetchExchangeRate, 10000); // Fetch every 5 seconds
    fetchExchangeRate();
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  useEffect(async () => {
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [service]);

  const handleOpenPurchaseSocialService = (plataformId, logo, name, services) => {
    if (userToken) {
      SetPurchaseSocialService({ ...purchaseSocialService, open: !purchaseSocialService.open, plataformId: plataformId, logo: logo, name: name, services: services })
    } else {
      navigate('/login');
    }
  }

  const handleChangeService = (event) => {
    if (userToken) {
      purchaseSocialService.services && purchaseSocialService.services.find((service) => {
        if (Number(service.serviceId) === Number(event.target.value)) {
          SetPurchaseSocialService({
            ...purchaseSocialService,
            preciofijo: user?.reseller ? Number(service.preciofijoreseller) : Number(service.preciofijouser),
            porcentajeaplciado: Number(service.porcentajeadicional),
            speedseconds: Number(service.speedseconds),
            quantity: 0,
            link: "",
            comments: "",
            acept_terms: false,
            servicetype: service.servicetype,
            urlexampledesktop: service.urlexampledesktop,
            urlexamplephone: service.urlexamplephone
          })
          const rawContent = service.policy;
          const contentState = convertFromRaw(JSON.parse(rawContent));
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState)
        }
      })
      setServiceId(event.target.value)
    } else {
      navigate('/login');
    }
  };

  const validarURL = (miurl) => {
    try {
      new URL(miurl);
      return true;
    } catch (err) {
      return false;
    }
  }

  const handleSubmitNewOrderServiceSocial = e => {
    e.preventDefault()
    if (userToken) {
      var qty = Number(purchaseSocialService.quantity)
      var min = serviceId.service.min
      var max = serviceId.service.max
      var refill = serviceId.service.refill
      var cancel = serviceId.service.cancel
      var serviceid = Number(service)
      var constototal = Number((purchaseSocialService.quantity / 1000) * ((serviceId.service.rate * usdtToPen) * ((purchaseSocialService.porcentajeaplciado * 0.01) + 1)) + purchaseSocialService.preciofijo)
      var link = purchaseSocialService.link
      var Idplataform = purchaseSocialService.plataformId
      var comments = purchaseSocialService.comments
      var servicetype = purchaseSocialService.servicetype


      if (qty % 10 === 0) {
        if (Number(qty) >= min && Number(qty) <= max) {
          if (serviceid !== "") {
            if (link !== "") {
              if (validarURL(link)) {
                sendNewSocialOrder({ userId: user?.id, plataformId: Idplataform, qty: qty, serviceId: serviceid, constototal: constototal, link: link, usdtToPen: usdtToPen, refill: refill, cancel: cancel, comments: comments, servicetype: servicetype })
              } else {
                message(`ERROR: Link inválido`, 'error', 3)
              }
            } else {
              message(`ERROR: Link está vacio`, 'error', 3)
            }
          } else {
            message(`ERROR: Id de servicio vacio`, 'error', 3)
          }
        } else {
          message(`La cantidad mínima/máxima es ${min + '/' + max}`, 'error', 3)
        }
      } else {
        message(`la cantidad debe ser múltiplo de 10, ejemplo : 10, 20, ... 200, 500, ... 1000, 2000, etc`, 'error', 6)
      }
    } else {
      navigate('/login');
    }
  }


  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [response?.error?.data?.errors])

  useEffect(() => {
    if (response?.isSuccess) {
      message(response?.data?.message, 'success', 5)
      UpdateUserById(response?.data?.token)
      SetPurchaseSocialService({
        ...purchaseSocialService,
        open: false,
        plataformId: "",
        preciofijo: 0,
        porcentajeaplciado: 0,
        speedseconds: 0,
        policy: "",
        logo: "",
        name: "",
        comments: "",
        services: null,
        serviceId: "",
        servicetype: "",
        link: "",
        urlexamplephone: "",
        urlexampledesktop: "",
        quantity: 0
      })
      setServiceId("")
      navigate('/manage-account/usersocialorders');
    }

  }, [response?.isSuccess])

  const handleClosePurchaseSocialService = () => {
    setServiceId("")
    SetPurchaseSocialService({
      ...purchaseSocialService,
      open: !purchaseSocialService.open,
      plataformId: "",
      preciofijo: 0,
      porcentajeaplciado: 0,
      speedseconds: 0,
      policy: "",
      logo: "",
      name: "",
      acept_terms: false,
      services: null,
      serviceId: "",
      servicetype: "",
      link: "",
      urlexamplephone: "",
      urlexampledesktop: "",
      quantity: 0
    })
  }


  return isFetching ? (
    <div className="flex flex-wrap -mx-4 mb-10">
      {[1, 2, 3].map((item) => (
        <div
          className="w-4/12 w-full p-4"
          key={item}
        >
          <Skeleton>
            <Thumbnail height="150px" />
          </Skeleton>
        </div>
      ))}
    </div>
  ) : (
    
    plataforms?.plataforms.length > 0 && (
      <>
        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full 
       text-neutral-100 bg-blue-custom
        mb-3 text-center text-xl md:text-3xl pt-5 pb-5">
          COMPRAR SEGUIDORES, LIKES ...
        </div>

        <Toaster position="top-right" reverseOrder={true} />
        <div className="flex flex-wrap w-full pb-10">
          <Modal
            open={purchaseSocialService.open}
            onClose={handleClosePurchaseSocialService}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <div id="authentication-modal" className="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
              <div className="relative m-auto p-5 w-full md:w-9/12 bg-neutral-50 dark:bg-neutral-950 pb-12">
                <div className="flex inline-flex items-center">
                  <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                    <IconButton color="error" onClick={handleClosePurchaseSocialService}>
                      <IoCloseSharp
                        className="text-neutral-200"
                        size={28}
                      />
                    </IconButton>
                  </div>
                  {<LazyLoadImage
                    className="object-cover w-24 ml-5 h-24 z-30 rounded-full p-2"
                    alt={`No image category`}
                    effect="blur"
                    src={`/images/logos/${purchaseSocialService.logo}`}
                  />}
                  <div className="-ml-3">
                    <div className="pl-7 pr-2 rounded-lg text-neutral-900 dark:text-neutral-200 text-2xl uppercase">{purchaseSocialService.name}</div>
                  </div>
                </div>
                <div className="w-full md:w-12/12 p-3">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Servicios disponibles</label>
                  <div className="relative">
                    <FormControl fullWidth>
                      <Select
                        required
                        name="service"
                        className="flex items-center"
                        variant="outlined"
                        vvalue={service}
                        onChange={handleChangeService}
                      >
                        {
                          purchaseSocialService.services && purchaseSocialService.services.filter(fil => fil.enabled).map((service) => {
                            return <MenuItem value={service.serviceId} key={service._id}><LazyLoadImage width={25} height={25} src={`/images/logos/${purchaseSocialService.logo}`} className="mr-2 rounded-full" />{service.name}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {service !== "" ? !loadingservice ? serviceId &&
                  <form onSubmit={handleSubmitNewOrderServiceSocial}>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-5">

                      <div className="w-full md:w-12/12 p-3 bg-neutral-200 dark:bg-neutral-900">
                        <div className="w-full p-3 relative">
                          <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Link de la página, imagen, video ...</label>
                          <input autoFocus name="link" className="bg-neutral-50 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Link de la página, imagen, video, etc"
                            autoComplete="off"
                            required
                            value={purchaseSocialService.link}
                            type="text"
                            onChange={(e) => SetPurchaseSocialService({ ...purchaseSocialService, link: e.target.value })}
                          />
                          <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            <FaLink size={20} />
                          </div>
                        </div>

                        <div className="w-full p-3 relative">
                          <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Cantidad{purchaseSocialService.servicetype === 'customcomments' && ' de comentarios'}</label>
                          <input name="quantity" className="bg-neutral-50 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Cantidad"
                            autoComplete="off"
                            required
                            disabled={purchaseSocialService.servicetype !== 'default' ? true : false}
                            value={purchaseSocialService.quantity}
                            type="number"
                            onChange={(e) => SetPurchaseSocialService({ ...purchaseSocialService, quantity: e.target.value })}
                            min={serviceId && serviceId.service.min}
                            max={serviceId && serviceId.service.max}
                          />
                          <div class={`absolute text-neutral-900 dark:text-neutral-200 inset-y-4 ${purchaseSocialService.servicetype === 'customcomments' ? 'mt-[18px]':'mt-[-10px]'} ${purchaseSocialService.servicetype === 'customcomments' ? 'md:mt-[18px]':'md:mt-[6px]'} left-0 pl-5 flex items-center text-lg leading-5`}>
                            <MdConfirmationNumber size={20} />
                          </div>
                          <p class="mt-2 text-xs text-red-600 dark:text-red-500">{purchaseSocialService.servicetype !== 'default' ? '':'Cantidad debe ser múltiplo de 10 (ejemplo: 10, 20, 30, 40, ...)'}</p>
                        </div>
                        {
                          purchaseSocialService.servicetype === 'customcomments' &&
                          <div className="w-full md:w-12/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Comentarios (1 por línea)</label>
                            <div className="relative">
                              <FormControl fullWidth>
                                <TextareaAutosize
                                  required={purchaseSocialService.servicetype === 'customcomments' ? true : false}
                                  name="comments"
                                  minRows={10}
                                  className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                  variant="outlined"
                                  value={purchaseSocialService.comments}
                                  onChange={(e) => SetPurchaseSocialService({ ...purchaseSocialService, comments: e.target.value, quantity: e.target.value.split('\n').filter(linea => linea.trim() !== '').length })}
                                />
                              </FormControl>
                            </div>
                          </div>
                        }


                        <div className="w-auto p-3 relative">
                          <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Precio</label>
                          <input name="price" className="bg-neutral-50 p-[15px] pl-11 text-neutral-900 dark:text-neutral-200 text-3xl rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Precio"
                            autoComplete="off"
                            disabled
                            value={purchaseSocialService.quantity > 0 ? ((purchaseSocialService.quantity / 1000) * ((serviceId.service.rate * usdtToPen) * ((purchaseSocialService.porcentajeaplciado * 0.01) + 1)) + purchaseSocialService.preciofijo).toFixed(2) : 0}
                            type="text"
                          />
                          <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-8 left-0 pl-5 flex items-center text-2xl leading-5">
                            S/
                          </div>
                        </div>

                        <div className="w-full p-3 relative">
                          <label className="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Términos y condiciones del servicio</label>
                          <div class="flex items-center">
                            <Checkbox
                              required
                              checked={purchaseSocialService.acept_terms ? true : false}
                              onClick={() => SetPurchaseSocialService({ ...purchaseSocialService, acept_terms: !purchaseSocialService.acept_terms })}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#1d4ed8' } }}
                            />
                            {!purchaseSocialService.acept_terms && <span class="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-blue-500 left-5"></span>}
                            <label for="link-checkbox" class="ml-1 text-sm font-medium text-neutral-900 dark:text-neutral-300">Eh leído la descripción y estoy de acuerdo con los
                              términos y condiciones del servicio.</label>

                          </div>
                        </div>


                        <div className="w-full p-3 relative">
                          <Button
                            disabled={(response.isLoading || !purchaseSocialService.acept_terms) ? true : false}
                            sx={{
                              "&.Mui-disabled": {
                                background: "#525252",
                                color: "#a3a3a3"
                              }
                            }}
                            type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained" color="primary" startIcon={response.isLoading ? <Spinner /> : <BsCash size={24} className="mr-2" />}>
                            {response.isLoading ? 'Procesando...' : 'PAGAR'}</Button>
                        </div>
                      </div>
                      <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full md:w-12/12 p-3 bg-neutral-200 dark:bg-neutral-900 text-xs text-neutral-900 dark:text-neutral-200">
                          <div className="bg-neutral-50 dark:bg-neutral-950 p-3 rounded">
                            <div className="w-full text-red-600 dark:text-red-500 font-bold">Cantidad mínimo/máximo</div>
                            <div className="w-full mt-3">{serviceId.service.min} / {serviceId.service.max}</div>
                          </div>

                          <div className="bg-neutral-50 dark:bg-neutral-950 p-3 rounded">
                            <div className="w-full text-red-600 dark:text-red-500 font-bold">Categoría</div>
                            <div className="w-full mt-3">{serviceId.service.category}</div>
                          </div>

                          <div className="bg-neutral-50 dark:bg-neutral-950 p-3 rounded">
                            <div className="w-full text-red-600 dark:text-red-500 font-bold">Descripción</div>
                            <div className="w-full mt-3">{parse(policyData)}</div>
                          </div>

                          <div className="bg-neutral-50 dark:bg-neutral-950 p-3 rounded">
                            <div className="w-full text-red-600 dark:text-red-500 font-bold">Tiempo aproximado</div>
                            <div className="w-full mt-3">{convertirsegundos(purchaseSocialService.speedseconds)}</div>
                          </div>

                        </div>

                        <div className="mt-3 grid grid-cols-1 md:grid-cols-1 gap-4 w-full md:w-12/12 p-3 bg-neutral-200 dark:bg-neutral-900 text-xs text-neutral-900 dark:text-neutral-200">
                          <div className="bg-neutral-50 dark:bg-neutral-950 p-3 rounded">
                            <div className="w-full text-yellow-600 dark:text-yellow-500 font-bold">Link ejemplo en celular</div>
                            <div className="w-full mt-3">{purchaseSocialService.urlexamplephone}</div>
                          </div>

                          <div className="bg-neutral-50 dark:bg-neutral-950 p-3 rounded">
                            <div className="w-full text-yellow-600 dark:text-yellow-500 font-bold">Link ejemplo en computadora</div>
                            <div className="w-full mt-3">{purchaseSocialService.urlexampledesktop}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                  :
                  <div className="mt-5 flex items-center justify-center text-neutral-900 dark:text-neutral-200"><Spinner />Cargando información del servicio</div> : ''
                }
              </div>
            </div>
          </Modal>
          <div className="w-full grid grid-cols-2 md:grid-cols-5 gap-4 mb-4">
            {
              !isFetching && plataforms?.plataforms.map((plataforms, index) => {
                return (
                  <div onClick={() => !plataforms.enabled ? handleOpenPurchaseSocialService() : handleOpenPurchaseSocialService(plataforms._id, plataforms.sublogo, plataforms.name, plataforms.subcategory)} key={index} className={`relative w-44 md:w-52 pb-3 transition-all duration-500 hover:-translate-y-[8px] cursor-pointer`}>
                    <div className="relative flex items-center justify-center rounded m-auto mt-[8px] w-44 h-72 md:w-52 md:h-80">
                      <LazyLoadImage
                        className="w-44 h-72 md:w-52 md:h-80 m-auto rounded z-10"
                        alt={`No image ${plataforms.name}`}
                        effect="blur"
                        src={`/images/logos/${plataforms.logo}`}
                      />
                    </div>

                    <div class="absolute top-0 right-0 ml-3 p-2 bg-green-600 bg-opacity-50 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                      <span class="flex rounded-full bg-green-600 px-2 py-1 text-sm">Nuevo</span>
                    </div>

                    <div style={{ background: `${plataforms.themecolor}` }} className="pt-3 pb-3 pl-1 pr-1 text-neutral-200 text-sm md:text-base text-center uppercase font-bold rounded-sm [text-shadow:2px_2px_8px_var(--tw-shadow-color)] shadow-black">
                      {plataforms.name}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>

    )
  );
};

export default SocialServicesHome;
