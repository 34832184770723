import { configureStore } from "@reduxjs/toolkit";
import authService from "./services/authService";
import categoryService from "./services/categoryService";
import cuentaService from "./services/cuentaService";
import paymentService from "./services/paymentService";
import historyService from "./services/historyService";
import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import rechargeReducer from "./reducers/rechargeReducer";
import userOrdersService from "./services/userOrdersService";
import userService from "./services/userService";
import ventasService from "./services/ventasService";
import gcventasService from "./services/gcventasService";
import tokenService from "./services/tokenService";
import emailService from "./services/emailService";
import giftcardService from "./services/giftcardService";
import whatsappService from "./services/whatsappService";
import socialService from "./services/socialService";
import categoryReducer from "./features/categorySlice";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [userService.reducerPath]: userService.reducer,
    [cuentaService.reducerPath]: cuentaService.reducer,
    [giftcardService.reducerPath]: giftcardService.reducer,
    [emailService.reducerPath]: emailService.reducer,
    [tokenService.reducerPath]: tokenService.reducer,
    [cuentaService.reducerPath]: cuentaService.reducer,
    [ventasService.reducerPath]: ventasService.reducer,
    [gcventasService.reducerPath]: gcventasService.reducer,
    [paymentService.reducerPath]: paymentService.reducer,
    [historyService.reducerPath]: historyService.reducer,
    [userOrdersService.reducerPath]: userOrdersService.reducer,
    [whatsappService.reducerPath]: whatsappService.reducer,
    [socialService.reducerPath]: socialService.reducer,
    authReducer: authReducer,
    globalReducer: globalReducer,
    rechargeReducer, rechargeReducer,
    categoryReducer: categoryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userService.middleware,
      tokenService.middleware,
      emailService.middleware,
      categoryService.middleware,
      giftcardService.middleware,
      ventasService.middleware,
      gcventasService.middleware,
      cuentaService.middleware,
      paymentService.middleware,
      historyService.middleware,
      userOrdersService.middleware,
      whatsappService.middleware,
      socialService.middleware
    ]),
});
export default Store;
