import { currencyFormat } from "../../utils/Funciones.js";
import { IoCash, IoLocation, IoLogIn, IoMenu, IoNotifications, IoPerson, IoPersonAddSharp } from "react-icons/io5";
import { Fragment, useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout, setOfflineUserToken } from "../../store/reducers/authReducer";
import { AiOutlineLogout } from "react-icons/ai";
import { FaArrowLeft, FaCoins, FaMoon, FaPhone, FaRegistered, FaSearch, FaShip, FaSun, FaUser } from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import AnimatedNumber from "react-animated-number";
import { MdLogin, MdMail, MdMenu, MdPhoneIphone, MdShoppingBag, MdVerified } from "react-icons/md";
import { BsArrowLeft, BsCashCoin, BsCheck, BsCheck2, BsMenuApp } from "react-icons/bs";
import Spinner from "../Spinner";
import { useCreateOfflineUserTokenMutation } from "../../store/services/tokenService.js";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Transition } from '@headlessui/react';
import toast from "react-hot-toast";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Nav = ({
  socket,
  openCloseSidebar,
  userToken,
  category,
  CategoryDataIsLoading,
  userById,
  loadinguserbyid,
  configuration,
  loadingconfiguration,
  handleOpenCategory
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewMenu, SetViewMenu] = useState(false)
  const [CurrentNumber, SetCurrentNumber] = useState(0)
  const [openSearch, setOpenSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [crearOfflineUserToken, ResponseCrearOfflineUserToken] = useCreateOfflineUserTokenMutation()
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('color-theme') ? localStorage.getItem('color-theme') : 'dark');

  const changeTheme = () => {
    if (localStorage.getItem('color-theme') === 'light') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      localStorage.setItem('color-theme', 'dark');
      setTheme('dark')
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
      setTheme('light')
    }
  }

  const refreshPage = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    if (!loadinguserbyid && userToken) {
      SetCurrentNumber(Number(userById.credits_purchased - userById.credits_used))
    }
  }, [CurrentNumber])
  const OpenMenu = () => {
    SetViewMenu(!viewMenu)
  }

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(500);

      if (active) {
        const newArray = []
        category && category.map((cat) => {
          cat.subcategory.map((subcat) => {
            let newObejct = {}
            newObejct.categoryId = cat._id
            newObejct.subcategory = cat.subcategory
            newObejct.categoryname = cat.name
            newObejct.subcategoryname = subcat.name
            newObejct.type = cat.type
            newObejct.rating = subcat.rating
            newObejct.logo = cat.logo
            newObejct.sublogo = cat.sublogo
            newObejct.themecolor = cat.themecolor
            newObejct.description = cat.description
            newArray.push(newObejct)
          })
        })
        setOptions(newArray)
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const HandleOpen = (categoryId, subcategory, categoryname, logo, sublogo, type, description) => {
    setOpen(false)
    setOpenSearch(!openSearch)
    handleOpenCategory(categoryId, subcategory, categoryname, logo, sublogo, type, description)
  }

  const OpenSearch = () => {
    setOpenSearch(!openSearch)
  }

  const onLogout = async () => {
    if (userToken) {
      await crearOfflineUserToken({ socket: socket.id })
    }
  }

  useEffect(async () => {

    if (ResponseCrearOfflineUserToken.isSuccess) {
      localStorage.setItem('offlineUserToken', ResponseCrearOfflineUserToken?.data?.token);
      dispatch(setOfflineUserToken(ResponseCrearOfflineUserToken?.data?.token))
      dispatch(logout('userToken'))
      socket.emit('logout')
      navigate('/')
    }
  }, [ResponseCrearOfflineUserToken.isSuccess])

  return (
    <>
      <div className={`fixed top-0 z-40 flex items-center transtion-all duration-800 w-full h-14 bg-white dark:bg-black text-neutral-900 dark:text-neutral-100`} bis_skin_checked="1">
        <div className="flex items-center h-14 w-full md:w-9/12 m-auto p-2">
          <div className="w-[calc(100%-68px)] flex flex-wrap gap-0">
            <div className="w-6/12 md:w-5/12 flex items-center text-green-600 dark:text-green-400">
              <div className="h-full w-[24px] flex items-center justify-center">
                <MdVerified className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center md:grid">
                <div className="w-full text-xs font-bold">OFFICIAL RETAILER</div>
                <div className="w-full text-[10px] hidden md:flex">EVERY DIGITAL CODE IS OFFICIALLY SOURCED</div>
              </div>
            </div>

            <div className="w-6/12 md:w-2/12 flex items-center">
              <div className="h-full w-[24px] flex items-center justify-center">
                <MdPhoneIphone className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center">
                <div className="w-full text-xs">+51 994 201 155</div>
              </div>
            </div>

            <div className="w-6/12 md:w-2/12 flex items-center">
              <div className="h-full w-[24px] flex items-center justify-center">
                <MdMail className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center">
                <div className="w-full text-xs">admin@cinedar.com</div>
              </div>
            </div>

            <div className="w-6/12 md:w-2/12 flex items-center">
              <div className="h-full w-[24px] flex items-center justify-center">
                <IoLocation className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center">
                <div className="w-full text-xs">Lima - Perú</div>
              </div>
            </div>
          </div>

          <div className="md:block hidden">
            <div className="flex items-center h-[65px]">

              <div className="w-[76px] flex flex-wrap -mt-[80px] cursor-pointer hover:mt-[5px] transition-all duration-500">
                <div className="w-full flex items-center justify-center">
                  <a href="https://www.facebook.com/condezodarvin" target="_blank" class="h-10"><img
                    src="https://cinedar.com/images/redes/facebook.png" class="h-10" /></a>
                </div>
                <div className="text-neutral-100 w-full h-8 bg-blue-900 flex items-center justify-center text-xs mt-1 font-semibold">
                  Facebook
                </div>

              </div>

              <div className="w-[76px] flex flex-wrap -mt-[80px] cursor-pointer hover:mt-[5px] transition-all duration-500">
                <div className="w-full flex items-center justify-center">
                  <a href="https://instagram.com/darvin.condezo" target="_blank" class="h-10"><img
                    src="https://cinedar.com/images/redes/instragram.png" class="h-10" /></a>
                </div>
                <div className="text-neutral-100 w-full h-8 bg-pink-700 flex items-center justify-center text-xs mt-1 font-semibold">
                  Instagram
                </div>

              </div>


              <div className="w-[76px] flex flex-wrap -mt-[80px] cursor-pointer hover:mt-[5px] transition-all duration-500">
                <div className="w-full flex items-center justify-center">
                  <a href="https://www.tiktok.com/@darvincondezo" target="_blank" class="h-10"><img
                    src="https://cinedar.com/images/redes/tiktok.png" class="h-10" /></a>
                </div>
                <div className="text-neutral-100 w-full h-8 bg-neutral-900 flex items-center justify-center text-xs mt-1 font-semibold">
                  Tik Tok
                </div>

              </div>

            </div>

          </div>

          <div className="flex items-center w-[68px]">
            <LazyLoadImage
              className="w-[28px] h-[20px] mr-3"
              alt={'No image es'}
              effect="blur"
              src={`/images/flags/4x3/es.svg`}
            />
            <LazyLoadImage
              className="w-[28px] h-[20px]"
              alt={'No image us'}
              effect="blur"
              src={`/images/flags/4x3/us.svg`}
            />
          </div>
        </div>
      </div>
      <nav className={`fixed top-14 transtion-all duration-800 z-40 w-full bg-gradient-blue-custom shadow-lg shadow-neutral-950`}>

        <div className="flex items-center w-full md:w-9/12 m-auto h-24 pr-2 pl-2">

          {window.location.href.includes('manage-account') &&
            <button onClick={openCloseSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button"
              className="absolute left-0 top-0 z-40 md:hidden">
              <span class="sr-only">Open sidebar</span>
              <HiMenuAlt2 size={32} className={'text-neutral-100'} />
            </button>
          }

          <div className="flex items-center w-[165px] h-24">
            <div className="flex items-center cursor-pointer">
              {
                !loadingconfiguration ? <Link to="/" className="">
                  <LazyLoadImage
                    onClick={ window.location.pathname === '/' && refreshPage}
                    className="w-[165px] h-[70px]"
                    alt={'No image logo'}
                    src={`/images/logos/${configuration && configuration.logo}`}
                  />
                </Link> : <Spinner />
              }
            </div>
          </div>

          <div className="flex items-center justify-end w-[calc(100%-165px)]">

            <div className={`flex items-center justify-end`}>

              {
                openSearch && <div className="bg-opacity-0 fixed inset-0 z-30" onClick={() => setOpenSearch(!openSearch)}></div>
              }

              <div className={`relative ${window.location.href.includes('manage-account') ? 'hidden' : userToken ? 'mr-2 md:mr-2' : 'mr-2 md:mr-2'}`}>
                <div onClick={() => setOpenSearch(!openSearch)}
                  className={`flex items-center justify-center rounded-sm ${userToken ? 'w-9 md:w-14' : 'w-12 md:w-14'} h-14 bg-none md:bg-blue-800 cursor-pointer`}>
                  <FaSearch size={24} className="text-white" />
                </div>
                <Transition
                  show={openSearch}
                  enter="transition ease-out duration-200 transform"
                  enterFrom="opacity-0 scale-0"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-200 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-0"
                >
                  {/* Contenido del menú desplegable */}
                  <div className="z-40 absolute w-80 origin-top-left divide-y divide-neutral-100 rounded-md focus:outline-none">
                    <Autocomplete
                      id="search-general"
                      style={{
                        color: '#c2c2c2',
                        borderRadius: 5, marginTop: -3
                      }}
                      className="w-full focus:outline-none focus:ring-0"
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.subcategoryname === value.subcategoryname}
                      getOptionLabel={(option) => option.subcategoryname}
                      options={options}
                      loading={loading}
                      renderOption={(props, option) => (
                        <Box style={{ fontFamily: "Jost, sans-serif" }} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}
                          onClick={() => HandleOpen(option.categoryId, option.subcategory, option.categoryname, option.logo, option.sublogo, option.type, option.description)}>
                          <div className="w-full flex items-center">
                            <div className="w-[55px] flex items-center justify-center">
                              <LazyLoadImage
                                className="rounded-full w-12 h-12 object-cover"
                                alt={'No image'}
                                effect="blur"
                                src={`/images/logos/${option.sublogo}`}
                              />
                            </div>
                            <div className="w-[calc(100%-55px)] p-2 text-base">
                              <div className="truncate ...">
                                {option.subcategoryname}
                              </div>
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Buscar en la tienda..."
                          sx={{
                            '&.MuiFormControl-root.MuiTextField-root': {
                              left: -90
                            }
                          }}
                          className="bg-neutral-50 text-neutral-900"
                          InputLabelProps={{
                            style: {
                              color: '#c2c2c2', fontSize: 15
                            }
                          }}
                          inputProps={{
                            style: {
                              background: '#171717',
                              color: '#ADB7BA',
                              fontSize: 15
                            },
                            maxLength: 50
                          }}
                          style={{
                            width: '100%', color: '#c2c2c2',
                            borderRadius: 5
                          }}
                          {...params}

                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </Transition>
              </div>

              <div className={`flex items-center justify-center rounded-sm ${userToken ? 'w-9 md:w-14' : 'w-12 md:w-14'} h-14 bg-none md:bg-blue-800 mr-2 cursor-pointer`}>
                {
                  theme === 'dark' ?
                    <div onClick={changeTheme} className={`flex items-center justify-center ${userToken ? 'w-9 md:w-14' : 'w-12 md:w-14'} h-14`}
                      sx={{ background: '#172554' }}>
                      <FaMoon size={24} className="cursor-pointer" color="white" />
                    </div>
                    :
                    <div onClick={changeTheme} className={`flex items-center justify-center ${userToken ? 'w-9 md:w-14' : 'w-12 md:w-14'} h-14`}
                      sx={{ background: '#172554' }}>
                      <FaSun size={24} className="cursor-pointer" color="yellow" />
                    </div>

                }
              </div>

              <div className="">
                {
                  (userToken && userById) ?
                    <div className="flex items-center justify-center rounded-sm w-20 h-14 cursor-pointer text-center text-white">
                      {
                        CurrentNumber !== Number(userById.credits_purchased - userById.credits_used) ?
                          <AnimatedNumber
                            component="text"
                            initialValue={CurrentNumber}
                            value={userById.credits_purchased - userById.credits_used}
                            stepPrecision={0}
                            style={{
                              transition: "0.8s ease-out",
                              fontSize: 13,
                              transitionProperty: "background-color, color, opacity"
                            }}
                            duration={2000}
                            formatValue={n => 'S/. ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                          /> : currencyFormat(userById.credits_purchased - userById.credits_used)
                      }
                    </div>
                    :
                    <Link to="/manage-account/recharge" className={`flex items-center justify-center rounded-sm ${userToken ? 'w-9 md:w-14' : 'w-12 md:w-14'} h-14 bg-none md:bg-blue-800 mr-2 cursor-pointer`}>
                      <FaCoins size={24} className="flex text-white" />
                    </Link>
                }


              </div>
              <div>
                {
                  (userToken && userById) ?
                    loadinguserbyid ? <Spinner /> :
                      <div className="flex items-center justify-center rounded-sm w-14 h-14 cursor-pointer">
                        {
                          viewMenu && <div className="bg-opacity-0 fixed inset-0 z-30" onClick={OpenMenu}></div>
                        }

                        <div className="relative w-14 h-14">
                          <div onClick={OpenMenu} className="rounded-full w-14 h-14 cursor-pointer">
                            {
                              userById && userById.photo ?
                                <LazyLoadImage
                                  className="w-14 h-14 rounded-full object-cover"
                                  alt={'No image'}
                                  effect="blur"
                                  src={`/images/photos/${userById && userById.photo}`}
                                /> :
                                <Spinner />
                            }
                          </div>
                          <Transition
                            show={viewMenu}
                            enter="transition ease-out duration-200 transform"
                            enterFrom="opacity-0 scale-0"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-0"
                          >
                            {/* Contenido del menú desplegable */}
                            <div className="z-40 absolute right-0 w-56 mt-2 origin-top-right bg-white border border-neutral-200 divide-y divide-neutral-300 rounded-md shadow-lg focus:outline-none">

                              <Link to="/manage-account/profile" className="flex items-center w-full h-14 text-base transition duration-75 hover:bg-neutral-100 active:bg-neutral-200">
                                <div className="flex items-center  h-14 transition-all duration-500 text-neutral-900 p-2 mr-2"><IoPerson size={20} className="mr-2" />Mi cuenta</div>
                              </Link>

                              <Link to="/manage-account/userorders" className="flex items-center w-full h-14 text-base transition duration-75 hover:bg-neutral-100 active:bg-neutral-200">
                                <div className="flex items-center h-14 transition-all duration-500 p-2 mr-2 text-neutral-900"><MdShoppingBag size={20} className="mr-2" />Mis pedidos</div>
                              </Link>

                              <Link to="/manage-account/recharge" className="flex items-center w-full h-14 text-base transition duration-75 hover:bg-neutral-100 active:bg-neutral-200">
                                <div className="flex items-center h-14 transition-all duration-500 p-2 mr-2 text-neutral-900"><FaCoins size={20} className="mr-2" />Recargar</div>
                              </Link>

                              <div onClick={onLogout} className="flex items-center h-14 transition-all duration-500 p-2 mr-2 text-red-600 hover:bg-neutral-100 active:bg-neutral-200"><AiOutlineLogout size={20} className="mr-2" />Salir</div>

                            </div>
                          </Transition>
                        </div>

                      </div> :
                    <Link to="/login" className={`flex items-center justify-center rounded-sm ${userToken ? 'w-9 md:w-14' : 'w-12 md:w-14'} h-14 bg-none md:bg-blue-800 cursor-pointer`}>
                      <FaUser size={24} className="flex text-white" />
                    </Link>
                }
              </div>

            </div>
          </div>
        </div>
      </nav >

    </>
  );
};
export default Nav;

