import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import ScreenHeader from "../../components/ScreenHeader";
import { Toaster } from 'react-hot-toast';
import Wrapper from "./Wrapper"
import { Button, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { BsPlusCircleDotted } from "react-icons/bs";
import { IoChevronDown, IoSave } from "react-icons/io5";
import { message } from "../../utils/Funciones.js";
import { useCreateGiftCardMutation } from "../../store/services/giftcardService";
import moment from 'moment';
import Spinner from "../../components/Spinner";
import { useGetAllCategoriesGiftCardQuery } from "../../store/services/categoryService";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CreateGiftCard = ({
    SECRET_KEY,
    socket,
    configuration,
    loadingconfiguration,
    categories,
    categoryIsLoading
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [currentSubCategory, SetcurrentSubCategory] = useState(null)

    const [state, setState] = useState({
        categoryId: "",
        subcategoryId: "",
        code: "",
    });

    const HandleChange = (e) => {
        if (e.target.name === 'categoryId') {
            setState({ ...state, [e.target.name]: "", subcategoryId: "" })
            SetcurrentSubCategory(null)
            !categoryIsLoading && categories.filter(fil => fil.type === 'GiftCard').find((cat) => {
                if (cat._id === e.target.value) {
                    SetcurrentSubCategory(cat.subcategory)
                    setState({ ...state, [e.target.name]: e.target.value, subcategoryId: "" })
                }
            })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }

    }

    const [saveGiftCard, response] = useCreateGiftCardMutation();

    const submitGiftCard = e => {
        e.preventDefault();
        saveGiftCard(state);
    }

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                message(err.msg, 'error', 4)
            })
        }
    }, [response?.error?.data?.errors])

    useEffect(() => {
        if (response?.isSuccess) {

            socket.emit('nuevaGC', response?.data?.giftcards, response?.data?.subcategoryId)
            message(response?.data?.message, 'success', 5)
            navigate('/admin/giftcards')
        }
    }, [response?.isSuccess])

    return (
        <Wrapper socket={socket} configuration={configuration}
            loadingconfiguration={loadingconfiguration}>
            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <BsPlusCircleDotted size={32} style={{ marginRight: 5 }} />
                        NUEVA GIFT CARD
                    </div>
                </div>
            </ScreenHeader>

            <div className="flex flex-wrap -mx-3 mt-[70px]">

                <form className="w-full xl:w-8/12 p-3" onSubmit={submitGiftCard}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Categoria</label>
                            <div className="relative">
                                <FormControl fullWidth>
                                    <Select
                                        required
                                        name="categoryId"
                                        className="flex items-center"
                                        variant="outlined"
                                        value={state.categoryId}
                                        onChange={HandleChange}
                                    >
                                        {!categoryIsLoading && categories.filter(fil => fil.type === 'GiftCard').map(cat => (
                                            <MenuItem value={cat._id} key={cat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${cat.sublogo}`} className="mr-2 rounded-full" />{cat.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Sub Categoria</label>
                            <div className="relative">
                                <FormControl fullWidth>
                                    <Select
                                        required
                                        name="subcategoryId"
                                        className="flex items-center"
                                        variant="outlined"
                                        value={state.subcategoryId}
                                        onChange={HandleChange}
                                    >
                                        {currentSubCategory && [...Object.freeze(currentSubCategory)].sort((a, b)=> a.created_at - b.created_at).map(cat => (
                                            <MenuItem value={cat._id} key={cat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${cat.logo}`} className="mr-2 rounded-full" />{cat.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="w-full md:w-12/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Códigos</label>
                            <textarea name="code" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Códigos"
                                autoComplete="off"
                                required
                                type="text"
                                value={state.code}
                                onChange={HandleChange}
                            />
                            <p class="mt-2 text-xs text-red-600 dark:text-red-500">Puedes ingresar los códigos separados por una coma, ejemplo: ABC1,ABC2,ABC3, ...</p>
                        </div>
                    </div>
                    <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                        <Button
                            type="submit"
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained" color="primary"
                            disabled={response.isLoading ? true : false}
                        >
                            {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                            {response.isLoading ? 'Guardando...' : 'registrar giftcards'}
                        </Button>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}
export default CreateGiftCard;