import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import categoryService from "./services/categoryService";
import toast, { } from "react-hot-toast";
import { message } from "../../utils/Funciones";

const initialState = {
    responseSuccessCreate: null,
    responseSuccessGet: null,
    responseSuccessUpdateCategory: null,
    responseSuccessUpdateSubCategory: null,
    responseSuccessDeleteCategory: null,
    responseSuccessDeleteSubCategory: null,
    categories: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalStoreValue: 0
};

export const createCategory = createAsyncThunk(
    "category/createCategory",
    async (formData, thunkAPI) => {
        try {
            return await categoryService.createCategory(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(msg);
        }
    }
);

export const getCategories = createAsyncThunk(
    "category/getCategories",
    async (_, thunkAPI) => {
        try {
            return await categoryService.getCategories();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(msg);
        }
    }
);

export const updateCategory = createAsyncThunk(
    "category/updateCategory/category",
    async (formData, thunkAPI) => {
        try {
            return await categoryService.updateCategory(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(msg);
        }
    }
);

export const updateSubCategory = createAsyncThunk(
    "category/updateSubCategory/subcategory",
    async (formData, thunkAPI) => {
        try {
            return await categoryService.updateSubCategory(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(msg);
        }
    }
);

export const deleteCategory = createAsyncThunk(
    "category/deleteCategory",
    async (id, thunkAPI) => {
        try {
            return await categoryService.deleteCategory(id);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(msg);
        }
    }
);

export const deleteSubCategory = createAsyncThunk(
    "category/deleteSubCategory/deleteSubCategory",
    async (ids, thunkAPI) => {
        try {
            return await categoryService.deleteSubCategory(ids);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            const msg =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(msg);
        }
    }
);

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.responseSuccessCreate = null
            state.responseSuccessGet = null
            state.responseSuccessUpdateCategory = null
            state.responseSuccessUpdateSubCategory = null
            state.responseSuccessDeleteCategory = null
            state.responseSuccessDeleteSubCategory = null
        },

        CALC_STORE_CATEGORY_VALUE(state, action) {
            const categories = action.payload;
            const array = [];
            categories.map((item) => {
                const { price, quantity } = item;
                const categoryValue = price * quantity;
                return array.push(categoryValue);
            });
            const totalValue = array.reduce((a, b) => {
                return a + b;
            }, 0);
            state.totalStoreValue = totalValue;
        },

        UPDATE_CATEGORY_FROM_SOCKET(state, action) {

            if (action.payload.action === 'nuevacuenta') {

                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.categoryId) {
                        return {
                            ...cat,
                            subcategory: action.payload.newData
                        }
                    }
                    return cat
                })

                state.categories = newStateCategory

            } else if (action.payload.action === 'deletecuenta') {

                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.categoryId) {
                        return {
                            ...cat,
                            subcategory: action.payload.newData
                        }
                    }
                    return cat
                })

                state.categories = newStateCategory

            } else if (action.payload.action === 'nuevopedido_streaming') {

                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.categoryId) {
                        return {
                            ...cat,
                            subcategory: action.payload.newData
                        }
                    }
                    return cat
                })

                state.categories = newStateCategory
            } else if (action.payload.action === 'nuevopedido_giftcard') {
                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.categoryId) {
                        return {
                            ...cat,
                            subcategory: action.payload.newData
                        }
                    }
                    return cat
                })
                state.categories = newStateCategory
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCategory.pending, (state) => {
                //state.isLoading = true;
            })
            .addCase(createCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.responseSuccessCreate = action.payload;
                if (action.payload.type === 'Streaming' || action.payload.type === 'GiftCard') {
                    //const Arraysubcategory = state.categories.filter(fil => action.payload.newStateData.categoryId === fil._id)
                    //Arraysubcategory.push(action.payload.newStateData.Objsubcategory)
                    console.log(action.payload.newStateData.newData)
                    const newStateCategory = state.categories.map((cat) => {
                        if (cat._id === action.payload.newStateData.categoryId) {
                            cat.subcategory.push(action.payload.newStateData.newData)
                        }
                        return cat
                    })
                    state.categories = newStateCategory
                } else {
                    state.categories.push(action.payload.newcategory);
                }
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(getCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.categories = action.payload;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                message(action.payload, 'success', 5)
            })



            .addCase(updateCategory.pending, (state) => {
                //state.isLoading = true;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.responseSuccessUpdateCategory = action.payload;
                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.newStateData.categoryId) {
                        return {
                            ...cat,
                            name: action.payload.newStateData.newData.name,
                            logo: action.payload.newStateData.newData.logo,
                            sublogo: action.payload.newStateData.newData.sublogo,
                            themecolor: action.payload.newStateData.newData.themecolor,
                            description: action.payload.newStateData.newData.description,
                            last_update: action.payload.newStateData.newData.last_update
                        }
                    }
                    return cat
                })
                state.categories = newStateCategory
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })



            .addCase(updateSubCategory.pending, (state) => {
                //state.isLoading = true;
            })
            .addCase(updateSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.responseSuccessUpdateSubCategory = action.payload;
                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.newStateData.categoryId) {
                        cat.subcategory = action.payload.newStateData.newData
                    }
                    return cat
                })
                state.categories = newStateCategory
            })
            .addCase(updateSubCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })




            .addCase(deleteCategory.pending, (state) => {
                //state.isLoading = true;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.responseSuccessDeleteCategory = action.payload;
                state.categories = state.categories.filter(fil => fil._id !== action.meta.arg)
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })

            .addCase(deleteSubCategory.pending, (state) => {
                //state.isLoading = true;
            })
            .addCase(deleteSubCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.responseSuccessDeleteSubCategory = action.payload;
                const newStateCategory = state.categories.map((cat) => {
                    if (cat._id === action.payload.newStateData.categoryId) {
                        cat.subcategory = action.payload.newStateData.newData
                    }
                    return cat
                })
                state.categories = newStateCategory
            })
            .addCase(deleteSubCategory.rejected, (state, action) => {
                console.log(action)
                state.isLoading = false;
                state.isError = true;
            })
    },
});

export const { CALC_STORE_CATEGORY_VALUE, UPDATE_CATEGORY_FROM_SOCKET, SET_TO_DEFAULT_RESPONSE } = categorySlice.actions;

export default categorySlice.reducer;

