import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Toaster } from 'react-hot-toast';
import ScreenHeader from "../../components/ScreenHeader"
import Wrapper from "./Wrapper"
import { IoEye, IoEyeOff, IoSave } from 'react-icons/io5';
import { Button, IconButton, TextField } from "@mui/material";
import { BsPlusCircleDotted } from "react-icons/bs";
import { MuiTelInput } from "mui-tel-input";
import { useCreateMutation } from "../../store/services/userService";
import { message } from "../../utils/Funciones.js";
import Spinner from "../../components/Spinner";

const CreateUser = ({
    socket,
    configuration,
    loadingconfiguration
}) => {
    const [state, Setstate] = useState({
        name: '',
        email: '',
        password: '',
        countrycode: '',
        phonenumber: '',
    });

    const [VerPassword, SetVerPassword] = useState(false);

    const onChange = (e) => {
        Setstate({ ...state, [e.target.name]: e.target.value });
    };

    const onChangePhone = (newValue, info) => {
        Setstate({ ...state, ['countrycode']: info.countryCode, ['phonenumber']: newValue });
    };

    const [createUser, response] = useCreateMutation();
    const onSubmitNewUser = e => {
        e.preventDefault();
        createUser(state);
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (response.isSuccess) {
            message(response?.data?.msg, 'success', 5)
            navigate('/admin/users');
        }
    }, [response.isSuccess])

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                message(err.msg, 'error', 4)
            })
        }
    }, [response?.error?.data?.errors])

    return (
        <Wrapper socket={socket} configuration={configuration}
            loadingconfiguration={loadingconfiguration}>
            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <BsPlusCircleDotted size={32} style={{ marginRight: 5 }} />
                        NUEVO USUARIO
                    </div>
                </div>
            </ScreenHeader>
            <div className="flex flex-wrap -mx-3 mt-[70px]">
                <form className="w-full xl:w-8/12 p-3" onSubmit={onSubmitNewUser}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre del usuario</label>
                            <input autoFocus name="name" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Nombre del usuario"
                                autoComplete="off"
                                required
                                value={state.name}
                                onChange={onChange}
                                type="text"
                            />
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Correo</label>
                            <input name="email" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Correo"
                                autoComplete="off"
                                required
                                value={state.email}
                                onChange={onChange}
                                type="email"
                            />
                        </div>

                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password</label>
                            <input name="password" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Password"
                                autoComplete="off"
                                required
                                value={state.password}
                                onChange={onChange}
                                type={VerPassword ? 'text' : 'password'}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                {<IconButton color='primary' onClick={() => SetVerPassword(!VerPassword)}>
                                    {VerPassword ? <IoEyeOff color='#c2c2c2' /> : <IoEye color='#c2c2c2' />}
                                </IconButton>}
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Numero celular</label>
                            <MuiTelInput
                                inputProps={{ className: "text-neutral-900 dark:text-neutral-200" }}
                                value={state.phonenumber}
                                defaultCountry={'PE'}
                                onChange={onChangePhone}
                                className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            />
                        </div>

                        <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                            <button
                                type="submit"
                                class="flex w-full disabled:opacity-30 justify-center rounded-md bg-blue-600 px-3 py-5 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                disabled={response.isLoading ? true : false}
                            >
                                {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                {response.isLoading ? 'Guardando...' : 'CREAR CUENTA'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}
export default CreateUser