import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { motion } from "framer-motion"
import { deleteOfflineUserToken, setUserToken } from "../../../store/reducers/authReducer";
import Nav from "../../../components/home/Nav";
import { useForgotpasswordMutation, useUserLoginMutation } from "../../../store/services/authService";
import { useForm } from "../../../hooks/Form";
import toast, { Toaster } from "react-hot-toast";
import { Button, IconButton, TextField } from "@mui/material";
import { FaKey, FaUserLock } from "react-icons/fa";
import { MdMail, MdRequestPage } from "react-icons/md";
import { IoCloseSharp, IoEye, IoEyeOff, IoFingerPrint, IoLogIn, IoRefresh, IoReloadSharp, IoSend } from "react-icons/io5";
import { message } from "../../../utils/Funciones.js";
import { useSendEmailForgotPaswordMutation } from "../../../store/services/emailService";
import Spinner from "../../../components/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
const moment = require("moment");
require("moment/locale/es");

const Login = ({
    socket,
    UpdateUserById,
    configuration,
    loadingconfiguration
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [recoverPassword, SetRecoverPassword] = useState({ open: false, email: '', randomkey: '', key: '' })
    const [forgotpassword, responsepasswordrequest] = useForgotpasswordMutation()
    const [SendEmailForgotPassword, responseSendEmailForgotPassword] = useSendEmailForgotPaswordMutation()

    const { state, onChange } = useForm({
        email: '',
        password: ''
    })
    const [loginUser, response] = useUserLoginMutation()
    const onSubmit = e => {
        e.preventDefault();
        if (configuration && configuration.login) {
            loginUser(state);
        } else {
            message('Ingreso temporalmente bloqueado, reintentalo denuevo mas tarde.', 'error', 4)
        }

    }

    const [VerPasswords, SetVerPasswords] = useState(
        {
            password: false,
        });

    useEffect(() => {
        if (response.isError) {

            socket.emit("userlogin", false, response?.error?.data?.username);
            response?.error?.data?.errors.map((err) => {
                message(err.msg, 'error', 4)
            });
        }
    }, [response?.error?.data])

    useEffect(async () => {
        if (response.isSuccess) {

            toast(response?.data?.msg,
                {
                    icon: '👏',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    }, duration: 8000, position: 'top-center'
                }
            )
            socket.emit("registrarse", response?.data?.userId, response?.data?.admin);
            socket.emit("userlogin", true, response?.data?.username);
            localStorage.setItem('userToken', response?.data?.token);
            dispatch(setUserToken(response?.data?.token))
            UpdateUserById(response?.data?.userInfo)
            dispatch(deleteOfflineUserToken('offlineUserToken'))
            navigate('/')
        }
    }, [response.isSuccess])

    const OpenRecoverPassword = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charactersLength = characters.length;
        let result = "";
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        SetRecoverPassword({ ...recoverPassword, open: true, randomkey: result })
    }

    const HandleChangeRandomKey = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charactersLength = characters.length;
        let result = "";
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        SetRecoverPassword({ ...recoverPassword, randomkey: result })
    }

    const SubmitRecoveryPassword = (e) => {
        e.preventDefault()
        var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (configuration && configuration.canrecoverpassword) {
            if (recoverPassword.email.length < 1) {
                message('El e-mail es requerido', 'error', 4)
            } else if (!validEmail.test(recoverPassword.email)) {
                message('El e-mail ingresado tiene un formato incorrecto', 'error', 4)
            } else if (recoverPassword.key.length < 1) {
                message('El código de verificación es requerido', 'error', 4)
            } else if (recoverPassword.randomkey !== recoverPassword.key) {
                message('El código de verificación ingresado es incorreccto', 'error', 4)
            } else {
                forgotpassword({ email: recoverPassword.email })
            }
        } else {
            message('Recuperación de contraseña temporalmente bloqueado, reintentalo denuevo mas tarde.', 'error', 4)
        }

    }

    useEffect(() => {
        if (responsepasswordrequest.isError) {
            message(responsepasswordrequest?.error?.data?.message, 'error', 4)
            const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            const charactersLength = characters.length;
            let result = "";
            for (let i = 0; i < 5; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            SetRecoverPassword({ ...recoverPassword, randomkey: result, key: '' })
        }
    }, [responsepasswordrequest?.error])

    useEffect(() => {
        if (responsepasswordrequest.isSuccess) {
            var year = new Date().getFullYear()
            var msg = `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
            
                <style>
                    p,
                    a,
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        font-family: Google Sans !important;
                        color: #171717;
                    }
            
                    h1 {
                        font-size: 30px !important;
                    }
            
                    h2 {
                        font-size: 25px !important;
                    }
            
                    h3 {
                        font-size: 18px !important;
                    }
            
                    h4 {
                        font-size: 16px !important;
                    }
            
                    p,
                    a {
                        font-size: 15px !important;
                    }
            
                    .claseBoton {
                        text-decoration: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
            
                    .btn-grad {
                        background-image: linear-gradient(to right, #3838ff 0%, #0000d8 51%, #3838ff 100%);
                        margin: 0px;
                        padding: 15px 20px;
                        text-align: center;
                        text-transform: uppercase;
                        transition: 0.5s;
                        background-size: 200% auto;
                        color: #ffffff !important;
                        box-shadow: 0 0 20px #eee;
                        border-radius: 10px;
                        display: block;
                        text-decoration: none;
                        cursor: pointer;
                    }
            
                    .btn-grad:hover {
                        background-position: right center;
                        /* change the direction of the change here */
                        color: #fff;
                        text-decoration: none;
                    }
            
                    .btn-grad:active{
                        background-image: linear-gradient(to right, #2727bb 0%, #0000b6 51%, #2727bb 100%);
                    }
                    .imag {
                        width: 30px;
                        height: 30px;
                    }
            
                    .contA {
                        margin: 0px 5px 0 5px;
                    }
            
                    .afooter {
                        color: #ffffff !important;
                        text-decoration: none;
                        font-size: 13px !important;
                    }
            
                    .contenido {
                        width: 25vw;
                        margin: auto;
                        text-align: left;
                        margin-bottom: 30px;
                        margin-top: 30px;
                        padding: 5px 5px;
                    }
            
                    .dataperfiles {
                        width: calc(100% - 30px);
                        background-color: #e3e3e3;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-top: 5px;
                        color: #141414;
                    }
            
                    .perfilnumber {
                        font-size: 18px !important;
                        color: #222222;
                        font-weight: bolder;
                    }
            
                    .container {
                        width: 40vw;
                        margin: auto;
                        background-color: #e3e3e3
                    }
            
                    .subcontainer {
                        padding: 30px 30px 30px 30px;
                    }
            
                    .logo-link {
                        width: 260px;
                        margin: auto;
                        text-align: center;
                        margin-bottom: 5px;
                    }
            
                    .thanks {
                        width: 100%;
                        float: left;
                    }
            
                    @media screen and (max-width: 640px) {
                        .contenido {
                            width: 100%;
                        }
            
                        .container {
                            width: 100%;
                            margin: auto;
                        }
            
                        .logo-link {
                            width: 100%;
                            margin: auto;
                        }
            
                        .subcontainer {
                            padding: 30px 0px 30px 0px;
                        }
                    }
                </style>
            </head>
            
            <body>
                <div class="container">
                    <div class="subcontainer">
                        <!-- Imagen inicial -->
                        <div style="padding: 0px 0px 20px 0px; width: 100%; text-align: center;">
                            <img src="https://cinedar.com/images/forgot-password.png" alt="" style="width: 200px; height: 200px;">
                        </div>
                        <!-- Imagen inicial -->
            
                        <!-- Contenido principal -->
                        <div style="background-color: #f5f5f5; padding: 20px 0px 5px 0px; width: 100%; text-align: center;">
                            <h1>RESTABLECER CONTRASEÑA</h1>
                            <P class="perfilnumber">Hola estimado(a), ${responsepasswordrequest?.data?.data?.name}</P>
                            <div class="contenido">
                                <p>Hemos recibido su pedido para restablecer su contraseña, para restablecer su contraseña haz click en el siguiente boton de lo contrario sólo ignore este mensaje.</p>
                                <div class="logo-link">
                                    <a class="btn-grad"
                                        href="https://cinedar.com/forgotpasswordreset/${responsepasswordrequest?.data?.data?.key}"
                                        target="_blank">RESTABLECER CONTRASEÑA</a>
                                </div>
                            </div>
                            <!-- Gracias -->
                            <div class="thanks">
                                <p>Gracias por su tiempo.</p>
                                <p style="margin-bottom: 50px;"><i>Atentamente:</i><br>Equipo CINEDAR</p>
                            </div>
            
            
                            <!-- Botón -->
                            <div class="logo-link">
                                <a class="claseBoton" href="https://cinedar.com" target="_blank">
                                    <img src="https://cinedar.com/images/logo2.png" alt="" style="width: 260px; height: 65px;">
                                </a>
                            </div>
                        </div>
                        <!-- Contenido principal -->
            
                        <!-- Footer -->
                        <div
                            style="background-color: #282828; color: #ffffff; padding: 15px 0px 0px 0px; width: 100%; text-align: center;">
                            <!-- Redes sociales -->
                            <a href="https://www.facebook.com/condezodarvin" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/facebook.png" class="imag" /></a>
                            <a href="https://www.instagram.com/policedarvin/" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/instragram.png" class="imag" /></a>
                            <a href="https://wa.me/51994201155" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/whatsapp.png" class="imag" /></a>
                            <a href="mailto:support@cinedar.net" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/mail.png" class="imag" /></a>
                            <a href="https://t.me/darvin_condezo" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/telegram.png" class="imag" /></a>
                            <!-- Redes sociales -->
            
                            <h3 style="color: #ffffff">Soporte</h3>
                            <p style="font-size: 13px; padding: 0px 20px 0px 20px; color: #ffffff">
                                Comunícate con nosotros por los siguientes medios:<br>
                                Correo: <a class="afooter" href="mailto:support@cinedar.net">support@cinedar.net</a><br>
                                Whatsapp: <a class="afooter" href="https://wa.me/51994201155">+51 994 201 155</a><br>
                            </p>
                            <p
                                style="background-color: black; color: #ffffff; padding: 10px 0px 10px 0px; font-size: 12px !important;">
                                © ${year} CINEDAR, todos los derechos reservados.
                            </p>
                        </div>
                        <!-- Footer -->
            
            
            
                    </div>
                </div>
            </body>
            
            </html>`

            SendEmailForgotPassword({ data: responsepasswordrequest?.data?.data, subject: 'Pedido de restablecimiento de contraseña.', html: msg })
        }
    }, [responsepasswordrequest?.isSuccess])

    useEffect(() => {
        if (responseSendEmailForgotPassword.isError) {
            responseSendEmailForgotPassword?.error?.data?.errors.map((err) => {
                message(err.msg, 'error', 3)
            });
        }
    }, [responseSendEmailForgotPassword?.isError])

    useEffect(() => {
        if (responseSendEmailForgotPassword.isSuccess) {
            message(responseSendEmailForgotPassword?.data?.msg, 'success', 5)
            SetRecoverPassword({ ...recoverPassword, open: false, randomkey: '', email: '', key: '' })
        }
    }, [responseSendEmailForgotPassword?.isSuccess])


    return (
        <>
            <Nav
                configuration={configuration}
                loadingconfiguration={loadingconfiguration}
            />
            <Toaster position="top-right" reverseOrder={true} />
            <div class="min-h-screen bg-neutral-50 dark:bg-neutral-950 py-6 flex flex-col justify-center sm:py-12">
                {
                    recoverPassword.open &&
                    <div id="authentication-modal" className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">
                        <div class="relative py-3 px-4 sm:max-w-xl sm:mx-auto">
                            <div class="relative px-4 py-10 bg-neutral-100 dark:bg-neutral-900 shadow-lg sm:rounded-3xl sm:p-20">
                                <div class="w-full">
                                    <div className="absolute top-2 left-2">
                                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                                            <IconButton color="error" onClick={() => SetRecoverPassword({ ...recoverPassword, open: false, randomkey: '', email: '', key: '' })}>
                                                <IoCloseSharp
                                                    className="text-neutral-200"
                                                    size={28}
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div>
                                        <h1 class="text-2xl font-semibold text-neutral-950 dark:text-neutral-50">Recuperar mi contraseña</h1>
                                    </div>
                                    <form onSubmit={SubmitRecoveryPassword} >
                                        <div class="divide-y divide-neutral-200">
                                            <div class="py-8 text-base leading-6 space-y-4 text-neutral-700 sm:text-lg sm:leading-7">
                                                <div class="relative">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Escribe tu e-mail de recuperación</label>
                                                    <input autoFocus name="email" className="pl-10 bg-neutral-50 dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                        placeholder="Escribe tu e-mail de recuperación"
                                                        autoComplete="off"
                                                        required
                                                        type="email"
                                                        value={recoverPassword.email}
                                                        onChange={(e) => SetRecoverPassword({ ...recoverPassword, email: e.target.value })}
                                                    />
                                                    <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center leading-5">
                                                        <MdMail size={24} className="text-neutral-800 dark:text-neutral-200 ml-2" />
                                                    </div>
                                                </div>
                                                <div class="relative">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Escribe código de verificación</label>
                                                    <input name="key" className="pl-10 pr-12 bg-neutral-50 dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                        placeholder="Escribe código de verificación"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        value={recoverPassword.key}
                                                        onChange={(e) => SetRecoverPassword({ ...recoverPassword, key: e.target.value })}
                                                    />
                                                    <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center text-sm leading-5">
                                                        <FaKey size={24} className="text-neutral-900 dark:text-neutral-200 ml-2" />
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-12/12 text-center text-6xl">
                                                    <div className="p-4 rounded-lg text-neutral-800 dark:text-neutral-300 bg-neutral-50 dark:bg-neutral-950 uppercase tracking-wider" onCopy={HandleChangeRandomKey}>{recoverPassword.randomkey}</div>
                                                </div>
                                                <div className="w-full">
                                                    <button
                                                        type="submit"
                                                        class="flex w-full disabled:opacity-30 justify-center rounded-md bg-blue-600 px-3 py-5 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                        disabled={responseSendEmailForgotPassword.isLoading ? true : false}
                                                    >
                                                        {responseSendEmailForgotPassword.isLoading ? <Spinner w={'35px'} h={'35px'} /> : <IoReloadSharp size={24} className="mr-2" />}
                                                        {responseSendEmailForgotPassword.isLoading ? 'Procesando...' : 'RECUPERAR CONTRASEÑA'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div class="relative py-3 px-4 sm:max-w-xl sm:mx-auto">
                    <div
                        class="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                    </div>
                    <div class="relative px-4 py-10 bg-neutral-50 dark:bg-neutral-950 shadow-lg sm:rounded-3xl sm:p-20">

                        <div class="w-full">
                            <div>
                                <h1 class="text-2xl font-semibold text-neutral-950 dark:text-neutral-50">Ingresar</h1>
                            </div>
                            <form onSubmit={onSubmit} >
                                <div class="divide-y divide-neutral-200">
                                    <div class="py-8 text-base leading-6 space-y-4 text-neutral-700 sm:text-lg sm:leading-7">
                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Correo</label>
                                            <input autoFocus name="email" className="pl-10 bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                placeholder="Correo"
                                                autoComplete="off"
                                                required
                                                value={state.email}
                                                onChange={onChange}
                                                type="email"
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center leading-5">
                                                <MdMail size={28} className="text-neutral-800 dark:text-neutral-200 ml-1" />
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password</label>
                                            <input name="password" className="pl-10 pr-12 bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                placeholder="Password"
                                                autoComplete="off"
                                                required
                                                onChange={onChange}
                                                value={state.password}
                                                type={VerPasswords.password ? 'text' : 'password'}
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center text-sm leading-5">
                                                <IoFingerPrint size={28} className="text-neutral-900 dark:text-neutral-200 ml-1" />
                                            </div>
                                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['password']: !VerPasswords.password })}>
                                                    {VerPasswords.password ? <IoEyeOff className="text-neutral-900 dark:text-neutral-200" /> : <IoEye className="text-neutral-900 dark:text-neutral-200" />}
                                                </IconButton>}
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <Button
                                                type="submit"
                                                disabled={response.isLoading ? true : false}
                                                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                                size='large' variant="contained"
                                            >
                                                {response.isLoading ? <Spinner /> : <IoLogIn size={24} className="mr-2" />}
                                                {response.isLoading ? 'Ingresando...' : 'INGRESAR'}
                                            </Button>
                                        </div>
                                        <div className="text-base mt-10 text-neutral-400 m-auto w-full text-center">
                                            <span className="text-neutral-800 dark:text-neutral-200 cursor-pointer" onClick={OpenRecoverPassword} >
                                                ¿Olvidaste tu contraseña?
                                            </span>
                                        </div>

                                        <div className="text-base mt-4 text-neutral-400 m-auto w-full text-center">No estás registrado ?
                                            <span className="text-neutral-800 dark:text-neutral-200 ml-2">
                                                <Link to="/register">
                                                    Registrarse
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;