import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AccountList from "../../components/home/AccountList";
import { useVerifyMercadoPagoPaymentQuery, useVerifyPaymentQuery } from "../../store/services/paymentService";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { IoCloseSharp, IoGlobeSharp, IoLockClosed, IoMail, IoPerson, IoSave, IoTimer } from "react-icons/io5";
import { BsCashCoin, BsCashStack } from "react-icons/bs";
import { MdEdit, MdPhone, MdPhoto, MdPhotoCamera, MdSave, MdVerifiedUser } from "react-icons/md";
import { FaCashRegister, FaUser } from "react-icons/fa";
import { currencyFormat, message, PermiteSoloLetras, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { useUpdatePhotoMutation, useUpdateProfileInfoMutation } from "../../store/services/userService";
import { setUserToken } from "../../store/reducers/authReducer";
import { MuiTelInput } from "mui-tel-input";
import PhotoPreview from "../../components/PhotoPreview";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Profile = ({ socket, userToken, user, loadinguserbyid, userById, UpdateUserById, configuration, loadingconfiguration }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateProfileInfo, responseUpdateProfileInfo] = useUpdateProfileInfoMutation();
  const [onUpdatePhoto, responseUpdatePhoto] = useUpdatePhotoMutation();

  const [params] = useSearchParams();
  const id = params.get("session_id");
  const payment_id = params.get("payment_id");
  const { data, isSuccess } = useVerifyPaymentQuery(id, {
    skip: id ? false : true,
  });
  const { data: queryMercadoPago, isSuccess: isSuccessMercadoPago } = useVerifyMercadoPagoPaymentQuery(payment_id, {
    skip: payment_id ? false : true,
  });

  const [email, SetEmail] = useState({ open: false, value: '' })
  const [name, SetName] = useState({ open: false, value: '' })
  const [phone, SetPhone] = useState({ open: false, code: 'PE', value: '' })
  const [preview, setPreview] = useState({ photo: '' })
  const [photo, SetPhoto] = useState('')

  useEffect(async () => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (userToken) {
      if (isSuccess) {
        message(data?.msg, 'success', 5)
        if (data?.reseller && (userById && !userById.reseller)) {
          message('Bienvenido reseller', 'success', 5)
        }
        UpdateUserById(data?.token)
        navigate('/manage-account/profile')
      }
    } else {
      navigate('/login')
    }
  }, [isSuccess]);

  useEffect(() => {
    if (userToken) {
      if (isSuccessMercadoPago) {
        message(queryMercadoPago?.msg, 'success', 5)
        if (queryMercadoPago?.reseller && !userById.reseller) {
          message('Bienvenido reseller', 'success', 5)
        }
        UpdateUserById(queryMercadoPago?.token)
        navigate('/manage-account/profile')
      }
    } else {
      navigate('/login')
    }
  }, [isSuccessMercadoPago]);

  const imageHandle = e => {
    if (e.target.files.length !== 0) {
      SetPhoto(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview({ ...preview, [e.target.name]: reader.result })
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }


  const HandleOpenEditInfo = (type) => {
    if (type === 'name') {
      SetName({ ...name, ['open']: true, ['value']: !loadinguserbyid && userById.name })
    } else if (type === 'email') {
      SetEmail({ ...email, ['open']: true, ['value']: userById.email })
    } else if (type === 'phone') {
      SetPhone({ ...phone, ['open']: true, ['value']: userById.phonenumber })
    }
  }

  const HandleUpdateProfileIfo = (type) => {
    if (type === 'name') {
      if (!loadinguserbyid && userById.name !== name.value) {
        if (name.value.length < 10) {
          message('Nombre completo no deber tener menos de 10 caracteres!', 'error', 4)
        } else {
          updateProfileInfo({ _id: userById._id, type: type, data: name })
        }
      } else {
        message('El nombre no tuvo ningún cambio!', 'error', 4)
        SetName({ ...name, ['open']: false, ['value']: '' })
      }
    } else if (type === 'email') {
      var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
      if (userById.email !== email.value) {
        if (email.value.length < 5) {
          message('Email no deber tener menos de 5 caracteres!', 'error', 4)
        } else if (!validEmail.test(email.value)) {
          message('Email ingresado tiene un formato incorrecto!', 'error', 4)
        } else {
          updateProfileInfo({ _id: userById._id, type: type, data: email })
        }
      } else {
        message('El email no tuvo ningún cambio!', 'error', 4)
        SetEmail({ ...email, ['open']: false, ['value']: '' })
      }

    } else if (type === 'phone') {
      if (userById.phonenumber !== phone.value) {
        if (phone.value.length < 8) {
          message('Celular no deber tener menos de 8 números!', 'error', 4)
        } else {
          updateProfileInfo({ _id: userById._id, type: type, data: phone })
        }
      } else {
        message('Número celular no tuvo ningún cambio!', 'error', 4)
        SetPhone({ ...phone, ['open']: false, ['value']: '', ['code']: 'PE' })
      }
    } else if (type === 'photo') {
      if (configuration && configuration.changeavatar) {
        const formData = new FormData();
        formData.append('data', JSON.stringify({ _id: userById._id }));
        formData.append('image1', photo)
        onUpdatePhoto(formData)
      } else {
        message('Cambiar foto de perfil está temporalmente bloqueado, intetalo denuevo mas tarde.', 'error', 4)
        setPreview({ ...preview, photo: '' })
      }

    }
  }

  useEffect(() => {
    if (responseUpdatePhoto?.isSuccess) {
      message(responseUpdatePhoto?.data?.message, 'success', 5)
      UpdateUserById(responseUpdatePhoto?.data?.token)
      setPreview({ ...preview, photo: '' })
    }
  }, [responseUpdatePhoto]);

  useEffect(() => {
    if (responseUpdatePhoto?.isError) {
      responseUpdatePhoto?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseUpdatePhoto?.error])

  useEffect(() => {
    if (responseUpdateProfileInfo?.isSuccess) {
      message(responseUpdateProfileInfo?.data?.message, 'success', 5)
      if (responseUpdateProfileInfo?.data?.type === 'name') {
        SetName({ ...name, ['open']: false, ['value']: '' })
      } else if (responseUpdateProfileInfo?.data?.type === 'email') {
        SetEmail({ ...email, ['open']: false, ['value']: '' })
      } else if (responseUpdateProfileInfo?.data?.type === 'phone') {
        SetPhone({ ...phone, ['open']: false, ['value']: '', ['code']: 'PE' })
      }
      UpdateUserById(responseUpdateProfileInfo?.data?.token)
    }
  }, [responseUpdateProfileInfo]);

  useEffect(() => {
    if (responseUpdateProfileInfo?.isError) {
      message(responseUpdateProfileInfo?.error?.data?.message, 'error', 4)
    }
  }, [responseUpdateProfileInfo?.error])

  return (

    <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <UserScreenHeader>
        <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
          <IoPerson size={32} className="mr-2" /> MI PERFIL <span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            text-neutral-800 bg-neutral-200 rounded-full"></span></div>
      </UserScreenHeader>

      <div className="flex flex-wrap mt-[106px] bg-neutral-200 dark:bg-neutral-900 p-3">
        {
          (userToken && !loadinguserbyid) &&
          <>
            <div className="w-full md:w-12/12 grid justify-center mt-5">
              <div className="relative rounded-full w-56 h-56 flex m-auto">
                <div class="w-56 h-56 rounded-full bg-neutral-50 dark:bg-neutral-950 flex inline-flex items-center justify-center">
                  <label htmlFor="photo" class="absolute z-20 cursor-pointer top-0 left-0 bg-neutral-50 dark:bg-neutral-950 contrast-[80%] rounded-full p-3">
                    <MdPhotoCamera size={32} className="text-neutral-900 dark:text-neutral-300" />
                    <input id="photo" accept="image/png,image/jpeg,image/webp" type="file" name="photo" class="hidden" onChange={imageHandle} />
                  </label>
                  {
                    preview.photo ? <PhotoPreview url={preview.photo} /> :
                      <LazyLoadImage
                        className="w-56 h-56 rounded-full object-cover flex inline-flex"
                        alt={`No image`}
                        effect="blur"
                        src={`/images/photos/${userById && userById.photo}`}
                      />
                  }
                </div>
                <div className="absolute right-0 bottom-0">
                  <LazyLoadImage
                    className="object-cover rounded-lg h-8 w-12"
                    alt={`No image`}
                    effect="blur"
                    src={`/images/flags/4x3/${userById.countrycode}.svg`}
                  />
                </div>
                {
                  preview.photo &&
                  <div className="absolute bottom-0 left-0 brounded-lg h-8 w-12g-blue-900  rounded-full">
                    <IconButton onClick={() => HandleUpdateProfileIfo('photo')} color="primary">
                      <MdSave size={32} className="text-neutral-300" />
                    </IconButton>
                  </div>
                }

              </div>
              <div className="w-full flex items-center mt-5">
                <div className="rounded-full bg-neutral-50 dark:bg-neutral-950 w-16 h-16 flex inline-flex items-center justify-center mr-2">
                  <IoPerson size={32} />
                </div>
                <div className="flex inline-flex items-center h-16 p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                  {name.open ?
                    <TextField
                      name="name"
                      className="bg-neutral-50 dark:bg-neutral-950 rounded-lg absolute"
                      style={{
                        width: '100%',
                        marginTop: 10, marginBottom: 10,
                        borderRadius: 5
                      }}
                      sid="outlined-basic" label="Nombre completo" variant="filled"
                      autoComplete="off"
                      color='primary'
                      inputProps={{
                        className: "text-neutral-900 dark:text-neutral-200",
                        style: {
                          fontFamily: "Jost, sans-serif",
                          fontSize: 16
                        }
                      }}
                      autoFocus
                      InputLabelProps={{
                        className: "text-neutral-900 dark:text-neutral-200",
                        style: {
                          fontSize: 17, paddingBottom: 5,
                          fontFamily: "Jost, sans-serif"
                        }, shrink: true
                      }}
                      value={name.value}
                      onChange={(e) => SetName({ ...name, ['value']: e.target.value })}
                      onKeyPress={PermiteSoloLetras}
                      type="text"
                    /> :
                    userById.name
                  }

                </div>
                <div className="flex inline-flex items-center h-16 p-3">
                  {
                    !name.open ?
                      <Tooltip title="Editar nombre" arrow={true}>
                        <div className="bg-neutral-50 dark:bg-neutral-950 rounded-full">
                          <IconButton onClick={() => HandleOpenEditInfo('name')} color="primary">
                            <MdEdit size={32} className="text-yellow-600" />
                          </IconButton>
                        </div>
                      </Tooltip>
                      :
                      <Tooltip title="Actualizar nombre" arrow={true}>
                        <div className="bg-neutral-50 dark:bg-neutral-950 rounded-full">
                          <IconButton onClick={() => HandleUpdateProfileIfo('name')} color="primary">
                            <MdSave size={32} className="text-neutral-300" />
                          </IconButton>
                        </div>
                      </Tooltip>
                  }
                </div>
              </div>

              <div className="w-full flex items-center mt-5">
                <div className="rounded-full bg-neutral-50 dark:bg-neutral-950 w-16 h-16 flex inline-flex items-center justify-center mr-2">
                  <IoMail size={32} />
                </div>
                <div className="flex inline-flex items-center h-16 p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                  {
                    email.open ?
                      <TextField
                        name="email"
                        className="bg-neutral-50 dark:bg-neutral-950 rounded-lg"
                        style={{
                          width: '100%',
                          marginTop: 10, marginBottom: 10, color: '#c2c2c2',
                          borderRadius: 5
                        }}
                        sid="outlined-basic" label="Email" variant="filled"
                        autoComplete="off"
                        color='primary'
                        inputProps={{
                          style: {
                            color: '#c2c2c2',
                            fontFamily: "Jost, sans-serif",
                            fontSize: 16
                          }
                        }}
                        autoFocus
                        InputLabelProps={{
                          style: {
                            color: '#c2c2c2', fontSize: 17, paddingBottom: 5,
                            fontFamily: "Jost, sans-serif"
                          }, shrink: true
                        }}
                        value={email.value}
                        onChange={(e) => SetEmail({ ...email, ['value']: e.target.value })}
                        type="email"
                      /> :
                      userById.email
                  }
                </div>
                <div className="flex inline-flex items-center h-16 p-3">
                  <>
                    {
                      !email.open ?
                        <Tooltip title="Editar email" arrow={true}>
                          <div className="bg-neutral-50 dark:bg-neutral-950 rounded-full">
                            <IconButton onClick={() => HandleOpenEditInfo('email')} color="primary">
                              <MdEdit size={32} className="text-yellow-600" />
                            </IconButton>
                          </div>
                        </Tooltip>
                        :
                        <Tooltip title="Actualizar email" arrow={true}>
                          <div className="bg-neutral-50 dark:bg-neutral-950 rounded-full">
                            <IconButton onClick={() => HandleUpdateProfileIfo('email')} color="primary">
                              <MdSave size={32} className="text-neutral-300" />
                            </IconButton>
                          </div>
                        </Tooltip>
                    }
                  </>
                </div>
              </div>

              <div className="w-full flex items-center mt-5">
                <div className="rounded-full bg-neutral-50 dark:bg-neutral-950 w-16 h-16 flex inline-flex items-center justify-center mr-2">
                  <BsCashStack size={32} />
                </div>
                <div className="flex inline-flex items-center h-16 p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                  {currencyFormat(userById.credits_purchased - userById.credits_used)}
                </div>
              </div>

              <div className="w-full flex items-center mt-5">
                <div className="rounded-full bg-neutral-50 dark:bg-neutral-950 w-16 h-16 flex inline-flex items-center justify-center mr-2">
                  <BsCashCoin size={32} />
                </div>
                <div className="flex inline-flex items-center h-16 p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                  {userById.reseller ? <span className="text-yellow-400">Eres reseller</span> : <Tooltip title={'Te conviertes en reseller cuando recargas 500 Créditos'}><span className="text-red-600">No eres reseller</span></Tooltip>}
                </div>
              </div>

              <div className="w-full flex items-center mt-5">
                <div className="rounded-full bg-neutral-50 dark:bg-neutral-950 w-16 h-16 flex inline-flex items-center justify-center mr-2">
                  <MdPhone size={32} />
                </div>
                <div className="flex inline-flex items-center h-16 p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                  {
                    phone.open ?
                      <MuiTelInput
                        label="Numero celular"
                        variant="filled"
                        InputLabelProps={{
                          style: {
                            color: '#c2c2c2', fontSize: 17, paddingBottom: 5,
                            fontFamily: "Jost, sans-serif"
                          }, shrink: true
                        }}
                        inputProps={{ className: "text-neutral-900 dark:text-neutral-200" }}
                        autoFocus
                        value={phone.value}
                        defaultCountry={'PE'}
                        onChange={(newValue, info) => SetPhone({ ...phone, ['code']: info.countryCode, ['value']: newValue })}
                        className="bg-neutral-50 dark:bg-neutral-950 rounded-lg"
                      />
                      :
                      userById.phonenumber
                  }
                </div>
                <div className="flex inline-flex items-center h-16 p-3">
                  {
                    !phone.open ?
                      <Tooltip title="Editar celular" arrow={true}>
                        <div className="bg-neutral-50 dark:bg-neutral-950 rounded-full">
                          <IconButton onClick={() => HandleOpenEditInfo('phone')} color="primary">
                            <MdEdit size={32} className="text-yellow-600" />
                          </IconButton>
                        </div>
                      </Tooltip>
                      :
                      <Tooltip title="Actualizar celular" arrow={true}>
                        <div className="bg-neutral-50 dark:bg-neutral-950 rounded-full">
                          <IconButton onClick={() => HandleUpdateProfileIfo('phone')} color="primary">
                            <MdSave size={32} className="text-neutral-300" />
                          </IconButton>
                        </div>
                      </Tooltip>
                  }
                </div>
              </div>

              <div className="w-full flex items-center mt-5">
                <div className="rounded-full bg-neutral-50 dark:bg-neutral-950 w-16 h-16 flex inline-flex items-center justify-center mr-2">
                  <IoTimer size={32} />
                </div>
                <div className="flex inline-flex items-center h-16 p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                  {setUnixToTimeFormat('info_contacto', userById.created_at)}
                </div>
              </div>
            </div>
          </>
        }

      </div>

    </AccountList>

  );
};

export default Profile;
