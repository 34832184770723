import Categories from "../../components/home/Categories";
import CategoryStreamingRating from "../../components/home/CategoryStreamingRating";
import Nav from "../../components/home/Nav";
import Slider from "../../components/home/Slider";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useCreateVentaMutation, useGetLastVentasQuery } from "../../store/services/ventasService";
import { Button, Checkbox, IconButton, Modal } from "@mui/material";
import { IoAlarm, IoCart, IoCloseSharp, IoDesktop, IoDesktopSharp, IoFlashSharp, IoMenu, IoServer, IoTime, IoTimeSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { currencyFormat, message, sleep } from "../../utils/Funciones.js";
import moment from 'moment';
import 'moment/locale/es';
import { MdClose, MdDevices, MdGpsFixed } from "react-icons/md";
import { FaCheck, FaClipboardCheck, FaDigitalTachograph, FaExpeditedssl, FaMinus, FaPaypal, FaPlus, FaShip } from "react-icons/fa";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import parse from 'html-react-parser';
import Spinner from "../../components/Spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LastVentas from "../../components/home/LastVentas";
import { useGetAllCategoriesGiftCardQuery, useGetAllCategoriesStreamingByRatingQuery, useGetAllCategoriesStreamingQuery } from "../../store/services/categoryService";
import { useGetAllGiftCardsQuery } from "../../store/services/giftcardService";
import io from "socket.io-client"
import SocialServices from "../../components/home/SocialServices";
import { BsClipboard2Check, BsFillInfoSquareFill, BsKey } from "react-icons/bs";
import { useGetAllSocialPlataformsQuery } from "../../store/services/socialService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Virtual } from 'swiper/modules';
import "swiper/css/effect-fade"
import { useGetAllCuentasQuery } from "../../store/services/cuentaService";
import AnimatedNumber from "react-animated-numbers";
import { useCreateGcVentaMutation } from "../../store/services/gcventasService";
import { Transition } from '@headlessui/react';
import { HiMenuAlt2 } from "react-icons/hi";
import SocialServicesHome from "../../components/home/SocialServicesHome";
import { FcKey, FcMultipleSmartphones } from "react-icons/fc";

const socket = io('https://cinedar.com:8443', {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5
})

const Home = ({
  userToken,
  user,
  userById,
  loadinguserbyid,
  configuration,
  loadingconfiguration,
  UpdateUserById,
  categories,
  CategoryIsLoading,
}) => {


  const { data: lastventas, isFetching: fechinglastventas } = useGetLastVentasQuery();

  const [optionsStreaming, setOptionsStreaming] = useState({ open: false, categoryId: "", name: "", type: "", logo: "", description: "", data: null })

  const [services, setServices] = useState('streaming')
  const [selectgiftcard, setSelectGiftCard] = useState({ open: false, categoryId: "", name: "", logo: "", description: "", data: null })
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState({ name: "", iamgeloaded: false })

  const onLoad = useCallback((name) => {
    setLoaded({ ...loaded, name: name, iamgeloaded: true });
  }, [])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  var htmlData2 = useMemo(
    () => draftToHtml(convertToRaw(editorState2.getCurrentContent())),
    [editorState2]
  );

  const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
  var htmlData3 = useMemo(
    () => draftToHtml(convertToRaw(editorState3.getCurrentContent())),
    [editorState3]
  );

  const [viewterm, SetViewterm] = useState(false)
  const [purchase, SetPurchase] = useState({
    idVenta: "",
    userId: "",
    categoryId: "",
    subcategoryId: "",
    maxscreems: 0,
    qtymonths: [1, 3, 6, 12],
    screems: ['1 pantalla', 'cuenta completa'],
    imglogo: "",
    name: "",
    discountperfiluser: 0,
    tipoentrega: false,
    isprofileblocked: false,
    open: false,
    user: "",
    selectedmonths: 0,
    totalamount: 0,
    actionTypeAdmin: false,
    location: "",
    minmonths: 1,
    policy: "",
    acept_terms: false,
    stockcuentasBoolean: false,
    stockperfilesBoolean: false,
    type: ""
  })

  const [purchaseGC, SetPurchaseGC] = useState({
    catId: "",
    subcatId: "",
    open: false,
    namesubGC: "",
    userId: "",
    priceunit: 0,
    totalamount: 0,
    logo: "",
    quatitygc: 0,
    currentstock: 0,
    location: "",
    isADMIN: false,
    action: 'create',
    _id: "",
    observation: ""
  })

  const onIncreaseGC = (e) => {
    if (Number(purchaseGC.quatitygc) < purchaseGC.currentstock)
      SetPurchaseGC({ ...purchaseGC, quatitygc: Number(purchaseGC.quatitygc) + 1, totalamount: purchaseGC.priceunit * (purchaseGC.quatitygc + 1) })

  }

  const onDecreaseGC = (e) => {
    if (Number(purchaseGC.quatitygc) > 0)
      SetPurchaseGC({ ...purchaseGC, quatitygc: Number(purchaseGC.quatitygc) - 1, totalamount: purchaseGC.priceunit * (purchaseGC.quatitygc - 1) })
  }

  const sectionRef = useRef();

  const open = (categoryId, subcategoryId, maximoperfiles, logo, name, discount, tipoentrega, isprofileblocked, location, minmonths, policy, stockcuentasBoolean, stockperfilesBoolean, type) => {

    if (userToken) {

      if (Number(minmonths) > 0) {
        if (configuration && configuration.buyservice) {

          !CategoryIsLoading && categories.find((___c) => {
            if (___c._id === categoryId) {
              ___c.subcategory.find((sub) => {
                if (sub._id === subcategoryId) {
                  if (user?.reseller) {
                    if (type) {
                      SetPurchase({
                        ...purchase,
                        userId: user?.id,
                        categoryId: categoryId,
                        subcategoryId: subcategoryId,
                        maxscreems: maximoperfiles,
                        imglogo: logo,
                        name: name,
                        discountperfiluser: discount,
                        tipoentrega: tipoentrega,
                        isprofileblocked: isprofileblocked,
                        open: true,
                        user: user,
                        selectedscreems: "",
                        selectedmonths: Number(minmonths),
                        totalamount: sub.costocuentareseller,
                        location: location,
                        minmonths: minmonths,
                        acept_terms: false,
                        stockcuentasBoolean: stockcuentasBoolean,
                        stockperfilesBoolean: stockperfilesBoolean,
                        type: type
                      })
                    } else {
                      SetPurchase({
                        ...purchase,
                        userId: user?.id,
                        categoryId: categoryId,
                        subcategoryId: subcategoryId,
                        maxscreems: maximoperfiles,
                        imglogo: logo,
                        name: name,
                        discountperfiluser: discount,
                        tipoentrega: tipoentrega,
                        isprofileblocked: isprofileblocked,
                        open: true,
                        user: user,
                        selectedscreems: "",
                        selectedmonths: Number(minmonths),
                        totalamount: sub.costoperfilreseller,
                        location: location,
                        minmonths: minmonths,
                        acept_terms: false,
                        stockcuentasBoolean: stockcuentasBoolean,
                        stockperfilesBoolean: stockperfilesBoolean,
                        type: type
                      })
                    }
                  } else {
                    if (type) {
                      SetPurchase({
                        ...purchase,
                        userId: user?.id,
                        categoryId: categoryId,
                        subcategoryId: subcategoryId,
                        maxscreems: maximoperfiles,
                        imglogo: logo,
                        name: name,
                        discountperfiluser: discount,
                        tipoentrega: tipoentrega,
                        isprofileblocked: isprofileblocked,
                        open: true,
                        user: user,
                        selectedscreems: "",
                        selectedmonths: Number(minmonths),
                        totalamount: sub.costocuentauser,
                        location: location,
                        minmonths: minmonths,
                        acept_terms: false,
                        stockcuentasBoolean: stockcuentasBoolean,
                        stockperfilesBoolean: stockperfilesBoolean,
                        type: type
                      })
                    } else {
                      SetPurchase({
                        ...purchase,
                        userId: user?.id,
                        categoryId: categoryId,
                        subcategoryId: subcategoryId,
                        maxscreems: maximoperfiles,
                        imglogo: logo,
                        name: name,
                        discountperfiluser: discount,
                        tipoentrega: tipoentrega,
                        isprofileblocked: isprofileblocked,
                        open: true,
                        user: user,
                        selectedscreems: "",
                        selectedmonths: Number(minmonths),
                        totalamount: (sub.costoperfiluser - (sub.costoperfiluser * sub.discountperfiluser) / 100),
                        location: location,
                        minmonths: minmonths,
                        acept_terms: false,
                        stockcuentasBoolean: stockcuentasBoolean,
                        stockperfilesBoolean: stockperfilesBoolean,
                        type: type
                      })
                    }
                  }
                }
              })
            }
          })

          const rawContent = policy;
          const contentState = convertFromRaw(JSON.parse(rawContent));
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState)
          setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: "", categoryId: "", sublogo: "", data: null })
        } else {
          message('Comprar servicios está temporalmente bloqueado, intentalo denuevo mas tarde.', 'error', 4)
        }
      } else {
        //SetPurchase({ ...purchase, selectedmonths: months })
      }
    } else {
      navigate('/login');
    }
  }

  const OpenPurchaseGiftCards = (stock, categoryId, subCategoryId, gift) => {
    if (userToken) {
      var costo = 0
      var currentstock = 0

      if (!user?.reseller) {
        costo = Number((gift.costouser) - (((gift.costouser) * gift.discountuser) / 100))
      } else {
        costo = Number(gift.costoreseller)
      }

      if (gift.tipoentrega) {
        currentstock = stock
      } else {
        currentstock = 10
      }

      setSelectGiftCard({ ...selectgiftcard, categoryId: "", open: !selectgiftcard.open, name: "", logo: "", description: "", data: null })

      SetPurchaseGC({
        ...purchaseGC,
        catId: categoryId,
        subcatId: subCategoryId,
        open: true,
        userId: user?.id,
        logo: gift.logo,
        namesubGC: gift.name,
        priceunit: costo,
        currentstock: currentstock,
        location: gift.location
      })
    } else {
      navigate('/login');
    }
  }

  const openOptions = (name, categoryId, sublogo, description) => {

    const data = !CategoryIsLoading && categories.filter((fil) => fil._id === categoryId)
    setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: name, categoryId: categoryId, sublogo: sublogo, description: description, data: [...Object.freeze(data[0].subcategory)] })
    const rawContent = description;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState2(editorState)
  }

  const handleOpenCategory = (categoryId, subcategory, categoryname, logo, sublogo, type, description) => {
    if (type === 'GiftCard') {
      setSelectGiftCard({ ...selectgiftcard, categoryId: categoryId, open: !selectgiftcard.open, name: categoryname, description: description, data: subcategory, logo: logo })

      const rawContent = description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState3(editorState)
    } else if (type === 'Streaming') {
      setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: categoryname, categoryId: categoryId, sublogo: sublogo, description: description, data: subcategory })
      const rawContent = description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState2(editorState)
    }
  }

  const [crearNuevaVenta, response] = useCreateVentaMutation();
  const PayPurchase = (e) => {
    e.preventDefault()
    if (userToken) {
      if (purchase.selectedmonths > 0) {
        if (purchase.acept_terms) {
          crearNuevaVenta({ ...purchase, htmlData: htmlData });
        } else {
          message('Aceptar términos y condiciones del servicio', 'error', 4)
        }
      } else {
        message('Selecciona cuántos meses quieres comprar', 'error', 4)
      }
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 6)
      })
    }
  }, [response?.error?.data?.errors])

  useEffect(() => {
    if (response?.isSuccess) {
      if (socket.connected) {
        message(response?.data?.message, 'success', 5)
        socket.emit("nuevopedido_streaming",
          response?.data?.callback?.socketdata,
          user?.name)
        socket.emit("nuevopedidoadmin",
          response?.data?.callback?.datacategoria,
          response?.data?.callback?.ventastatus,
          user?.name)
        UpdateUserById(response?.data?.callback?.token)
        SetPurchase({
          ...purchase,
          categoryId: "",
          maxscreems: 0,
          imglogo: "",
          name: "",
          discountperfiluser: 0,
          tipoentrega: false,
          isprofileblocked: false,
          open: false,
          user: "",
          selectedscreems: "",
          selectedmonths: 0,
          totalamount: 0
        })
        navigate('/manage-account/userorders');
      }

    }

  }, [response?.isSuccess])

  const handleSelectService = (type) => {
    setServices(type)
  }

  const handleSelectGiftCard = (categoryId, name, description, data, logo) => {
    setSelectGiftCard({ ...selectgiftcard, categoryId: categoryId, open: !selectgiftcard.open, name: name, description: description, data: data, logo: logo })

    const rawContent = description;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState3(editorState)
  }

  const [createGcVenta, responseCreateGcVenta] = useCreateGcVentaMutation();

  const PayPurchaseGC = (e) => {
    e.preventDefault()
    if (userToken) {
      if (purchaseGC.quatitygc > 0) {
        createGcVenta(purchaseGC);
      } else {
        message('La cantidad de tarjetas de regalo debe ser mayor a 0', 'error', 4)
      }
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    if (!responseCreateGcVenta.isSuccess) {
      responseCreateGcVenta?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [responseCreateGcVenta?.error?.data?.errors])

  useEffect(() => {
    if (responseCreateGcVenta?.isSuccess) {
      if (responseCreateGcVenta?.data?.tipoentrega) {
        socket.emit('nuevopedidoGC', responseCreateGcVenta?.data?.callback, responseCreateGcVenta?.data?.socketdata)
        message(responseCreateGcVenta?.data?.message, 'success', 5)
        UpdateUserById(responseCreateGcVenta?.data?.token)
        SetPurchaseGC({ ...purchaseGC, currentstock: 0, open: false, quatitygc: 0, totalamount: 0, priceunit: 0, location: "" })
        navigate('/manage-account/mygiftcards')
      } else {
        message(responseCreateGcVenta?.data?.message, 'success', 5)
        UpdateUserById(responseCreateGcVenta?.data?.token)
        SetPurchaseGC({ ...purchaseGC, currentstock: 0, open: false, quatitygc: 0, totalamount: 0, priceunit: 0, location: "" })
        navigate('/manage-account/mygiftcards')
      }
    }
  }, [responseCreateGcVenta?.isSuccess])

  return (
    <div className="w-full bg-white dark:bg-black">
      <Toaster position="top-right" reverseOrder={true} />

      <Modal
        open={purchaseGC.open}
        onClose={() => SetPurchaseGC({ ...purchaseGC, currentstock: 0, open: false, quatitygc: 0, totalamount: 0, priceunit: 0, location: "" })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div id="authentication-modal" className="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30 text-neutral-900 dark:text-neutral-200">
          <div className="relative m-auto p-5 w-full md:w-6/12 h-auto bg-neutral-50 dark:bg-neutral-950 pb-12">
            <div className="flex inline-flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => SetPurchaseGC({ ...purchaseGC, currentstock: 0, open: false, quatitygc: 0, totalamount: 0, priceunit: 0, location: "" })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <LazyLoadImage
                className="w-20 ml-5 h-20 z-30 rounded-full object-cover"
                alt={`No image`}
                effect="blur"
                src={`/images/logos/${purchaseGC.logo}`}
              />
              <div className="ml-3">
                <div className="pr-2 rounded-lg text-neutral-900 dark:text-neutral-200 text-xl font-bold">{purchaseGC.namesubGC}</div>
              </div>
            </div>

            <div className="text-neutral-100 mt-5">
              <div className="w-full flex items-center border-b-neutral-300 dark:border-b-neutral-800 mb-3">
                <label className="text-lg text-neutral-900 dark:text-neutral-200">Información de la compra</label>
              </div>
              <div className="w-full p-5 text-neutral-100 bg-neutral-200 dark:bg-neutral-800">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <div className="border-2 border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-950 w-20 h-20 flex items-center justify-center">
                      <LazyLoadImage
                        className="object-cover w-16 h-16 z-30 rounded-full p-2"
                        alt={`No image category`}
                        effect="blur"
                        src={`/images/locations/${purchaseGC.location}.svg`}
                      />
                    </div>
                    <div className="pl-3 text-neutral-900 dark:text-neutral-200">{purchaseGC.location}</div>
                  </div>

                  <div className="flex items-center">
                    <div className="border-2 border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-950 w-20 h-20 flex items-center justify-center">
                      <FcKey size={45} />
                    </div>
                    <div className="pl-3 text-neutral-900 dark:text-neutral-200">Key</div>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={PayPurchaseGC}>
              <div className="w-full mt-5">
                <label>Cuántas tarjetas de regalo quieres comprar ? min: 1 - max: 10</label>
                <div className="relative w-full flex items-center justify-center text-2xl mt-3 p-3">
                  <img className="w-64 object-cover"
                    src={`/images/logos/giftcard-ui.png`} alt={`No image giftcard-ui`}>
                  </img>
                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="absolute top-[78px] flex items-center text-3xl text-neutral-300">
                    GIFT CARD
                  </div>
                  <div className="absolute flex items-center">
                    <div onClick={() => onDecreaseGC()} className="w-[65px] h-[65px] flex items-center justify-center rounded-full bg-neutral-200 dark:bg-neutral-900 hover:bg-neutral-300 dark:bg-neutral-700 transition-all duration-500 cursor-pointer">
                      <FaMinus size={24} className="text-neutral-900 dark:text-neutral-200" />
                    </div>

                    <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-[40px] text-3xl mr-2 ml-2 text-center flex items-center justify-center text-neutral-300">
                      <AnimatedNumber
                        animateToNumber={purchaseGC.quatitygc}
                        includeComma
                        config={{ tension: 89, friction: 40 }}
                        onStart={() => console.log("onStart")}
                        onFinish={() => console.log("onFinish")}
                        animationType={"calm"}
                        duration={200}
                      />
                    </div>
                    <div onClick={() => onIncreaseGC()} className="w-[65px] h-[65px] flex items-center justify-center rounded-full bg-neutral-200 dark:bg-neutral-900 hover:bg-neutral-300 dark:bg-neutral-700 transition-all duration-500 cursor-pointer">
                      <FaPlus size={24} className="text-neutral-900 dark:text-neutral-200" />
                    </div>
                  </div>
                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="absolute bottom-14 flex items-center text-4xl text-neutral-200">
                    {currencyFormat(purchaseGC.totalamount)}
                  </div>
                </div>

              </div>
              <div className="w-full mt-1">
                <Button disabled={(responseCreateGcVenta.isLoading || purchaseGC.quatitygc) < 1 ? true : false}
                  sx={{
                    "&.Mui-disabled": {
                      background: "#525252",
                      color: "#a3a3a3"
                    }
                  }}
                  type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained" color="primary" startIcon={responseCreateGcVenta.isLoading ? <Spinner w={'35px'} h={'35px'} /> : <IoCart size={24} />}>{responseCreateGcVenta.isLoading ? 'Cargando...' : `Pagar ${currencyFormat(purchaseGC.totalamount)}`} </Button>
              </div>
            </form>

          </div>
        </div>
      </Modal>

      <Modal
        open={optionsStreaming.open}
        onClose={() => setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: "", categoryId: "", sublogo: "", description: "", data: null })}
        keepMounted
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div id="authentication-modal" className="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
          <div className="relative m-auto p-2 w-full md:w-9/12 h-auto bg-white dark:bg-black pb-12">
            <div className="w-full flex pb-5 pt-3 pl-3 flex items-center border-b border-neutral-300 dark:border-neutral-800">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: "", categoryId: "", sublogo: "", description: "", data: null })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="">
                <div className="pl-7 pr-2 rounded-lg text-neutral-900 dark:text-neutral-200 text-4xl uppercase">{optionsStreaming.name}</div>
              </div>
            </div>
            <div className="flex flex-wrap p-3 relative">
              <div className="w-full flex items-center justify-center md:w-6/12">
                <div className="w-full h-96 md:w-96 flex items-center justify-center shadow-lg shadow-neutral-400 dark:shadow-neutral-900">
                  <LazyLoadImage
                    className="w-80 h-80 z-10 rounded-[2.3rem]"
                    alt={`No image ${optionsStreaming.name}`}
                    effect="blur"
                    src={`/images/logos/${optionsStreaming.sublogo}`}
                  />
                </div>

              </div>
              <div className="w-full md:w-6/12 text-neutral-900 dark:text-neutral-200 mt-7">
                <div className="uppercase text-xl font-bold">{optionsStreaming.name}</div>
                <div className="w-full flex-wrap mt-10">
                  {
                    (optionsStreaming.data && optionsStreaming.data.length) > 0 ? [...Object.freeze(optionsStreaming.data)].sort((a, b) => (Math.floor(new Date(a.created_at).getTime() / 1000)) - (Math.floor(new Date(b.created_at).getTime() / 1000))).map((category) => {

                      var stockcuentasBoolean = false
                      var stockperfilesBoolean = false
                      var totalstock = category.stock ? category.stock : 0
                      if (category.cuentacompleta && totalstock > 0) {
                        stockcuentasBoolean = true
                      }

                      if (!category.cuentacompleta && totalstock > 0) {
                        stockperfilesBoolean = true
                      }

                      return (
                        <div className="shadow-lg shadow-neutral-400 dark:shadow-black relative w-full flex items-center mb-10 border-4 border-neutral-100 dark:border-neutral-900 p-2 hover:bg-neutral-100 dark:hover:bg-neutral-900 tansition-all duration-500">
                          <div className="absolute right-0 -top-6 flex items-center text-sm bg-neutral-50 dark:bg-neutral-950 p-2">
                            <LazyLoadImage
                              className="w-7 h-7 z-10 mr-1"
                              alt={`No image ${category.location}`}
                              effect="blur"
                              src={`/images/locations/${category.location}.svg`}
                            />
                            Región {category.location}
                          </div>
                          <div className="flex flex-wrap w-[calc(100%-150px)] md:w-[calc(100%-230px)] relative">
                            <div className="w-full font-bold uppercase pt-1 pb-1">{category.name}</div>
                            <div className="w-full pt-1 pb-1">
                              {
                                !user?.reseller ?
                                  <div className="text-green-600 dark:text-green-500 font-bold">{(category.costoperfiluser) - (((category.costoperfiluser) * category.discountperfiluser) / 100) === 0 ? <div className="text-neutral-800 dark:text-neutral-200">GRATIS</div>
                                    :
                                    currencyFormat((category.costoperfiluser) - (((category.costoperfiluser) * category.discountperfiluser) / 100))}</div> :
                                  <div className="text-yellow-500 font-bold">
                                    {
                                      currencyFormat(category.costoperfilreseller)
                                    }
                                  </div>
                              }
                            </div>
                            <div className="w-full pt-1 pb-1">
                              {
                                category.tipoentrega ?
                                  <div className="w-full flex flex-wrap gap-2">
                                    <div className="w-full flex items-center text-green-600 dark:text-green-500 text-xs">
                                      <IoFlashSharp size={24} className="mr-1" /><div className="text-neutral-900 dark:text-neutral-200 capitalize"> Entrega digital instantánea</div>
                                    </div>
                                    <div className="w-full flex items-center text-green-600 dark:text-green-500 text-xs">
                                      <FaExpeditedssl size={24} className="mr-1 text-yellow-500 dark:text-yellow-400" /><div className="text-yellow-500 dark:text-yellow-400 capitalize"> Garantía 30 días</div>
                                    </div>
                                  </div>
                                  :
                                  <div className="w-full flex flex-wrap gap-2">
                                    <div className="w-full flex items-center text-neutral-600 dark:text-neutral-500 text-xs">
                                      <FaClipboardCheck size={24} className="mr-1 text-yellow-500 dark:text-yellow-400" /><div className="text-neutral-900 dark:text-neutral-200 capitalize"> Entrega digital 10-30 minutos</div>
                                    </div>
                                    <div className="w-full flex items-center text-green-600 dark:text-green-500 text-xs">
                                      <FaExpeditedssl size={24} className="mr-1 text-yellow-500 dark:text-yellow-400" /><div className="text-yellow-500 dark:text-yellow-400 capitalize"> Garantía 30 días</div>
                                    </div>
                                  </div>
                              }
                            </div>
                            <div className="w-full pt-1 pb-1 text-neutral-900 dark:text-neutral-200">
                              {
                                category.cuentacompleta ?
                                  <div className="flex items-center text-xs">
                                    <IoServer size={24} className="mr-1" /><div className="text-neutral-900 dark:text-neutral-200 capitalize"> Cuenta Completa</div>
                                  </div>
                                  :
                                  <div className="flex items-center text-xs">
                                    <IoDesktopSharp size={24} className="mr-1" /><div className="text-neutral-900 dark:text-neutral-200 capitalize"> 1 Pantalla</div>
                                  </div>
                              }
                            </div>
                          </div>
                          <div className="flex flex-wrap w-[150px] md:w-[230px]">
                            <div className="w-full md:w-[80px] flex items-center justify-center md:justify-start">
                              {
                                category.tipoentrega ?
                                  <div className={`${totalstock > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>Stock: {totalstock}</div> : <div className={`text-green-600 dark:text-green-400`}>Stock: 10</div>
                              }
                            </div>

                            <div className="w-full md:w-[150px] flex justify-end">
                              <Button
                                disabled={(!stockcuentasBoolean && !stockperfilesBoolean && category.tipoentrega && userToken) ? true : false}
                                onClick={() => open(
                                  optionsStreaming.categoryId,
                                  category._id,
                                  category.maximoperfiles,
                                  category.logo,
                                  category.name,
                                  category.discountperfiluser,
                                  category.tipoentrega,
                                  category.isprofileblocked,
                                  category.location,
                                  category.minmonths,
                                  category.policy,
                                  stockcuentasBoolean,
                                  stockperfilesBoolean,
                                  category.cuentacompleta
                                )}
                                sx={{
                                  "&.Mui-disabled": {
                                    background: "#525252",
                                    color: "#a3a3a3"
                                  }
                                }}
                                type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                size='large' variant="contained" color="primary" startIcon={<IoCart size={24} />}>
                                {(!stockcuentasBoolean && !stockperfilesBoolean && category.tipoentrega && userToken) ? 'Sin stock' : 'Comprar'}</Button>
                            </div>
                          </div>
                        </div>
                      )
                    }) :
                      <div class="flex items-center p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-neutral-800 dark:text-yellow-500" role="alert">
                        <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>
                          <span class="font-bold uppercase">Sin datos!</span> La categoría <b>{optionsStreaming.name}</b> aún no tiene datos registrados!
                        </div>
                      </div>
                  }
                </div>
              </div>
              <div className="w-full flex flex-wrap mt-8 text-neutral-900 dark:text-neutral-200">
                <div className="w-full border-b border-neutral-300 dark:border-neutral-800 flex flex-wrap mb-2">
                  <div className="-mb-[1px] bg-neutral-50 dark:bg-neutral-950 border-t border-l border-r border-neutral-300 dark:border-neutral-800 p-2 flex items-center text-blue-600 dark:text-blue-300">Descripción y modo de uso</div>
                </div>
                <div style={{ whiteSpace: 'pre-wrap' }} className="w-full flex flex-wrap">
                  <div>{parse(htmlData2)}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Modal >

      <Modal
        open={selectgiftcard.open}
        onClose={() => setSelectGiftCard({ ...selectgiftcard, categoryId: "", open: !selectgiftcard.open, name: "", logo: "", description: "", data: null })}
        keepMounted
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" className="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
          <div className="relative m-auto p-5 w-full md:w-9/12 h-auto bg-white dark:bg-black pb-12">
            <div className="w-full flex pb-3 flex items-center border-b border-neutral-300 dark:border-neutral-800">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => setSelectGiftCard({ ...selectgiftcard, categoryId: "", open: !selectgiftcard.open, name: "", logo: "", description: "", data: null })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="ml-5">
                <div className="text-center rounded-lg text-neutral-900 dark:text-neutral-200 text-4xl uppercase">{selectgiftcard.name}</div>
              </div>
            </div>
            <div className="flex flex-wrap relative">
              <div className="w-full flex items-center justify-center md:w-6/12">
                <div className="w-full md:w-96 h-96 flex items-center justify-center shadow-lg shadow-neutral-400 dark:shadow-neutral-900">
                  <LazyLoadImage
                    className="w-80 h-80 z-10 rounded-[2.3rem]"
                    alt={`No image ${selectgiftcard.name}`}
                    effect="blur"
                    src={`/images/logos/${selectgiftcard.logo}`}
                  />
                </div>

              </div>
              <div className="w-full md:w-6/12 text-neutral-900 dark:text-neutral-200">
                <div className="uppercase text-xl font-bold mt-6 md:mt-10 text-left">{selectgiftcard.name}</div>
                <div className="w-full flex-wrap mt-10">
                  {
                    selectgiftcard.data && selectgiftcard.data.length > 0 ? [...Object.freeze(selectgiftcard.data)].sort((a, b) => a.created_at - b.created_at).map((gift) => {

                      return (
                        <div className="shadow-lg shadow-neutral-400 dark:shadow-black relative w-full flex items-center mb-10 border-4 border-neutral-100 dark:border-neutral-900 p-2 hover:bg-neutral-100 dark:hover:bg-neutral-900 tansition-all duration-500">
                          <div className="absolute right-0 -top-6 flex items-center text-sm bg-neutral-50 dark:bg-neutral-950 p-2">
                            <LazyLoadImage
                              className="w-7 h-7 z-10 mr-1"
                              alt={`No image ${gift.location}`}
                              effect="blur"
                              src={`/images/locations/${gift.location}.svg`}
                            />
                            Región {gift.location}
                          </div>
                          <div className="flex flex-wrap w-[calc(100%-150px)] md:w-[calc(100%-230px)] relative">
                            <div className="w-full font-bold uppercase pt-1 pb-1">{gift.name}</div>
                            <div className="w-full pt-1 pb-1">
                              {
                                !user?.reseller ?
                                  <div className="text-green-500 font-bold">{(gift.costouser) - (((gift.costouser) * gift.discountuser) / 100) === 0 ? <div className="text-neutral-800 dark:text-neutral-200">GRATIS</div>
                                    :
                                    currencyFormat((gift.costouser) - (((gift.costouser) * gift.discountuser) / 100))}</div> :
                                  <div className="text-yellow-500 font-bold">
                                    {
                                      currencyFormat(gift.costoreseller)
                                    }
                                  </div>
                              }
                            </div>
                            <div className="w-full pt-1 pb-1">
                              {
                                gift.tipoentrega ?
                                  <div className="w-full flex flex-wrap gap-2">
                                    <div className="w-full flex items-center text-green-600 dark:text-green-500 text-xs">
                                      <IoFlashSharp size={24} className="mr-1" /><div className="text-neutral-900 dark:text-neutral-200 capitalize"> Entrega digital instantánea</div>
                                    </div>
                                    <div className="w-full flex items-center text-green-600 dark:text-green-500 text-xs">
                                      <FaExpeditedssl size={24} className="mr-1 text-yellow-500 dark:text-yellow-400" /><div className="text-yellow-500 dark:text-yellow-400 capitalize"> Garantía 30 días</div>
                                    </div>
                                  </div>
                                  :
                                  <div className="w-full flex flex-wrap gap-2">
                                    <div className="w-full flex items-center text-neutral-600 dark:text-neutral-500 text-xs">
                                      <FaClipboardCheck size={24} className="mr-1 text-yellow-500 dark:text-yellow-400" /><div className="text-neutral-900 dark:text-neutral-200 capitalize"> Entrega digital 10-30 minutos</div>
                                    </div>
                                    <div className="w-full flex items-center text-green-600 dark:text-green-500 text-xs">
                                      <FaExpeditedssl size={24} className="mr-1 text-yellow-500 dark:text-yellow-400" /><div className="text-yellow-500 dark:text-yellow-400 capitalize"> Garantía 30 días</div>
                                    </div>
                                  </div>
                              }
                            </div>
                          </div>

                          <div className="flex flex-wrap w-[150px] md:w-[230px]">
                            <div className="w-full md:w-[80px] flex items-center justify-center md:justify-start">
                              {
                                gift.tipoentrega ?
                                  <div className={`${gift.stock > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>Stock: {gift.stock}</div> : <div className={`text-green-600 dark:text-green-400`}>Stock: 10</div>
                              }
                            </div>

                            <div className="w-full md:w-[150px] flex justify-end">
                              <Button
                                disabled={(gift.stock > 0 || !gift.tipoentrega) ? false : true}
                                onClick={() => OpenPurchaseGiftCards(gift.stock, selectgiftcard.categoryId, gift._id, gift)}
                                sx={{
                                  "&.Mui-disabled": {
                                    background: "#525252",
                                    color: "#a3a3a3"
                                  }
                                }}
                                type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                size='large' variant="contained" color="primary" startIcon={<IoCart size={24} />}>
                                {(gift.stock > 0 || !gift.tipoentrega) ? 'Comprar' : 'Sin stock'}</Button>
                            </div>
                          </div>
                        </div>
                      )
                    }) :
                      <div class="flex items-center p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-neutral-800 dark:text-yellow-500" role="alert">
                        <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>
                          <span class="font-bold uppercase">Sin datos!</span> La categoría <b>{selectgiftcard.name}</b> aún no tiene datos registrados!
                        </div>
                      </div>
                  }
                </div>
              </div>
              <div className="w-full flex flex-wrap mt-8 text-neutral-900 dark:text-neutral-200">
                <div className="w-full border-b border-neutral-300 dark:border-neutral-800 flex flex-wrap mb-2">
                  <div className="-mb-[1px] bg-neutral-50 dark:bg-neutral-950 border-t border-l border-r border-neutral-300 dark:border-neutral-800 p-2 flex items-center text-blue-600 dark:text-blue-300">Descripción y modo de uso</div>
                </div>
                <div style={{ whiteSpace: 'pre-wrap' }} className="w-full flex flex-wrap">
                  <div>{parse(htmlData3)}</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Modal>

      <Modal
        keepMounted
        open={purchase.open}
        onClose={() => SetPurchase({ ...purchase, open: false })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <div id="authentication-modal" className="w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">
          <div className="relative m-auto p-5 w-full md:w-6/12 bg-white dark:bg-black pb-24">
            <div className="flex inline-flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => SetPurchase({ ...purchase, open: false })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <LazyLoadImage
                className="object-cover w-24 ml-5 h-24 z-30 rounded-full p-2"
                alt={`No image category`}
                effect="blur"
                src={`/images/logos/${purchase.imglogo}`}
              />
              <div className="-ml-3">
                <div className="pl-7 pr-2 text-base rounded-lg text-neutral-900 dark:text-neutral-200 text-xl font-bold">{purchase.name}</div>
              </div>
            </div>

            <div className="text-neutral-100 mt-5">
              <div className="w-full flex items-center border-b-neutral-300 dark:border-b-neutral-800 mb-3">
                <label className="text-lg text-neutral-900 dark:text-neutral-200">Información de la compra</label>
              </div>
              <div className="w-full p-5 text-neutral-100 bg-neutral-200 dark:bg-neutral-800">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <div className="border-2 border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-950 w-20 h-20 flex items-center justify-center">
                      <LazyLoadImage
                        className="object-cover w-16 h-16 z-30 rounded-full p-2"
                        alt={`No image category`}
                        effect="blur"
                        src={`/images/locations/${purchase.location}.svg`}
                      />
                    </div>
                    <div className="pl-3 text-neutral-900 dark:text-neutral-200">{purchase.location}</div>
                  </div>

                  <div className="flex items-center">
                    <div className="border-2 border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-950 w-20 h-20 flex items-center justify-center">
                      <FcMultipleSmartphones size={45} />
                    </div>
                    <div className="pl-3 text-neutral-900 dark:text-neutral-200">Digital</div>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={PayPurchase}>
              <div className="w-full">
                <div className="w-full flex items-center border-b-neutral-300 dark:border-b-neutral-800 mt-7">
                  <label className="text-lg text-neutral-900 dark:text-neutral-200">Cuántos meses quieres comprar ?</label>
                </div>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-3 gap-4 w-full bg-neutral-200 dark:bg-neutral-800 p-5 border-b border-b-neutral-300 dark:border-b-neutral-800">
                  <div /*onClick={() => handleSelectedMonths(Number(purchase.minmonths))}*/
                    className={`flex items-center h-24 bg-white cursor-pointer rounded-xl ${purchase.selectedmonths === Number(purchase.minmonths) && 'bg-gradient-blue-custom text-white'}`} key={0}>
                    <div className='w-[75px] h-[75px] flex items-center justify-center'>
                      <LazyLoadImage
                        className="z-10 w-[55px] h-[55px] rounded-full flex"
                        alt={`No image timer}`}
                        effect="blur"
                        src={`/images/logos/timer.webp`}
                      />
                    </div>
                    <div className='grid grid-wrap w-[calc(100%-75)]'>
                      <div className={`text-xl font-bold  ${purchase.selectedmonths === Number(purchase.minmonths) ? 'text-neutral-50' : 'text-neutral-900'}`}>
                        {purchase.minmonths} {purchase.minmonths > 1 ? 'meses' : 'mes'} <span className="text-base">({purchase.minmonths * 30} días)</span>
                      </div>
                      <div className={`text-sm font-bold  ${purchase.selectedmonths === Number(purchase.minmonths) ? 'text-neutral-300' : 'text-neutral-700'}`}>
                        {moment(new Date().setDate(new Date().getDate() + purchase.minmonths * 30)).format("DD/MM/yyyy hh:mm:ss")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative mt-8">
                <label className="text-neutral-900 dark:text-neutral-200">Términos y condiciones del servicio</label>
                <div class="flex items-center">
                  <Checkbox
                    checked={purchase.acept_terms ? true : false}
                    onClick={() => SetPurchase({ ...purchase, acept_terms: !purchase.acept_terms })}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#0027dc' } }}
                  />
                  {!purchase.acept_terms && <span class="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-blue-500 left-2"></span>}
                  <label for="link-checkbox" class="ml-1 text-sm font-medium text-neutral-900 dark:text-neutral-300">Estoy de acuerdo con los
                    <a href="#" onClick={() => SetViewterm(!viewterm)} class="text-blue-600 hover:text-blue-500"> términos y condiciones del servicio</a>.</label>

                </div>
                {
                  viewterm &&
                  <div className="fixed overflow-y-auto top-0 left-0 right-0 bottom-0 z-[1060] w-full h-full bg-neutral-50 bg-opacity-30">
                    <div className="realtive m-auto p-5 w-full h-full md:w-9/12 bg-neutral-200 dark:bg-neutral-900">
                      <div className="flex inline-flex items-center mb-5">
                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                          <IconButton color="error" onClick={() => SetViewterm(!viewterm)}>
                            <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                            />
                          </IconButton>
                        </div>
                        <div className="-ml-3">
                          <div className="pl-7 pr-2 text-3xl rounded-lg text-neutral-900 dark:text-neutral-200 text-lg">Términos y condiciones del servicio</div>
                        </div>
                      </div>
                      <div className={`h-auto text-neutral-900 dark:text-neutral-200 relative transition-all duration-500 ease-in-out`}>
                        {parse(htmlData)}
                      </div>
                    </div>
                  </div>
                }

              </div>

              <div className="w-full mt-5">
                <Button disabled={(purchase.selectedmonths < 1 || !purchase.acept_terms || response.isLoading) ? true : false}
                  sx={{
                    "&.Mui-disabled": {
                      background: "#525252",
                      color: "#a3a3a3"
                    }
                  }}
                  type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained" color="primary" startIcon={(response.isLoading) ? <Spinner w={'35px'} h={'35px'} /> : <IoCart size={24} className="mr-2" />}>{response.isLoading ? ' Procesando...' : 'Pagar ' + currencyFormat(purchase.totalamount)} </Button>
              </div>
            </form>

          </div>
        </div>

      </Modal>
      <Nav
        socket={socket}
        userToken={userToken}
        user={user}
        category={categories}
        CategoryDataIsLoading={CategoryIsLoading}
        userById={userById}
        loadinguserbyid={loadinguserbyid}
        configuration={configuration}
        loadingconfiguration={loadingconfiguration}
        handleOpenCategory={handleOpenCategory}
      />

      <div className="w-full md:w-9/12 m-auto mt-[144px] mb-10 md:pt-4 md:pl-4 md:pr-4 md:pb-10 p-4 md:shadow-lg md:shadow-neutral-400 md:dark:shadow-black bg-neutral-50 dark:bg-neutral-950">
        <Swiper
          modules={[Virtual]}
          slidesPerView={8}
          breakpoints={{
            320: { slidesPerView: 2, spaceBetween: 15 },
            640: { slidesPerView: 4, spaceBetween: 15 },
            1024: { slidesPerView: 8, spaceBetween: 15 },
          }}
          virtual
          className="w-full h-[65px] mb-4"
        >

          {
            !CategoryIsLoading && categories.filter(fil => fil.type === 'GiftCard').map((category, index) => (
              <SwiperSlide onClick={() => handleSelectGiftCard(category._id, category.name, category.description, category.subcategory, category.logo)} className="slide overflow-hidden w-32 cursor-pointer bg-blue-custom text-neutral-100 hover:text-yellow-400 rounded-lg" key={index}>
                <div className="overflow-hidden flex items-center w-full h-[65px] ">
                  <div className="h-[50px] w-[50px] flex items-center justify-center">
                    <LazyLoadImage
                      className="h-[35px] w-[35px] rounded-full"
                      alt={`No image`}
                      effect="blur"
                      src={`./images/logos/${category.sublogo}`}
                    />
                  </div>

                  <div className="w-[calc(100%-50px)] text-[12px] text-left font-semibold pr-1">{category.name}</div>
                </div>
              </SwiperSlide>
            ))

          }

        </Swiper>
        <Slider />
        <div className="flex flex-wrap rounded bg-neutral-200 dark:bg-neutral-900">
          {
            isOpen && <div className="bg-opacity-0 fixed inset-0 z-30" onClick={() => setIsOpen(!isOpen)}></div>
          }

          <div className="w-full md:w-[300px] p-4 bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 border-r border-neutral-300 dark:border-neutral-800">
            <div className="block md:hidden">
              <div className={`fixed top-14 left-0 z-40 ${isOpen ? 'w-full' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                <div className={`flex items-center mb-0`}>
                  <div onClick={() => setIsOpen(!isOpen)} className="rounded-full w-14 h-14 cursor-pointer mr-4 flex items-center">
                    <HiMenuAlt2 size={32} className="text-neutral-100" />
                  </div>
                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="text-4xl"></div>
                </div>
                <Transition
                  show={isOpen}
                  enter="transition ease-out duration-75 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {/* Contenido del menú desplegable */}
                  <div className="text-neutral-900 z-40 absolute left-0 w-full mt-0 origin-top-right bg-neutral-100 divide-y divide-neutral-300">

                    <div className="flex items-center p-3">
                      <div className="w-[45px] flex items-center">
                        <LazyLoadImage
                          className="w-[45px] rounded-full"
                          alt={`No image`}
                          effect="blur"
                          src={`/images/logos/streaming.png`}
                        />
                      </div>
                      <div onClick={() => handleSelectService('streaming')} className={`${services === 'streaming' && 'font-bold text-yellow-600'} capitalize ml-2 hover:font-bold transition-all duration-300 cursor-pointer h-full w-[calc(100%-45px)]`}>
                        Servicios streaming
                      </div>
                    </div>
                    <div className="flex items-center p-3">
                      <div className="w-[45px] h-[45px] flex items-center">
                        <LazyLoadImage
                          className="w-[45px] h-[45px] rounded-full"
                          alt={`No image`}
                          effect="blur"
                          src={`/images/logos/rsociales.png`}
                        />
                      </div>
                      <div onClick={() => handleSelectService('rsociales')} className={`${services === 'rsociales' && 'font-bold text-yellow-600'} capitalize ml-2 hover:font-bold transition-all duration-300 cursor-pointer h-full w-[calc(100%-45px)]`}>
                        Comprar <span className="text-xs">seguidores, likes, ...</span>
                      </div>
                    </div>

                    {
                      !CategoryIsLoading ? categories.filter(fil => fil.type === 'GiftCard').map((category, index) => (
                        <div className="flex items-center p-3" key={index}>
                          <div className="w-[45px] h-[45px] flex items-center">
                            <LazyLoadImage
                              className="w-[45px] h-[45px] rounded-full"
                              alt={`No image`}
                              effect="blur"
                              src={`/images/logos/${category.logo}`}
                            />
                          </div>

                          <div onClick={() => handleSelectGiftCard(category._id, category.name, category.description, category.subcategory, category.logo)} className={`${selectgiftcard.name === category.name && 'font-bold text-yellow-500'} capitalize ml-2 hover:font-bold transition-all duration-300 cursor-pointer h-full w-[calc(100%-45px)]`}>
                            {category.name}
                          </div>
                        </div>
                      )) : <div className="w-full flex items-center justify-center"><Spinner /></div>
                    }
                  </div>
                </Transition>
              </div>
            </div>


            <div className="hidden md:block">
              <div style={{ fontFamily: 'Outfit, sans-serif' }} className="text-xl md:text-3xl mb-4 bg-blue-custom pt-5 pb-5 text-center text-neutral-200">
                SERVICIOS
              </div>
              <div onClick={() => handleSelectService('streaming')} className="flex transition-all ease-in duration-500 cursor-pointer items-center border-b border-neutral-400 dark:border-neutral-700 pb-2 pt-4 pl-1 hover:bg-neutral-300 dark:hover:bg-neutral-800">
                <div className="w-[45px]">
                  <LazyLoadImage
                    className="w-[45px]"
                    alt={`No image`}
                    effect="blur"
                    src={`/images/logos/streaming.png`}
                  />
                </div>
                <div className={`${services === 'streaming' && 'font-bold text-yellow-500'} capitalize ml-2 h-full w-[calc(100%-45px)]`}>
                  Servicios streaming
                </div>
              </div>
              <div onClick={() => handleSelectService('rsociales')} className="flex transition-all ease-in duration-500 cursor-pointer items-center border-b border-neutral-400 dark:border-neutral-700 pb-2 pt-4 pl-1 hover:bg-neutral-300 dark:hover:bg-neutral-800">
                <div className="w-[45px]">
                  <LazyLoadImage
                    className="w-[45px]"
                    alt={`No image`}
                    effect="blur"
                    src={`/images/logos/rsociales.png`}
                  />
                </div>
                <div className={`${services === 'rsociales' && 'font-bold text-yellow-500'} capitalize ml-2 cursor-pointer h-full w-[calc(100%-45px)]`}>
                  Comprar <span className="text-xs">seguidores, likes, ...</span>
                </div>
              </div>

              {
                !CategoryIsLoading ? categories.filter(fil => fil.type === 'GiftCard').map((category, index) => (
                  <div onClick={() => handleSelectGiftCard(category._id, category.name, category.description, category.subcategory, category.logo)} className="flex transition-all ease-in duration-500 cursor-pointer items-center border-b border-neutral-400 dark:border-neutral-700 pb-2 pt-4 pl-1 hover:bg-neutral-300 dark:hover:bg-neutral-800" key={index}>
                    <div className="w-[45px]">
                      <LazyLoadImage
                        className="w-[45px] h-[45px] rounded-full"
                        alt={`No image`}
                        effect="blur"
                        src={`/images/logos/${category.sublogo}`}
                      />
                    </div>

                    <div className={`${selectgiftcard.categoryId === category._id && 'font-bold text-yellow-500'} capitalize ml-2 cursor-pointer h-full w-[calc(100%-45px)]`}>
                      {category.name}
                    </div>
                  </div>
                )) : <div className="w-full flex items-center justify-center mt-5"><Spinner /></div>
              }
            </div>
          </div>
          <div ref={sectionRef} className="w-full md:w-[calc(100%-300px)] p-4 bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            {services === 'streaming' &&
              <Categories
                socket={socket}
                CategoryData={categories.filter(fil => fil.type === 'Streaming')}
                CategoryDataIsLoading={CategoryIsLoading}
                openOptions={openOptions}
              />
            }

            {services === 'rsociales' &&
              <div>
                <SocialServices
                  socket={socket}
                  userToken={userToken}
                  user={user}
                  UpdateUserById={UpdateUserById}
                />
              </div>
            }
          </div>
          <SocialServicesHome
            socket={socket}
            userToken={userToken}
            user={user}
            UpdateUserById={UpdateUserById}
          />
          <LastVentas
            lastventas={lastventas?.ventas}
            loadinglastventas={fechinglastventas}
          />
        </div>
      </div>
      <footer className="w-full p-4 md:p-0">
        <div class="bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 py-8 text-neutral-400 text-sm p-4 md:p-0">
          <div class="container px-4 mx-auto w-full md:w-9/12">
            <div class="-mx-4 flex flex-wrap justify-between">
              <div class="my-4 w-full md:w-6/12">
                <div className="w-full md:w-6/12">
                  {
                    !loadingconfiguration ? <Link to="/" className="">
                      <img className="object-cover w-40" src={`/images/logos/${configuration && configuration.logo}`} alt={`No image logo`}></img>
                    </Link> : <Spinner w={'35px'} h={'35px'} />
                  }
                  <p class="text-justify mt-5">
                    En cinedar ofrecemos un servicio de streaming de calidad, podras disfrutar con tus amigos y familia, películas, series, animes, musica, documentales, tv en vivo y mucho mas; al mejor precio del mercado, que esperas registrate y adquiere el servicio que más te guste.
                  </p>
                </div>
              </div>
              <div class="w-full flex justify-start md:justify-center my-4 w-full md:w-3/12">
                <div className="">
                  <div className="w-full">
                    <h2 class="text-xl pb-4 mb-0 border-b-4 border-blue-600 text-left">Métodos de pago</h2>
                  </div>
                  <div className="w-full flex justify-end mt-4">
                    <LazyLoadImage
                      className="w-84 h-10"
                      alt={`No image`}
                      effect="blur"
                      src={`/images/paymethods/paymethods.webp`}
                    />
                  </div>
                </div>
              </div>
              <div class="w-full flex justify-start md:justify-end md:w-3/12 pt-4">
                <div className="">
                  <div className="w-full">
                    <h2 class="text-xl pb-4 mb-0 border-b-4 border-blue-600 text-left">Contáctanos</h2>
                  </div>
                  <div className="w-full flex justify-end mt-4">
                    <a href="https://www.facebook.com/condezodarvin" class="inline-flex items-center justify-center h-8 w-8 border border-neutral-100 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400">
                      <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                      </svg>
                    </a>
                    <a href="https://instagram.com/darvin.condezo" class="inline-flex items-center justify-center h-8 w-8 border border-neutral-100 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400">
                      <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                      </svg>
                    </a>
                    <a href="https://www.tiktok.com/@darvincondezo" class="inline-flex items-center justify-center h-8 w-8 border border-neutral-100 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400">
                      <svg class="w-4 h-4 fill-current" fill="#000000" width="800px" height="800px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                        <path d="M232,84v40a7.99977,7.99977,0,0,1-8,8,103.32406,103.32406,0,0,1-48.00049-11.70752L176,156A76,76,0,1,1,86.59766,81.17971,7.99952,7.99952,0,0,1,96,89.05569l-.00049,41.63916a7.99971,7.99971,0,0,1-4.56689,7.22607A20.00272,20.00272,0,1,0,120,156V28a7.99977,7.99977,0,0,1,8-8h40a7.99977,7.99977,0,0,1,8,8,48.05436,48.05436,0,0,0,48,48A7.99977,7.99977,0,0,1,232,84Z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gradient-blue-custom py-4 text-neutral-100">
          <div class="container mx-auto px-16">
            <div class="-mx-4 flex flex-wrap justify-between">
              <div class="px-4 w-full text-center sm:w-auto sm:text-left">
                Copyright © 2023
                {new Date().getFullYear() > 2020 && (" - " + new Date().getFullYear() + " ")} {configuration && configuration.name}
              </div>
              <div class="px-4 w-full text-center sm:w-auto sm:text-left">
                Hecho por
                <a
                  className="text-decoration-none fw-bolder ml-2"
                  href="https://t.me/celtecxxi"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  JasonDEV
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div >
  );
};
export default Home;
