import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tokenService = createApi({
  reducerPath: "token",
  tagTypes: "tokens",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createPurchaseToken: builder.mutation({
        query: (data) => {
          return {
            url: "create-purchase-token",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["tokens"],
      }),

      createOfflineUserToken: builder.mutation({
        query: (data) => {
          return {
            url: "create-offlineuser-token",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["tokens"],
      })
    };
  },
});
export const {
  useCreatePurchaseTokenMutation,
  useCreateOfflineUserTokenMutation
} = tokenService;
export default tokenService;
