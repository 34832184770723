import { useState, useEffect } from "react"
import { useAuthLoginMutation } from "../../store/services/authService"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { deleteOfflineUserToken, setAdminToken } from "../../store/reducers/authReducer"
import toast, { Toaster } from 'react-hot-toast'
import { message } from "../../utils/Funciones.js"
import { Button, IconButton, TextField } from "@mui/material"
import { IoEye, IoEyeOff, IoFingerPrint, IoLogIn } from "react-icons/io5"
import { MdMail } from "react-icons/md"
import Spinner from "../../components/Spinner"
import { LazyLoadImage } from "react-lazy-load-image-component"
const moment = require("moment");
require("moment/locale/es");

const AdminLogin = ({ socket }) => {
    const { userToken, user } = useSelector((state) => state.authReducer);
    const { adminToken, admin } = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
    const [VerPasswords, SetVerPasswords] = useState(
        {
            password: false,
        });

    const [state, setState] = useState({
        email: '',
        password: ''
    })
    const handleInputs = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const [login, response] = useAuthLoginMutation();
    const adminLoginFunction = e => {
        e.preventDefault();
        login(state);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (response.isSuccess) {

            if (response?.data?.admin) {
                if (userToken || adminToken) {
                    message('ERROR: Existe una sesión abierta.', 'error', 5)
                } else {
                    message(response?.data?.msg, 'success', 5)
                    socket.emit("registrarse", response?.data?.userId, true);
                    localStorage.setItem('admin-token', response?.data?.token);
                    dispatch(deleteOfflineUserToken('offlineUserToken'))
                    dispatch(setAdminToken(response?.data?.token));
                    socket.emit('adminlogin', true, response?.data?.username)
                    navigate('/admin/dashboard')
                }
            } else {
                message("Privilegios insuficientes!", 'error', 3)
            }
        }
    }, [response.isSuccess])

    useEffect(() => {
        if (response.isError) {
            socket.emit('adminlogin', false, response?.error?.data?.username)
            response?.error?.data?.errors.map((err) => {
                message(err.msg, 'error', 3)
            });
        }
    }, [response.isError])

    return (
        <div class="min-h-screen bg-neutral-50 dark:bg-neutral-950 text-neutral-900 dark:text-neutral-200 flex justify-center">
            <Toaster position="top-right" reverseOrder={true} />
            <div class="max-w-screen-xl m-0 sm:m-10 bg-neutral-200 dark:bg-neutral-900 shadow sm:rounded-lg flex justify-center flex-1">
                <div class="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                    <div className="w-8/12 md:w-6/12 m-auto">
                        <LazyLoadImage
                            className="w-mx-auto"
                            alt={'No image logo'}
                            effect="blur"
                            src={`${localStorage.getItem('color-theme') === 'light' ? '/images/logos/logo-light.png' : '/images/logos/logo-dark.png'}`}
                        />
                    </div>
                    <div class="mt-12 flex flex-col items-center">
                        <div className="w-10/12 md:w-8/12 m-auto">
                            <LazyLoadImage
                                className="w-mx-auto"
                                alt={'No image logo'}
                                effect="blur"
                                src={`${localStorage.getItem('color-theme') === 'light' ? '/images/logos/admin-login-head-light.png' : '/images/logos/admin-login-head-dark.png'}`}
                            />
                        </div>

                        <form onSubmit={adminLoginFunction}>
                            <div class="w-full flex-1 mt-0">
                                <div class="mx-auto max-w-xs">
                                    <div className="w-full p-3 relative">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Correo</label>
                                        <input autoFocus name="email" className="bg-neutral-50 dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] pl-12 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                            placeholder="Correo"
                                            autoComplete="off"
                                            required
                                            onChange={handleInputs}
                                            value={state.email}
                                            type="email"
                                        />
                                        <div class="absolute inset-y-4 mt-7 right-left pl-3 flex items-center text-sm leading-5">
                                            <MdMail size={26} className="text-neutral-700 dark:text-neutral-300 mr-1" />
                                        </div>
                                    </div>
                                    <div className="w-full p-3 relative">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Contraseña</label>
                                        <input name="password" className="bg-neutral-50 dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] pl-12 pr-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                            placeholder="Contraseña"
                                            autoComplete="off"
                                            required
                                            onChange={handleInputs}
                                            value={state.password}
                                            type={VerPasswords.password ? 'text' : 'password'}
                                        />
                                        <div class="absolute inset-y-4 mt-7 right-left pl-3 flex items-center text-sm leading-5">
                                            <IoFingerPrint size={26} className="text-neutral-700 dark:text-neutral-300 mr-1" />
                                        </div>
                                        <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                            <IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['password']: !VerPasswords.password })}>
                                                {VerPasswords.password ? <IoEyeOff className="text-neutral-700 dark:text-neutral-300" /> : <IoEye className="text-neutral-700 dark:text-neutral-300" />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                                        <Button
                                            type="submit"
                                            disabled={response.isLoading ? true : false}
                                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                            size='large' variant="contained"
                                        >
                                            {response.isLoading ? <Spinner /> : <IoLogIn size={24} className="mr-2" />}
                                            {response.isLoading ? 'Ingresando...' : 'Ingresar'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="flex-1 bg-neutral-100 dark:bg-neutral-800 text-center hidden lg:flex h-full">
                    <div className="flex self-center">
                        <LazyLoadImage
                            className="w-mx-auto w-10/12 md:w-7/12 m-auto"
                            alt={'No image logo'}
                            effect="blur"
                            src={`/images/logos/admin-login-right.png`}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AdminLogin;