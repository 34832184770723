import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import TextField from '@mui/material/TextField';
import toast, { Toaster } from "react-hot-toast";
import { useCreatePaymentIziPayDonationMutation, useCreatePaymentIziPayMutation, useGenerateQrCodeBinancePayDonationMutation, useGenerateQrCodeBinancePayMutation, useGetPaymentsByUserIdQuery, useSendPaymentMercadoPagoDonationMutation, useSendPaymentMercadoPagoMutation, useSendPaymentMutation, useSendYapePaymentMutation, useValidatePaymentIziPayDonationMutation, useValidatePaymentIziPayMutation } from "../../store/services/paymentService";
import { Button, IconButton, Tooltip } from "@mui/material";
import UserScreenHeader from '../../components/home/UserScreenHeader';
import { FaCoins, FaUpload } from 'react-icons/fa';
import { currencyFormat, getUrlParameters, message, PermiteSoloNumeros } from '../../utils/Funciones.js';
import { IoCard, IoChevronForward, IoCloseSharp, IoInformation, IoLogoWhatsapp, IoSend } from 'react-icons/io5';
import { MdPayment } from 'react-icons/md';
import { SiBinance, SiMastercard } from "react-icons/si";
import { setUserToken } from '../../store/reducers/authReducer';
import AccountList from '../../components/home/AccountList';
import Spinner from '../../components/Spinner';
import ImagesPreviewPayment from "../../components/home/ImagesPreviewPayment";
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSendMessageWhatsappNewServiceMutation } from '../../store/services/whatsappService';
import IziPayPaymentForm from '../../components/home/IziPayPaymentForm';
import KRGlue from '@lyracom/embedded-form-glue';

const Gracias = () => {
  const navigate = useNavigate();

  const goToDonation = () => {
    navigate('/donacion')
  }


  return (
    <div className='pt-5 w-full flex justify-center'>
      <div className="w-full md:w-7/12 flex flex-wrap">
        <div className="realtive m-auto p-1 w-full md:w-7/12 bg-neutral-200 dark:bg-neutral-900 flex flex-wrap justify-center">
          <LazyLoadImage
            className="z-10 w-full h-screen rounded-sm flex"
            alt={`No image}`}
            effect="blur"
            src={`/images/gracias.jpg`}
          />
          <Button
            className='absolute bottom-[70px] m-auto'
            onClick={goToDonation}
            type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
            size='large' variant="contained" color="primary">Donar otra vez</Button>
        </div>
      </div>
    </div>
  );
};

export default Gracias;