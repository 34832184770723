import AccountList from "../../components/home/AccountList";
import { Toaster } from "react-hot-toast";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { IoAlarm, IoBagCheck, IoBan, IoCheckbox, IoChevronDown, IoCloseCircleOutline, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoPerson, IoRefreshSharp, IoReload, IoReloadCircleSharp, IoSearch, IoThumbsDownSharp, IoTime } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import Text from "../../components/skeleton/Text";
import { CalcularExpiracion, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdAvTimer, MdDevices, MdMail, MdNearbyError, MdPhoneIphone, MdUpdate } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { Pagination, PaginationItem, Stack, Button, IconButton, MenuItem, TextField, Tooltip, FormControl, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FaClipboard, FaShippingFast } from "react-icons/fa";
import { useGetAllVentasByUserIdQuery, useRenewServiceMutation } from "../../store/services/ventasService";
import { setUserToken } from "../../store/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment/locale/es';
import Modal from '@mui/material/Modal';
import { useGetAllCuentasQuery } from "../../store/services/cuentaService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BsCalendar2CheckFill } from "react-icons/bs";


const UserOrders = ({
  socket,
  userToken,
  user,
  SECRET_KEY,
  loadinguserbyid,
  userById,
  UpdateUserById,
  configuration,
  loadingconfiguration
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [limit, setLimit] = useState(30);
  const [infopedido, setInfopedido] = useState('')

  const { data: ventas, isFetching: loadingventas, refetch } = useGetAllVentasByUserIdQuery({ id: userToken && user?.id, search: searchTerm.trim(), page: currentPage, limit: limit });
  const { data: cuentas, isFetching: loadingcuentas, refetch: refetchcuentas } = useGetAllCuentasQuery();

  const { categories, isLoading: categoryIsLoading } = useSelector((state) => state.categoryReducer);
  const [curTime, SetCurTime] = useState(null)
  const [searchVenta, SetSearchVenta] = useState("")
  const [verdetalles, SetVerDetalles] = useState({
    open: false,
    categoryname: "",
    categorylogo: "",
    correo: "",
    passwordcuenta: "",
    data: [],
    copied: false
  })
  const [renew, SetRenew] = useState({
    open: false,
    quantitymonths: 0,
    costo: 0,
    type: "",
    id: "",
    catname: "",
    logo: "",
    ventaId: "",
    minmonths: 1
  })

  useEffect(async () => {
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [searchTerm, currentPage, limit]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [])

  useEffect(async () => {
    await refetchcuentas()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetchcuentas.abort();
    };
  }, [])

  useEffect(() => {
    setInterval(function () {
      SetCurTime(new Date().toLocaleString());
    }.bind(this), 1000);
  }, [])

  const HandleOpenDetalles = (id, CategoryName, CategoryLogo, Correo, passwordcuenta) => {
    const VentaById = !loadingventas && ventas?.ventas.filter((venta) => {
      if (venta._id === id) {
        return venta
      }
    })

    SetVerDetalles({
      ...verdetalles,
      ['open']: !verdetalles.open,
      ['data']: VentaById,
      ['categoryname']: CategoryName,
      ['categorylogo']: CategoryLogo,
      ['correo']: Correo,
      ['passwordcuenta']: passwordcuenta
    })

    if (VentaById) {
      var InformacionPedido =
        `*INFORMACIÓN DE LA CUENTA* 
*Servicio :* ${CategoryName}
*Correo :* ${Correo}
*Contraseña :* ${passwordcuenta}
*Tipo de servicio :* ${VentaById[0]?.type}
*Expiración :* ${moment(VentaById[0]?.expirationdate).format("dddd, DD [de] MMMM [de] YYYY")}
${VentaById[0]?.isprofileblocked ?
          `*INFORMACIÓN DE PERFILES* 
*Nombre :* ${VentaById[0]?.dataperfiles[0].name}
*PIN :* ${VentaById[0]?.dataperfiles[0].password}` : ''}`

      setInfopedido(InformacionPedido)
    }
  }

  const HandleOpenRenew = (id, catId, subcategoryId, ventaId, type, catname, logo) => {
    var actualcosto = 0
    var minimomonths = 1
    if (userToken && user) {
      !categoryIsLoading && categories.find((cat) => {
        if (cat._id === catId) {
          cat.subcategory.find((sub) => {
            if (subcategoryId === sub._id) {
              minimomonths = Number(sub.minmonths)

              if (type === '1 pantalla') {
                if (user.reseller) {
                  actualcosto = sub.costoperfilreseller
                } else {
                  actualcosto = ((sub.costoperfiluser - (sub.costoperfiluser * sub.discountperfiluser) / 100))
                }
              } else if (type === 'cuenta completa') {
                if (user.reseller) {
                  actualcosto = sub.costocuentareseller
                } else {
                  actualcosto = sub.costocuentauser
                }
              }
            }
          })
        }
      })
    } else {
      message('Token inválido, este problema se resolverá reingresando al sistema', 'error', 4)
    }

    SetRenew({ ...renew, open: true, costo: actualcosto, type: type, catname: catname, logo: logo, id: id, ventaId: ventaId, minmonths: minimomonths })
  }

  const [renewService, ResponseRenewService] = useRenewServiceMutation();

  const HandleRenewService = () => {
    if (userToken && user) {
      if (renew.quantitymonths > 0) {
        renewService(
          {
            _id: renew.id,
            ventaId: renew.ventaId,
            costo: renew.costo,
            quantitymonths: renew.quantitymonths,
            minmonths: renew.minmonths
          })
      } else {
        message('Debes seleccionar la cantidad de meses que vas a renovar', 'error', 4)
      }
    } else {
      message('Token inválido, este problema se resolverá reingresando al sistema', 'error', 4)
    }
  }

  useEffect(() => {
    if (ResponseRenewService?.isSuccess) {
      if (userToken) {

        message(ResponseRenewService?.data?.msg, 'success', 5)
        socket.emit("renewservice",
          user?.name,
          ResponseRenewService?.data?.callback?.ventaId,
          ResponseRenewService?.data?.callback?.quantitymonths)
        SetRenew({ ...renew, open: false, quantitymonths: 0, costo: 0, type: "", id: "", catname: "", logo: "", ventaId: "" })

        UpdateUserById(ResponseRenewService?.data?.callback?.token)
      }
    }
  }, [ResponseRenewService?.isSuccess])

  useEffect(() => {
    if (ResponseRenewService?.isError) {
      message(ResponseRenewService?.error?.data?.message, 'error', 4)
    }
  }, [ResponseRenewService?.error])

  const onCopy = (text) => {
    SetVerDetalles({ ...verdetalles, copied: true });
    message(text, 'success', 2)
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  return (
    <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <UserScreenHeader>
        <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
          <IoBagCheck size={32} className="mr-2" /> Mis pedidos streaming<span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">{!loadingventas && ventas?.totalventas}</span></div>
      </UserScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[106px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pt-8 pb-8">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe el nombre de plataforma, correo de cuenta o código de pedido"
            required
            onChange={debouncedChangeHandler}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[211px] pb-5 pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950">
        <div className="w-full flex items-center justify-center md:justify-end text-neutral-800 dark:text-neutral-200 mt-0 h-10">
          {
            !loadingventas ?
              <>
                <div className="hidden md:block">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!loadingventas && Number(ventas?.totalventasFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                </div>
                <div className="block md:hidden">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!loadingventas && Number(ventas?.totalventasFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                </div>
              </>
              : <Spinner />
          }

        </div>
      </div>
      <Modal
        keepMounted
        open={verdetalles.open}
        onClose={() => SetVerDetalles({
          ...verdetalles,
          open: false,
          categoryname: "",
          categorylogo: "",
          correo: "",
          passwordcuenta: "",
          data: []
        })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">

          <div className="relative m-auto p-5 w-full md:w-5/12 bg-white dark:bg-black pb-24">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => SetVerDetalles({
                  ...verdetalles,
                  open: false,
                  categoryname: "",
                  categorylogo: "",
                  correo: "",
                  passwordcuenta: "",
                  data: []
                })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Detalles del pedido</div>
            </div>


            <div className="w-full mt-5 mb-3 text-neutral-900 dark:text-neutral-200">
              <div className="grid grid-wrap w-full md:w-6/12">
                <div className="flex inline-flex items-center">
                  <img class="w-12 h-12 z-20 rounded-full flex inline-flex scale"
                    src={`/images/logos/${verdetalles.categorylogo}`} alt={`No image ${verdetalles.categoryname}`} />
                  <div className="flex items-center">
                    <div className="ml-3 text-xl font-semibold">{verdetalles.categoryname}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex inline-flex flex-wrap mt-5 text-neutral-900 dark:text-neutral-200 bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black rounded-lg mb-5 pb-5">
              <label className="absolute -mt-4 text-lg ml-5">Información general</label>
              <div className="grid grid-wrap w-full md:w-6/12 mt-4 pl-5 pr-5 pt-3 pb-3">

                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg"><IoTime size={28} className="mr-2" />
                  {setUnixToTimeFormat("info_contacto", verdetalles?.data[0]?.created_at)}</div>
                {moment(verdetalles?.data[0]?.created_at).format("yyyy-MM-DDTHH:mm") !== moment(verdetalles?.data[0]?.last_update).format("yyyy-MM-DDTHH:mm") &&
                  <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><span className="text-green-400 flex items-center"><IoReload size={28} className="mr-2" />{setUnixToTimeFormat("renew", verdetalles?.data[0]?.last_update)}</span></div>}

                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><IoAlarm size={28} className={`${CalcularExpiracion(verdetalles?.data[0]?.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-500'} mr-2`} />
                  <span className={`${CalcularExpiracion(verdetalles?.data[0]?.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-400'}`}>{moment(verdetalles?.data[0]?.expirationdate).format("dddd, DD [de] MMMM [de] yyyy")}</span></div>

                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><BsCalendar2CheckFill size={24} className="mr-2" />
                  <span>{verdetalles?.data[0]?.totalmonths < 2 ? verdetalles?.data[0]?.totalmonths + ' mes' : verdetalles?.data[0]?.totalmonths + ' meses'}</span></div>
                <div className="flex inline-flex items-center p-2 bg-white dark:bg-black rounded-lg mt-2">
                  {verdetalles?.data[0]?.state === "complete" && <div className="flex inline-flex items-center text-green-400 text-sm">
                    <FaCircleCheck size={24} className="mr-2" /> Completado</div>}
                  {verdetalles?.data[0]?.state === "pending" && <div className="flex inline-flex items-center text-yellow-400 text-sm">
                    <MdAvTimer size={28} className="mr-2" />Pendiente</div>}
                  {verdetalles?.data[0]?.state === "rejected" && <div className="flex inline-flex items-center text-red-400 text-sm">
                    <IoThumbsDownSharp size={28} className="mr-2" /> Rechazado</div>}
                  {verdetalles?.data[0]?.state === "locked" && <div className="flex inline-flex items-center text-red-400 text-sm">
                    <IoLockClosed size={28} className="mr-2" /> Bloqueado</div>}
                </div>
                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><IoDesktopSharp size={28} className="mr-2" />{verdetalles?.data[0]?.type}</div>
              </div>
            </div>
            <div className="mt-5 text-neutral-900 dark:text-neutral-200 bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black p-5 rounded-lg">
              <label className="absolute -mt-9 text-lg">Información de cuenta</label>
              <div className="flex items-center w-full bg-neutral-200 dark:bg-neutral-900 p-3 rounded-lg">
                <div className="w-[35px] flex items-center"><MdMail size={28} className="text-yellow-500" /></div>
                <div className="w-[calc(100%-55px)] text-sm flex items-center">
                  <span className="flex items-center text-base mr-1">Correo:</span>
                  <div className="truncate ...">
                    {verdetalles?.data[0]?.state === "locked" ?
                      <span className="text-red-400"> Bloqueado</span>
                      :
                      verdetalles.correo}
                  </div>
                </div>
                <div className="w-[20px] flex items-center">
                  {
                    verdetalles?.data[0]?.state === "complete" && <CopyToClipboard text={verdetalles.correo} onCopy={() => onCopy('Correo copiado')}>
                      <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                    </CopyToClipboard>
                  }
                </div>
              </div>
              <div className="relative flex items-center w-full bg-neutral-200 dark:bg-neutral-900 p-3 mt-3 rounded-lg">
                <div className="w-[35px] flex items-center"><IoFingerPrint size={28} className="mr-1 text-red-500" /></div>
                <div className="w-[calc(100%-55px)] text-sm flex items-center">
                  <span className="flex items-center text-base mr-1">Contraseña: </span>
                  <div className="truncate ...">
                    {verdetalles?.data[0]?.state === "locked" ?
                      <span className="text-red-400"> Bloqueado</span>
                      :
                      verdetalles.passwordcuenta}
                  </div>
                </div>
                <div className="w-[20px] flex items-center">
                  {
                    verdetalles?.data[0]?.state === "complete" && <CopyToClipboard text={verdetalles.passwordcuenta} onCopy={() => onCopy('Contraseña copiada')}>
                      <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                    </CopyToClipboard>
                  }
                </div>
              </div>
            </div>

            {verdetalles?.data[0]?.isprofileblocked &&
              <div className="w-full flex inline-flex flex-wrap mt-5 text-neutral-900 dark:text-neutral-200">
                <div className="w-full flex flex-wrap mt-5 bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black rounded-lg p-5">
                  <label className="absolute -mt-9 text-lg">Información de perfiles</label>
                  {
                    verdetalles?.data[0]?.state !== "locked" ? (
                      verdetalles?.data[0]?.dataperfiles?.map((dp, index) => {
                        return (
                          <div key={index} className="w-full">
                            <div className="w-full mt-3">
                              <div className="grid grid-wrap w-full md:w-4/12">
                                <div className="flex inline-flex items-center">
                                  <div className="m-2 w-14 h-14 rounded-full bg-neutral-300 dark:bg-neutral-700 flex inline-flex items-center"><IoPerson className="w-10 h-10 z-30 flex inline-flex m-auto" /></div>
                                  <div className="grid self-center">
                                    <div className="ml-2 text-base font-semibold">{dp.name}</div>
                                    <div className="ml-2 text-sm flex inline-flex items-center bg-neutral-200 dark:bg-neutral-700 p-3"><IoFingerPrint size={24} /><span className="text-yellow-700 dark:text-yellow-500 font-semibold text-xl">{dp.password === "" ? 'Sin contraseña' : dp.password}</span></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : <span className="text-red-400 ml-5">Bloqueado</span>
                  }
                </div>
              </div>
            }

            <div className='flex items-center justify-center mt-10 mb-5'>
              <div className="bg-neutral-300 dark:bg-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-950 rounded-full w-24 h-24 flex items-center justify-center">
                <CopyToClipboard text={infopedido} onCopy={() => onCopy('Información copiada')}>
                  <FaClipboard size={45} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        keepMounted
        open={renew.open}
        onClose={() => SetRenew({ ...renew, open: false, quantitymonths: 0, costo: 0, type: "", id: "", catname: "", logo: "", ventaId: "" })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">

          <div className="realtive m-auto p-5 w-full md:w-5/12 bg-white dark:bg-black">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => SetRenew({ ...renew, open: false, quantitymonths: 0, costo: 0, type: "", id: "", catname: "", logo: "", ventaId: "" })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Renovar servicio</div>
            </div>
            <div className="grid grid-wrap w-full md:w-4/12 mt-5 mb-5">
              <div className="flex inline-flex items-center">
                <span className="absolute -mb-[100px] ml-[0px] text-neutral-900 dark:text-neutral-200 
              text-base rounded-lg bg-neutral-50 dark:bg-neutral-950 p-2 flex inline-flex 
              items-center justify-center">{renew.ventaId}</span>
                <img class="w-14 h-14 z-30 rounded-full flex inline-flex"
                  src={`/images/logos/${renew.logo}`} alt={`No image ${renew.catname}`} />
                <div className="grid self-center">
                  <div className="ml-2 text-base font-semibold text-neutral-900 dark:text-neutral-200">{renew.catname}</div>
                </div>
              </div>
            </div>
            <div className="w-full mb-5 mt-12">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Cantidad de meses que quieres renovar?</label>
              <div className="relative">
                <FormControl fullWidth>
                  <Select
                    name="quantitymonths"
                    className="flex items-center"
                    variant="outlined"
                    value={renew.quantitymonths}
                    onChange={(e) => SetRenew({ ...renew, quantitymonths: e.target.value })}
                  >
                    {
                      renew.minmonths === 1 ?
                        [1, 3, 6, 12].map((q) => {
                          return <MenuItem value={q} key={q} className="flex items-center">{q} {q === 1 ? 'mes' : 'meses'}</MenuItem>
                        })

                        :
                        <MenuItem value={renew.minmonths} key={renew.minmonths} className="flex items-center">{renew.minmonths} meses</MenuItem>

                    }
                  </Select>
                </FormControl>
              </div>
            </div>
            {
              renew.quantitymonths > 0 &&
              <div className="text-3xl mt-5 mb-5">
                {userToken && user.reseller ?
                  <span className="text-yellow-400">Total a pagar : {currencyFormat(renew.minmonths === 1 ? (renew.costo * renew.quantitymonths) : renew.costo)}</span>
                  :
                  <span className="text-green-400">Total a pagar : {currencyFormat(renew.minmonths === 1 ? (renew.costo * renew.quantitymonths) : renew.costo)}</span>
                }
              </div>
            }

            <div className="w-full mt-2">
              <Button
                onClick={HandleRenewService}
                sx={{
                  "&.Mui-disabled": {
                    background: "#525252",
                    color: "#a3a3a3"
                  }
                }}
                disabled={(ResponseRenewService.isLoading || renew.quantitymonths < 1) ? true : false}
                type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color="success" startIcon={ResponseRenewService.isLoading ? <Spinner w={'35px'} h={'35px'} /> : <IoRefreshSharp size={24} />}>{ResponseRenewService.isLoading ? 'Guardando...' : 'Renovar'}</Button>
            </div>

          </div>
        </div>
      </Modal>
      <div className="w-full mt-[316px]">
        {
          !loadingventas ? ventas?.ventas.length > 0 ? (
            <div>
              {
                ventas?.ventas.map((venta) => {
                  var CategoryName = ""
                  var CategoryLogo = ""
                  var Correo = ""
                  var passwordcuenta = ""
                  var renovable = false
                  !categoryIsLoading && categories.find((cat) => {
                    if (venta.categoryId === cat._id) {
                      cat.subcategory.find((sub) => {
                        if (venta.subcategoryId === sub._id) {
                          CategoryName = sub.name
                          CategoryLogo = sub.logo
                          renovable = sub.renovable
                        }
                      })
                    }
                  })

                  !loadingcuentas && cuentas?.cuentas.find((cuenta) => {
                    if (venta.correoId === cuenta._id) {
                      Correo = cuenta.correo
                      passwordcuenta = cuenta.passwordcuenta
                    }
                  })
                  return (
                    <div className={`${(venta.state === 'complete' || venta.state === 'pending') && 'transition-all duration-500 hover:scale-x-[101%] hover:contrast-[110%]'} 
                     bg-neutral-200 dark:bg-neutral-900 flex flex-wrap mb-10 shadow-xl rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer animation`} key={venta._id}>
                      <div className="grid grid-wrap w-full md:w-3/12">
                        <div className="flex inline-flex items-center">
                          <span className="absolute -mt-28 -ml-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-lg bg-neutral-200 dark:bg-neutral-900 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center">{venta.ventaId}</span>
                          <LazyLoadImage
                            className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                            alt={`No image ${CategoryName}`}
                            effect="blur"
                            src={`/images/logos/${CategoryLogo}`}
                          />
                          <div className="grid self-center">
                            <div className="ml-2 text-base font-semibold">{CategoryName}</div>
                            <div className="ml-2 text-sm">{venta.state === "pending" && <span className="text-yellow-400">Correo pendiente de activación</span>}</div>
                            <div className="ml-2 text-sm">{venta.state === "complete" && <span className="text-neutral-800 dark:text-neutral-200">{Correo}</span>}</div>
                            <div className="ml-2 text-sm">{(venta.state === "locked" && CalcularExpiracion(venta.expirationdate) === "Caducado" && renovable) && <span className="text-red-400">Servicio bloqueado - No hizo renovación </span>}</div>
                            <div className="ml-2 text-sm">{(venta.state === "locked" && CalcularExpiracion(venta.expirationdate) === "Caducado" && !renovable) && <span className="text-red-400">Servicio bloqueado - Caducado</span>}</div>
                            <div className="ml-2 text-sm">{(venta.state === "locked" && CalcularExpiracion(venta.expirationdate) !== "Caducado") && <span className="text-red-400">Servicio bloqueado</span>}</div>
                            <div className="ml-2 text-sm">{venta.state === "rejected" && <span className="text-red-500">Servicio rechazado</span>}</div>
                            <div className="ml-2 text-xs mt-2 truncate ...">{setUnixToTimeFormat('info_contacto', venta.created_at)}</div>
                          </div>
                        </div>
                      </div>

                      <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-[calc(75%-350px)] text-sm mt-5 md:mt-0">

                        <div className="md:w-2/12 w-full grid">
                          <div className="flex items-center justify-center font-bold">Costo total</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center text-base">
                              <span className='text-green-500 font-bold'>{currencyFormat(venta.totalamount)}</span>
                            </div>
                          </div>
                        </div>

                        <div className="md:w-4/12 w-full grid">
                          <div className="flex items-center justify-center font-bold">Expiración</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center text-sm text-green-500">
                              {venta.expirationdate === null ?
                                <span className="text-yellow-400">Expiración aún no extablecida</span> :
                                <span>{(CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") ?
                                  <span className="text-red-400">{CalcularExpiracion(venta.expirationdate)}</span> :
                                  <div>{(CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                                    Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3) ? <span className="text-orange-400">{CalcularExpiracion(venta.expirationdate)}</span> : (venta.state === 'rejected' || venta.state === 'locked') ? <span className="text-red-400">{CalcularExpiracion(venta.expirationdate)}</span> : <span>{moment(venta.expirationdate).format("dddd, DD [de] MMMM [de] yyyy")}</span>}</div>}</span>}
                            </div>
                          </div>
                        </div>

                        <div className="md:w-3/12 w-full grid">
                          <div className="flex items-center justify-center font-bold">Tipo servicio</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center text-sm">
                              {venta.type === '1 pantalla' ?
                                <span className='text-blue-500'>{venta.type}</span> : <span className='text-red-500'>{venta.type}</span>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="md:w-3/12 w-full grid">
                          <div className="flex items-center justify-center font-bold">Estado</div>
                          <div className="flex items-center justify-center">
                            {venta.state === "complete" &&
                              <div class="m-auto p-2 bg-green-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-green-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Completado</span>
                              </div>}
                            {venta.state === "pending" &&
                              <div class="m-auto p-2 bg-yellow-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-yellow-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Pendiente</span>
                              </div>}
                            {venta.state === "rejected" &&
                              <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Rechazado</span>
                              </div>}
                            {venta.state === "locked" &&
                              <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Bloqueado</span>
                              </div>}
                          </div>
                          {venta.observations !== "" &&
                            <span className='text-xs text-center'>
                              {venta.observations}
                            </span>
                          }
                        </div>

                      </div>

                      <div className="flex inline-flex items-center justify-end w-full md:w-[350px] mt-6 md:mt-0">

                        <div className="mr-2 block">
                          <Tooltip title="ver detalles" arrow={true}>
                            <Button
                              startIcon={<IoEye />}
                              sx={{
                                "&.Mui-disabled": {
                                  background: "#525252",
                                  color: "#a3a3a3"
                                }, "&:hover": {
                                  background: "#1e3a8a"
                                }, background: '#1d4ed8'
                              }}

                              onClick={() => HandleOpenDetalles(venta._id, CategoryName, CategoryLogo, Correo, passwordcuenta)} variant="contained" color="primary" disabled={(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked") ? true : false}>
                              Detalles
                            </Button>
                          </Tooltip>
                        </div>

                        {
                          renovable ?
                            <>
                              <div className="mr-2 block">
                                <Tooltip title="renovar" arrow={true}>
                                  <Button
                                    startIcon={<IoReloadCircleSharp />}
                                    sx={{
                                      "&.Mui-disabled": {
                                        background: "#525252",
                                        color: "#a3a3a3"
                                      }, "&:hover": {
                                        background: "#166534"
                                      }, background: '#15803d'
                                    }}

                                    onClick={() => HandleOpenRenew(venta._id, venta.categoryId, venta.subcategoryId, venta.ventaId, venta.type, CategoryName, CategoryLogo)} variant="contained" color="success" disabled={(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked") ? true : false}>
                                    Renovar
                                  </Button>
                                </Tooltip>
                              </div>
                            </>
                            :
                            <>
                              <div className="mr-2 block">
                                <Tooltip title="este servicio es no renovable" arrow={true}>
                                  <Button
                                    startIcon={<IoReloadCircleSharp />}
                                    sx={{
                                      "&.Mui-disabled": {
                                        background: "#525252",
                                        color: "#a3a3a3"
                                      }, "&:hover": {
                                        background: "#166534"
                                      }, background: '#15803d'
                                    }}
                                    variant="contained" color="success" disabled={renovable ? false : true}>
                                    No renovable
                                  </Button>
                                </Tooltip>
                              </div>

                            </>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>) : (<div className="alert-danger font-semibold mt-[316px]">Sin resultados en la búsqueda! o no existen pedidos registrados</div>) : (<Spinner />)
        }

      </div>

    </AccountList >
  );
};

export default UserOrders;