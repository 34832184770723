import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const historyService = createApi({
  reducerPath: "history",
  tagTypes: "histories",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      headers.set('authorization', userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {

      getHistoryPage: builder.query({
        query: (params) => {
          var finalquery = ''
          if (params.search === '') {
            finalquery = 'emptyquery'
          } else {
            finalquery = params.search
          }
          return {
            url: `history/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["histories"],
      }),

      deleteAllHistory: builder.mutation({
        query: () => {
          return {
            url: `/delete-history`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['histories']
      }),
    }
  }
});
export const {
  useGetHistoryPageQuery,
  useDeleteAllHistoryMutation
} = historyService;
export default historyService;
