import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import { useDeleteCuentaMutation } from "../../store/services/cuentaService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import { MdContactMail, MdDelete, MdEdit, MdError, MdSendAndArchive } from 'react-icons/md';
import { IconButton, Pagination, PaginationItem, Stack, TextField, Tooltip } from "@mui/material";
import { CalcularExpiracion, currencyFormat, decryptAES, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { IoAlarm, IoBagCheck, IoBan, IoCheckmarkCircle, IoCloseCircleSharp, IoCloseSharp, IoEye, IoEyeOff, IoPerson, IoSearch, IoSend, IoTime } from "react-icons/io5";
import UpdateCuenta from "./UpdateCuenta";
import Wrapper from "./Wrapper";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BsCashStack, BsCheck, BsCheck2 } from "react-icons/bs";
import { useDeleteGiftCardMutation, useGetAllGiftCardsByPageQuery } from "../../store/services/giftcardService";
import { FcPlus } from "react-icons/fc";
import { Link } from "react-router-dom";
import debounce from 'lodash.debounce';
import SpinnerData from "../../components/SpinnerData";
import { useGetAllUsersQuery } from "../../store/services/userService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useGetAllCategoriesGiftCardQuery } from "../../store/services/categoryService";
import Spinner2 from "../../components/Spinner2";
import { FaUser } from "react-icons/fa";
const GiftCards = ({
   SECRET_KEY,
   socket,
   configuration,
   loadingconfiguration
}) => {

   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [pedidosByState, SetPedidosByState] = useState('')
   const [infopedido, setInfopedido] = useState('')
   const [limit, setLimit] = useState(30);

   const { data: categoryGC, isFetching: loadingcategoryGC } = useGetAllCategoriesGiftCardQuery();
   const { data: giftcards, isFetching, refetch } = useGetAllGiftCardsByPageQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
   const { data: users, isFetching: loadingusers } = useGetAllUsersQuery();

   const [delGiftCard, response] = useDeleteGiftCardMutation();
   const [searchGiftCard, SetSearchGiftCard] = useState("")
   const [VerPasswords, SetVerPasswords] = useState({ id: '', state: false });

   useEffect(async () => {
      await refetch()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetch.abort();
      };
   }, [searchTerm, currentPage, limit]);

   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const deleteGiftCard = id => {
      alertaConfirmar('Eliminar GiftCard', 'Estas seguro que quieres eliminar esta GiftCard? recuerda que se eliminarán todos los datos relacionados a esta GiftCard.', 'question', (res_alert) => {
         if (res_alert === true) {
            delGiftCard(id);
         }
      })
   }

   useEffect(() => {
      if (response.isSuccess) {
         message(response?.data?.msg, 'success', 5)
      }
   }, [response?.data?.msg])

   useEffect(() => {
      console.log()
   }, [])

   const handleChangePage = (e, p) => {
      setCurrentPage(p);
   };

   const changeHandleSearch = (event) => {
      SetPedidosByState('')
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

   const HandleChangeFilterGc = async (state) => {
      setSearchTerm(state)
      SetPedidosByState(state)
      setCurrentPage(1);
   }

   return (
      <Wrapper socket={socket} configuration={configuration}
         loadingconfiguration={loadingconfiguration}>
         <Toaster position="top-right" reverseOrder={true} />
         <ScreenHeader>
            <div className="w-full h-14 text-lg md:text-2xl flex items-center">
               <div className="flex items-center w-9/12 md:w-6/12">
                  <MdContactMail size={32} style={{ marginRight: 5 }} />
                  GIFT CARDS
                  <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
                     {!isFetching && giftcards?.totalgc}
                  </div>
               </div>
               <div className="flex items-center justify-end w-3/12 md:w-6/12">
                  <Link to="/admin/create-giftcard" className="w-16 h-16 flex justify-end">
                     <Tooltip title="Agregar giftcard" arrow={true}>
                        <div className="w-16 h-16 rounded-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-900">
                           <IconButton color="primary" className="w-16 h-16">
                              <FcPlus size={45} />
                           </IconButton>
                        </div>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </ScreenHeader>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
            <div className="relative">
               <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
               </div>
               <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Escribe código de la giftcard..."
                  required
                  onChange={debouncedChangeHandler}
                  autoComplete="off"
               />
            </div>
         </div>

         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[128px] pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
            <div className="w-full flex items-center justify-center md:justify-end text-neutral-200 mt-0 h-10">
               {
                  !isFetching ?
                     <>
                        <div className="hidden md:block">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isFetching && Number(giftcards?.totalgcFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChangePage}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="large"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-neutral-200 dark:bg-neutral-900"
                              />
                           </Stack>
                        </div>
                        <div className="block md:hidden">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isFetching && Number(giftcards?.totalgcFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChangePage}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="small"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-neutral-200 dark:bg-neutral-900"
                              />
                           </Stack>
                        </div>
                     </>
                     : <Spinner />
               }

            </div>
            <div className='mt-8 -ml-5'>

               {
                  <Tooltip title="Click aquí para filtrar GiftCards Libres" arrow={true}>
                     <div onClick={() => HandleChangeFilterGc("Free")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "Free" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-green-500 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><BsCheck className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!isFetching ? giftcards?.totalfree : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

               {
                  <Tooltip title="Click aquí para filtrar GiftCards Usadas" arrow={true}>
                     <div onClick={() => HandleChangeFilterGc("Used")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "Used" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-700 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><IoBan className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!isFetching ? giftcards?.totalused : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

               {
                  <Tooltip title="Click aquí para filtrar Todas las GiftCards" arrow={true}>
                     <div onClick={() => HandleChangeFilterGc("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-green-500 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><IoBagCheck className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!isFetching ? giftcards?.totalgc : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

            </div>
         </div>

         <div className="mt-[330px]">

            {!isFetching ? giftcards?.gc.length > 0 ? giftcards?.gc.map((giftcard) => {
               var CategoryName = ""
               var SubCategoryName = ""
               var logo = ""
               var userName = ""
               var Email = ""

               !loadingcategoryGC && categoryGC?.categories.find((category) => {
                  if (giftcard.categoryId === category._id) {
                     CategoryName = category.category
                     category.subcategory.find((cat) => {
                        if (giftcard.subcategoryId === cat._id) {
                           SubCategoryName = cat.name
                           logo = cat.logo
                        }
                     })
                  }
               })

               !loadingusers && users?.users.find((u) => {
                  if (u._id === giftcard.isusedbyuserId) {
                     userName = u.name
                     Email = u.email
                  }

               })
               return (
                  <div className={`transition-all duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer hover:cursor-pointer p-4 w-full md:w-12/12 shadow-lg bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-wrap rounded-lg mb-12 animation`} key={giftcard._id}>
                     <div className="flex flex-wrap items-center w-full md:w-3/12">
                        <div className="flex inline-flex items-center">
                        <span className="absolute -mt-28 -ml-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-t-lg bg-white dark:bg-neutral-900 pt-2 pl-2 pr-2 pb-2 flex inline-flex 
                          items-center justify-center"><div className="flex items-center ml-2 text-xs truncate ..."><FaUser size={16} className='mr-2' />{giftcard.isused ? <><div className='mr-2'>{Email}</div>-<div className='ml-2 text-xs'>{giftcard.ventaId}</div></>:<span className="text-yellow-500">Sin datos</span>}</div> </span>
                           <LazyLoadImage
                              className={`object-cover w-14 h-14 z-10 rounded-full flex inline-flex`}
                              alt={`No image ${SubCategoryName}`}
                              effect="blur"
                              src={`/images/logos/${logo}`}
                           />
                           <div className="grid self-center">
                              <div className="ml-2 text-base font-semibold">{SubCategoryName}</div>
                              <div className="ml-2 text-sm font-semibold opacity-80">{CategoryName}</div>
                           </div>
                        </div>
                     </div>

                     <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-[calc(75%-50px)] text-sm mt-10 md:mt-0">
                        <div className="md:w-2/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Código</div>
                           <div className="flex items-center justify-center blur-sm hover:blur-none transition-all duration-500">{giftcard.code}</div>
                        </div>

                        <div className="md:w-3/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Fecha de registro</div>
                           <div className="flex items-center justify-center">{setUnixToTimeFormat('info_contacto', giftcard.created_at)}</div>
                        </div>

                        <div className="md:w-3/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Fecha de venta</div>
                           <div className="flex items-center justify-center">{giftcard.selldate !== null ? setUnixToTimeFormat('selldate', giftcard.selldate) : <span className="text-yellow-500">Sin datos</span>}</div>
                        </div>

                        <div className="md:w-2/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Precio de venta</div>
                           <div className="flex items-center justify-center">{giftcard.sellprice > 0 ? currencyFormat(giftcard.sellprice) : <span className="text-yellow-500">Sin datos</span>}</div>
                        </div>

                        <div className="md:w-2/12 w-full grid">
                           <div className="flex items-center justify-center font-bold">Estado</div>
                           <div className="flex items-center justify-center">{giftcard.isused ?
                              <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                 <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Usado</span>
                              </div>
                              :
                              <div class="m-auto p-2 bg-green-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                 <span class="text-center rounded-full bg-green-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Libre</span>
                              </div>
                           }</div>
                        </div>
                     </div>

                     <div className="flex items-center justify-center w-[50px] bg-neutral-50 dark:bg-neutral-950 rounded">

                        <div className="w-14 h-14 rounded-full mt-3 md:mt-0 ">
                           <Tooltip title="Eliminar" arrow={true}>
                              <IconButton onClick={() => deleteGiftCard(giftcard._id)} className="w-14 h-14" color="primary">
                                 <MdDelete
                                    size={25}
                                    className={`text-red-600`}
                                 />
                              </IconButton>
                           </Tooltip>
                        </div>
                     </div>
                  </div>
               )
            }) : (<div className="alert-danger font-semibold mt-[330px]">Sin resultados en la búsqueda! o no existen giftcards registrados</div>) : (<div className="mt-[330px]"><SpinnerData>Cargando giftcards, por favor espere...</SpinnerData></div>)}
         </div>

      </Wrapper>
   )
}
export default GiftCards;