import React, { useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const GraphsLine = ({ categoryStreamingRating, text, textlabel1, textlabel2, balance }) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const options = {
        responsive: true,
        Animation: true,
        scales: {
            y: {
                angleLines: { color: '#e5e5e54b' },
                grid: { color: '#e5e5e54b' },
                pointLabels: { color: '#e5e5e5' },
            },
            x: {
                angleLines: { color: '#e5e5e54b' },
                grid: { color: '#e5e5e54b' },
                pointLabels: { color: '#e5e5e5' },
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: text,
            }
        },
    };

    const labels = categoryStreamingRating && categoryStreamingRating.map((name) => name.name);

    const data = {
        labels: labels,
        datasets: [
            {
                fill: true,
                label: textlabel1,
                data: categoryStreamingRating && categoryStreamingRating.map((cat) => cat.totalVendido),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                fill: true,
                label: textlabel2,
                data: balance && balance.map((ven) => ven.totalVendido),
                borderColor: '#ffae00cc',
                backgroundColor: '#f7ba3680',
            }
        ],
    };

    return (<Line
        options={options}
        data={data}
        width={'100%'}
        height={`${windowSize.current[0] > 640 ? '53%' : '100%'}`}
    />)
}

export default GraphsLine;
