import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper"
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../store/services/userService";
import Spinner from "../../components/Spinner";
import { MdEdit, MdEditOff, MdVerifiedUser } from "react-icons/md";
import { Button, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MuiTelInput } from 'mui-tel-input'
import { IoChevronDown, IoCloseSharp, IoSave } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import UpdateScreenHeader from "../../components/UpdateScreenHeader";
import moment from 'moment';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaChessKing, FaDollarSign, FaUserTie } from "react-icons/fa";
const UpdateUser = ({ HandleCloseEditUser, user, socket }) => {
    const { id } = useParams();
    const [credits, SetCredits] = useState(0)
    const [state, setState] = useState({
        _id: "",
        name: "",
        email: "",
        password: "",
        admin: false,
        reseller: false,
        countrycode: "",
        phonenumber: "",
        credits_purchased: "",
        credits_used: "",
        created_at: "",
        locked: false,
        last_update: ""
    });

    useEffect(() => {
        setState({
            _id: user._id,
            name: user.name,
            email: user.email,
            password: "",
            admin: user.admin,
            reseller: user.reseller,
            countrycode: user.countrycode,
            phonenumber: user.phonenumber,
            credits_purchased: user.credits_purchased,
            credits_used: user.credits_used,
            created_at: moment(user.created_at).format("yyyy-MM-DDTHH:mm"),
            locked: user.locked,
            last_update: moment(user.last_update).format("yyyy-MM-DDTHH:mm"),
        })
        SetCredits(Number(user.credits_purchased - user.credits_used))
    }, [user])

    const [updateUser, response] = useUpdateUserMutation();

    const updateSubmit = e => {
        e.preventDefault();
        const CurrentInfo = {
            name: user.name,
            email: user.email,
            admin: user.admin,
            reseller: user.reseller,
            countrycode: user.countrycode,
            phonenumber: user.phonenumber,
        }

        const userEdited = {
            name: state.name,
            email: state.email,
            admin: state.admin,
            reseller: state.reseller,
            countrycode: state.countrycode,
            phonenumber: state.phonenumber,
        }
        console.log(CurrentInfo)
        console.log(userEdited)



        if (JSON.stringify(CurrentInfo) !== JSON.stringify(userEdited) || state.password !== "") {
            updateUser(state);
        } else {
            message('No hubo ningún cambio', 'error', 3)
            HandleCloseEditUser()
        }
    }

    useEffect(() => {
        if (response?.isSuccess) {

            socket.emit('updateuser', response?.data?.user, response?.data?.userold, response?.data?.passwordchange)
            message(response?.data?.msg, 'success', 3)
            HandleCloseEditUser()
        }
    }, [response?.isSuccess])

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                message(err.msg, 'error', 3)
            })
        }
    }, [response?.error?.data?.errors])

    const HandleChangeEditUser = (e) => {

        setState({ ...state, [e.target.name]: e.target.value });
    }

    const onChangePhone = (newValue, info) => {
        setState({ ...state, ['countrycode']: info.countryCode, ['phonenumber']: newValue });
    }


    return (
        <>
            <Toaster position="top-right" reverseOrder={true} />
            <UpdateScreenHeader>
                <div className="flex items-center">
                    <div className="flex inline-flex items-center">
                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                            <IconButton color="error" onClick={HandleCloseEditUser}>
                                <IoCloseSharp
                                    className="text-neutral-200"
                                    size={28}
                                />
                            </IconButton>
                        </div>

                    </div>
                    <div className="w-full text-neutral-900 dark:text-neutral-200 text-2xl ml-5 flex inline-flex items-center">EDITAR USUARIO</div>
                </div>
            </UpdateScreenHeader>
            <form className="w-full md:w-12/12 mt-[125px]" onSubmit={updateSubmit}>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre del usuario</label>
                        <input autoFocus name="name" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Nombre del usuario"
                            autoComplete="off"
                            required
                            value={state.name}
                            type="text"
                            onChange={HandleChangeEditUser}
                        />
                    </div>
                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Correo</label>
                        <input name="email" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Correo"
                            autoComplete="off"
                            required
                            value={state.email}
                            type="email"
                            onChange={HandleChangeEditUser}
                        />
                    </div>


                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Contraseña</label>
                        <input name="password" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Contraseña"
                            autoComplete="off"
                            value={state.password}
                            onChange={HandleChangeEditUser}
                            type="password"
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Es admin?</label>
                        <div className="relative">
                            <FormControl fullWidth>
                                <Select
                                    required
                                    name="admin"
                                    className="flex items-center"
                                    variant="outlined"
                                    value={state.admin}
                                    onChange={HandleChangeEditUser}
                                >
                                    <MenuItem value={true} key={true} selected={state.admin && true} className="flex items-center"><FaChessKing size={24} className="mr-2 text-red-500"/>Administrador</MenuItem>
                                    <MenuItem value={false} key={false} selected={!state.admin && false} className="flex items-center"><MdVerifiedUser size={24} className="mr-2 text-green-600 dark:text-green-400"/>Registrado</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Es reseller?</label>
                        <div className="relative">
                            <FormControl fullWidth>
                                <Select
                                    required
                                    name="reseller"
                                    className="flex items-center"
                                    variant="outlined"
                                    value={state.reseller}
                                    onChange={HandleChangeEditUser}
                                >
                                    <MenuItem value={true} key={true} selected={state.reseller && true} className="flex items-center"><FaDollarSign size={24} className="mr-2 text-yellow-500"/>Reseller</MenuItem>
                                    <MenuItem value={false} key={false} selected={!state.reseller && false} className="flex items-center"><FaUserTie size={24} className="mr-2 text-blue-500"/>Consumidor</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Numero celular</label>
                        <MuiTelInput
                            inputProps={{ className: "text-neutral-900 dark:text-neutral-200" }}
                            value={state.phonenumber}
                            onChange={onChangePhone}
                            className="bg-neutral-100 p-[15px] text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Créditos disponibles</label>
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Creditos no se puede editar, puedes agregar créditos directo en la lista de usuarios.
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>
                            <input autoFocus name="credits" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Créditos disponibles"
                                autoComplete="off"
                                value={currencyFormat(credits)}
                                type="text"
                                disabled
                            />
                        </Tooltip>
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Fecha de registro</label>
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Fecha de registro no se puede editar.
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>
                            <input autoFocus name="credits" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                autoComplete="off"
                                value={setUnixToTimeFormat("info_contacto", state.created_at)}
                                type="text"
                                disabled
                            />
                        </Tooltip>
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Última vez que se actualizó</label>
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Última vez que se actualizó no se puede editar.
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>
                            <input autoFocus name="credits" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                autoComplete="off"
                                value={setUnixToTimeFormat("last_update", state.last_update)}
                                type="text"
                                disabled
                            />
                        </Tooltip>
                    </div>

                </div>
                <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                    <Button
                        type="submit"
                        style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                        size='large' variant="contained"
                        disabled={response.isLoading ? true : false}
                    >
                        {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                        {response.isLoading ? 'Actualizando...' : 'Actualizar usuario'}
                    </Button>
                </div>
            </form>
        </>
    )
}
export default UpdateUser;