import { Link } from "react-router-dom";
import CuentaSkeleton from "./CuentaSkeleton";
import { IoChevronForward, IoStar } from "react-icons/io5";
import { Pagination, Autoplay, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { BsHandbag, BsStar } from "react-icons/bs";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CategoryStreamingRating = ({ categoryStreamingRating, loadingcategoryStreamingRating }) => {
  return loadingcategoryStreamingRating ? (
    <CuentaSkeleton />
  ) : (
    categoryStreamingRating.length > 0 && (
      <>
        <div className="w-full font-semibold text-neutral-800 dark:text-neutral-200 mt-5 mb-10 flex inline-flex items-center text-2xl">
          Streaming más populares <IoChevronForward />
        </div>
        <div className="flex flex-wrap w-full pb-5 ">

          <div className="flex flex-wrap w-full">
            <Swiper
              modules={[Virtual, Pagination]}
              pagination={{
                type: 'fraction'
              }}
              slidesPerView={3}
              breakpoints={{
                320: { slidesPerView: 2, spaceBetween: 15 },
                640: { slidesPerView: 3, spaceBetween: 15 },
                1024: { slidesPerView: 4, spaceBetween: 15 },
              }}
              virtual
              className="w-full h-[250px] mb-10 b"
            >

              {
                categoryStreamingRating.map((_c, index) => {
                  const rating = []
                  var i = 1
                  if (_c.rating === 0) {
                    rating.push("Sin valoración aún")
                  } else if (_c.rating > 0 & _c.rating <= 10) {
                    for (i; i <= 1; i++) {
                      rating.push(<IoStar key={_c._id + i} className="text-yellow-300" />)
                    }
                  } else if (_c.rating > 10 & _c.rating <= 20) {
                    for (i; i <= 2; i++) {
                      rating.push(<IoStar key={_c._id + i} className="text-yellow-300" />)
                    }
                  } else if (_c.rating > 20 & _c.rating <= 30) {
                    for (i; i <= 3; i++) {
                      rating.push(<IoStar key={_c._id + i} className="text-yellow-300" />)
                    }
                  } else if (_c.rating > 30 & _c.rating <= 40) {
                    for (i; i <= 4; i++) {
                      rating.push(<IoStar key={_c._id + i} className="text-yellow-300" />)
                    }
                  } else if (_c.rating > 40) {
                    for (i; i <= 5; i++) {
                      rating.push(<IoStar key={_c._id + i} className="text-yellow-300" />)
                    }
                  }


                  return (
                    <SwiperSlide
                      className="w-full overflow-hidden rounded-lg relative bg-neutral-300 dark:bg-neutral-700"
                      key={index}
                      virtualIndex={index}
                    >
                      <div className="absolute hover:bg-neutral-300 dark:bg-neutral-700 transition-all duration-500 hover:scale-[105%] hover:contrast-[150%] cursor-pointer contrast-[120%] bg-neutral-200 dark:bg-neutral-900 inset-0 w-full h-full flex items-center justify-center p-4">

                        <div className="absolute block top-0 mt-5 font-medium capitalize text-neutral-800 dark:text-neutral-200">
                          <div>
                            <LazyLoadImage
                              className="object-cover w-24 h-24 rounded-full m-auto"
                              alt={`No image`}
                              effect="blur"
                              src={`/images/logos/${_c.logo}`}
                            />
                          </div>

                          <div className="mt-2">
                            {_c.name}
                          </div>

                          <div className="mt-2 flex flex-inline items-center">

                          </div>
                        </div>
                        <div className="w-full relative mt-20 text-sm">
                          {_c.rating === 1 ? _c.rating + ' persona está viendo ' : _c.rating + ' personas están viendo '} {_c.name}.
                        </div>
                        <div className="absolute bottom-0 left-0 p-10">
                          <div className="flex items-center text-sm text-yellow-400">
                            {rating}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }

            </Swiper>
          </div>
        </div>
      </>
    )
  );
};

export default CategoryStreamingRating;
