import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import { useDeleteCuentaMutation } from "../../store/services/cuentaService";
import ScreenHeader from "../ScreenHeader";
import Spinner from "../Spinner";
import { MdCardGiftcard, MdContactMail, MdDelete, MdEdit, MdError } from 'react-icons/md';
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { CalcularExpiracion, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { IoAlarm, IoCartSharp, IoCloseSharp, IoEye, IoEyeOff, IoHeartOutline, IoPerson, IoRefreshSharp, IoSearch, IoSend, IoTime, IoWarningSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "./Nav";
import { useForgotPasswordResetCheckQuery, useUpdateForgotPasswordMutation } from "../../store/services/authService";
import HeaderPages from "./HeaderPages";

const ForgotPasswordReset = ({
   userToken,
   user,
   openCloseSidebar
}) => {
   const { key } = useParams();
   const { data, isFetching } = useForgotPasswordResetCheckQuery(key);
   const [updatePassword, responseUpdatePassword] = useUpdateForgotPasswordMutation();
   const [newPassword, SetNewPassword] = useState({ newpassword: '', repeatnewpassword: '' })
   const [verPassword, SetVerPassword] = useState({ newpassword: false, repeatnewpassword: false, })

   const SubmitHandleChangePassword = (e) => {
      e.preventDefault()
      if (newPassword.newpassword.length < 1) {
         message('Nueva contraseña es requerido.', 'error', 4)
      } else if (newPassword.repeatnewpassword.length < 1) {
         message('Repetir nueva contraseña es requerido.', 'error', 4)
      } else if (newPassword.newpassword.length < 5 || newPassword.repeatnewpassword.length < 5) {
         message('La contraseña debe tener mínimo 5 caracteres.', 'error', 4)
      } else if (newPassword.newpassword !== newPassword.repeatnewpassword) {
         message('Las contraseñas no coinciden.', 'error', 4)
      } else {
         var year = new Date().getFullYear()
         var msg = `<!DOCTYPE html>
         <html lang="en">
         
         <head>
             <meta charset="UTF-8">
             <meta http-equiv="X-UA-Compatible" content="IE=edge">
             <meta name="viewport" content="width=device-width, initial-scale=1.0">
         
             <style>
                 p,
                 a,
                 h1,
                 h2,
                 h3,
                 h4,
                 h5,
                 h6 {
                     font-family: Google Sans !important;
                     color: #171717;
                 }
         
                 h1 {
                     font-size: 30px !important;
                 }
         
                 h2 {
                     font-size: 25px !important;
                 }
         
                 h3 {
                     font-size: 18px !important;
                 }
         
                 h4 {
                     font-size: 16px !important;
                 }
         
                 p,
                 a {
                     font-size: 15px !important;
                 }
         
                 .claseBoton {
                     text-decoration: none;
                     cursor: pointer;
                     border-radius: 5px;
                 }
         
                 .btn-grad {
                     background-image: linear-gradient(to right, #3838ff 0%, #0000d8 51%, #3838ff 100%);
                     margin: 0px;
                     padding: 15px 20px;
                     text-align: center;
                     text-transform: uppercase;
                     transition: 0.5s;
                     background-size: 200% auto;
                     color: #ffffff !important;
                     box-shadow: 0 0 20px #eee;
                     border-radius: 10px;
                     display: block;
                     text-decoration: none;
                     cursor: pointer;
                 }
         
                 .btn-grad:hover {
                     background-position: right center;
                     /* change the direction of the change here */
                     color: #fff;
                     text-decoration: none;
                 }
         
                 .btn-grad:active{
                     background-image: linear-gradient(to right, #2727bb 0%, #0000b6 51%, #2727bb 100%);
                 }
                 .imag {
                     width: 30px;
                     height: 30px;
                 }
         
                 .contA {
                     margin: 0px 5px 0 5px;
                 }
         
                 .afooter {
                     color: #ffffff !important;
                     text-decoration: none;
                     font-size: 13px !important;
                 }
         
                 .contenido {
                     width: 25vw;
                     margin: auto;
                     text-align: left;
                     margin-bottom: 30px;
                     margin-top: 30px;
                     padding: 5px 5px;
                 }
         
                 .dataperfiles {
                     width: calc(100% - 30px);
                     background-color: #e3e3e3;
                     padding-left: 10px;
                     padding-right: 10px;
                     padding-top: 10px;
                     padding-bottom: 10px;
                     margin-top: 5px;
                     color: #141414;
                 }
         
                 .perfilnumber {
                     font-size: 18px !important;
                     color: #222222;
                     font-weight: bolder;
                 }
         
                 .container {
                     width: 40vw;
                     margin: auto;
                     background-color: #e3e3e3
                 }
         
                 .subcontainer {
                     padding: 30px 30px 30px 30px;
                 }
         
                 .logo-link {
                     width: 260px;
                     margin: auto;
                     text-align: center;
                     margin-bottom: 5px;
                 }
         
                 .thanks {
                     width: 100%;
                     float: left;
                 }
         
                 @media screen and (max-width: 640px) {
                     .contenido {
                         width: 100%;
                     }
         
                     .container {
                         width: 100%;
                         margin: auto;
                     }
         
                     .logo-link {
                         width: 100%;
                         margin: auto;
                     }
         
                     .subcontainer {
                         padding: 30px 0px 30px 0px;
                     }
                 }
             </style>
         </head>
         
         <body>
             <div class="container">
                 <div class="subcontainer">
                     <!-- Imagen inicial -->
                     <div style="padding: 0px 0px 20px 0px; width: 100%; text-align: center;">
                         <img src="https://cinedar.com/images/forgot-password-success.png" alt="" style="width: 200px; height: 200px;">
                     </div>
                     <!-- Imagen inicial -->
         
                     <!-- Contenido principal -->
                     <div style="background-color: #f5f5f5; padding: 20px 0px 5px 0px; width: 100%; text-align: center;">
                         <h1>NUEVAS CREDENCIALES</h1>
                         <p class="perfilnumber">Hola estimado(a), ${!isFetching && data?.user?.name}</p>
                         <div class="contenido">
                             <p><b>Su contraseña se ah restablecido correctamente.</b></p>
                             <p>Correo: ${!isFetching && data?.user?.email}</p>
                             <p>Nueva contraseña: ${newPassword.newpassword}</p>
                         </div>
                         <!-- Gracias -->
                         <div class="thanks">
                             <p>Gracias por su tiempo.</p>
                             <p style="margin-bottom: 50px;"><i>Atentamente:</i><br>Equipo CINEDAR</p>
                         </div>
         
                         <!-- Botón -->
                         <div class="logo-link">
                             <a class="claseBoton" href="https://cinedar.com" target="_blank">
                                 <img src="https://cinedar.com/images/logo2.png" alt="" style="width: 260px; height: 65px;">
                             </a>
                         </div>
                     </div>
                     <!-- Contenido principal -->
         
                     <!-- Footer -->
                     <div
                         style="background-color: #282828; color: #ffffff; padding: 15px 0px 0px 0px; width: 100%; text-align: center;">
                         <!-- Redes sociales -->
                         <a href="https://www.facebook.com/condezodarvin" target="_blank" class="contA"><img
                                 src="https://cinedar.com/images/redes/facebook.png" class="imag" /></a>
                         <a href="https://www.instagram.com/policedarvin/" target="_blank" class="contA"><img
                                 src="https://cinedar.com/images/redes/instragram.png" class="imag" /></a>
                         <a href="https://wa.me/51994201155" target="_blank" class="contA"><img
                                 src="https://cinedar.com/images/redes/whatsapp.png" class="imag" /></a>
                         <a href="mailto:support@cinedar.net" target="_blank" class="contA"><img
                                 src="https://cinedar.com/images/redes/mail.png" class="imag" /></a>
                         <a href="https://t.me/darvin_condezo" target="_blank" class="contA"><img
                                 src="https://cinedar.com/images/redes/telegram.png" class="imag" /></a>
                         <!-- Redes sociales -->
         
                         <h3 style="color: #ffffff">Soporte</h3>
                         <p style="font-size: 13px; padding: 0px 20px 0px 20px; color: #ffffff">
                             Comunícate con nosotros por los siguientes medios:<br>
                             Correo: <a class="afooter" href="mailto:support@cinedar.net">support@cinedar.net</a><br>
                             Whatsapp: <a class="afooter" href="https://wa.me/51994201155">+51 994 201 155</a><br>
                         </p>
                         <p
                             style="background-color: black; color: #ffffff; padding: 10px 0px 10px 0px; font-size: 12px !important;">
                             © ${year} CINEDAR, todos los derechos reservados.
                         </p>
                     </div>
                     <!-- Footer -->
         
         
         
                 </div>
             </div>
         </body>
         
         </html>`
         updatePassword({ key: key, newpassword: newPassword.newpassword, html: msg })
      }
   }

   useEffect(() => {
      if (responseUpdatePassword.isError) {
         message(responseUpdatePassword?.error?.data?.message, 'error', 4)
      }
   }, [responseUpdatePassword?.error])

   const navigate = useNavigate();

   useEffect(() => {
      if (responseUpdatePassword.isSuccess) {
         message(responseUpdatePassword?.data?.msg, 'success', 5)
         navigate('/login')
      }
   }, [responseUpdatePassword?.isSuccess])

   return (
      <div className="w-full bg-neutral-200 dark:bg-neutral-900 h-screen">
         <Nav openCloseSidebar={openCloseSidebar} userToken={userToken} user={user} />
         <div className="w-full md:w-9/12 m-auto p-4">
         <Toaster position="top-right" reverseOrder={true} />
            <HeaderPages>
               <div className="font-semibold mt-[95px] w-full text-neutral-800 dark:text-neutral-200 text-2xl flex inline-flex items-center"> <IoRefreshSharp size={32} style={{ marginRight: 5 }} />
                  Restablecer contraseña </div>

            </HeaderPages>

            <div className="w-full md:w-12/12 flex flex-wrap pt-[215px]">
               {!isFetching ? data?.user ?

                  (CalcularExpiracion(data?.user?.expirationforgotpasswordkey)) !== 'Caducado' ?

                     <form className="w-full md:w-4/12" onSubmit={SubmitHandleChangePassword}>
                        <div className="w-full md:w-12/12 p-3">
                           <TextField
                              name="newpassword"
                              className="bg-neutral-700 dark:bg-neutral-700 rounded-lg"
                              style={{
                                 width: '100%',
                                 marginTop: 10, marginBottom: 10, color: '#c2c2c2',
                                 borderRadius: 5
                              }}
                              sid="outlined-basic" label="Nueva contraseña" variant="outlined"
                              autoComplete="off"
                              color='primary'
                              inputProps={{
                                 style: {
                                    color: '#c2c2c2',
                                    fontFamily: "Jost, sans-serif",
                                    fontSize: 16
                                 }
                              }}
                              InputProps={{
                                 endAdornment:
                                    <>{<IconButton color='primary' onClick={() => SetVerPassword({ ...verPassword, newpassword: !verPassword.newpassword })}>
                                       {verPassword.newpassword ? <IoEyeOff color='#c2c2c2' /> : <IoEye color='#c2c2c2' />}
                                    </IconButton>}</>
                              }}
                              InputLabelProps={{
                                 style: {
                                    color: '#c2c2c2', fontSize: 17, paddingBottom: 5,
                                    fontFamily: "Jost, sans-serif"
                                 }, shrink: true,
                                 inputProps: { min: "0", max: "200", step: "1" }
                              }}
                              type={verPassword.newpassword ? 'text' : 'password'}
                              value={newPassword.newpassword}
                              onChange={(e) => SetNewPassword({ ...newPassword, newpassword: e.target.value })}
                           />
                        </div>
                        <div className="w-full md:w-12/12 p-3">
                           <TextField
                              name="repeatnewpassword"
                              className="bg-neutral-700 dark:bg-neutral-700 rounded-lg"
                              style={{
                                 width: '100%',
                                 marginTop: 10, marginBottom: 10, color: '#c2c2c2',
                                 borderRadius: 5
                              }}
                              sid="outlined-basic" label="Repetir nueva contraseña" variant="outlined"
                              autoComplete="off"
                              color='primary'
                              inputProps={{
                                 style: {
                                    color: '#c2c2c2',
                                    fontFamily: "Jost, sans-serif",
                                    fontSize: 16
                                 }
                              }}
                              InputProps={{
                                 endAdornment:
                                    <>{<IconButton color='primary' onClick={() => SetVerPassword({ ...verPassword, repeatnewpassword: !verPassword.repeatnewpassword })}>
                                       {verPassword.repeatnewpassword ? <IoEyeOff color='#c2c2c2' /> : <IoEye color='#c2c2c2' />}
                                    </IconButton>}</>
                              }}
                              InputLabelProps={{
                                 style: {
                                    color: '#c2c2c2', fontSize: 17, paddingBottom: 5,
                                    fontFamily: "Jost, sans-serif"
                                 }, shrink: true,
                                 inputProps: { min: "0", max: "200", step: "1" }
                              }}
                              type={verPassword.repeatnewpassword ? 'text' : 'password'}
                              value={newPassword.repeatnewpassword}
                              onChange={(e) => SetNewPassword({ ...newPassword, repeatnewpassword: e.target.value })}
                           />
                        </div>

                        <div className="w-full p-3 mt-1">
                           <Button
                              sx={{
                                 "&.Mui-disabled": {
                                    background: "#525252",
                                    color: "#a3a3a3"
                                 }
                              }}
                              disabled={responseUpdatePassword.isLoading ? true : false}
                              type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                              size='large' variant="contained" color="success" startIcon={responseUpdatePassword.isLoading ? <Spinner w={'35px'} h={'35px'}/> : <IoRefreshSharp size={24} />}>{responseUpdatePassword.isLoading ? 'Guardando...' : 'Cambiar Contraseña'}</Button>
                        </div>
                     </form>
                     :
                     <div className="w-full flex items-center justify-center"><p className="bg-red-200 border border-red-100 p-2 
                  rounded-md text-sm text-red-800 font-semibold flex inline-flex items-center">
                        <div className='h-[40px] w-[50px] rounded-full bg-red-800 mr-3 
                    flex inline-flex items-center justify-center text-red-100'><MdError size={24} className="w-[40px]" /></div>
                        KEY: {key} Key ah expirado (las key expiran en 3 días despues de pedir restablecer su contraseña)!
                     </p></div>
                  :
                  <div className="w-full flex items-center justify-center"><p className="bg-red-200 border border-red-100 p-2 
                  rounded-md text-sm text-red-800 font-semibold flex inline-flex items-center">
                     <div className='h-10 w-10 rounded-full bg-red-800 mr-3 
                    flex inline-flex items-center justify-center text-red-100'><MdError size={24} className="w-[40px]" /></div>
                     KEY: {key} Key erronea!
                  </p></div>
                  :
                  <div className="w-full flex items-center justify-center"><Spinner w={'35px'} h={'35px'}/></div>
               }
            </div>
         </div>
      </div >
   )
}
export default ForgotPasswordReset;