import { useState, useEffect, useMemo } from "react"
import UpdateScreenHeader from "../../components/UpdateScreenHeader";
import { useUpdateCategoryMutation, useUpdateSubCategoryMutation } from "../../store/services/categoryService";
import { MdEdit, MdEditOff } from "react-icons/md";
import { Button, FormControl, IconButton, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import { message, PermiteSoloNumeros, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { IoChevronDown, IoChevronForward, IoCloseSharp, IoSave } from "react-icons/io5";
import { Toaster } from "react-hot-toast";
import ImagesPreview from "../../components/ImagesPreview";
import { BsCalendar2Date, BsCalendar3 } from "react-icons/bs";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import moment from 'moment';
import 'moment/locale/es';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Spinner from "../../components/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector, useDispatch } from "react-redux";
import ImagesPreview2 from "../../components/ImagesPreview2";
import { updateSubCategory, SET_TO_DEFAULT_RESPONSE } from "../../store/features/categorySlice";

const UpdateSubCategorySTR = ({ socket, categoryId, category, HandleCloseEditCategory, newdata }) => {
    const dispatch = useDispatch();
    const { responseSuccessUpdateSubCategory, isLoading, isSuccess, isError } = useSelector((state) => state.categoryReducer);
    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [cuentacompleta, SetCuentaCompleta] = useState(false)
    const [renovable, SetRenovable] = useState(false)
    const [profileblocked, SetProfileBlocked] = useState(false)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [state, setState] = useState({
        _id: "",
        subcategoryId: "",
        name: "",
        maximoperfiles: "",
        costoperfiluser: "",
        discountperfiluser: 0,
        costocuentauser: "",
        costoperfilreseller: "",
        costocuentareseller: "",
        created_at: "",
        last_update: "",
        logo: "",
        currentlogo: "",
        tipoentrega: "",
        rating: "",
        url: '',
        isprofileblocked: "",
        cuentacompleta: "",
        renovable: "",
        location: '',
        typecategory: "",
        minmonths: "",
    });

    const [preview, setPreview] = useState({
        logo: '',
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const updateSubmit = async e => {
        e.preventDefault();
        if (state.location.trim().length > 0) {
            const contentState = editorState.getCurrentContent();
            if (contentState.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
                formData.append('image1', state.logo)
                await dispatch(updateSubCategory(formData));
            } else {
                message('Términos y condiciones de servicio', 'error', 4)
            }
        } else {
            message('Localización es requerido', 'error', 4)
        }
    }

    useEffect(async () => {
        if (isSuccess) {
            message(responseSuccessUpdateSubCategory.message, 'success', 5)
            newdata(responseSuccessUpdateSubCategory.newStateData.newData)
            //socket.emit('updatecategory', responseCategory.datacategoria, responseCategory.olddata, responseCategory.newdata)
            HandleCloseEditCategory()
        }
        await dispatch(SET_TO_DEFAULT_RESPONSE())
    }, [responseSuccessUpdateSubCategory])

    const HandleChangeEditCategory = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const OnChangeTipoEntrega = () => {
        SetTipoEntrega(!tipoentrega)
        setState({ ...state, ['tipoentrega']: !tipoentrega })
    }

    const OnChangeCuentaCompleta = () => {
        if (state.maximoperfiles > 1) {
            message('No se puede editar cuando máximo de perfiles es mayor a 1', 'error')
        } else {
            SetCuentaCompleta(!cuentacompleta)
            setState({ ...state, ['cuentacompleta']: !cuentacompleta })
        }
    }

    const OnChangeRenovable = () => {

        SetRenovable(!renovable)
        setState({ ...state, ['renovable']: !renovable })

    }

    const OnChangeProfileBlocked = () => {

        SetProfileBlocked(!profileblocked)
        setState({ ...state, ['isprofileblocked']: !profileblocked })

    }

    useEffect(() => {
        const CategoryEdit = {
            _id: categoryId,
            subcategoryId: category._id,
            name: category.name,
            maximoperfiles: category.maximoperfiles,
            costoperfiluser: category.costoperfiluser,
            discountperfiluser: category.discountperfiluser,
            costocuentauser: category.costocuentauser,
            costoperfilreseller: category.costoperfilreseller,
            costocuentareseller: category.costocuentareseller,
            created_at: category.created_at,
            last_update: category.last_update,
            logo: category.logo,
            rating: category.rating,
            tipoentrega: category.tipoentrega,
            isprofileblocked: category.isprofileblocked,
            cuentacompleta: category.cuentacompleta ? category.cuentacompleta : false,
            renovable: category.renovable ? category.renovable : false,
            url: category.url,
            currentlogo: category.logo,
            location: category.location,
            typecategory: "Streaming",
            minmonths: category.minmonths
        }
        setState({ ...CategoryEdit })
        SetTipoEntrega(category.tipoentrega)
        SetCuentaCompleta(category.cuentacompleta ? category.cuentacompleta : false)
        SetRenovable(category.renovable ? category.renovable : false)
        SetProfileBlocked(category.isprofileblocked)

        const rawContent = category.policy;
        const contentState = convertFromRaw(JSON.parse(rawContent));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)

    }, [category]);

    return (
        <>
            <Toaster position="top-right" reverseOrder={true} />
            <UpdateScreenHeader>
                <div className="flex items-center">
                    <div className="flex inline-flex items-center">
                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                            <IconButton color="error" onClick={HandleCloseEditCategory}>
                                <IoCloseSharp
                                    className="text-neutral-200"
                                    size={28}
                                />
                            </IconButton>
                        </div>

                    </div>
                    <div className="w-full text-neutral-900 dark:text-neutral-200 text-2xl ml-5 flex inline-flex items-center"> EDITAR SUBCATEGORIA</div>
                </div>
            </UpdateScreenHeader>

            <form className="w-full md:w-12/12 mt-[125px]" onSubmit={updateSubmit}>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la categoría</label>
                        <input autoFocus name="name" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Nombre de la categoría"
                            autoComplete="off"
                            required
                            value={state.name}
                            type="text"
                            onChange={HandleChangeEditCategory}
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Máximo de perfiles no se puede editar
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Máximo de perfiles</label>
                            <input name="maximoperfiles" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Máximo de perfiles"
                                autoComplete="off"
                                disabled
                                required
                                value={state.maximoperfiles}
                                type="number"
                            /></Tooltip>
                    </div>

                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo perfil usuario * 30 dias</label>
                        <input name="costoperfiluser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Costo perfil usuario * 30 dias"
                            autoComplete="off"
                            required
                            value={state.costoperfiluser}
                            type="number"
                            step={0.01}
                            onChange={HandleChangeEditCategory}
                            onWheel={(e) => e.target.blur()}
                            min={0}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            S/
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descuento por perfil de usuario (%)</label>
                        <input name="discountperfiluser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Descuento por perfil de usuario (%)"
                            autoComplete="off"
                            required
                            value={state.discountperfiluser}
                            type="number"
                            step={0.01}
                            onChange={HandleChangeEditCategory}
                            onWheel={(e) => e.target.blur()}
                            min={0}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            %
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo cuenta completa usuario * 30 dias</label>
                        <input name="costocuentauser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Costo cuenta completa usuario * 30 dias"
                            autoComplete="off"
                            required
                            value={state.costocuentauser}
                            type="number"
                            step={0.01}
                            onChange={HandleChangeEditCategory}
                            onWheel={(e) => e.target.blur()}
                            min={0}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            S/
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo perfil reseller * 30 dias</label>
                        <input name="costoperfilreseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Costo cuenta completa usuario * 30 dias"
                            autoComplete="off"
                            required
                            value={state.costoperfilreseller}
                            type="number"
                            step={0.01}
                            onChange={HandleChangeEditCategory}
                            onWheel={(e) => e.target.blur()}
                            min={0}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            S/
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo cuenta completa reseller * 30 dias</label>
                        <input name="costocuentareseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Costo cuenta completa reseller * 30 dias"
                            autoComplete="off"
                            required
                            value={state.costocuentareseller}
                            type="number"
                            step={0.01}
                            onWheel={(e) => e.target.blur()}
                            onChange={HandleChangeEditCategory}
                            min={0}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            S/
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Cantidad mínima de meses</label>
                        <input name="minmonths" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Cantidad mínima de meses"
                            autoComplete="off"
                            required
                            value={state.minmonths}
                            type="number"
                            onChange={HandleChangeEditCategory}
                            onWheel={(e) => e.target.blur()}
                            min={1}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            <BsCalendar3 size={20} className="text-neutral-900 dark:text-neutral-200" />
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Localización</label>
                        <div className="relative">
                            <FormControl fullWidth>
                                <Select
                                    required
                                    name="location"
                                    className="flex items-center"
                                    variant="outlined"
                                    value={state.location}
                                    onChange={HandleChangeEditCategory}
                                >
                                    <MenuItem value={'Global'} key={'Global'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Global.svg`} className="mr-2 rounded-full" />Global</MenuItem>
                                    <MenuItem value={'Latam'} key={'Latam'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Latam.svg`} className="mr-2 rounded-full" />Latam</MenuItem>
                                    <MenuItem value={'Perú'} key={'Perú'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Perú.svg`} className="mr-2 rounded-full" />Perú</MenuItem>
                                    <MenuItem value={'Brasil'} key={'Brasil'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Brasil.svg`} className="mr-2 rounded-full" />Brasil</MenuItem>
                                    <MenuItem value={'Colombia'} key={'Colombia'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Colombia.svg`} className="mr-2 rounded-full" />Colombia</MenuItem>
                                    <MenuItem value={'Turquía'} key={'Turquía'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Turquía.svg`} className="mr-2 rounded-full" />Turquía</MenuItem>
                                    <MenuItem value={'Estados Unidos'} key={'Estados Unidos'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Estados Unidos.svg`} className="mr-2 rounded-full" />Estados Unidos</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeTipoEntrega} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${tipoentrega && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeTipoEntrega} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!tipoentrega && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Cuenta completa ? <span className="font-semibold">{cuentacompleta ? "Si" : "No"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeCuentaCompleta} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${cuentacompleta && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeCuentaCompleta} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!cuentacompleta && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Es renovable ? <span className="font-semibold">{renovable ? "Si" : "No"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeRenovable} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${renovable && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeRenovable} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!renovable && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Se bloquea los perfiles ? <span className="font-semibold">{profileblocked ? "Si" : "No"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeProfileBlocked} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${profileblocked && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeProfileBlocked} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!profileblocked && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div class="w-full md:w-6/12 p-3">
                        <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                            </div>
                            <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                        </label>

                    </div>
                    <div class="w-full p-3 md:w-6/12 z-24 rounded-full flex inline-flex items-center justify-center">
                        <div className="w-100 h-100 z-24 rounded-full flex items-center justify-center">
                            <div className="w-[155px] h-[155px] rounded-full flex items-center justify-center">
                                <LazyLoadImage
                                    className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                                    alt={`No image`}
                                    effect="blur"
                                    id='prelogo'
                                    src={`/images/logos/${state.currentlogo}`}
                                />
                            </div>
                            {preview.logo && <IoChevronForward className="text-neutral-900 dark:text-neutral-200" size={32} />}
                            <ImagesPreview url={preview.logo} />
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Sitio web</label>
                        <input name="url" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="url"
                            autoComplete="off"
                            required
                            value={state.url}
                            type="text"
                            onChange={HandleChangeEditCategory}
                        />
                    </div>

                    <div className="w-full p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Términos y condiciones del servicio</label>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            placeholder="Escribe aquí..."
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Registro de categoria</label>
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Registro de categoria no se puede editar
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>

                            <input name="created_at" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Registro de categoria"
                                autoComplete="off"
                                value={setUnixToTimeFormat("info_contacto", state.created_at)}
                                type="text"
                                disabled
                            />
                        </Tooltip>
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Ultima vez que se actualizó</label>
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Ultima vez que se actualizó no se puede editar
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>
                            <input name="last_update" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Registro de categoria"
                                autoComplete="off"
                                value={setUnixToTimeFormat("last_update", state.last_update)}
                                type="text"
                                disabled
                            />
                        </Tooltip>
                    </div>
                    <div className="flex m-auto w-full  md:w-12/12 p-3 w-full p-3 mt-5">
                        <Button
                            type="submit"
                            disabled={isLoading ? true : false}
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained"
                        >
                            {isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                            {isLoading ? 'Actualizando...' : 'Actualizar SubCategoria'}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default UpdateSubCategorySTR;