import { useSelector } from "react-redux";
import currency from "currency-formatter";
import { Toaster } from "react-hot-toast";
import ScreenHeader from "../../components/ScreenHeader";
import { IoAlarm, IoAlert, IoAlertSharp, IoBagCheck, IoBan, IoBuild, IoCheckbox, IoCheckmark, IoCloseCircleOutline, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoPin, IoReload, IoReloadCircleSharp, IoRocket, IoSearch, IoSendSharp, IoSettings, IoSettingsSharp, IoThumbsDownSharp, IoTime, IoTimeSharp } from "react-icons/io5";
import { useCreateVentaMutation, useLockOrRejectedVentaMutation } from "../../store/services/ventasService";
import Spinner from "../../components/Spinner";
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat, sleep } from "../../utils/Funciones.js";
import { MdAllInbox, MdAvTimer, MdDelete, MdDevices, MdError, MdEventNote, MdLocalShipping, MdNearbyError, MdOutgoingMail, MdPhoneIphone, MdRunningWithErrors, MdSettings, MdTimer, MdTimerOff, MdVerified } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip, Link, Pagination, Stack, PaginationItem } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Wrapper from "./Wrapper";
import debounce from 'lodash.debounce';
import moment from 'moment';
import { FaClipboard, FaClipboardCheck, FaCogs, FaFrown, FaShippingFast } from "react-icons/fa";
import { AiFillAlert } from "react-icons/ai";
import { useCountPaymentsByStateQuery, useDeletePaymentMutation, useGetAllPaymentsByPageQuery, useGetAllPaymentsQuery, useVerifyPaymentYapeMutation } from "../../store/services/paymentService";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SpinnerData from "../../components/SpinnerData";
import usePagination from "@mui/material/usePagination/usePagination";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Modal from '@mui/material/Modal';
import { useGetAllUsersQuery } from "../../store/services/userService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Spinner2 from "../../components/Spinner2";
import { createWorker } from 'tesseract.js';

const Payments = ({
  socket,
  userToken,
  user,
  SECRET_KEY,
  configuration,
  loadingconfiguration
}) => {

  const [verFilterPayments, SetFilterPayments] = useState('')
  const [curTime, SetCurTime] = useState(null)
  const [verifypayments, SetVerifyPayments] = useState({ _id: '', username: '', action: '', open: false, compobanteimg: '', userId: '', amount: 0, method: '', observation: '' })
  const [copied, setCopie] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);
  const [progressbar, setProgressBar] = useState(0);

  const { data: users, isFetching: loadingusers } = useGetAllUsersQuery();
  const { data: payments, isFetching: loadingpayments, refetch } = useGetAllPaymentsByPageQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
  const { data: counpaymentsbystate, isFetching: loadingcountpaymentsbystate, refetch: refetchpaymentsbystate } = useCountPaymentsByStateQuery();
  const [VerifyPaymentYape, responseVerifyPaymentYape] = useVerifyPaymentYapeMutation();
  //const { data: paymentsdata, isFetching: loadingpaymentsdata } = useGetAllPaymentsQuery();

  useEffect(async () => {
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [searchTerm, currentPage, limit]);

  useEffect(async () => {
    await refetchpaymentsbystate()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetchpaymentsbystate.abort();
    };
  }, [counpaymentsbystate]);

  useEffect(() => {
    setInterval(async function () {
      await sleep(300)
      SetCurTime(new Date().toLocaleString());
    }.bind(this), 1000);
  }, [])

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const HandleVerifyPayment = (action, username, id, compobanteimg, method, userId, amount) => {
    var actionfinal = ""
    if (action === 'rejected') {
      actionfinal = 'RECHAZADO'
    } else if (action === 'complete') {
      actionfinal = 'COMPLETADO'
    }
    var year = new Date().getFullYear()
    var msg = `<!DOCTYPE html>
        <html lang="en">
        
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
        
            <style>
                p,
                a,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-family: Google Sans !important;
                    color: #171717;
                }
        
                h1 {
                    font-size: 30px !important;
                }
        
                h2 {
                    font-size: 25px !important;
                }
        
                h3 {
                    font-size: 18px !important;
                }
        
                h4 {
                    font-size: 16px !important;
                }
        
                p,
                a {
                    font-size: 15px !important;
                }
        
                .claseBoton {
                    text-decoration: none;
                    cursor: pointer;
                    border-radius: 5px;
                }
        
                .imag {
                    width: 30px;
                    height: 30px;
                }
        
                .contA {
                    margin: 0px 5px 0 5px;
                }
        
                .afooter {
                    color: #ffffff !important;
                    text-decoration: none;
                    font-size: 13px !important;
                }
        
                .contenido {
                    width: 25vw;
                    margin: auto;
                    text-align: left;
                    margin-bottom: 30px;
                    margin-top: 15px;
                    padding: 5px 5px;
                }

                .dataperfiles{
                  width: calc(100% - 30px);
                  background-color: #e3e3e3;
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  margin-top: 5px;
                  color: #141414;
                }

                .perfilnumber{
                    font-size: 18px !important;
                    color: #222222;
                    font-weight: bolder;
                }
        
                .container {
                    width: 40vw;
                    margin: auto;
                    background-color: #e3e3e3
                }

                .subcontainer{
                    padding: 50px 30px 50px 30px;
                }
        
                .logo-link {
                    width: 260px;
                    margin: auto;
                    text-align: center;
                    margin-bottom: 5px;
                }

                .thanks{
                    width: 100%;
                    float: left;
                }
        
                @media screen and (max-width: 640px) {
                    .contenido {
                        width: 100%;
                    }
        
                    .container {
                        width: 100%;
                        margin: auto;
                    }
        
                    .logo-link {
                        width: 100%;
                        margin: auto;
                    }

                    .subcontainer{
                    padding: 50px 0px 50px 0px;
                }
                }
            </style>
        </head>
        
        <body>
            <div class="container">
                <div class="subcontainer">
                    <!-- Imagen inicial -->
                    <div style="background-color: #f5f5f5; padding: 20px 0px 20px 0px; width: 100%; text-align: center;">
                        <img src="https://cinedar.com/images/paymethods/yape.png" alt="" style="width: 200px; height: 200px;">
                    </div>
                    <!-- Imagen inicial -->
        
                    <!-- Contenido principal -->
                    <div style="background-color: #f5f5f5; padding: 20px 0px 5px 0px; width: 100%; text-align: center;">
                        <h1>NOTIFICACIÓN DE PAGO YAPE</h1>
                        <h3 style="color: ${actionfinal === 'COMPLETADO' ? "#16a34a" : "#dc2626"}; font-size: 28px">PAGO ${actionfinal}</h3>
                        <div class="contenido">
                            <p>Estimado(a), ${verifypayments.username} su pago ah sido ${actionfinal}.</p>
                            <p>Monto :  <b>${'S/. ' + Number(verifypayments.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b></p>
                            <p>Tipo de pago : <b>YAPE</b></p>
                            <p>Observación : <b>${verifypayments.observation}</b></p>
                            <p style="margin-top: 10px; color: #c2410c"><b>Si tiene alguna duda sobre el servicio puede comunicarse con atención al cliente.</b></p>
                        </div>
                        <!-- Gracias -->
                        <div class="thanks">
                            <p>Gracias por su tiempo.</p>
                            <p style="margin-bottom: 50px;"><i>Atentamente:</i><br>Equipo CINEDAR</p>
                        </div>
                        
        
                        <!-- Botón -->
                        <div class="logo-link">
                            <a class="claseBoton" href="https://cinedar.com" target="_blank">
                                <img src="https://cinedar.com/images/logo2.png" alt="" style="width: 260px; height: 65px;">
                            </a>
                        </div>
                    </div>
                    <!-- Contenido principal -->
        
                    <!-- Footer -->
                    <div
                        style="background-color: #282828; color: #ffffff; padding: 15px 0px 0px 0px; width: 100%; text-align: center;">
                        <!-- Redes sociales -->
                        <a href="https://www.facebook.com/condezodarvin" target="_blank" class="contA"><img
                                src="https://cinedar.com/images/redes/facebook.png" class="imag" /></a>
                        <a href="https://www.instagram.com/policedarvin/" target="_blank" class="contA"><img
                                src="https://cinedar.com/images/redes/instragram.png" class="imag" /></a>
                        <a href="https://wa.me/51994201155" target="_blank" class="contA"><img
                                src="https://cinedar.com/images/redes/whatsapp.png" class="imag" /></a>
                        <a href="mailto:support@cinedar.net" target="_blank" class="contA"><img src="https://cinedar.com/images/redes/mail.png"
                                class="imag" /></a>
                        <a href="https://t.me/darvin_condezo" target="_blank" class="contA"><img src="https://cinedar.com/images/redes/telegram.png"
                                class="imag" /></a>
                        <!-- Redes sociales -->
        
                        <h3 style="color: #ffffff">Soporte</h3>
                        <p style="font-size: 13px; padding: 0px 20px 0px 20px; color: #ffffff">
                            Comunícate con nosotros por los siguientes medios:<br>
                            Correo: <a class="afooter" href="mailto:support@cinedar.net">support@cinedar.net</a><br>
                            Whatsapp: <a class="afooter" href="https://wa.me/51994201155">+51 994 201 155</a><br>
                        </p>
                        <p style="background-color: black; color: #ffffff; padding: 10px 0px 10px 0px; font-size: 12px !important;">
                            © ${year} CINEDAR, todos los derechos reservados.
                        </p>
                    </div>
                    <!-- Footer -->
        
        
        
                </div>
            </div>
        </body>
        
        </html>`
    if (action === 'verify') {
      SetVerifyPayments({ ...verifypayments, username: username, _id: id, action: action, open: true, compobanteimg: compobanteimg, method: method, userId: userId, amount: amount })
    } else if (action === 'complete') {
      if (verifypayments.observation !== '') {
        alertaConfirmar(`Completar recarga`, `Se recargará ${currencyFormat(verifypayments.amount)}, estás seguro que el pago fue recibido?`, 'question', (res_alert) => {
          if (res_alert === true) {
            VerifyPaymentYape({ _id: verifypayments._id, action: action, observation: verifypayments.observation, html: msg })
          }
        })
      } else {
        message('Observación es requerido', 'error', 4)
      }
    } else if (action === 'rejected') {
      if (verifypayments.observation !== '') {
        alertaConfirmar(`Rechazar recarga`, `Estas seguro que quieres rechazar esta recarga?`, 'question', (res_alert) => {
          if (res_alert === true) {
            VerifyPaymentYape({ _id: verifypayments._id, action: action, observation: verifypayments.observation, html: msg })
          }
        })
      } else {
        message('Observación es requerido', 'error', 4)
      }
    }
  }

  useEffect(() => {
    if (!responseVerifyPaymentYape.isSuccess) {
      responseVerifyPaymentYape?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseVerifyPaymentYape?.error?.data?.errors])

  useEffect(() => {
    if (responseVerifyPaymentYape?.isSuccess) {

      socket.emit('verifypaymentyape',
        responseVerifyPaymentYape?.data?.userId,
        responseVerifyPaymentYape?.data?.token,
        responseVerifyPaymentYape?.data?.dataPaymentsByUserId,
        responseVerifyPaymentYape?.data?.amount,
        responseVerifyPaymentYape?.data?.action
      )
      message(responseVerifyPaymentYape?.data?.message, 'success', 5)
      SetVerifyPayments({ ...verifypayments, _id: '', action: '', open: false, compobanteimg: '', method: '', userId: '', amount: 0, observation: '' })
    }
  }, [responseVerifyPaymentYape?.isSuccess])

  const HandleChangeFilterPayments = async (state) => {
    setSearchTerm(state)
    SetFilterPayments(state)
    setCurrentPage(1);
  }

  const changeHandleSearch = (event) => {
    SetFilterPayments('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const onCopy = (text) => {
    setCopie(true);
    message(text, 'success', 2)
  }

  const [delPayment, responseDeletePayment] = useDeletePaymentMutation();

  const onDeletePayment = (id) => {
    alertaConfirmar('Eliminar Recarga', 'Estas seguro que quieres eliminar esta recarga? recuerda que se eliminarán todos los datos relacionados a esta recarga.', 'question', (res_alert) => {
      if (res_alert === true) {
        delPayment(id)
      }
    })
  }

  useEffect(() => {
    if (responseDeletePayment.isSuccess) {
      message(responseDeletePayment?.data?.msg, 'success', 5)
    }
  }, [responseDeletePayment?.data?.msg])

  useEffect(() => {
    if (!responseDeletePayment.isSuccess) {
      responseDeletePayment?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [!responseDeletePayment?.error?.data?.errors])

  /*const handleReconocerImagen = async (url) => {
    const worker = await createWorker({
      logger: (m) => {
        console.log(m)
        setProgressBar(Number(m.progress))
      }
    });

    (async () => {
      await worker.loadLanguage('eng');
      await worker.initialize('eng');
      const { data: { text } } = await worker.recognize(url);
      var existe = false
      !loadingpaymentsdata && paymentsdata?.payments.find((payment) => {
        if (text.includes(payment.payment_id)) {
          existe = true
        }
      })
      if (existe) {
        message('Comprobante ya existe', 'error', 6)
      } else {
        message('Todo en orden', 'success', 6)
      }
      console.log(text);
      console.log(text.substring(text.length - 9))
      await worker.terminate();
    })();
  }*/

  return (
    <Wrapper socket={socket} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <ScreenHeader>
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-9/12 md:w-6/12">
            <IoBagCheck size={32} className="mr-2" /> Recargas
            <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
              {!loadingpayments && payments?.totalpayments}
            </div>
          </div>
        </div>
      </ScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe usuario, correo, tipo de pago o id de pago..."
            required
            onChange={debouncedChangeHandler}
            autoComplete="off"
          />
        </div>

        <div className="pt-5">
        <div className="w-full flex items-center justify-center md:justify-end text-neutral-200 mt-0 h-10">
          <div className="hidden md:block">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(!loadingpayments && Number(payments?.totalpaymentsFilter) / limit)}
                page={currentPage}
                onChange={handleChangePage}
                variant="text"
                color="primary"
                shape="rounded"
                size="large"
                renderItem={(item) => (
                  <PaginationItem className="text-neutral-900 dark:text-neutral-200"
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                className="bg-neutral-200 dark:bg-neutral-900"
              />
            </Stack>
          </div>
          <div className="block md:hidden">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(!loadingpayments && Number(payments?.totalpaymentsFilter) / limit)}
                page={currentPage}
                onChange={handleChangePage}
                variant="text"
                color="primary"
                shape="rounded"
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                className="bg-neutral-200 dark:bg-neutral-900"
              />
            </Stack>
          </div>

        </div>
        
        {
          <Tooltip title="Click aquí para filtrar recargas pendientes" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("pending")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "pending" && "border-dashed border-2 border-neutral-500"} mt-7 bg-neutral-200 dark:bg-neutral-900 text-yellow-400 p-3 rounded-lg`}>
              <div className="flex inline-flex items-center"><IoBuild className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!loadingcountpaymentsbystate ? counpaymentsbystate?.totalPaymentsPending : <Spinner2 />}

                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar recargas rechazadas" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("rejected")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "rejected" && "border-dashed border-2 border-neutral-500"} mt-7 bg-neutral-200 dark:bg-neutral-900 text-red-500 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBuild className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!loadingcountpaymentsbystate ? counpaymentsbystate?.totalPaymentsRejected : <Spinner2 />}

                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar todas las recargas" arrow={true}>
            <div onClick={() => HandleChangeFilterPayments("")} className={`flex inline-flex cursor-pointer items-center mb-8 ${verFilterPayments === "" && "border-dashed border-2 border-neutral-500"} mt-7 bg-neutral-200 dark:bg-neutral-900 text-green-400 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><MdAllInbox className="mr-0 text-2xl md:text-4xl" /><span className="hidden md:block"></span> </div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!loadingpayments ? payments?.totalpayments : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }
      </div>
      </div>
      <Modal
        keepMounted
        open={verifypayments.open && verifypayments.action === 'verify'}
        onClose={() => SetVerifyPayments({ ...verifypayments, _id: '', action: '', open: false, compobanteimg: '', method: '', userId: '', amount: 0, observation: '' })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">

          <div className="realtive m-auto p-5 w-full md:w-5/12 bg-white dark:bg-black">
            <div className="flex items-center mb-4">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500 ">

                <IconButton color="error" onClick={() => SetVerifyPayments({ ...verifypayments, _id: '', action: '', open: false, compobanteimg: '', method: '', userId: '', amount: 0, observation: '' })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>

              </div>
              <div className="w-full text-neutral-900 dark:text-neutral-200 text-2xl ml-5 flex inline-flex items-center"> COMPROBANTE DE YAPE</div>
            </div>

            <div className="relative w-full flex justify-center">
              <label className="absolute top-0">Comprobante de pago</label>
              <div className="m-auto mt-5 border-2 w-[208px] h-[378px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">

                <LazyLoadImage
                  className="w-[200px] h-[370px] z-10 rounded-lg flex object-cover"
                  alt={`No image ${verifypayments.method}`}
                  effect="blur"
                  src={`/images/comprobantesyape/${verifypayments.compobanteimg}`}
                />
              </div>
            </div>

            {/*<div className="w-full">
              {(progressbar * 100).toFixed()}%
              <div class="w-full bg-neutral-900 rounded-full h-2.5 dark:bg-neutral-200">
                <div class="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progressbar * 100}%` }}></div>
              </div>
              <button className="bg-blue-700 hover:bg-blue-600 p-3 rounded-lg mt-5" onClick={() => handleReconocerImagen(`http://localhost:3000/images/comprobantesyape/${verifypayments.compobanteimg}`)}>
                Verificar Imagen
              </button>
            </div>*/}

            <div className="w-full mb-5 mt-7">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Escribe una observación (max: 45 chars)</label>
              <input autoFocus name="observation" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Escribe una observación (max: 45 chars)"
                autoComplete="off"
                required
                value={verifypayments.observation}
                onChange={(e) => SetVerifyPayments({ ...verifypayments, observation: e.target.value })}
                type="text"
              />
            </div>
            <div className="w-full mt-2 flex items-center">

              <div className="w-[calc(50%-5px)]">
                <Button onClick={() => HandleVerifyPayment('complete', '', '', '', '', '', '')}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  disabled={responseVerifyPaymentYape.isLoading ? true : false}
                  sx={{
                    "&.Mui-disabled": {
                      background: "#525252",
                      color: "#a3a3a3"
                    }
                  }}
                  size='large' variant="contained" color="success" startIcon={(responseVerifyPaymentYape.isLoading && verifypayments.action === 'complete') ? <Spinner w={'35px'} h={'35px'} /> : <IoRocket size={24} />}>{(responseVerifyPaymentYape.isLoading && verifypayments.action === 'complete') ? 'Enviando...' : 'COMPLETAR'}</Button>
              </div>

              <div className="w-[calc(50%-5px)] ml-10">
                <Button onClick={() => HandleVerifyPayment('rejected', '', '', '', '', '', '')}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  disabled={responseVerifyPaymentYape.isLoading ? true : false}
                  sx={{
                    "&.Mui-disabled": {
                      background: "#525252",
                      color: "#a3a3a3"
                    }
                  }}
                  size='large' variant="contained" color="error" startIcon={(responseVerifyPaymentYape.isLoading && verifypayments.action === 'rejected') ? <Spinner w={'35px'} h={'35px'} /> : <IoBan size={24} />}>{(responseVerifyPaymentYape.isLoading && verifypayments.action === 'rejected') ? 'Enviando...' : 'RECHAZAR'}</Button>
              </div>

            </div>

          </div>
        </div>
      </Modal>

      <div className="mt-[340px]">

        {
          (!loadingpayments && !loadingusers) ? payments?.payments.length > 0 ? (
            <div>
              {
                payments?.payments.map((payment) => {
                  var username = ""
                  var email = ""
                  !loadingusers && users?.users.find((_u) => {
                    if (_u._id === payment.userId) {
                      username = _u.name
                      email = _u.email
                    }
                  })

                  return (
                    <div className={`transition-all relative duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer text-neutral-900 dark:text-neutral-200 bg-white dark:bg-neutral-900 flex flex-wrap mb-9 shadow-xl rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer animation`} key={payment._id}>
                      <div className="grid grid-wrap w-full md:w-5/12">
                        <div className="flex relative inline-flex items-center">
                          <span className="absolute -mt-36 -ml-4 text-neutral-900 dark:text-neutral-200 
                          text-sm rounded-lg bg-white dark:bg-neutral-900 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center">{payment.payment_id}</span>
                          <div>
                            <LazyLoadImage
                              className="w-[45px] h-[45px] z-10 rounded-full flex inline-flex"
                              alt={`No image ${payment.method}`}
                              effect="blur"
                              src={`/images/paymethods/${payment.method}.webp`}
                            />
                          </div>

                          <div className="w-[calc(100%-45px)]">
                            <div className="ml-2 text-xl font-semibold capitalize mt-3">{payment.method}</div>
                            <div className="ml-2 text-base flex items-cnter">
                              <div className="truncate ...">{email.length > 27 ? email.substr(0, 27) + '...' : email}</div>
                              <div className="w-[25px] ml-5">
                                <CopyToClipboard text={email} onCopy={() => onCopy('Correo copiado')}>
                                  <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                                </CopyToClipboard>
                              </div>
                            </div>
                            <div className="ml-2 text-base">{username}</div>
                            <div style={{ fontFamily: 'Outfit, sans-serif', letterSpacing: 3 }} className={`ml-2 text-xl font-semibold ${payment.status === 'complete' ? 'text-green-500' : payment.status === 'pending' ? 'text-yellow-400' : 'text-red-400'}`}>{currencyFormat(payment.total_recharge)}</div>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-wrap w-full md:w-3/12 mt-3 md:mt-0">
                        <div className="flex items-center text-sm"><IoTime size={28} className="mr-2" /> {setUnixToTimeFormat("info_contacto", payment.created_at)}</div>
                        {
                          payment.status === 'pending' &&
                          <div className="flex items-center text-sm"><AiFillAlert size={28} className="mr-2 text-red-500" />
                            El usuario está esperando : {CalcularTiempoTranscurrido(payment.created_at)}
                          </div>
                        }

                      </div>

                      <div className="flex items-center w-full md:w-3/12 pr-2">
                        <div className="flex items-center justify-start w-full md:w-6/12">
                          <div className="w-[35px] h-[35px] flex items-center"><MdEventNote size={28} className="mr-2 text-yellow-400" /></div>
                          <div className="text-sm">
                            {payment.status === "complete" && payment.observations}
                            {payment.status === "pending" && <span className="text-red-400">Verifica y completa para recargar el saldo correspondiente al usuariio.</span>}
                            {payment.status === "rejected" && <span className="text-red-400">{payment.observations}</span>}
                          </div>
                        </div>

                        <div className="flex items-center justify-end mr-2 w-full md:w-6/12 ">
                          {payment.status === "complete" && <div className="flex inline-flex items-center text-green-400 text-sm">
                            <MdVerified size={28} className="mr-2" /> Completado</div>}
                          {payment.status === "pending" && <div className="flex inline-flex items-center text-yellow-400 text-sm">
                            <FaShippingFast size={28} className="mr-2" />Pendiente</div>}
                          {payment.status === "rejected" && <div className="flex inline-flex items-center text-red-400 text-sm">
                            <FaFrown size={28} className="mr-2" />Rechazado</div>}
                        </div>
                      </div>

                      <div className="flex inline-flex items-center justify-end w-full md:w-1/12 mt-3 md:mt-0">
                        <div className="flex inline-flex items-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                          <div className="flex items-center justify-center w-14 h-14 rounded-full mr-2">
                            <Tooltip title="Verificar" arrow={true}>
                              <IconButton onClick={() => HandleVerifyPayment('verify', username, payment._id, payment.image, payment.method, payment.userId, payment.total_recharge)} color="primary" className="flex items-center justify-center w-14 h-14"
                                disabled={(payment.status === 'complete' || payment.status === 'rejected') ? true : false}>
                                <MdVerified size={32} className={`${(payment.status === 'complete' || payment.status === 'rejected') ? 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed' : 'text-green-400'}`} />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="w-14 h-14 rounded-full">
                            <Tooltip title="Eliminar" arrow={true}>
                              <IconButton onClick={() => onDeletePayment(payment._id)} className="w-14 h-14" color="primary" disabled={payment.status === 'rejected' ? false : true}>
                                <MdDelete
                                  size={25}
                                  className={`${payment.status === 'rejected' ? 'text-red-600' : 'text-neutral-400 dark:text-neutral-700'}`}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>) : (<div className="alert-danger font-semibold mt-[340px]">Sin resultados en la búsqueda! o no existen recargas registrados</div>) : (<div className="mt-[340px]"><SpinnerData>Cargando recargas, por favor espere...</SpinnerData></div>)
        }

      </div>

    </Wrapper>
  );
};

export default Payments;
