import { Link } from "react-router-dom";
import CuentaSkeleton from "./CuentaSkeleton";
import { IoChevronForward, IoStar } from "react-icons/io5";
import { Pagination, Autoplay, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { BsHandbag, BsStar } from "react-icons/bs";
import { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from 'moment';
import 'moment/locale/es';
import Spinner from "../Spinner";

const LastVentas = ({
  lastventas,
  loadinglastventas }) => {

  const [curTime, SetCurTime] = useState(null)

  useEffect(async () => {
    const interval = setInterval(async () => {
      SetCurTime(new Date().toLocaleString());
    }, 30000);
    return () => clearInterval(interval);
  }, [])

  return loadinglastventas ? (
    <Spinner/>
  ) : (
    lastventas.length > 0 && (
      <>
        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="mt-3 w-full 
       text-neutral-100 bg-blue-custom
        mb-3 text-center text-xl md:text-3xl pt-5 pb-5 contrast-[125%]">
          ULTIMAS VENTAS REALIZADAS
        </div>
        <div className="flex flex-wrap w-full">

          <div className="flex flex-wrap w-full">
            <Swiper
              modules={[Virtual, Pagination, Autoplay]}
              pagination={{
                type: 'fraction'
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              slidesPerView={3}
              breakpoints={{
                320: { slidesPerView: 2, spaceBetween: 15 },
                640: { slidesPerView: 3, spaceBetween: 15 },
                1024: { slidesPerView: 4, spaceBetween: 15 },
              }}
              virtual
              className="w-full h-[250px]"
            >

              {
                lastventas.map((venta, index) => {

                  return (
                    <SwiperSlide
                      className="w-full overflow-hidden relative"
                      key={index}
                      virtualIndex={index}
                    >
                      <div key={venta._id} className="absolute bg-neutral-50 dark:bg-neutral-950 hover:bg-neutral-300 dark:bg-neutral-700 transition-all duration-500 hover:scale-[105%] hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer inset-0 w-full h-full flex items-center justify-center p-2">

                        <div className="absolute block top-0 mt-3 font-medium capitalize text-neutral-900 dark:text-neutral-200">
                          <div>
                            <LazyLoadImage
                              className="object-cover w-24 h-24 rounded-full m-auto"
                              alt={`No image ${venta.categoryname}`}
                              effect="blur"
                              src={`/images/logos/${venta.logocategory}`}
                            />
                          </div>
                        </div>
                        <div className="w-full relative mt-12 text-sm text-center text-neutral-800 dark:text-neutral-200">
                          El usuario {venta.useremail.replace(/[a-z0-9\-_.]+@/ig, (c) => c.substr(0, 3) + c.split('').slice(3, -1).map(v => '*').join('') + '@')} ah comprado un servicio de {venta.categoryname}
                        </div>
                        <div className="absolute bottom-3 p-2">
                          <div className="flex items-center text-xs dark:text-green-400 text-green-700">
                            {moment(venta.created_at).fromNow()}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }

            </Swiper>
          </div>
        </div>
      </>
    )
  );
};

export default LastVentas;
