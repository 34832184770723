import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import TextField from '@mui/material/TextField';
import toast, { Toaster } from "react-hot-toast";
import { useCreatePaymentIziPayMutation, useGenerateQrCodeBinancePayMutation, useGetPaymentsByUserIdQuery, useSendPaymentMercadoPagoMutation, useSendPaymentMutation, useSendYapePaymentMutation, useValidatePaymentIziPayMutation } from "../../store/services/paymentService";
import { Button, IconButton, Tooltip } from "@mui/material";
import UserScreenHeader from '../../components/home/UserScreenHeader';
import { FaCoins, FaQuestion, FaUpload, FaWhatsapp } from 'react-icons/fa';
import { currencyFormat, getUrlParameters, message, PermiteSoloNumeros } from '../../utils/Funciones.js';
import { IoCard, IoChevronForward, IoCloseSharp, IoInformation, IoLogoWhatsapp, IoSend } from 'react-icons/io5';
import { MdPayment } from 'react-icons/md';
import { SiBinance, SiMastercard } from "react-icons/si";
import { setUserToken } from '../../store/reducers/authReducer';
import AccountList from '../../components/home/AccountList';
import Spinner from '../../components/Spinner';
import ImagesPreviewPayment from "../../components/home/ImagesPreviewPayment";
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSendMessageWhatsappNewServiceMutation } from '../../store/services/whatsappService';
import IziPayPaymentForm from '../../components/home/IziPayPaymentForm';
import KRGlue from '@lyracom/embedded-form-glue';

const Recharge = ({ socket, userToken, user, loadinguserbyid, userById, configuration, loadingconfiguration, UpdateUserById }) => {
  const navigate = useNavigate();
  const { data: paymentsbyId, isFetching: loadingpaymentsbyId } = useGetPaymentsByUserIdQuery(userToken && user?.id);
  const [s_PackSelected, SetPackSelected] = useState(0)
  const [s_tarifa, SetTarifa] = useState(0)
  const [s_PayMethod, SetPayMethod] = useState("")
  const [selectImageYape, SetSelectImageYape] = useState({ open: false, image: '' })
  const dispatch = useDispatch();
  const [usdtToPen, setUsdtToPen] = useState(3.60);
  const [isShow, setIsShow] = useState(false);
  const [formId, setFormId] = useState("");
  const [showPayAuto, setShowPayAuto] = useState(false);
  const [showPayManual, setShowPayManual] = useState(false);
  //test 84423304:testpublickey_NuryFFeEoujHrn9T0Nc93NAfT1BqIbqTKIADqhjP4NO1H
  //prod 84423304:publickey_WBPl2IvbLSPk6DPbtgL33rDhhYOELyx6xEYezz6TCfLVW
  const publicKey = '84423304:publickey_WBPl2IvbLSPk6DPbtgL33rDhhYOELyx6xEYezz6TCfLVW';
  const endPoint = 'https://api.micuentaweb.pe';

  const addTarifaToRecharge = (fee) => {
    SetTarifa(fee)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setUsdtToPen(data.rates.PEN);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const interval = setInterval(fetchExchangeRate, 5000); // Fetch every 5 seconds
    fetchExchangeRate();
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const addPackToRecharge = (cantidad) => {
    if (s_PayMethod === 'mercadopago') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(((cantidad + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(cantidad)).toFixed(2))
    } else if (s_PayMethod === 'stripe') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(((cantidad + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(cantidad)).toFixed(2))
    } else if (s_PayMethod === 'yape') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(0)
    } else if (s_PayMethod === 'binance') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(0)
    } else {
      SetPackSelected(cantidad)
      addTarifaToRecharge(0)
    }
  };

  const addPayMethodPayMethodToRecharge = (method) => {
    if (method === 'mercadopago') {
      SetPayMethod(method)
      addTarifaToRecharge(((Number(s_PackSelected) + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(Number(s_PackSelected))).toFixed(2))
    } else if (method === 'stripe') {
      SetPayMethod(method)
      addTarifaToRecharge(((Number(s_PackSelected) + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(Number(s_PackSelected))).toFixed(2))
    } else if (method === 'yape') {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    } else if (method === 'izipay') {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    } else if (method === 'binance') {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    }
  };

  const imageHandle = e => {
    if (e.target.files.length !== 0) {
      SetSelectImageYape({ ...selectImageYape, [e.target.name]: e.target.files[0] });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview({ ...preview, [e.target.name]: reader.result })
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const [preview, setPreview] = useState({
    image: '',
  })

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const CloseSelectImageYape = () => {
    setPreview({ ...preview, image: '' })
    SetSelectImageYape({ ...selectImageYape, open: false, image: '' })
  }

  const [doPayment, response] = useSendPaymentMutation();
  const [doPaymentMercadoPago, responseMercadoPago] = useSendPaymentMercadoPagoMutation();
  const [doPaymentBinance, responseBinance] = useGenerateQrCodeBinancePayMutation();
  const [doPaymentIziPay, responseIziPay] = useCreatePaymentIziPayMutation();
  const [validatePaymentIziPay, responsevalidatePaymentIziPay] = useValidatePaymentIziPayMutation();

  const pay = async () => {
    const recharge = [{
      name: 'PACK' + s_PackSelected,
      totalPay: s_PackSelected,
      _id: user?.id
    }]
    if (!userToken) {
      message(`No tienes permiso para realizar esta acción!`, 'error', 4);
    } else if (configuration && !configuration.buycredits) {
      message(`Comprar créditos está temporalmente bloqueado, intentalo denuevo mas tarde.`, 'error', 4);
    } else if (s_PackSelected <= 0) {
      message(`Debes seleccionar por lo menos un paquete!`, 'error', 4);
    } else if (userToken && !user.reseller && (s_PackSelected < 4 || s_PackSelected > 1000)) {
      message(`El valor mínimo es 4 y el valor máximo es 1000`, 'error', 4);
    } else if (userToken && user.reseller && (s_PackSelected < 4 || s_PackSelected > 1000)) {
      message(`El valor mínimo es 4 y el valor máximo es 1000`, 'error', 4);
    } else if (s_PayMethod === "binance") {
      const response = await axios.get('https://api.ipify.org?format=json');
      const BinanceData = {
        amount: s_PackSelected,
        currency: 'USDT',
        usdtToPen: usdtToPen ? usdtToPen : 3.60,
        userId: user?.id,
        ip_location: response.data.ip ? response.data.ip : '0.0.0.0'
      }
      doPaymentBinance(BinanceData)

    } else if (s_PayMethod === "izipay") {
      const response = await axios.get('https://api.ipify.org?format=json');
      const monto = Number(s_PackSelected)
      var montowf = ((monto * 0.0344) + ((monto * 0.0344) * 0.18) + 0.69 + (0.69 * 0.18)) + monto
      var totalrealfee = ((montowf * 0.0344) + ((montowf * 0.0344) * 0.18) + 0.69 + (0.69 * 0.18))
      const IziPayData = {
        amount: monto,
        currency: 'PEN',
        fee: totalrealfee.toFixed(2),
        userId: user?.id,
        email: user?.email,
        ip_location: response.data.ip ? response.data.ip : '0.0.0.0'
      }

      alertaConfirmar(`Acuerdo importante!`, `El monto actual no incluye tarifas de IziPay <br>Tarifa: <b>+${currencyFormat(totalrealfee)}</b><br> quieres continuar?`, 'question', (res_alert) => {
        if (res_alert === true) {
          doPaymentIziPay(IziPayData)
        }
      })
    } else if (s_PayMethod === "yape") {
      const existpaymentyapepending = !loadingpaymentsbyId && paymentsbyId?.payments.filter((pay) => (pay.method === 'yape' && pay.status === 'pending'))
      if (existpaymentyapepending.length > 0) {
        message('Aún existe un pago pendiente de verificación de YAPE.', 'error', 4)
      } else {
        SetSelectImageYape({ ...selectImageYape, open: true })
      }
    } else if (s_PayMethod === "stripe") {
      alertaConfirmar(`Acuerdo importante!`, `El monto actual no incluye tarifas de stripe <br>Tarifa: <b>+${currencyFormat(Number(s_tarifa))}</b><br> quieres continuar?`, 'question', (res_alert) => {
        doPayment({ recharge: recharge });
      })
    } else if (s_PayMethod === "mercadopago") {
      alertaConfirmar(`Acuerdo importante!`, `El monto actual no incluye tarifas de mercadopago <br>Tarifa: <b>+${currencyFormat(Number(s_tarifa))}</b><br> quieres continuar?`, 'question', (res_alert) => {
        if (res_alert === true) {
          const rechargeMercadoPago = {
            name: 'PACK' + s_PackSelected,
            totalPay: Number(s_PackSelected),
            userId: user?.id
          }
          doPaymentMercadoPago({ recharge: rechargeMercadoPago });
        }
      })
    }
  };

  const HandleChangeMontoManual = (e) => {
    if (s_PayMethod === 'mercadopago') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(((Number(e.target.value) + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(Number(e.target.value))).toFixed(2))
    } else if (s_PayMethod === 'stripe') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(((Number(e.target.value) + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(Number(e.target.value))).toFixed(2))
    } else if (s_PayMethod === 'yape') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    } else if (s_PayMethod === 'binance') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    } else {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    }
  }

  useEffect(() => {
    if (responseBinance?.isSuccess) {
      window.location.href = responseBinance?.data?.response?.data?.checkoutUrl;
    }
  }, [responseBinance]);

  useEffect(() => {
    if (responseIziPay?.isSuccess) {
      setIsShow(true);
      KRGlue.loadLibrary(endPoint, publicKey)
        .then(({ KR }) => KR.setFormConfig({
          formToken: responseIziPay?.data?.formToken
          // formToken:data.formToken,
        }))
        .then(({ KR }) => KR.onSubmit(validatePayment))
        .then(({ KR }) => KR.attachForm("#form"))
        .then(({ KR, result }) => KR.showForm(result.formId))
        // })
        .catch(err => console.log(err))
    }
  }, [responseIziPay]);

  const validatePayment = (resp) => {
    validatePaymentIziPay({ clientAnswer: resp.clientAnswer, hash: resp.hash, hashKey: resp.hashKey })
  }

  useEffect(() => {
    if (!responseIziPay.isSuccess) {
      responseIziPay?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseIziPay?.error?.data?.errors])

  useEffect(async () => {
    if (responsevalidatePaymentIziPay?.isSuccess) {
      message(responsevalidatePaymentIziPay?.data.message, 'success', 4)
      UpdateUserById(responsevalidatePaymentIziPay?.data?.token)
      await KRGlue.loadLibrary(endPoint, publicKey)
        .then(({ KR }) => {
          KR.removeForms();
        })
        .catch(error => {
          console.error("Error al cargar la librería", error);
        });
      navigate('/manage-account/paymentshistory')
    }
  }, [responsevalidatePaymentIziPay]);

  useEffect(() => {
    if (!responsevalidatePaymentIziPay.isSuccess) {
      responsevalidatePaymentIziPay?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responsevalidatePaymentIziPay?.error?.data?.errors])


  useEffect(() => {
    if (response?.isSuccess) {
      window.location.href = response?.data?.url;
    }
  }, [response]);

  const [yapepayment, responseyapepayment] = useSendYapePaymentMutation();

  const onSubmitSendYapePayment = async e => {
    e.preventDefault();
    if (selectImageYape.image) {

      const formData = new FormData();
      var year = new Date().getFullYear()
      var msg = `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
            
                <style>
                    p,
                    a,
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        font-family: Google Sans !important;
                        color: #171717;
                    }
            
                    h1 {
                        font-size: 30px !important;
                    }
            
                    h2 {
                        font-size: 25px !important;
                    }
            
                    h3 {
                        font-size: 18px !important;
                    }
            
                    h4 {
                        font-size: 16px !important;
                    }
            
                    p,
                    a {
                        font-size: 15px !important;
                    }
            
                    .claseBoton {
                        text-decoration: none;
                        cursor: pointer;
                        border-radius: 5px;
                    }
            
                    .imag {
                        width: 30px;
                        height: 30px;
                    }
            
                    .contA {
                        margin: 0px 5px 0 5px;
                    }
            
                    .afooter {
                        color: #ffffff !important;
                        text-decoration: none;
                        font-size: 13px !important;
                    }
            
                    .contenido {
                        width: 25vw;
                        margin: auto;
                        text-align: left;
                        margin-bottom: 30px;
                        margin-top: 15px;
                        padding: 5px 5px;
                    }
    
                    .dataperfiles{
                      width: calc(100% - 30px);
                      background-color: #e3e3e3;
                      padding-left: 10px;
                      padding-right: 10px;
                      padding-top: 10px;
                      padding-bottom: 10px;
                      margin-top: 5px;
                      color: #141414;
                    }
    
                    .perfilnumber{
                        font-size: 18px !important;
                        color: #222222;
                        font-weight: bolder;
                    }
            
                    .container {
                        width: 40vw;
                        margin: auto;
                        background-color: #e3e3e3
                    }
    
                    .subcontainer{
                        padding: 50px 30px 50px 30px;
                    }
            
                    .logo-link {
                        width: 260px;
                        margin: auto;
                        text-align: center;
                        margin-bottom: 5px;
                    }
    
                    .thanks{
                        width: 100%;
                        float: left;
                    }
            
                    @media screen and (max-width: 640px) {
                        .contenido {
                            width: 100%;
                        }
            
                        .container {
                            width: 100%;
                            margin: auto;
                        }
            
                        .logo-link {
                            width: 100%;
                            margin: auto;
                        }
    
                        .subcontainer{
                        padding: 50px 0px 50px 0px;
                    }
                    }
                </style>
            </head>
            
            <body>
                <div class="container">
                    <div class="subcontainer">
                        <!-- Imagen inicial -->
                        <div style="background-color: #f5f5f5; padding: 20px 0px 20px 0px; width: 100%; text-align: center;">
                            <img src="https://cinedar.com/images/paymethods/yape.png" alt="" style="width: 200px; height: 200px;">
                        </div>
                        <!-- Imagen inicial -->
            
                        <!-- Contenido principal -->
                        <div style="background-color: #f5f5f5; padding: 20px 0px 5px 0px; width: 100%; text-align: center;">
                            <h1>NOTIFICACIÓN DE PAGO YAPE</h1>
                            <h3 style="color: #dc2626; font-size: 36px">URGENTE</h3>
                            <div class="contenido">
                                <p>El usuario <b>${user.name}</b> ah realizado una recarga de yape.</p>
                                <p>Monto :  <b>${'S/. ' + Number(s_PackSelected).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b></p>
                                <p>Tipo de pago : <b>YAPE</b></p>
                                <p style="margin-top: 10px; color: #c2410c"><b>Verifica que el pago ah sido recibido correctamente y procede con la recarga correspondiente.</b></p>
                            </div>
                            <!-- Gracias -->
                            <div class="thanks">
                                <p>Gracias por su tiempo.</p>
                                <p style="margin-bottom: 50px;"><i>Atentamente:</i><br>Equipo CINEDAR</p>
                            </div>
                            
            
                            <!-- Botón -->
                            <div class="logo-link">
                                <a class="claseBoton" href="https://cinedar.com" target="_blank">
                                    <img src="https://cinedar.com/images/logo2.png" alt="" style="width: 260px; height: 65px;">
                                </a>
                            </div>
                        </div>
                        <!-- Contenido principal -->
            
                        <!-- Footer -->
                        <div
                            style="background-color: #282828; color: #ffffff; padding: 15px 0px 0px 0px; width: 100%; text-align: center;">
                            <!-- Redes sociales -->
                            <a href="https://www.facebook.com/condezodarvin" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/facebook.png" class="imag" /></a>
                            <a href="https://www.instagram.com/policedarvin/" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/instragram.png" class="imag" /></a>
                            <a href="https://wa.me/51994201155" target="_blank" class="contA"><img
                                    src="https://cinedar.com/images/redes/whatsapp.png" class="imag" /></a>
                            <a href="mailto:support@cinedar.net" target="_blank" class="contA"><img src="https://cinedar.com/images/redes/mail.png"
                                    class="imag" /></a>
                            <a href="https://t.me/darvin_condezo" target="_blank" class="contA"><img src="https://cinedar.com/images/redes/telegram.png"
                                    class="imag" /></a>
                            <!-- Redes sociales -->
            
                            <h3 style="color: #ffffff">Soporte</h3>
                            <p style="font-size: 13px; padding: 0px 20px 0px 20px; color: #ffffff">
                                Comunícate con nosotros por los siguientes medios:<br>
                                Correo: <a class="afooter" href="mailto:support@cinedar.net">support@cinedar.net</a><br>
                                Whatsapp: <a class="afooter" href="https://wa.me/51994201155">+51 994 201 155</a><br>
                            </p>
                            <p style="background-color: black; color: #ffffff; padding: 10px 0px 10px 0px; font-size: 12px !important;">
                                © ${year} CINEDAR, todos los derechos reservados.
                            </p>
                        </div>
                        <!-- Footer -->
            
            
            
                    </div>
                </div>
            </body>
            
            </html>`
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        formData.append('data', JSON.stringify({ userId: user.id, method: 'yape', amount: Number(s_PackSelected), html: msg, ip_location: response.data.ip ? response.data.ip : '0.0.0.0' }));
        formData.append('image1', selectImageYape.image)
        yapepayment(formData);
      } catch (error) {
        message('Se detectó adblock, desactivar por favor.', 'error', 3)
      }

    } else {
      message('Es necesario un comprobante de pago', 'error', 4)
    }
  }

  useEffect(() => {
    if (!responseyapepayment.isSuccess) {
      responseyapepayment?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseyapepayment?.error?.data?.errors])

  useEffect(() => {
    if (responseyapepayment?.isSuccess) {
      socket.emit('yapepayment', responseyapepayment?.data?.callback)
      CloseSelectImageYape()
      Swal.fire({
        title: "Mensaje importante !",
        text: 'El comprobante de pago se envió correctamente, por favor espere que el ADMINISTRADOR verifique y libere sus créditos, gracias. ',
        icon: "success",
        confirmButtonText: 'OK, entiendo',
        confirmButtonColor: '#2C3E50',
      });
    }
  }, [responseyapepayment?.isSuccess])

  useEffect(() => {
    if (!responseMercadoPago.isSuccess) {
      responseMercadoPago?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseMercadoPago?.error?.data?.errors])

  useEffect(() => {
    if (responseMercadoPago?.isSuccess) {
      window.location.href = responseMercadoPago?.data?.init_point
    }
  }, [responseMercadoPago?.isSuccess])

  const [onSendWhatsapp, responseWhatsapp] = useSendMessageWhatsappNewServiceMutation()

  const onSendMessageWhatsapp = (e) => {
    e.preventDefault()
    onSendWhatsapp()
  }

  const onCloseFormPaymentIziPay = () => {
    setIsShow(!isShow)
    KRGlue.loadLibrary(endPoint, publicKey)
      .then(({ KR }) => {
        KR.removeForms();
      })
      .catch(error => {
        console.error("Error al cargar la librería", error);
      });
  }


  return (
    <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="bottom-center" reverseOrder={true} />
      <UserScreenHeader>
        <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
          <FaCoins size={32} className="mr-2" /> Recargar <span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            text-neutral-800 bg-neutral-200 rounded-full"></span></div>
      </UserScreenHeader>


      <div className="w-full md:w-12/12 mt-[106px] bg-neutral-200 dark:bg-neutral-900 p-3">

        {
          selectImageYape.open &&
          <div id="authentication-modal" className="overflow-y-auto fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">
            <div className="realtive m-auto p-5 w-full md:w-6/12 bg-neutral-200 dark:bg-neutral-900">
              <div className="flex inline-flex items-center">
                <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                  <IconButton color="error" onClick={CloseSelectImageYape}>
                    <IoCloseSharp
                      className="text-neutral-200"
                      size={28}
                    />
                  </IconButton>
                </div>
                <div className='pl-5 text-3xl'>
                  Pagar {currencyFormat(Number(s_PackSelected))} con YAPE
                </div>
              </div>

              <form className='w-full flex flex-wrap p-3 mt-5' onSubmit={onSubmitSendYapePayment}>
                <div className="relative w-full flex items-center justify-center mb-6 mt-4">
                  <label className="absolute -top-10 text-2xl">Escanear QR</label>
                  <LazyLoadImage
                    className="rounded-lg w-96 object-cover"
                    alt={`No image Qr Yape`}
                    effect="blur"
                    src={`/images/paymethods/qr-yape.jpg`}
                  />
                </div>
                <div className='text-yellow-600 dark:text-yellow-300 text-2xl font-semibold'>DESPUÉS DE ENVIAR EL YAPE, POR FAVOR ENVIAR LA CAPTURA AL WHATSAPP A FIN DE RECARGAR SU SALDO.</div>

                <div className="relative w-full mb-3 mt-8 flex flex-wrap justify-center">
                  <label className='w-full text-center font-bold'>Contáctanos</label>
                  <a href={`https://wa.me/51994201155?text=Hola hice una recarga por yape con el monto de ${currencyFormat(Number(s_PackSelected))}, mi correo es : ${userToken && user.email}, por favor podría verificar, gracias. `} target="_blank" className='flex items-center cursor-pointer flex items-center'>
                    <div className='z-20 bg-green-600 rounded-full flex items-center justify-center p-5 shadow-md shadow-neutral-950'><FaWhatsapp size={45} /></div>
                    <div className='bg-green-600 pt-5 pb-5 pr-5 pl-10 -ml-5 rounded-lg text-lg font-bold'>Hablemos por whatsapp</div>
                  </a>
                </div>
                {/*<div className="w-full md:w-7/12 mb-5">
                  <label htmlFor="image" class="flex flex-col items-center justify-center w-full h-[370px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-300 dark:bg-neutral-700 hover:bg-neutral-200 dark:bg-neutral-900">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <FaUpload size={32} className='text-neutral-100 mb-2' />
                      <p class="mb-2 text-sm text-neutral-400"><span class="font-semibold">Click para subir comprobante de YAPE </span> o arrastrar y soltar</p>
                      <p class="text-xs text-neutral-400">PNG o JPG (MAX. 800x400px)</p>
                    </div>
                    <input id="image" accept="image/png,image/jpeg" type="file" name="image" class="hidden" onChange={imageHandle} />
                  </label>

                </div>

                <div class="w-full md:w-5/12 rounded-full flex inline-flex items-center justify-center mb-5">
                  <div className="w-[208px] h-[378px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                    <ImagesPreviewPayment url={preview.image} />
                  </div>
                </div>
                <div className="w-full">
                  <Button
                    type="submit"
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                    disabled={responseyapepayment.isLoading ? true : false}
                  >
                    {responseyapepayment.isLoading ? <Spinner /> : <IoSend size={24} className='mr-2' />}
                    {responseyapepayment.isLoading ? 'Enviando...' : 'Enviar Comprobante'}
                  </Button>
                </div>*/}
              </form>

            </div>
          </div>
        }

        <div className="flex flex-wrap gap-7">
          <div className="w-full relative">
            <label for="first_name" class="block mb-2 text-xl font-bold text-neutral-900 dark:text-white">Monto</label>
            <input autoFocus type="text" id="monto" className="pl-9 bg-neutral-300 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-xl rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-500 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
              placeholder="Monto"
              required
              value={s_PackSelected}
              onChange={HandleChangeMontoManual}
              autoComplete="off"
              onKeyPress={PermiteSoloNumeros}
            />
            <div class="absolute inset-y-4 mt-9 left-1 pr-3 flex items-center text-2xl font-bold leading-5 text-neutral-700 dark:text-neutral-300">
              S/
            </div>
          </div>

          <div className="w-full">
            <label for="first_name" class="w-full block text-lg font-bold text-neutral-900 dark:text-white text-center border-b border-neutral-200 dark:border-neutral-700 pb-5 pt-5">Recarga de saldo <b>MANUAL</b>
              <Tooltip title={
                <div style={{ display: 'block' }}>
                  <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-neutral-600'>Recarga de saldo MANUAL</div>
                  <div className='text-neutral-300 text-sm font-normal'>Al ralizar una recarga manual los créditos son recibidos después que el administrador haya verificado que el pago se realizó de forma correcta; tiempo estimado 10 a 30 minutos.</div>
                </div>}

                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      fontSize: 12.5,
                    }
                  }
                }} arrow>
                <IconButton color='primary' className='w-14 h-14 rounded-full bg-neutral-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
            </label>
            <div className='w-full flex items-center justify-center bg-neutral-950 p-5'>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4 w-full md:w-2/12">
                {/*
              userToken && user?.admin &&
              <div onClick={(e) => addPayMethodPayMethodToRecharge('stripe')} 
              className={`w-full`}>
                <LazyLoadImage
                  className="z-10 w-16 h-16 rounded-full flex mr-2"
                  alt={`No image stripe}`}
                  effect="blur"
                  src={`/images/paymethods/stripe.png`}
                />
                <div className='grid grid-wrap'>
                  STRIPE<span className='text-sm'>crédito/débito</span>
                </div>
              </div>
            */}

                <div onClick={(e) => addPayMethodPayMethodToRecharge('yape')}
                  className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'yape' && 'bg-gradient-blue-custom text-white'}`}>
                  <div className='w-[65px] h-[65px] flex items-center justify-center'>
                    <LazyLoadImage
                      className="z-10 w-[45px] h-[45px] rounded-full flex"
                      alt={`No image yape}`}
                      effect="blur"
                      src={`/images/paymethods/yape.webp`}
                    />
                  </div>
                  <div className='grid grid-wrap w-[calc(100%-65)]'>
                    <div className={`text-xl font-bold  ${s_PayMethod === 'yape' ? 'text-neutral-50' : 'text-neutral-900'}`}>YAPE</div>
                    <div className={`text-sm font-bold  ${s_PayMethod === 'yape' ? 'text-neutral-300' : 'text-neutral-700'}`}>BCP</div>
                  </div>
                </div>
              </div>
            </div>

            <label for="first_name" class="w-full block text-lg font-bold text-neutral-900 dark:text-white text-center border-b border-neutral-200 dark:border-neutral-700 pb-5 pt-5">Recarga de saldo <b>AUTOMÁTICO</b>
              <Tooltip title={
                <div style={{ display: 'block' }}>
                  <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-neutral-600'>Recarga de saldo AUTOMÁTICO</div>

                  <div className='text-neutral-300 text-sm font-normal'>Al ralizar una recarga automática los créditos son recibidos instantaneamente después de realizar el pago de forma correcta.</div>
                </div>}

                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      fontSize: 12.5,
                    }
                  }
                }} arrow><IconButton color='primary' className='w-14 h-14 rounded-full bg-neutral-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
            </label>

            <div className='w-full flex items-center justify-center bg-neutral-950 p-5'>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full md:w-7/12">
                <div onClick={(e) => addPayMethodPayMethodToRecharge('mercadopago')}
                  className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'mercadopago' && 'bg-gradient-blue-custom text-white'}`}>
                  <div className='w-[65px] h-[65px] flex items-center justify-center'>
                    <LazyLoadImage
                      className="z-10 w-[45px] h-[45px] rounded-full flex"
                      alt={`No image mercadopago}`}
                      effect="blur"
                      src={`/images/paymethods/mercadopago.webp`}
                    />
                  </div>
                  <div className='grid grid-wrap w-[calc(100%-65)]'>
                    <div className={`text-xl font-bold  ${s_PayMethod === 'mercadopago' ? 'text-neutral-50' : 'text-neutral-900'}`}>MERCADOPAGO</div>
                    <div className={`text-sm font-bold  ${s_PayMethod === 'mercadopago' ? 'text-neutral-300' : 'text-neutral-700'}`}>MERCADOLIBRE</div>
                  </div>
                </div>

                <div onClick={(e) => addPayMethodPayMethodToRecharge('binance')}
                  className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'binance' && 'bg-gradient-blue-custom text-white'}`}>
                  <div className='w-[65px] h-[65px] flex items-center justify-center'>
                    <LazyLoadImage
                      className="z-10 w-[45px] h-[45px] rounded-full flex"
                      alt={`No image yape}`}
                      effect="blur"
                      src={`/images/paymethods/binance.webp`}
                    />
                  </div>
                  <div className='grid grid-wrap w-[calc(100%-65)]'>
                    <div className={`text-xl font-bold  ${s_PayMethod === 'binance' ? 'text-neutral-50' : 'text-neutral-900'}`}>BINANCE</div>
                    <div className={`text-sm font-bold  ${s_PayMethod === 'binance' ? 'text-neutral-300' : 'text-neutral-700'}`}>USDT</div>
                  </div>
                </div>

                <div onClick={(e) => addPayMethodPayMethodToRecharge('izipay')}
                  className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'izipay' && 'bg-gradient-blue-custom text-white'}`}>
                  <div className='w-[65px] h-[65px] flex items-center justify-center'>
                    <LazyLoadImage
                      className="z-10 w-[45px] h-[45px] rounded-full flex"
                      alt={`No image izipay}`}
                      effect="blur"
                      src={`/images/paymethods/izipay.webp`}
                    />
                  </div>
                  <div className='grid grid-wrap w-[calc(100%-65)]'>
                    <div className={`text-xl font-bold  ${s_PayMethod === 'izipay' ? 'text-neutral-50' : 'text-neutral-900'}`}>IZIPAY</div>
                    <div className={`text-sm font-bold  ${s_PayMethod === 'izipay' ? 'text-neutral-300' : 'text-neutral-700'}`}>DEBIT & CREDIT CARD</div>
                  </div>
                </div>

                <div id="popup-modal" tabindex="-1" class={`${isShow ? 'fixed block' : 'hidden'} flex items-center justify-center inset-0 bg-neutral-600 bg-opacity-50 overflow-y-auto h-full w-full overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                  <div class="relative p-4 w-full max-w-md max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-neutral-700 flex items-center justify-center p-2">
                      <button type="button" class="absolute top-3 end-2.5 text-neutral-400 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-neutral-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                          <IconButton color="error" onClick={onCloseFormPaymentIziPay}>
                            <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                            />
                          </IconButton>
                        </div>
                      </button>
                      <div className='relative p-3'>
                        <div className='w-36 h-16'>
                          <LazyLoadImage
                            className="z-10 w-18 h-18"
                            alt={`No image izipay}`}
                            effect="blur"
                            src={`/images/logos/izipaylogo-2.png`}
                          />
                        </div>
                        <div class="flex items-center justify-center w-full p-3 relative m-auto">
                          <div className="d-flex justify-content-center">
                            <div id="myDIV" className="formulario">
                              <div id="form">
                                {/* Formulario de pago incrustado */}
                                <IziPayPaymentForm />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex mb-6 w-full">
            <div class="flex-auto flex space-x-4">
              <Button disabled={(response.isLoading || responseBinance.isLoading || responseMercadoPago.isLoading || s_PackSelected < 1 || s_PayMethod === "" || responseIziPay.isLoading) ? true : false}
                sx={{
                  "&.Mui-disabled": {
                    background: "#525252",
                    color: "#a3a3a3"
                  }
                }}
                onClick={pay}
                type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color="primary" startIcon={<MdPayment size={24} className={`mr-2 ${(response.isLoading || responseBinance.isLoading || responseMercadoPago.isLoading || responseIziPay.isLoading) && 'hidden'}`} />}>{response.isLoading || responseBinance.isLoading || responseMercadoPago.isLoading || responseIziPay.isLoading ? <><Spinner w={'35px'} h={'35px'} /> Procesando... </> : `Ir a pagar`}</Button>
            </div>
          </div>

        </div>

      </div>
    </AccountList>
  );
};

export default Recharge;