// App.js
import { Pagination, PaginationItem, Stack, TextField, Link, Tooltip, IconButton, Button } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast, { Toaster } from "react-hot-toast";
import { FaClipboard, FaShippingFast, FaUser } from 'react-icons/fa';
import { IoAlarm, IoBagCheck, IoBan, IoBuild, IoCheckbox, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoReload, IoReloadCircleSharp, IoRocket, IoSave, IoSearch, IoThumbsDownSharp, IoTime } from 'react-icons/io5';
import ScreenHeader from '../../components/ScreenHeader';
import SpinnerData from '../../components/SpinnerData';
import { useCreateVentaMutation, useGetAllVentasByPageQuery, useCountAllVentasByStateQuery, useLockOrRejectedVentaMutation, useDeleteVentaMutation, useUpdateServiceMutation } from '../../store/services/ventasService';
import { CalcularExpiracion, CalcularTiempoTranscurrido, message, setUnixToTimeFormat } from '../../utils/Funciones';
import { useSendEmailWhitInformationServiceMutation } from "../../store/services/emailService";
import draftToHtml from "draftjs-to-html";
import Swal from "sweetalert2";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import Modal from '@mui/material/Modal';
import Wrapper from './Wrapper';
import moment from 'moment';
import { MdAvTimer, MdClose, MdDelete, MdDevices, MdMail, MdOutgoingMail, MdOutlineTimerOff, MdPhoneIphone, MdTimer, MdTimerOff, MdVerified, MdWarning } from 'react-icons/md';
import Spinner from '../../components/Spinner';
import { useGetAllCuentasQuery } from '../../store/services/cuentaService';
import { useGetAllCategoriesStreamingQuery } from '../../store/services/categoryService';
import { useGetAllUsersQuery } from '../../store/services/userService';
import Spinner2 from '../../components/Spinner2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const StreamingOrders = ({
  socket,
  SECRET_KEY,
  configuration,
  loadingconfiguration
}) => {

  const { data: category, isFetching: loadingcategory } = useGetAllCategoriesStreamingQuery();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const [curTime, SetCurTime] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [infopedido, setInfopedido] = useState('')
  const [limit, setLimit] = useState(30);
  const [verdetalles, SetVerDetalles] = useState({
    open: false,
    categoryname: "",
    categorylogo: "",
    correo: "",
    passwordcuenta: "",
    data: [],
    copied: false
  })

  const [changeExpirationTime, setChangeExpirationTime] = useState({
    open: false,
    _id: "",
    newexpiration: ""
  })

  const [questionrejected, SetQuestionRejected] = useState({
    open: false,
    _id: "",
    actiontype: "",
    message: ""
  })

  const { data: ventas, isFetching, refetch } = useGetAllVentasByPageQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
  const { data: cuentas, isFetching: fechingcuentas } = useGetAllCuentasQuery();
  const { data: users, isFetching: fechingusers } = useGetAllUsersQuery();
  const [sendDeleteVenta, responseDel] = useDeleteVentaMutation();

  const deleteVenta = (id) => {
    alertatresbotones('Eliminar pedido', 'Click en el boton borrar este pedido para eliminar y click borrar todo para borrar todos los pedidos bloqueados y rechazados.', 'question', (res_alert) => {
      if (res_alert === true) {
        sendDeleteVenta({ id, type: 'uno' });
      } else if (res_alert === false) {
        sendDeleteVenta({ id, type: 'todos' });
      }
    })
  }

  useEffect(() => {
    if (responseDel.isSuccess) {
      message(responseDel?.data?.message, 'success', 4)
    }
  }, [responseDel?.data])

  useEffect(() => {
    if (!responseDel.isSuccess) {
      responseDel?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [responseDel?.error?.data?.errors])

  useEffect(async () => {
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [searchTerm, currentPage, limit]);

  /*useEffect(async () => {
    await refetchusers()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetchusers.abort();
    };
  }, [users]);*/

  useEffect(async () => {
    const interval = setInterval(async () => {
      SetCurTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  const HandleLinkWhatsapp = (Identificador) => {
    setTimeout(() => {
      document.getElementById(Identificador).click()
    }, 100)
  }

  const [rejectedorlocked, response] = useLockOrRejectedVentaMutation();

  const HandleLockOrRejectedVenta = (id, actiontype, state, isExpired) => {

    if (actiontype === 'locked') {
      if (state === 'complete' && isExpired !== 'Caducado') {
        alertaConfirmar('Bloquear pedido - No expirado', 'Este pedido aún no ah expirado, deseas forzar el bloqueo ?', 'question', (res_alert) => {
          if (res_alert === true) {
            rejectedorlocked({ _id: id, actiontype: actiontype, message: questionrejected.message, force: true });
          }
        })
      } else {
        alertaConfirmar('Bloquear pedido', 'Al bloquear este pedido el usuario ya no podrá renovarlo ni tener ningún acceso.', 'warning', (res_alert) => {
          if (res_alert === true) {
            rejectedorlocked({ _id: id, actiontype: actiontype, message: questionrejected.message, force: false });
          }
        })
      }
    } else if (actiontype === 'rejected') {
      if (questionrejected.message.length > 0) {
        alertaConfirmar('Rechazar Pedido', 'El usuario recibira una notificación sobre el motivo de rechazo de su pedido.', 'warning', (res_alert) => {
          if (res_alert === true) {
            rejectedorlocked({ _id: id, actiontype: actiontype, message: questionrejected.message });
          }
        })
      } else {
        message('Debes ingresar un motivo por el cual estás rechazando este pedido', 'error', 4)
      }
    }
  }

  useEffect(() => {
    if (response.isSuccess) {

      socket.emit("lockedorrejectedventa", response?.data?.callback)
      message(response?.data?.msg, 'success', 3)
      if (response?.data?.callback?.type === 'locked') {
        toast((t) => (
          <div className="flex inline-flex items-center">
            <div className="mr-3">
              <MdWarning size={24} className="mr-2 text-yellow-600" />
            </div>
            <div className="">
              Recuerda que si bloqueaste debes cambiar la contraseña de la cuenta.
            </div>
            <div className="w-8 h-full ml-[10px] flex items-center justify-center">
              <div className="w-8 h-8">
                <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                  <MdClose size={22} className="text-neutral-200" />
                </button>
              </div>
            </div>

          </div>
        ),
          {
            position: 'top-center',
            style: {
              borderRadius: '10px',
              background: '#0a0a0a',
              color: '#e5e5e5',
            }, duration: Infinity
          })
      }
      SetQuestionRejected({ ...questionrejected, open: false, _id: '', actiontype: '', message: '' })
    }
  }, [response.isSuccess])

  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [response?.error?.data?.errors])

  const HandleOpenDetalles = (id, correoid, CategoryName, CategoryLogo, UserName) => {
    const VentaById = !isFetching && ventas?.ventas.filter((venta) => {
      if (venta._id === id) {
        return venta
      }
    })

    var Correo = ""
    var passwordcuenta = ""
    var username_ = ""

    !fechingcuentas && cuentas?.cuentas.find((cuenta) => {
      if (correoid === cuenta._id) {
        Correo = cuenta.correo
        passwordcuenta = cuenta.passwordcuenta
      }
    })

    !fechingusers && users?.users.find((user_) => {
      if (user_._id === UserName) {
        username_ = user_.name
      }
    })

    SetVerDetalles({
      ...verdetalles,
      ['open']: !verdetalles.open,
      ['data']: VentaById,
      ['categoryname']: CategoryName,
      ['categorylogo']: CategoryLogo,
      ['correo']: Correo,
      ['username']: username_,
      ['passwordcuenta']: passwordcuenta
    })

    if (VentaById) {
      var InformacionPedido =
        `*INFORMACIÓN DE LA CUENTA* 
*Servicio :* ${CategoryName}
*Correo :* ${Correo}
*Contraseña :* ${passwordcuenta}
*Tipo de servicio :* ${VentaById[0]?.type}
*Expiración :* ${moment(VentaById[0]?.expirationdate).format("dddd, DD [de] MMMM [de] YYYY")}
${VentaById[0]?.isprofileblocked ?
          `*INFORMACIÓN DE PERFILES* 
*Nombre :* ${VentaById[0]?.dataperfiles[0].name}
*PIN :* ${VentaById[0]?.dataperfiles[0].password}` : ''}`

      setInfopedido(InformacionPedido)
    }

  }

  const [Completarpedido, responsePedido] = useCreateVentaMutation();

  const HandleCompletarPedido = (idVenta, categoryId, subcategoryId, userId, maxscreems, type, totalmonths, totalamount, discountperfil, CategoryName, isprofileblocked) => {
    if (!isFetching) {
      const PedidoData = {
        idVenta: idVenta,
        categoryId: categoryId,
        subcategoryId: subcategoryId,
        userId: userId,
        selectedscreems: type,
        maxscreems: maxscreems,
        selectedmonths: totalmonths,
        totalamount: totalamount,
        discountperfiluser: discountperfil,
        name: CategoryName,
        tipoentrega: true,
        isprofileblocked: isprofileblocked,
        actionTypeAdmin: true
      }
      Completarpedido(PedidoData);
    }
  }

  useEffect(() => {
    if (!responsePedido.isSuccess) {
      responsePedido?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [responsePedido?.error?.data?.errors])

  useEffect(() => {
    if (responsePedido?.isSuccess) {

      socket.emit('completarpedido', responsePedido?.data?.callback)
      message(responsePedido?.data?.message, 'success', 5)
    }
  }, [responsePedido?.isSuccess])

  const [sendEmailWhitInformationService, responseSendEmailWhitInformationService] = useSendEmailWhitInformationServiceMutation();

  const handleSendEmailWhitInformationService = (id, policy) => {
    const rawContent = policy;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState)
    alertaConfirmar('Reenviar pedido', 'Estas seguro que quieres reenviar el pedido ?', 'question', (res_alert) => {
      if (res_alert === true) {
        sendEmailWhitInformationService({ id, htmlData })
      }
    })
  }

  useEffect(() => {
    if (!responseSendEmailWhitInformationService.isSuccess) {
      responseSendEmailWhitInformationService?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 5)
      })
    }
  }, [responseSendEmailWhitInformationService?.error?.data?.errors])


  useEffect(() => {
    if (responseSendEmailWhitInformationService?.isSuccess) {
      message(responseSendEmailWhitInformationService?.data?.message, 'success', 5)
    }
  }, [responseSendEmailWhitInformationService?.isSuccess])

  const onCopy = (text) => {
    SetVerDetalles({ ...verdetalles, copied: true });
    message(text, 'success', 2)
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const alertatresbotones = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar este pedido',
      denyButtonText: `Borrar todo`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        callback(true)
      } else if (result.isDenied) {
        callback(false)
      }
    })
  }

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',

      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const HandleChangeFilterPedidos = async (state) => {
    setSearchTerm(state)
    SetPedidosByState(state)
    setCurrentPage(1);
  }

  const openHandleChangeExpireTime = (currentexpiration, id) => {
    setChangeExpirationTime({ ...changeExpirationTime, _id: id, newexpiration: moment(currentexpiration).format("yyyy-MM-DDTHH:mm"), open: true })
  }

  const [onUpdateService, responseupdateservice] = useUpdateServiceMutation()
  const onSendChangeExpirationDate = () => {
    onUpdateService({ id: changeExpirationTime._id, newexpiration: changeExpirationTime.newexpiration })
  }

  useEffect(() => {
    if (responseupdateservice.isSuccess) {
      setChangeExpirationTime({ ...changeExpirationTime, _id: "", newexpiration: "", open: false })
      message(responseupdateservice.data?.message, 'success', 4)
    }
  }, [responseupdateservice?.data?.message])

  useEffect(() => {
    if (!responseupdateservice.isSuccess) {
      responseupdateservice?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [responseupdateservice?.error?.data?.errors])

  return (

    <Wrapper socket={socket} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <ScreenHeader>
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center  w-full">
            <IoBagCheck size={32} className="mr-2" /> PEDIDOS STREAMING
            <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
              {!isFetching && ventas?.totalventas}
            </div>
          </div>
        </div>
      </ScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe correo de usuario, nombre o categoria..."
            required
            onChange={debouncedChangeHandler}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[128px] pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
        <div className="w-full flex items-center justify-center md:justify-end text-neutral-200 mt-0 h-10">
          {
            !isFetching ?
              <>
                <div className="hidden md:block">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!isFetching && Number(ventas?.totalventasFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                    />
                  </Stack>
                </div>
                <div className="block md:hidden">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!isFetching && Number(ventas?.totalventasFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                    />
                  </Stack>
                </div>
              </>
              : <Spinner />
          }

        </div>
      </div>

      <div className='-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[195px] pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pl-0 md:pl-4'>
        {
          <Tooltip title="Click aquí para filtrar servicios expirados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("expired")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "expired" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-500 p-3 rounded-lg`}>
              <div className="flex inline-flex items-center"><MdOutlineTimerOff className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!isFetching ? ventas?.totalventasExpired : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar pedidos próximos a expirar" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("proxim")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "proxim" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-orange-400 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><MdTimer className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!isFetching ? ventas?.totalventasProxExpired : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar pedidos pendientes" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("pending")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "pending" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-yellow-500 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><FaShippingFast className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!isFetching ? ventas?.totalventasPending : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar pedidos bloqueados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("locked")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "locked" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-700 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoLockClosed className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!isFetching ? ventas?.totalventasLocked : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar pedidos rechazados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("rejected")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "rejected" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-700 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBan className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!isFetching ? ventas?.totalventasRejected : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

        {
          <Tooltip title="Click aquí para filtrar todos los pedidos" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-green-500 p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBagCheck className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                    {!isFetching ? ventas?.totalventas : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>
        }

      </div>

      <div className='w-full'>
        <Modal
          keepMounted
          open={changeExpirationTime.open}
          onClose={() => { setChangeExpirationTime({ ...changeExpirationTime, open: false }) }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          sx={{ zIndex: 50 }}
        >
          <div id="authentication-modal" class="w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">
            <div className={`m-auto relative p-5 w-full md:w-4/12 bg-white dark:bg-black pb-5 overflow-y-auto`}>
              <div className="flex items-center">
                <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                  <IconButton color="error" onClick={() => { setChangeExpirationTime({ ...changeExpirationTime, open: false }) }}>
                    <IoCloseSharp
                      className="text-neutral-200"
                      size={28}
                    />
                  </IconButton>
                </div>
                <div className="text-2xl ml-3 text-neutral-900 dark:text-neutral-200 uppercase font-semibold">Editar expiración del servicio</div>
              </div>
              <div className="w-full md:w-12/12 p-3">
                <div className="w-full md:w-12/12 p-3">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nueva expiración del servicio</label>
                  <input autoFocus name="newexpiration" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                    autoComplete="off"
                    value={changeExpirationTime.newexpiration}
                    onChange={(e) => { setChangeExpirationTime({ ...changeExpirationTime, newexpiration: e.target.value }) }}
                    type="datetime-local"
                  />
                </div>
                <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                  <Button
                    onClick={onSendChangeExpirationDate}
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                    disabled={response.isLoading ? true : false}
                  >
                    {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                    {onUpdateService.isLoading ? 'Actualizando...' : 'Actualizar Servicio'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          keepMounted
          open={verdetalles.open}
          onClose={() => HandleOpenDetalles("")}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          sx={{ zIndex: 50 }}
        >
          <div id="authentication-modal" class="w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">
            <div className={`m-auto relative p-5 w-full md:w-6/12 bg-neutral-200 dark:bg-black pb-24 overflow-y-auto`}>
              <div className="flex items-center">
                <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                  <IconButton color="error" onClick={() => HandleOpenDetalles("")}>
                    <IoCloseSharp
                      className="text-neutral-200"
                      size={28}
                    />
                  </IconButton>
                </div>
                <div className="text-2xl ml-3 text-neutral-900 dark:text-neutral-200 uppercase font-semibold">Detalles del pedido</div>
              </div>

              <div className="w-full mt-16 text-neutral-900 dark:text-neutral-200">
                <div className="grid grid-wrap w-full md:w-5/12">
                  <div className="flex inline-flex items-center">
                    <span className="absolute -mt-28 -ml-0 text-neutral-900 dark:text-neutral-200 
                          text-base rounded-lg bg-neutral-100 dark:bg-neutral-800 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center">{verdetalles?.data[0]?.ventaId}</span>
                    <LazyLoadImage
                      className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                      alt={`No image ${verdetalles.categoryname}`}
                      effect="blur"
                      src={`/images/logos/${verdetalles.categorylogo}`}
                    />
                    <div className="grid self-center">
                      <div className="ml-2 text-base font-semibold flex inline-flex">{verdetalles.username}</div>
                      <div className="ml-2 text-sm font-semibold">{verdetalles.categoryname}</div>
                      <div className="ml-2 text-sm"><span className="text-yellow-600 dark:text-yellow-400 truncate ...">
                        {verdetalles?.data[0]?.state === "locked" ?
                          <span className="text-red-600"> Bloqueado</span>
                          :
                          verdetalles.correo
                        }
                      </span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex inline-flex flex-wrap mt-8 bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black rounded-lg mb-5 pb-5">
                <label className="text-neutral-900 dark:text-neutral-200 font-semibold absolute -mt-4 text-lg ml-5">Información general</label>
                <div className="text-neutral-900 dark:text-neutral-200 grid grid-wrap w-full md:w-6/12 mt-4 pl-5">

                  <div className="flex inline-flex items-center text-sm p-2"><IoTime size={28} className="mr-2" />
                    {setUnixToTimeFormat("info_contacto", verdetalles?.data[0]?.created_at)}</div>
                  {moment(verdetalles?.data[0]?.created_at).format("yyyy-MM-DDTHH:mm") !== moment(verdetalles?.data[0]?.last_update).format("yyyy-MM-DDTHH:mm") && <div className="flex inline-flex items-center text-sm p-2">
                    <span className="text-green-400 flex items-center"><IoReload size={28} className="mr-2" />{setUnixToTimeFormat("renew", verdetalles?.data[0]?.last_update)}</span></div>}
                  <div className="flex inline-flex items-center text-sm p-2"><IoAlarm size={28} className={`${CalcularExpiracion(verdetalles?.data[0]?.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-500'} mr-2`} />
                    <span className={`${CalcularExpiracion(verdetalles?.data[0]?.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-500'}`}>{moment(verdetalles?.data[0]?.expirationdate).format("dddd, DD [de] MMMM [de] yyyy")}</span></div>

                  <div className="flex inline-flex items-center text-sm p-2"><IoTime size={28} className="mr-2" />
                    <span>{verdetalles?.data[0]?.totalmonths < 2 ? verdetalles?.data[0]?.totalmonths + ' mes' : verdetalles?.data[0]?.totalmonths + ' meses'}</span></div>
                  <div className="flex inline-flex items-center p-2">
                    {verdetalles?.data[0]?.state === "complete" && <div className="flex inline-flex items-center text-green-500 text-sm">
                      <IoCheckbox size={28} className="mr-2" /> Completado</div>}
                    {verdetalles?.data[0]?.state === "pending" && <div className="flex inline-flex items-center text-yellow-400 text-sm">
                      <MdAvTimer size={28} className="mr-2" />Pendiente</div>}
                    {verdetalles?.data[0]?.state === "rejected" && <div className="flex inline-flex items-center text-red-400 text-sm">
                      <IoThumbsDownSharp size={28} className="mr-2" /> Rechazado</div>}
                    {verdetalles?.data[0]?.state === "locked" && <div className="flex inline-flex items-center text-red-400 text-sm">
                      <IoLockClosed size={28} className="mr-2" /> Bloqueado</div>}
                  </div>
                  <div className="flex inline-flex items-center text-sm p-2"><IoDesktopSharp size={28} className="mr-2" />{verdetalles?.data[0]?.type}</div>
                </div>
              </div>

              <div className="mt-5 bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black p-5 rounded-lg">
                <label className="absolute -mt-9 text-lg text-neutral-900 dark:text-neutral-200 font-semibold">Información de cuenta</label>
                <div className="flex items-center w-full bg-neutral-200 dark:bg-neutral-900 p-3 rounded-lg">
                  <div className="w-[35px] flex items-center"><MdMail size={28} className="text-yellow-500" /></div>
                  <div className="w-[calc(100%-55px)] text-sm flex items-center">
                    <span className="text-neutral-900 dark:text-neutral-100 flex items-center mr-1 font-semibold">Correo :</span>
                    <div className="text-neutral-800 dark:text-neutral-300 w-[70%] truncate ...">
                      {verdetalles?.data[0]?.state === "locked" ?
                        <span className="text-red-400"> Bloqueado</span>
                        :
                        verdetalles.correo}
                    </div>
                  </div>
                  <div className="w-[20px] flex items-center">
                    {
                      verdetalles?.data[0]?.state === "complete" && <CopyToClipboard text={verdetalles.correo} onCopy={() => onCopy('Correo copiado')}>
                        <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                      </CopyToClipboard>
                    }
                  </div>
                </div>
                <div className="relative flex items-center w-full bg-neutral-200 dark:bg-neutral-900 p-3 mt-3 rounded-lg">
                  <div className="w-[35px] flex items-center"><IoFingerPrint size={28} className="mr-1 text-red-500" /></div>
                  <div className="w-[calc(100%-55px)] text-sm flex items-center">
                    <span className="text-neutral-900 dark:text-neutral-100 flex items-center mr-1 font-semibold">Contraseña : </span>
                    <div className="text-neutral-800 dark:text-neutral-300 truncate ...">
                      {verdetalles?.data[0]?.state === "locked" ?
                        <span className="text-red-400"> Bloqueado</span>
                        :
                        verdetalles.passwordcuenta}
                    </div>
                  </div>
                  <div className="w-[20px] flex items-center">
                    {
                      verdetalles?.data[0]?.state === "complete" && <CopyToClipboard text={verdetalles.passwordcuenta} onCopy={() => onCopy('Contraseña copiada')}>
                        <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                      </CopyToClipboard>
                    }
                  </div>
                </div>
              </div>

              {verdetalles?.data[0]?.isprofileblocked &&
                <div className="w-full flex inline-flex flex-wrap mt-5">
                  <div className="w-full flex flex-wrap mt-5 bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black rounded-lg p-5">
                    <label className="absolute -mt-9 text-lg text-neutral-900 dark:text-neutral-200 font-semibold">Información de perfiles</label>
                    {
                      verdetalles?.data[0]?.state !== "locked" ? (
                        verdetalles?.data[0]?.dataperfiles?.map((dp, index) => {
                          return (
                            <div key={index} className="w-full text-neutral-900 dark:text-neutral-200">
                              <div className="w-full mt-3">
                                <div className="grid grid-wrap w-full md:w-4/12">
                                  <div className="flex inline-flex items-center">
                                    <div className="m-2 w-14 h-14 rounded-full bg-neutral-700 dark:bg-neutral-400 flex inline-flex items-center"><IoPerson className="w-10 h-10 z-30 flex inline-flex m-auto text-neutral-300" /></div>
                                    <div className="grid self-center">
                                      <div className="ml-2 text-base font-semibold">{dp.name}</div>
                                      <div className="ml-2 text-sm flex inline-flex items-center bg-neutral-200 dark:bg-neutral-700 p-3"><IoFingerPrint size={24} /><span className="text-yellow-700 dark:text-yellow-500 font-semibold text-xl">{dp.password === "" ? 'Sin contraseña' : dp.password}</span></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : <span className="text-red-600 ml-5">Bloqueado</span>
                    }
                  </div>
                </div>
              }

              <div className='flex items-center justify-center mt-10 mb-5'>
                <CopyToClipboard text={infopedido} onCopy={() => onCopy('Información copiada')}>
                  <FaClipboard size={45} className="text-neutral-900 dark:text-neutral-200 cursor-pointer" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          keepMounted
          open={questionrejected.open}
          onClose={() => SetQuestionRejected({ ...questionrejected, open: false, _id: '', actiontype: '', message: '' })}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          sx={{ zIndex: 50 }}
        >
          <div id="authentication-modal" class="w-full h-full bg-neutral-50 bg-opacity-30">

            <div className="realtive m-auto p-5 w-full md:w-5/12 bg-white dark:bg-black">
              <div className="flex items-center">
                <div className="flex inline-flex items-center">
                  <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                    <IconButton color="error" onClick={() => SetQuestionRejected({ ...questionrejected, open: false, _id: '', actiontype: '', message: '' })}>
                      <IoCloseSharp
                        className="text-neutral-200"
                        size={28}
                      />
                    </IconButton>
                  </div>
                </div>
                <div className="w-full text-neutral-900 dark:text-neutral-200 text-2xl ml-5 flex inline-flex items-center">RECHAZAR PEDIDO</div>
              </div>

              <div className="w-full mb-5 mt-7">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Motivo por el cual rechazas este pedido (max: 20 chars)</label>
                <input autoFocus name="message" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Motivo por el cual rechazas este pedido (max: 20 chars)"
                  autoComplete="off"
                  required
                  value={questionrejected.message}
                  onChange={e => SetQuestionRejected({ ...questionrejected, message: e.target.value })}
                  type="text"
                />
              </div>
              <div className="w-full mt-2">
                <Button
                  onClick={() => HandleLockOrRejectedVenta(questionrejected._id, 'rejected')}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained" color="error"
                  disabled={response.isLoading ? true : false}
                >
                  {response.isLoading ? <Spinner /> : <IoBan size={24} className="mr-2" />}
                  {response.isLoading ? 'Actualizando...' : 'RECHAZAR PEDIDO'}
                </Button>
              </div>

            </div>
          </div>

        </Modal>
      </div>

      <div className="mt-[345px]">
        {(!isFetching && !fechingusers) ? ventas?.ventas.length > 0 ? ventas?.ventas.map((venta) => {
          var CategoryName = ""
          var CategoryLogo = ""
          var Correo = ""
          var UserName = ""
          var ContactNumber = ""
          var msgtype = ""
          var msgtype1 = ""
          var maxscreems = 0
          var passwordcuenta = ""
          var email = ""
          var policy = ""

          !fechingusers && users?.users.find((user) => {
            if (user._id === venta.userId) {
              email = user.email
              UserName = user.name
            }
          })

          !loadingcategory && category?.categories.find((cat) => {
            if (venta.categoryId === cat._id) {
              cat.subcategory.find((sub) => {
                if (venta.subcategoryId === sub._id) {
                  CategoryName = sub.name
                  CategoryLogo = sub.logo
                  maxscreems = sub.maximoperfiles
                  policy = sub.policy
                }
              })
            }
          })

          return (
            <div className={`animation ${(venta.state === 'complete' || venta.state === 'pending') && 'transition-all duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer'} 
            bg-white relative dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 transition-all duration-500 flex flex-wrap mb-14 rounded-lg pl-4 pr-4 pt-2 pb-2 w-full md:w-12/12 hover:cursor-pointer`}
              key={venta._id}>
              <span className="absolute w-full md:w-auto top-0 -mt-9 -ml-4 pt-2 pb-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-neutral-900 flex inline-flex 
                          items-center justify-center">
                <div className="flex items-center w-full">
                  <FaUser size={16} className='w-[35px]' />
                  <div className='flex items-center w-[calc(100%-35px)]'>
                    <div className='flex items-center w-[calc(100%-160px)]'>
                      <div className='w-auto text-xs truncate ...'>{email}</div>
                      <div className='w-[35px]'>
                        <CopyToClipboard text={email} onCopy={() => onCopy('correo copiado')}>
                          <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer w-[35px]" />
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className='text-xs w-[calc(160px)] text-right ml-2 pr-2'>{venta.ventaId}</div>
                  </div>
                </div>
              </span>
              <div className="flex flex-wrap items-center w-full md:w-4/12">
                <div className="flex inline-flex items-center">

                  <LazyLoadImage
                    className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex"
                    alt={`No image ${CategoryName}`}
                    effect="blur"
                    src={`/images/logos/${CategoryLogo}`}
                  />
                </div>
                <div className="w-[calc(100%-48px)]">
                  <div className="ml-2 text-sm text-neutral-900 dark:text-neutral-200 font-bold">{CategoryName}</div>
                  <div className="ml-2 text-xs mt-2 truncate ...">{setUnixToTimeFormat('info_contacto', venta.created_at)}</div>
                </div>

              </div>

              <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-6/12 text-sm mt-5 md:mt-0">

                <div className="md:w-4/12 w-full grid">
                  <div className="flex items-center justify-center font-bold">Expiración</div>
                  <div className="flex items-center justify-center">
                    <div className="flex inline-flex items-center text-sm text-green-500">
                      {venta.expirationdate === null ?
                        <span className="text-yellow-400">Expiración aún no extablecida</span> :
                        <span>{(CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") ?
                          <span className="text-red-400">
                            {CalcularExpiracion(venta.expirationdate)}
                          </span> : <div>{(CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                            Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3) ? <span className="text-orange-400">{CalcularExpiracion(venta.expirationdate)}</span> : (venta.state === 'rejected' || venta.state === 'locked') ? <span className="text-red-400">{CalcularExpiracion(venta.expirationdate)}</span> : <span>{CalcularExpiracion(venta.expirationdate)}</span>}</div>}</span>}
                    </div>
                  </div>
                </div>

                <div className="md:w-4/12 w-full grid">
                  <div className="flex items-center justify-center font-bold">Tipo servicio</div>
                  <div className="flex items-center justify-center">
                    <div className="flex inline-flex items-center text-sm">
                      {venta.type === '1 pantalla' ?
                        <span className='text-blue-500'>{venta.type}</span> : <span className='text-red-500'>{venta.type}</span>
                      }
                    </div>
                  </div>
                </div>

                <div className="md:w-4/12 w-full grid">
                  <div className="flex items-center justify-center font-bold">Estado</div>
                  <div className="flex items-center justify-center">
                    {venta.state === "complete" &&
                      <div class="m-auto p-2 bg-green-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                        <span class="text-center rounded-full bg-green-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Completado</span>
                      </div>}
                    {venta.state === "pending" &&
                      <div class="m-auto p-2 bg-yellow-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                        <span class="text-center rounded-full bg-yellow-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Pendiente</span>
                      </div>}
                    {venta.state === "rejected" &&
                      <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                        <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Rechazado</span>
                      </div>}
                    {venta.state === "locked" &&
                      <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                        <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Bloqueado</span>
                      </div>}
                  </div>

                  {venta.observations !== "" &&
                    <span className='text-xs text-center'>
                      {venta.observations}
                    </span>
                  }

                </div>

              </div>

              <div className="flex inline-flex items-center justify-end w-full md:w-2/12 mt-3 md:mt-0">

                <div className="flex inline-flex items-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">

                  <div className="w-12 h-12 rounded-full mr-2">
                    <Tooltip title="enviar información por correo" arrow={true}>
                      <IconButton onClick={() => handleSendEmailWhitInformationService(venta._id, policy)} className="w-12 h-12" color="primary" disabled={(venta.state === 'complete' && !responseSendEmailWhitInformationService.isLoading) ? false : true}>
                        <MdOutgoingMail size={24} className={`${(venta.state === 'complete' && !responseSendEmailWhitInformationService.isLoading) ? 'text-red-400' : 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium'}`} />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div className="w-12 h-12 rounded-full mr-2">
                    <Tooltip title="ver detalles" arrow={true}>
                      <IconButton onClick={() => HandleOpenDetalles(venta._id, venta.correoId, CategoryName, CategoryLogo, venta.userId)} className="w-12 h-12" color="primary" disabled={(venta.state === "rejected" || venta.state === "pending") ? true : false}>
                        <IoEye size={24} className={`${(venta.state === "rejected" || venta.state === "pending") ? 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium' : 'text-neutral-900 dark:text-neutral-200'}`} />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div className="w-12 h-12 rounded-full mr-2">
                    {
                      (venta.state === "pending" && venta.state !== "rejected") ?
                        <Tooltip title="completar" arrow={true}>
                          <IconButton onClick={() => HandleCompletarPedido(
                            venta._id,
                            venta.categoryId,
                            venta.subcategoryId,
                            venta.userId,
                            maxscreems,
                            venta.type,
                            venta.totalmonths,
                            venta.totalamount,
                            venta.discountperfil,
                            CategoryName,
                            venta.isprofileblocked
                          )} className="w-12 h-12" color="primary" disabled={(venta.state === "rejected" || responsePedido.isLoading) ? true : false}>
                            <IoRocket size={24} className={`text-green-600 ${(venta.state === "rejected") && 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium'}`} />
                          </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="editar" arrow={true}>
                          <IconButton onClick={() => openHandleChangeExpireTime(venta.expirationdate, venta._id)} className="w-12 h-12" color="primary" disabled={(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked") ? true : false}>
                            <IoReloadCircleSharp size={24} className={`${(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked") ? 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium' : 'text-yellow-600'}`} />
                          </IconButton>
                        </Tooltip>
                    }

                  </div>

                  <div className="w-12 h-12 rounded-full mr-2">
                    {
                      venta.state === 'pending' ?
                        <Tooltip title="rechazar" arrow={true}>
                          <IconButton onClick={() => SetQuestionRejected({ ...questionrejected, open: true, _id: venta._id, actiontype: 'rejected' })} className="w-12 h-12" color="primary" disabled={(CalcularExpiracion(venta.expirationdate) !== "Caducado" || (venta.state === "complete" && CalcularExpiracion(venta.expirationdate) !== "Caducado")) ? true : false}>
                            <IoBan size={24} className={`${(CalcularExpiracion(venta.expirationdate) !== "Caducado" || (venta.state === "complete" && CalcularExpiracion(venta.expirationdate) !== "Caducado")) ? 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium' : 'text-red-600'}`} />
                          </IconButton>
                        </Tooltip>
                        :
                        (venta.state === 'locked') ?
                          <Tooltip title="desbloquear" arrow={true}>
                            <IconButton className="w-12 h-12" color="primary" disabled={(CalcularExpiracion(venta.expirationdate) !== "Caducado" || (venta.state === "complete" && CalcularExpiracion(venta.expirationdate) !== "Caducado")) ? true : false}>
                              <IoLockOpen size={24} className={`${(CalcularExpiracion(venta.expirationdate) !== "Caducado" || (venta.state === "complete" && CalcularExpiracion(venta.expirationdate) !== "Caducado")) ? 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium' : 'text-green-600'}`} />
                            </IconButton>
                          </Tooltip>
                          :
                          <Tooltip title="bloquear" arrow={true}>
                            <IconButton onClick={() => HandleLockOrRejectedVenta(venta._id, 'locked', venta.state, CalcularExpiracion(venta.expirationdate))} className="w-12 h-12" color="primary" disabled={(venta.state === 'rejected' || venta.state === 'pending') ? true : false}>
                              <IoLockClosed size={24} className={`${(venta.state === 'rejected' || venta.state === 'pending') ? 'text-neutral-400 dark:text-neutral-700 cursor-not-allowed font-medium' : 'text-red-600'}`} />
                            </IconButton>
                          </Tooltip>
                    }
                  </div>
                  <div className="w-12 h-12 rounded-full">
                    <Tooltip title="eliminar" arrow={true}>
                      <IconButton onClick={() => deleteVenta(venta._id)} className="w-12 h-12" color="primary" disabled={(venta.state !== 'locked' && venta.state !== 'rejected') ? true : false}>
                        <MdDelete size={25}
                          className={`${(venta.state !== 'locked' && venta.state !== 'rejected') ? 'text-neutral-400 dark:text-neutral-800' : 'text-red-600'} m-auto`}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          )
        }) : (<div className="alert-danger font-semibold mt-[345px]">Sin resultados en la búsqueda! o no existen ventas registrados</div>) : (<div className="mt-[345px]"><SpinnerData>Cargando ventas, por favor espere...</SpinnerData></div>)}
      </div>
    </Wrapper>
  );
};

export default StreamingOrders;
