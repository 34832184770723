import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"
import TextField from '@mui/material/TextField';
import toast, { Toaster } from "react-hot-toast";
import { useCreatePaymentIziPayDonationMutation, useCreatePaymentIziPayMutation, useGenerateQrCodeBinancePayDonationMutation, useGenerateQrCodeBinancePayMutation, useGetPaymentsByUserIdQuery, useSendPaymentMercadoPagoDonationMutation, useSendPaymentMercadoPagoMutation, useSendPaymentMutation, useSendYapePaymentMutation, useValidatePaymentIziPayDonationMutation, useValidatePaymentIziPayMutation } from "../../store/services/paymentService";
import { Button, IconButton, Tooltip } from "@mui/material";
import UserScreenHeader from '../../components/home/UserScreenHeader';
import { FaCoins, FaUpload } from 'react-icons/fa';
import { currencyFormat, getUrlParameters, message, PermiteSoloNumeros } from '../../utils/Funciones.js';
import { IoCard, IoChevronForward, IoCloseSharp, IoInformation, IoLogoWhatsapp, IoSend } from 'react-icons/io5';
import { MdPayment } from 'react-icons/md';
import { SiBinance, SiMastercard } from "react-icons/si";
import { setUserToken } from '../../store/reducers/authReducer';
import AccountList from '../../components/home/AccountList';
import Spinner from '../../components/Spinner';
import ImagesPreviewPayment from "../../components/home/ImagesPreviewPayment";
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSendMessageWhatsappNewServiceMutation } from '../../store/services/whatsappService';
import IziPayPaymentForm from '../../components/home/IziPayPaymentForm';
import KRGlue from '@lyracom/embedded-form-glue';

const Donacion = () => {
  const navigate = useNavigate();
  const [s_PackSelected, SetPackSelected] = useState(0)
  const [s_tarifa, SetTarifa] = useState(0)
  const [s_PayMethod, SetPayMethod] = useState("")
  const [selectImageYape, SetSelectImageYape] = useState({ open: false, image: '' })
  const dispatch = useDispatch();
  const [usdtToPen, setUsdtToPen] = useState(3.60);
  const [isShow, setIsShow] = useState(false);
  const [formId, setFormId] = useState("");
  const publicKey = '84423304:publickey_WBPl2IvbLSPk6DPbtgL33rDhhYOELyx6xEYezz6TCfLVW';
  const endPoint = 'https://api.micuentaweb.pe';

  const addTarifaToRecharge = (fee) => {
    SetTarifa(fee)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setUsdtToPen(data.rates.PEN);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const interval = setInterval(fetchExchangeRate, 5000); // Fetch every 5 seconds
    fetchExchangeRate();
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const addPackToRecharge = (cantidad) => {
    if (s_PayMethod === 'mercadopago') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(((cantidad + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(cantidad)).toFixed(2))
    } else if (s_PayMethod === 'stripe') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(((cantidad + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(cantidad)).toFixed(2))
    } else if (s_PayMethod === 'yape') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(0)
    } else if (s_PayMethod === 'binance') {
      SetPackSelected(cantidad)
      addTarifaToRecharge(0)
    } else {
      SetPackSelected(cantidad)
      addTarifaToRecharge(0)
    }
  };

  const addPayMethodPayMethodToRecharge = (method) => {
    if (method === 'mercadopago') {
      SetPayMethod(method)
      addTarifaToRecharge(((Number(s_PackSelected) + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(Number(s_PackSelected))).toFixed(2))
    } else if (method === 'stripe') {
      SetPayMethod(method)
      addTarifaToRecharge(((Number(s_PackSelected) + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(Number(s_PackSelected))).toFixed(2))
    } else if (method === 'yape') {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    } else if (method === 'izipay') {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    } else if (method === 'binance') {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    }
  };

  const [preview, setPreview] = useState({
    image: '',
  })

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const CloseSelectImageYape = () => {
    setPreview({ ...preview, image: '' })
    SetSelectImageYape({ ...selectImageYape, open: false, image: '' })
  }

  const [doPayment, response] = useSendPaymentMutation();
  const [doPaymentMercadoPago, responseMercadoPago] = useSendPaymentMercadoPagoDonationMutation();
  const [doPaymentBinance, responseBinance] = useGenerateQrCodeBinancePayDonationMutation();
  const [doPaymentIziPay, responseIziPay] = useCreatePaymentIziPayDonationMutation();
  const [validatePaymentIziPay, responsevalidatePaymentIziPay] = useValidatePaymentIziPayDonationMutation();

  const pay = async () => {

    if (s_PackSelected <= 0) {
      message(`Debes seleccionar por lo menos un paquete!`, 'error', 4);
    } else if (s_PayMethod === "binance") {
      const response = await axios.get('https://api.ipify.org?format=json');
      const BinanceData = {
        amount: s_PackSelected,
        currency: 'USDT',
        usdtToPen: usdtToPen ? usdtToPen : 3.60,
        userId: "",
        ip_location: response.data.ip ? response.data.ip : '0.0.0.0'
      }
      doPaymentBinance(BinanceData)

    } else if (s_PayMethod === "izipay") {
      const response = await axios.get('https://api.ipify.org?format=json');
      const monto = Number(s_PackSelected)
      var montowf = ((monto * 0.0344) + ((monto * 0.0344) * 0.18) + 0.69 + (0.69 * 0.18)) + monto
      var totalrealfee = ((montowf * 0.0344) + ((montowf * 0.0344) * 0.18) + 0.69 + (0.69 * 0.18))
      const IziPayData = {
        amount: monto,
        currency: 'PEN',
        fee: totalrealfee.toFixed(2),
        userId: "",
        email: "",
        ip_location: response.data.ip ? response.data.ip : '0.0.0.0'
      }

      alertaConfirmar(`Acuerdo importante!`, `El monto actual no incluye tarifas de IziPay <br>Tarifa: <b>+${currencyFormat(totalrealfee)}</b><br> quieres continuar?`, 'question', (res_alert) => {
        if (res_alert === true) {
          doPaymentIziPay(IziPayData)
        }
      })
    } else if (s_PayMethod === "yape") {
      SetSelectImageYape({ ...selectImageYape, open: true })
    } else if (s_PayMethod === "mercadopago") {
      alertaConfirmar(`Acuerdo importante!`, `El monto actual no incluye tarifas de mercadopago <br>Tarifa: <b>+${currencyFormat(Number(s_tarifa))}</b><br> quieres continuar?`, 'question', async (res_alert) => {
        if (res_alert === true) {
          const response = await axios.get('https://api.ipify.org?format=json');
          const rechargeMercadoPago = {
            name: 'DONATION' + s_PackSelected,
            totalPay: Number(s_PackSelected),
            userId: response.data.ip ? response.data.ip : '0.0.0.0'
          }
          doPaymentMercadoPago({ recharge: rechargeMercadoPago });
        }
      })
    }
  };

  const HandleChangeMontoManual = (e) => {
    if (s_PayMethod === 'mercadopago') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(((Number(e.target.value) + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(Number(e.target.value))).toFixed(2))
    } else if (s_PayMethod === 'stripe') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(((Number(e.target.value) + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(Number(e.target.value))).toFixed(2))
    } else if (s_PayMethod === 'yape') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    } else if (s_PayMethod === 'binance') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    } else {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    }
  }

  useEffect(() => {
    if (responseBinance?.isSuccess) {
      window.location.href = responseBinance?.data?.response?.data?.checkoutUrl;
    }
  }, [responseBinance]);

  useEffect(() => {
    if (responseIziPay?.isSuccess) {
      setIsShow(true);
      KRGlue.loadLibrary(endPoint, publicKey)
        .then(({ KR }) => KR.setFormConfig({
          formToken: responseIziPay?.data?.formToken
          // formToken:data.formToken,
        }))
        .then(({ KR }) => KR.onSubmit(validatePayment))
        .then(({ KR }) => KR.attachForm("#form"))
        .then(({ KR, result }) => KR.showForm(result.formId))
        // })
        .catch(err => console.log(err))
    }
  }, [responseIziPay]);

  const validatePayment = (resp) => {
    validatePaymentIziPay({ clientAnswer: resp.clientAnswer, hash: resp.hash, hashKey: resp.hashKey })
  }

  useEffect(() => {
    if (!responseIziPay.isSuccess) {
      responseIziPay?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseIziPay?.error?.data?.errors])

  useEffect(async () => {
    if (responsevalidatePaymentIziPay?.isSuccess) {
      message(responsevalidatePaymentIziPay?.data.message, 'success', 4)
      await KRGlue.loadLibrary(endPoint, publicKey)
        .then(({ KR }) => {
          KR.removeForms();
        })
        .catch(error => {
          console.error("Error al cargar la librería", error);
        });
      navigate('/gracias')
    }
  }, [responsevalidatePaymentIziPay]);

  useEffect(() => {
    if (!responsevalidatePaymentIziPay.isSuccess) {
      responsevalidatePaymentIziPay?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responsevalidatePaymentIziPay?.error?.data?.errors])


  useEffect(() => {
    if (response?.isSuccess) {
      window.location.href = response?.data?.url;
    }
  }, [response]);

  const onSubmitSendYapePayment = async e => {
    e.preventDefault();
    SetSelectImageYape({ ...selectImageYape, open: false })
    SetPayMethod("")
    SetPackSelected(0)
    navigate('/gracias')
  }

  useEffect(() => {
    if (!responseMercadoPago.isSuccess) {
      responseMercadoPago?.error?.data?.errors.map(err => {
        message(err.msg, 'error', 4)
      })
    }
  }, [responseMercadoPago?.error?.data?.errors])

  useEffect(() => {
    if (responseMercadoPago?.isSuccess) {
      window.location.href = responseMercadoPago?.data?.init_point
    }
  }, [responseMercadoPago?.isSuccess])

  const onCloseFormPaymentIziPay = () => {
    setIsShow(!isShow)
    KRGlue.loadLibrary(endPoint, publicKey)
      .then(({ KR }) => {
        KR.removeForms();
      })
      .catch(error => {
        console.error("Error al cargar la librería", error);
      });
  }


  return (
    <div className='w-full flex justify-center bg-transparent' id='image-bg' >
      <div className="w-full md:w-7/12 bg-transparent">
        <div className='w-full text-5xl bg-neutral-950 h-[100px] pt-5 pb-5 text-center flex items-center justify-center text-yellow-400 text-center font-semibold'><FaCoins className='mr-2' /> DONACION</div>
        <Toaster position="bottom-center" reverseOrder={true} /><div className="w-full md:w-12/12 mt-[106px] bg-transparent p-3">

          {
            selectImageYape.open &&
            <div id="authentication-modal" className="overflow-y-auto fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">
              <div className="realtive m-auto p-5 w-full md:w-7/12 bg-neutral-200 bg-neutral-900">
                <div className="flex inline-flex items-center">
                  <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                    <IconButton color="error" onClick={CloseSelectImageYape}>
                      <IoCloseSharp
                        className="text-neutral-200"
                        size={28}
                      />
                    </IconButton>
                  </div>
                  <div className='pl-5 text-3xl'>
                    Pagar {currencyFormat(Number(s_PackSelected))} con YAPE
                  </div>
                </div>

                <form className='w-full flex flex-wrap p-3 mt-5' onSubmit={onSubmitSendYapePayment}>
                  <div className="relative w-full flex items-center justify-center mb-6 mt-4">
                    <label className="absolute -top-10 text-2xl">Escanear QR</label>
                    <LazyLoadImage
                      className="rounded-lg w-96 object-cover"
                      alt={`No image Qr Yape`}
                      effect="blur"
                      src={`/images/paymethods/qr-yape.jpg`}
                    />
                  </div>
                  <div className="w-full">
                    <Button
                      type="submit"
                      style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                      size='large' variant="contained"
                    >
                      Ya, eh pagado
                    </Button>
                  </div>
                </form>

              </div>
            </div>
          }

          <div className="w-full flex flex-wrap gap-7 flex justify-center ">
            <div className="w-full md:w-8/12 relative">
              <label for="first_name" class="block mb-2 text-xl font-bold text-neutral-700">Monto</label>
              <input autoFocus type="text" id="monto" className="pl-9 bg-neutral-800 shadow-md shadow-neutral-500 shadow-black p-[15px] text-neutral-200 text-xl rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-500 placeholder-neutral-600 focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Monto"
                required
                value={s_PackSelected}
                onChange={HandleChangeMontoManual}
                autoComplete="off"
                onKeyPress={PermiteSoloNumeros}
              />
              <div class="absolute inset-y-4 mt-9 left-1 pr-3 flex items-center text-2xl font-bold leading-5 text-neutral-300">
                S/
              </div>
            </div>

            <div className="w-full">
              <label for="first_name" class="w-full block font-bold text-neutral-700 text-center pb-5 pt-5 text-2xl">Métodos de pago</label>
              <div className='flex justify-center md:bg-neutral-950 bg-transparent w-full rounded-lg'>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full md:w-12/12 p-5">
                  {/*
      userToken && user?.admin &&
      <div onClick={(e) => addPayMethodPayMethodToRecharge('stripe')} 
      className={`w-full`}>
        <LazyLoadImage
          className="z-10 w-16 h-16 rounded-full flex mr-2"
          alt={`No image stripe}`}
          effect="blur"
          src={`/images/paymethods/stripe.png`}
        />
        <div className='grid grid-wrap'>
          STRIPE<span className='text-sm'>crédito/débito</span>
        </div>
      </div>
    */}

                  <div onClick={(e) => addPayMethodPayMethodToRecharge('yape')}
                    className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'yape' && 'bg-gradient-blue-custom text-white'}`}>
                    <div className='w-[65px] h-[65px] flex items-center justify-center'>
                      <LazyLoadImage
                        className="z-10 w-[45px] h-[45px] rounded-full flex"
                        alt={`No image yape}`}
                        effect="blur"
                        src={`/images/paymethods/yape.webp`}
                      />
                    </div>
                    <div className='grid grid-wrap w-[calc(100%-65)]'>
                      <div className={`text-xl font-bold  ${s_PayMethod === 'yape' ? 'text-neutral-50' : 'text-neutral-900'}`}>YAPE</div>
                      <div className={`text-sm font-bold  ${s_PayMethod === 'yape' ? 'text-neutral-300' : 'text-neutral-700'}`}>BCP</div>
                    </div>
                  </div>
                  <div onClick={(e) => addPayMethodPayMethodToRecharge('mercadopago')}
                    className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'mercadopago' && 'bg-gradient-blue-custom text-white'}`}>
                    <div className='w-[65px] h-[65px] flex items-center justify-center'>
                      <LazyLoadImage
                        className="z-10 w-[45px] h-[45px] rounded-full flex"
                        alt={`No image mercadopago}`}
                        effect="blur"
                        src={`/images/paymethods/mercadopago.webp`}
                      />
                    </div>
                    <div className='grid grid-wrap w-[calc(100%-65)]'>
                      <div className={`text-xl font-bold  ${s_PayMethod === 'mercadopago' ? 'text-neutral-50' : 'text-neutral-900'}`}>MERCADOPAGO</div>
                      <div className={`text-sm font-bold  ${s_PayMethod === 'mercadopago' ? 'text-neutral-300' : 'text-neutral-700'}`}>MERCADOLIBRE</div>
                    </div>
                  </div>

                  <div onClick={(e) => addPayMethodPayMethodToRecharge('binance')}
                    className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'binance' && 'bg-gradient-blue-custom text-white'}`}>
                    <div className='w-[65px] h-[65px] flex items-center justify-center'>
                      <LazyLoadImage
                        className="z-10 w-[45px] h-[45px] rounded-full flex"
                        alt={`No image yape}`}
                        effect="blur"
                        src={`/images/paymethods/binance.webp`}
                      />
                    </div>
                    <div className='grid grid-wrap w-[calc(100%-65)]'>
                      <div className={`text-xl font-bold  ${s_PayMethod === 'binance' ? 'text-neutral-50' : 'text-neutral-900'}`}>BINANCE</div>
                      <div className={`text-sm font-bold  ${s_PayMethod === 'binance' ? 'text-neutral-300' : 'text-neutral-700'}`}>USDT</div>
                    </div>
                  </div>

                  <div onClick={(e) => addPayMethodPayMethodToRecharge('izipay')}
                    className={`flex items-center h-24 bg-neutral-50 cursor-pointer rounded-xl ${s_PayMethod === 'izipay' && 'bg-gradient-blue-custom text-white'}`}>
                    <div className='w-[65px] h-[65px] flex items-center justify-center'>
                      <LazyLoadImage
                        className="z-10 w-[45px] h-[45px] rounded-full flex"
                        alt={`No image izipay}`}
                        effect="blur"
                        src={`/images/paymethods/izipay.webp`}
                      />
                    </div>
                    <div className='grid grid-wrap w-[calc(100%-65)]'>
                      <div className={`text-xl font-bold  ${s_PayMethod === 'izipay' ? 'text-neutral-50' : 'text-neutral-900'}`}>IZIPAY</div>
                      <div className={`text-sm font-bold  ${s_PayMethod === 'izipay' ? 'text-neutral-300' : 'text-neutral-700'}`}>DEBIT & CREDIT CARD</div>
                    </div>
                  </div>

                  <div id="popup-modal" tabindex="-1" class={`${isShow ? 'fixed block' : 'hidden'} flex items-center justify-center inset-0 bg-neutral-600 bg-opacity-50 overflow-y-auto h-full w-full overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div class="relative p-4 w-full max-w-md max-h-full">
                      <div class="relative bg-white rounded-lg shadow bg-neutral-700 flex items-center justify-center p-2">
                        <button type="button" class="absolute top-3 end-2.5 text-neutral-400 bg-transparent hover:bg-neutral-200 hover:text-neutral-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-neutral-600 hover:text-white" data-modal-hide="popup-modal">
                          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                          </svg>
                          <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                            <IconButton color="error" onClick={onCloseFormPaymentIziPay}>
                              <IoCloseSharp
                                className="text-neutral-200"
                                size={28}
                              />
                            </IconButton>
                          </div>
                        </button>
                        <div className='relative p-3'>
                          <div className='w-36 h-16'>
                            <LazyLoadImage
                              className="z-10 w-18 h-18"
                              alt={`No image izipay}`}
                              effect="blur"
                              src={`/images/logos/izipaylogo-2.png`}
                            />
                          </div>
                          <div class="flex items-center justify-center w-full p-3 relative m-auto">
                            <div className="d-flex justify-content-center">
                              <div id="myDIV" className="formulario">
                                <div id="form">
                                  {/* Formulario de pago incrustado */}
                                  <IziPayPaymentForm />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="flex mb-6 w-full">
              <div class="flex-auto flex space-x-4">
                <Button disabled={(response.isLoading || responseBinance.isLoading || responseMercadoPago.isLoading || s_PackSelected < 1 || s_PayMethod === "" || responseIziPay.isLoading) ? true : false}
                  sx={{
                    "&.Mui-disabled": {
                      background: "#525252",
                      color: "#a3a3a3"
                    }
                  }}
                  onClick={pay}
                  type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained" color="primary" startIcon={<MdPayment size={24} className={`mr-2 ${(response.isLoading || responseBinance.isLoading || responseMercadoPago.isLoading || responseIziPay.isLoading) && 'hidden'}`} />}>{response.isLoading || responseBinance.isLoading || responseMercadoPago.isLoading || responseIziPay.isLoading ? <><Spinner w={'35px'} h={'35px'} /> Procesando... </> : `Ir a pagar`}</Button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Donacion;