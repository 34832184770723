import { useState } from "react";
import { BsCashStack, BsPlusCircleDotted, BsServer } from "react-icons/bs";
import { FaAngleDown, FaAngleRight, FaAngleUp } from "react-icons/fa";
import { IoBag, IoBagCheck, IoChevronDown, IoChevronUp, IoGiftSharp, IoHome, IoLogoMicrosoft, IoPodium, IoReceipt, IoServer, IoShareSocialSharp } from "react-icons/io5";
import { MdAddReaction, MdAllInbox, MdCardGiftcard, MdContactMail, MdDashboard, MdHistory, MdManageAccounts, MdOutlineCardGiftcard, MdSettingsApplications, MdSupervisorAccount } from "react-icons/md";
import { SiServerfault } from "react-icons/si";
import { Link } from "react-router-dom";
const Sidebar = ({ side, closeSidebar }) => {
  const [OpenCloseManageAccounts, SetOpenCloseManageAccounts] = useState(false)
  const [OpenCloseManageCategories, SetOpenCloseManageCategories] = useState(false)
  const [OpenCloseManageUsers, SetOpenCloseManageUsers] = useState('hidden')
  const [OpenCloseManagePedidos, SetOpenCloseManagePedidos] = useState('hidden')
  const [OpenCloseManageServer, SetOpenCloseManageServer] = useState('hidden')
  const [OpenCloseManageGiftCard, SetOpenCloseManageGiftCard] = useState('hidden')
  const [OpenCloseManagePayments, SetOpenCloseManagePayments] = useState('hidden')
  const [OpenCloseManagePlataforms, SetOpenCloseManagePlataforms] = useState('hidden')

  const HandleOpenCloseManageAccounts = (e) => {
    SetOpenCloseManageAccounts(!OpenCloseManageAccounts)
  }

  const HandleOpenCloseManageCategories = (e) => {
    SetOpenCloseManageCategories(!OpenCloseManageCategories)
  }

  const HandleOpenCloseManageUsers = (e) => {
    if (OpenCloseManageUsers === "hidden") {
      SetOpenCloseManageUsers("")
    } else {
      SetOpenCloseManageUsers("hidden")
    }
  }

  const HandleOpenCloseManagePedidos = (e) => {
    if (OpenCloseManagePedidos === "hidden") {
      SetOpenCloseManagePedidos("")
    } else {
      SetOpenCloseManagePedidos("hidden")
    }
  }

  const HandleOpenCloseManageServer = (e) => {
    if (OpenCloseManageServer === "hidden") {
      SetOpenCloseManageServer("")
    } else {
      SetOpenCloseManageServer("hidden")
    }
  }

  const HandleOpenCloseManageGiftCard = (e) => {
    if (OpenCloseManageGiftCard === "hidden") {
      SetOpenCloseManageGiftCard("")
    } else {
      SetOpenCloseManageGiftCard("hidden")
    }
  }

  const HandleOpenCloseManagePayments = (e) => {
    if (OpenCloseManagePayments === "hidden") {
      SetOpenCloseManagePayments("")
    } else {
      SetOpenCloseManagePayments("hidden")
    }
  }

  const HandleOpenCloseManagePlataforms = (e) => {
    if (OpenCloseManagePlataforms === "hidden") {
      SetOpenCloseManagePlataforms("")
    } else {
      SetOpenCloseManagePlataforms("hidden")
    }
  }



  return (
    <div>
      {
        side === "" && <div className="bg-neutral-50 bg-opacity-30 fixed inset-0 z-40" onClick={closeSidebar}></div>
      }

      <aside id="logo-sidebar"
        class={`fixed top-20 left-0 z-50 md:z-40 w-64 h-screen pt-5 transition-all duration-500 ${side} bg-white dark:bg-neutral-900 border-r border-neutral-300 dark:border-neutral-800 sm:translate-x-0`} aria-label="Sidebar">
        <div class="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-neutral-900">
          <ul class="space-y-2">
            <li>
              <Link to="/admin/dashboard" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg p-2 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800 bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4">
                <MdDashboard className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Dashboard</span>
              </Link>
            </li>
            <li>
              <button onClick={HandleOpenCloseManageAccounts} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <MdAllInbox className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Gestionar Cuentas</span>
                {
                  OpenCloseManageAccounts ?
                    <FaAngleRight size={16} className="absolute right-4 rotate-90 transition ease-in-out duration-700" />
                    :
                    <FaAngleRight size={16} className="absolute right-4 transition ease-in-out duration-700" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageAccounts ? `h-[85px]` : 'h-[0px]'} block transition-all ease-in-out duration-700 overflow-hidden space-y-2`}>
                <li>
                  <Link to="/admin/cuentas" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <MdContactMail className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Cuentas</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-cuenta" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <BsPlusCircleDotted className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Nueva Cuenta</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManageCategories} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoServer className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar Categorías</span>
                {
                  OpenCloseManageCategories ?
                    <FaAngleRight size={16} className="absolute right-4 rotate-90 transition ease-in-out duration-700" />
                    :
                    <FaAngleRight size={16} className="absolute right-4 transition ease-in-out duration-700" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageCategories ? `h-[85px]` : 'h-[0px]'} block transition-all ease-in-out duration-700 overflow-hidden space-y-2`}>
                <li>
                  <Link to="/admin/categories" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoPodium className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Categorías</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/categoriesGC" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoPodium className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Categorías GC</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-category" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <BsPlusCircleDotted className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Nueva Categoría</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button onClick={HandleOpenCloseManageGiftCard} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <MdOutlineCardGiftcard className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar Gift Cards</span>
                {
                  OpenCloseManageGiftCard === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageGiftCard} py-2 space-y-2`}>
                <li>
                  <Link to="/admin/giftcards" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoPodium className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Gift Cards</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-giftcard" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <BsPlusCircleDotted className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Nueva Gift Card</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button onClick={HandleOpenCloseManageUsers} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <MdManageAccounts className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar Usuarios</span>
                {
                  OpenCloseManageUsers === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageUsers} py-2 space-y-2`}>
                <li>
                  <Link to="/admin/users" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <MdSupervisorAccount className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Usuarios</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/create-user" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <BsPlusCircleDotted className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Nuevo Usuario</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManagePedidos} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoBag className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar Pedidos</span>
                {
                  OpenCloseManagePedidos === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }
              </button>
              <ul id="dropdown-example" class={`${OpenCloseManagePedidos} py-2 space-y-2`}>
                <li>
                  <Link to="/admin/streamingorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoBagCheck className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Pedidos Streaming</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/giftcardsorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoBagCheck className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Pedidos GiftCards</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManagePayments} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <BsCashStack className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar Recargas</span>
                {
                  OpenCloseManagePayments === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManagePayments} py-2 space-y-2`}>
                <li>
                  <Link to="/admin/payments" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoBagCheck className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Recargas</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/admin/history" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg p-2 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4">
                <MdHistory className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Historial</span>
              </Link>
            </li>

            <li>
              <button onClick={HandleOpenCloseManagePlataforms} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoShareSocialSharp className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Redes Sociales</span>
                {
                  OpenCloseManagePlataforms === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManagePlataforms} py-2 space-y-2`}>
                <li>
                  <Link to="/admin/create-social-service" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <MdAddReaction className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Crear Servicio</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/socialplataforms" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoLogoMicrosoft className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Plataformas</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin/socialorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoReceipt className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 group-hover:font-semibold mr-2" /><span className="group-hover:font-semibold">Pedidos</span>
                  </Link>
                </li>
              </ul>
            </li>


            <li>
              <button onClick={HandleOpenCloseManageServer} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  bg-neutral-200 dark:bg-neutral-950 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <BsServer className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                "/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar Servidor</span>
                {
                  OpenCloseManageServer === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageServer} py-2 space-y-2`}>
                <li>
                  <Link to="/admin/configuration" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-9 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <MdSettingsApplications className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Configurar Servidor</span>
                  </Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </aside>
    </div>
  );
};
export default Sidebar;
