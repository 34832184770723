import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryService = createApi({
  reducerPath: "category",
  tagTypes: "categories",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      const offlineUserToken = reducers?.authReducer?.offlineUserToken
      headers.set('authorization', (userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '' || offlineUserToken ? `Bearer ${offlineUserToken}` : ''));
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      create: builder.mutation({
        query: (datacategory) => {
          return {
            url: "create-category",
            method: "POST",
            body: datacategory,
          };
        },
        invalidatesTags: ["categories"],
      }),
      updateSubCategory: builder.mutation({
        query: (datacategory) => {
          return {
            url: `update-subcategory/${datacategory._id}`,
            method: "PATCH",
            body: datacategory,
          };
        },
        invalidatesTags: ["categories"],
      }),
      updateCategory: builder.mutation({
        query: (datacategory) => {
          return {
            url: `update-category/${datacategory._id}`,
            method: "PATCH",
            body: datacategory,
          };
        },
        invalidatesTags: ["categories"],
      }),
      deleteCategory: builder.mutation({
        query: (id) => {
          return {
            url: `delete-category/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["categories"],
      }),
      deleteSubCategory: builder.mutation({
        query: (params) => {
          return {
            url: `delete-subcategory/${params.categoryId}/${params.subcategoryId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["categories"],
      }),
      getAllCategories: builder.query({
        query: () => {
          return {
            url: `categories`,
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),
      getCategoryById: builder.query({
        query: (id) => {
          return {
            url: `get-category/${id}`,
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),

      getAllCategoriesStreaming: builder.query({
        query: () => {
          return {
            url: "/categories-streaming",
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),

      getAllCategoriesGiftCard: builder.query({
        query: () => {
          return {
            url: "/categories-giftcard",
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),

      getAllCategoriesStreamingByRating: builder.query({
        query: () => {
          return {
            url: "/categories-streaming-rating",
            method: "GET",
          };
        },
        providesTags: ["categories"],
      }),


      randomCategories: builder.query({
        query: () => {
          return {
            url: "random-categories",
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useCreateMutation,
  useGetAllCategoriesQuery,
  useGetCategoryByIdQuery,
  useGetAllCategoriesStreamingQuery,
  useGetAllCategoriesGiftCardQuery,
  useGetAllCategoriesStreamingByRatingQuery,
  useRandomCategoriesQuery,
  useUpdateSubCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useDeleteSubCategoryMutation
} = categoryService;
export default categoryService;
