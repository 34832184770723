import { useState, useEffect, useMemo } from "react"
import UpdateScreenHeader from "../../components/UpdateScreenHeader";
import { useUpdateCategoryMutation, useUpdateSubCategoryMutation } from "../../store/services/categoryService";
import { MdEdit, MdEditOff } from "react-icons/md";
import { Button, FormControl, IconButton, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import { message, PermiteSoloNumeros, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { IoChevronDown, IoChevronForward, IoCloseSharp, IoSave } from "react-icons/io5";
import { Toaster } from "react-hot-toast";
import ImagesPreview from "../../components/ImagesPreview";
import { BsCalendar2Date, BsCalendar3 } from "react-icons/bs";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import moment from 'moment';
import 'moment/locale/es';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Spinner from "../../components/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateServiceMutation } from "../../store/services/socialService";

const UpdateSubCategorySocial = ({ socket, plataformId, serviceData, newData, HandleCloseEditSubCategorySocial }) => {
    const dispatch = useDispatch();
    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [enabled, SetEnabled] = useState(true)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [state, setState] = useState({
        _id: "",
        subcategoryId: "",
        serviceId: "",
        servicetype: "",
        name: "",
        preciofijouser: 0,
        preciofijoreseller: 0,
        porcentajeadicional: 0,
        tipoentrega: false,
        enabled: true,
        speedseconds: 0,
        policy: "",
        urlexamplephone: "",
        urlexampledesktop: ""

    });

    const [updateService, response] = useUpdateServiceMutation();

    const updateSubmit = async e => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();
        if (contentState.hasText()) {
            const formData = new FormData();
            formData.append('data', JSON.stringify(state));
            formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
            await updateService(formData);
        } else {
            message('Términos y condiciones de servicio', 'error', 4)
        }
    }

    useEffect(() => {
        const subcategory = {
            _id: plataformId,
            subcategoryId: serviceData._id,
            serviceId: serviceData.serviceId,
            name: serviceData.name,
            preciofijouser: serviceData.preciofijouser ? serviceData.preciofijouser : 0,
            preciofijoreseller: serviceData.preciofijoreseller ? serviceData.preciofijoreseller : 0,
            porcentajeadicional: serviceData.porcentajeadicional ? serviceData.porcentajeadicional : 0,
            tipoentrega: serviceData.tipoentrega,
            enabled: serviceData.enabled,
            servicetype: serviceData.servicetype ? serviceData.servicetype:"",
            urlexamplephone: serviceData.urlexamplephone ? serviceData.urlexamplephone : "",
            urlexampledesktop: serviceData.urlexampledesktop ? serviceData.urlexampledesktop : "",
            speedseconds: serviceData.speedseconds ? serviceData.speedseconds : 0,
            policy: serviceData.policy ? serviceData.policy : "",
        }
        setState({ ...subcategory })
        SetTipoEntrega(serviceData.tipoentrega)
        SetEnabled(serviceData.enabled)

        const rawContent = serviceData.policy ? serviceData.policy : JSON.stringify({ "blocks": [{ "key": "dq4ce", "text": "okk", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} });
        const contentState = convertFromRaw(JSON.parse(rawContent));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)

    }, [serviceData]);


    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map((err) => {
                message(err.message, 'error', 4)
            });
        }
    }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (response?.isSuccess) {
            message(response?.data?.message, 'success', 5)
            newData(response?.data?.newData)
            HandleCloseEditSubCategorySocial()
        }
    }, [response?.isSuccess]);


    const HandleChangeEditSubCategory = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const OnChangeTipoEntrega = () => {
        SetTipoEntrega(!tipoentrega)
        setState({ ...state, ['tipoentrega']: !tipoentrega })
    }

    const OnChangeEnabled = () => {
        SetEnabled(!enabled)
        setState({ ...state, ['enabled']: !enabled })
    }

    return (
        <>
            <Toaster position="top-right" reverseOrder={true} />
            <UpdateScreenHeader>
                <div className="flex items-center">
                    <div className="flex inline-flex items-center">
                        <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                            <IconButton color="error" onClick={HandleCloseEditSubCategorySocial}>
                                <IoCloseSharp
                                    className="text-neutral-200"
                                    size={28}
                                />
                            </IconButton>
                        </div>

                    </div>
                    <div className="w-full text-neutral-900 dark:text-neutral-200 text-2xl ml-5 flex inline-flex items-center"> EDITAR SUBCATEGORIA SOCIAL</div>
                </div>
            </UpdateScreenHeader>

            <form className="w-full md:w-12/12 mt-[125px]" onSubmit={updateSubmit}>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la categoría</label>
                        <input autoFocus name="name" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Nombre de la categoría"
                            autoComplete="off"
                            required
                            value={state.name}
                            type="text"
                            onChange={HandleChangeEditSubCategory}
                        />
                    </div>
                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_serviceId" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Id de servicio</label>
                        <Tooltip title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MdEditOff size={18} style={{ marginRight: 5 }} />
                                Id de servicio no se puede editar
                            </div>}

                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        fontSize: 12.5,
                                    }
                                }
                            }}
                            arrow>
                            <input name="serviceId" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Id de servicio"
                                autoComplete="off"
                                required
                                disabled
                                value={state.serviceId}
                                type="text"
                            /></Tooltip>
                    </div>
                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_preciofijouser" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Precio fijo usuario</label>
                        <input name="preciofijouser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Precio fijo usuario"
                            autoComplete="off"
                            required
                            min={0}
                            step={0.01}
                            onWheel={(e) => e.target.blur()}
                            value={state.preciofijouser}
                            type="number"
                            onChange={HandleChangeEditSubCategory}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            S/
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_preciofijoreseller" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Precio fijo reseller</label>
                        <input name="preciofijoreseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Precio fijo reseller"
                            autoComplete="off"
                            required
                            min={0}
                            step={0.01}
                            onWheel={(e) => e.target.blur()}
                            value={state.preciofijoreseller}
                            type="number"
                            onChange={HandleChangeEditSubCategory}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            S/
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_porcentajeadicional" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Porcentaje adicional aplicado</label>
                        <input name="porcentajeadicional" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Porcentaje adicional aplicado"
                            autoComplete="off"
                            required
                            min={0}
                            max={100}
                            value={state.porcentajeadicional}
                            type="number"
                            onChange={HandleChangeEditSubCategory}
                        />
                        <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                            %
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 p-3 relative">
                        <label for="first_speedseconds" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Tiempo estimado para completar (segundos)</label>
                        <input name="speedseconds" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Tiempo estimado para completar"
                            autoComplete="off"
                            required
                            min={0}
                            value={state.speedseconds}
                            type="number"
                            onChange={HandleChangeEditSubCategory}
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_urlexamplephone" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Ejemplo url para celular</label>
                        <input name="urlexamplephone" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Ejemplo url para celular"
                            autoComplete="off"
                            required
                            value={state.urlexamplephone}
                            type="text"
                            onChange={HandleChangeEditSubCategory}
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_urlexampledesktop" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Ejemplo url para computadora</label>
                        <input name="urlexampledesktop" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Ejemplo url para computadora"
                            autoComplete="off"
                            required
                            value={state.urlexampledesktop}
                            type="text"
                            onChange={HandleChangeEditSubCategory}
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Tipo de servicio</label>
                        <FormControl fullWidth>
                            <Select
                                required
                                name="servicetype"
                                className="flex items-center"
                                variant="outlined"
                                value={state.servicetype}
                                onChange={HandleChangeEditSubCategory}
                            >
                                <MenuItem value={'default'} key={'default'} className="flex items-center">Default</MenuItem>
                                <MenuItem value={'customcomments'} key={'customcomments'} className="flex items-center">Custom comments</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeTipoEntrega} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${tipoentrega && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeTipoEntrega} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!tipoentrega && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>
                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Estado del servicio <span className="font-semibold">{enabled ? "habilitado" : "deshabilitado"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeEnabled} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${enabled && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeEnabled} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!enabled && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Términos y condiciones del servicio</label>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            placeholder="Escribe aquí..."
                        />
                    </div>

                    <div className="flex m-auto w-full  md:w-12/12 p-3 w-full p-3 mt-5">
                        <Button
                            type="submit"
                            disabled={response.isLoading ? true : false}
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained"
                        >
                            {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                            {response.isLoading ? 'Actualizando...' : 'Actualizar SubCategoria'}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default UpdateSubCategorySocial;