import { Virtual, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "../skeleton/Skeleton";
import Thumbnail from "../skeleton/Thumbnail";
import { currencyFormat } from "../../utils/Funciones.js";
import { BsHandbag, BsTagFill } from "react-icons/bs";
import { IoCardSharp, IoCartSharp, IoChevronForward, IoDesktopSharp, IoHeartOutline, IoStar, IoTimer, IoVideocamSharp } from "react-icons/io5";
import { MdDevices, MdPhoneIphone, MdSecurity } from "react-icons/md";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../Spinner";
import SpinnerImages from "../SpinnerImages";
import { FcMultipleDevices } from "react-icons/fc";
import { useGetAllStockCuentasQuery } from "../../store/services/cuentaService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Spinner2 from "../Spinner2";
import { FaAward } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

const Categories = ({ socket, CategoryData, CategoryDataIsLoading, openOptions }) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState({ id: "", iamgeloaded: false })
  const [loaded2, setLoaded2] = useState({ id: "", iamgeloaded: false })

  const onLoad = useCallback((id) => {
    setLoaded({ ...loaded, id: id, iamgeloaded: true });
  }, [])

  const onLoad2 = useCallback((id) => {
    setLoaded2({ ...loaded2, id: id, iamgeloaded: true });
  }, [])

  const HandleOpenOptions = (name, categoryId, sublogo, description) => {
    openOptions(name, categoryId, sublogo, description)
  }

  let i = 1;
  return (

    <>
      <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full 
       text-neutral-100 bg-blue-custom
        mb-3 text-center text-xl md:text-3xl pt-5 pb-5 contrast-[125%]">
        SERVICIOS STREAMING
      </div>
      <div className="flex flex-wrap w-full pb-10">
        <div className="w-full grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-4 mb-4">
          {
            !CategoryDataIsLoading ? CategoryData && CategoryData.map((category, index) => {

              return (
                <div onClick={() => HandleOpenOptions(category.name, category._id, category.sublogo, category.description)} key={index} className={`relative pb-3 transition-all ease-in-out duration-500 hover:-translate-y-[8px] cursor-pointer`}>
                  <div className="relative flex items-center justify-center rounded m-auto mt-[8px] w-44 h-72 md:w-52 md:h-80">
                    <LazyLoadImage
                      className="w-44 h-72 md:w-52 md:h-80 m-auto rounded z-10"
                      alt={`No image ${category.name}`}
                      effect="blur"
                      src={`/images/logos/${category.logo}`}
                    />
                  </div>
                  <div style={{ background: `${category.themecolor}` }} className="pt-3 pb-3 pl-1 pr-1 text-neutral-200 text-sm md:text-base text-center uppercase font-bold rounded-sm [text-shadow:2px_2px_8px_var(--tw-shadow-color)] shadow-black">
                    {category.name}
                  </div>
                </div>
              )
            }) : <Spinner />
          }
        </div>
      </div>
    </>
  );
};

export default Categories;
