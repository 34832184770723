import Routing from "./routes/Routing"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { IconButton, Modal, Tooltip } from "@mui/material";
import { IoCloseSharp, IoLogoWhatsapp } from "react-icons/io5";
import io from "socket.io-client"
import { useCreateOfflineUserTokenMutation } from "./store/services/tokenService";
import { setOfflineUserToken } from "./store/reducers/authReducer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { getCategories } from "./store/features/categorySlice";

const socket = io('https://cinedar.com:8443', {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5
})

function App() {
  const { userToken, user } = useSelector((state) => state.authReducer);
  const { adminToken, admin } = useSelector((state) => state.authReducer);
  const { offlineUserToken } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [crearOfflineUserToken, ResponseCrearOfflineUserToken] = useCreateOfflineUserTokenMutation()


  useEffect(async () => {
    if (!userToken && !adminToken && !offlineUserToken) {
      await crearOfflineUserToken({ socket: socket.id })
    }
  }, [crearOfflineUserToken])

  useEffect(async () => {

    if (ResponseCrearOfflineUserToken.isSuccess) {
      localStorage.setItem('offlineUserToken', ResponseCrearOfflineUserToken?.data?.token);
      dispatch(setOfflineUserToken(ResponseCrearOfflineUserToken?.data?.token))
    }
  }, [ResponseCrearOfflineUserToken.isSuccess])


  useEffect(async () => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    // if set via local storage previously
    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
        localStorage.setItem('color-theme', 'dark');
      }

      // if NOT set via local storage previously
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      }
    }
  }, [])

  return (
    <>
      {((offlineUserToken || adminToken || userToken) && socket) &&
        <Routing socket={socket} />
      }

      <Tooltip title="Solicita un servicio por whatsapp" arrow={true}>
        <a href="https://wa.me/51994201155" className="float flex items-center justify-center" target="_blank">
          <IoLogoWhatsapp />
        </a>
      </Tooltip>
    </>

  );
}
export default App;
