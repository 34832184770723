import React, { useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    BarElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    BarElement,
    Filler,
    Tooltip,
    Legend
);

const GraphsBarRechargeUsers = ({ rechargeRanking, labelname, title }) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const options = {
        responsive: true,
        Animation: true,
        scales: {
            r: {
                angleLines: { color: '#059669' },
                grid: { color: '#059669' },
                pointLabels: { color: '#e5e5e5' },
                ticks: { color: '#171717'}
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: title
            }
        },
    };

    const data = {
        labels: rechargeRanking && rechargeRanking.map((rech) => rech.email),
        datasets: [
            {
                label: 'Total',
                data: rechargeRanking && rechargeRanking.map((rech) => rech.totalRecargado),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 1,
            }
        ],
    };
    return (
        <Bar
            options={options}
            data={data}
            width={'100%'}
            height={`${windowSize.current[0] > 640 ? '100%' : '80%'}`}
        />
    )
}
export default GraphsBarRechargeUsers