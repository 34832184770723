import { createSlice } from "@reduxjs/toolkit";
import { discount } from "../../utils/discount";
const rechargeData = localStorage.getItem("recharge");
const rechargeArray = rechargeData ? JSON.parse(rechargeData) : [];
function allItems(data) {
  let items = 0;
  for (let i = 0; i < data.length; i++) {
    items += data[i].quantity;
  }
  return items;
}
function calcuateTotal(data) {
  let total = 0;
  for (let i = 0; i < data.length; i++) {
    total += discount(data[i].price, data[i].discount) * data[i].quantity;
  }
  return total;
}
const rechargeReducer = createSlice({
  name: "recharge",
  initialState: {
    recharge: rechargeArray.length > 0 ? rechargeArray : [],
    items: rechargeArray.length > 0 ? allItems(rechargeArray) : 0,
    total: rechargeArray.length > 0 ? calcuateTotal(rechargeArray) : 0,
  },
  reducers: {
    addRecharge: (state, { payload }) => {
      state.recharge.push(payload);
      state.items += payload.quantity;
      state.total +=
        discount(payload.price, payload.discount) * payload.quantity;
    },
    incQuantity: (state, { payload }) => {
      const find = state.recharge.find((item) => item._id === payload);
      if (find) {
        find.quantity += 1;
        state.items += 1;
        state.total += discount(find.price, find.discount);
        const index = state.recharge.indexOf(find);
        state.recharge[index] = find;
        localStorage.setItem("recharge", JSON.stringify(state.recharge));
      }
    },
    decQuantity: (state, { payload }) => {
      const find = state.recharge.find((item) => item._id === payload);
      if (find && find.quantity > 1) {
        find.quantity -= 1;
        state.items -= 1;
        state.total -= discount(find.price, find.discount);
        const index = state.recharge.indexOf(find);
        state.recharge[index] = find;
        localStorage.setItem("recharge", JSON.stringify(state.recharge));
      }
    },
    removeItem: (state, { payload }) => {
      const find = state.recharge.find((item) => item._id === payload);
      if (find) {
        const index = state.recharge.indexOf(find);
        state.items -= find.quantity;
        state.total -= discount(find.price, find.discount) * find.quantity;
        state.recharge.splice(index, 1);
        localStorage.setItem("recharge", JSON.stringify(state.recharge));
      }
    },
    emptyRecharge: (state) => {
      state.recharge = [];
      state.items = 0;
      state.total = 0;
    },
  },
});
export const { addRecharge, incQuantity, decQuantity, removeItem, emptyRecharge } =
rechargeReducer.actions;
export default rechargeReducer.reducer;
