import { useEffect, useState } from "react"
import ScreenHeader from "../../components/ScreenHeader";
import { Toaster } from 'react-hot-toast';
import { useDeleteCategoryMutation } from "../../store/services/categoryService";
import Spinner from "../../components/Spinner";
import { MdDelete, MdDevices, MdEdit, MdPhoneIphone } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { IoChevronForward, IoPodium, IoReload } from "react-icons/io5";
import moment from 'moment';
import Wrapper from "./Wrapper";
import { Link } from "react-router-dom";
import { FcPlus } from "react-icons/fc";
import Modal from '@mui/material/Modal';
import { FaCogs, FaServer, FaUserSlash } from "react-icons/fa";
import { message, PermiteSoloNumeros } from "../../utils/Funciones";
import { useUpdateConfigurationMutation } from "../../store/services/userService";
import ImagesPreview from "../../components/ImagesPreview";
import Swal from "sweetalert2";

const Configuration = ({ socket, configurations, loadingconfiguration }) => {
    const [searchCategories, SetSearchCategories] = useState("")
    const [onlineusers, setOnlineUsers] = useState(0)
    const [configuration, setConfiguration] = useState({
        _id: "",
        appKey: "",
        name: "",
        logo: "",
        currentlogo: "",
        login: false,
        register: false,
        buyservice: false,
        buycredits: false,
        changepassword: false,
        changeavatar: false,
        howmuchforreseller: 0,
        howmuchtimeforeditpassworddays: 0,
        canrecoverpassword: false
    })

    useEffect(() => {
        if (!loadingconfiguration) {
            if (configurations) {
                setConfiguration({
                    ...configuration,
                    _id: configurations._id,
                    appKey: configurations.appKey,
                    name: configurations.name,
                    logo: configurations.logo,
                    currentlogo: configurations.logo,
                    login: configurations.login,
                    register: configurations.register,
                    buyservice: configurations.buyservice,
                    buycredits: configurations.buycredits,
                    changepassword: configurations.changepassword,
                    changeavatar: configurations.changeavatar,
                    howmuchforreseller: configurations.howmuchforreseller,
                    howmuchtimeforeditpassworddays: configurations.howmuchtimeforeditpassworddays,
                    canrecoverpassword: configurations.canrecoverpassword
                })
            }

        }
    }, [loadingconfiguration])

    const onChangeHandle = (e) => {
        setConfiguration({ ...configuration, [e.target.name]: e.target.value })
    }

    const [preview, setPreview] = useState({
        logo: '',
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setConfiguration({ ...configuration, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const [updateConfiguration, response] = useUpdateConfigurationMutation();

    const onSubmitUpdateConfiguration = (e) => {
        e.preventDefault();
        const errors = []
        if (configuration.name.trim().length < 1) {
            errors.push({ msg: `Nombre de la empresa es requerido` })
        }

        if (configuration.howmuchforreseller.toString().trim().length < 1) {
            errors.push({ msg: `Créditos mínimos para reseller es requerido` })
        }

        if (configuration.howmuchtimeforeditpassworddays.toString().trim().length < 1) {
            errors.push({ msg: `Días para poder restablecer la contraseña es requerido` })
        }

        console.log(configuration.logo)

        if (errors.length === 0) {
            const configcurrent = {
                _id: configurations._id,
                appKey: configurations.appKey,
                name: configurations.name,
                logo: configurations.logo,
                currentlogo: configurations.logo,
                login: configurations.login,
                register: configurations.register,
                buyservice: configurations.buyservice,
                buycredits: configurations.buycredits,
                changepassword: configurations.changepassword,
                changeavatar: configurations.changeavatar,
                howmuchforreseller: configurations.howmuchforreseller,
                howmuchtimeforeditpassworddays: configurations.howmuchtimeforeditpassworddays,
                canrecoverpassword: configurations.canrecoverpassword
            }

            if (JSON.stringify(configuration) !== JSON.stringify(configcurrent)) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(configuration));
                formData.append('image1', configuration.logo)
                updateConfiguration(formData)
            } else {
                message('No hubo ningún cambio.', 'error', 4)
            }

        } else {
            errors.map((err) => {
                message(err.msg, 'error', 4)
            });
        }

    }

    useEffect(() => {
        if (response?.isSuccess) {

            socket.emit('updateconfiguration', response?.data?.configuration)
            message(response?.data?.message, 'success', 4)
            console.log(response?.data?.configuration)
        }
    }, [response?.isSuccess]);


    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map((err) => {
                message(err.msg, 'error', 4)
            });
        }
    }, [response?.error?.data?.errors]);

    useEffect(async () => {

        await socket.emit('checkusersonline', async (callback) => {
            setOnlineUsers(callback)
        })

        socket.on('authenticated', (res) => {
            setOnlineUsers(res)
        })

    }, [socket]);

    const onDisconnetAllUsers = () => {

        alertaConfirmar('Desconectar usuarios', 'Estas seguro que quieres desconectar a todos los usuarios?', 'question', async (res_alert) => {
            if (res_alert === true) {
                await socket.emit('checkusersonline', async (callback) => {
                    if (callback > 0) {
                        await socket.emit('disconectusers', (res) => {
                            message(`Se ah desconectado a ${res} usuarios.`, 'success', 4)
                        })
                    } else {
                        message(`No existen usuarios para desconectar.`, 'error', 4)
                    }
                })
            }
        })

    }

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#2C3E50',
            cancelButtonColor: '#CB4335'
        }).then((result) => {
            if (result.isConfirmed) {
                callback(result.isConfirmed)
            }
        })
    }

    return (
        <Wrapper socket={socket} configuration={configurations}
            loadingconfiguration={loadingconfiguration}>

            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <FaCogs size={32} style={{ marginRight: 5 }} />
                        CONFIGURACIÓN
                        <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium text-neutral-800 bg-neutral-200 dark:bg-neutral-900 rounded-full">
                        </div>
                    </div>
                </div>
                <div className="absolute right-14 rounded-full text-neutral-100 bg-green-600 hover:bg-green-700 w-12 h-12 flex items-center justify-center mr-2">
                    {onlineusers}
                </div>
                <Tooltip title="Desconectar todos los usuarios" arrow={true}>
                    <div className="absolute right-2 rounded-full bg-red-600 hover:bg-red-700 w-12 h-12 flex items-center justify-center">
                        <IconButton onClick={onDisconnetAllUsers} className="">
                            <FaUserSlash size={28} className="text-neutral-200" />
                        </IconButton>
                    </div>
                </Tooltip>
            </ScreenHeader>
            <div className="w-full md:w-6/12 mt-[80px]">

                <form className="w-full flex flex-wrap" onSubmit={onSubmitUpdateConfiguration}>

                    <div className="w-full md:w-6/12 p-3 ">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">APP KEY</label>
                        <input name="appKey" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="APP KEY"
                            autoComplete="off"
                            disabled
                            value={configuration.appKey}
                            type="text"
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3 ">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la empresa</label>
                        <input autoFocus name="name" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Nombre de la empresa"
                            autoComplete="off"
                            required
                            value={configuration.name}
                            onChange={onChangeHandle}
                            type="text"
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3 ">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Créditos mínimos para reseller</label>
                        <input name="howmuchforreseller" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Créditos mínimos para reseller"
                            autoComplete="off"
                            required
                            value={configuration.howmuchforreseller}
                            onChange={onChangeHandle}
                            type="number"
                            min={0}
                            onKeyPress={PermiteSoloNumeros}
                        />
                    </div>

                    <div className="w-full md:w-6/12 p-3 ">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Días para poder restablecer contraseña</label>
                        <input name="howmuchtimeforeditpassworddays" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            placeholder="Días para poder restablecer contraseña"
                            autoComplete="off"
                            required
                            value={configuration.howmuchtimeforeditpassworddays}
                            onChange={onChangeHandle}
                            type="number"
                            min={0}
                            onKeyPress={PermiteSoloNumeros}
                        />
                    </div>

                    <div className="w-6/12 p-3 ">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Ingreso? <span className="font-semibold">{configuration.login ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="login" onClick={() => setConfiguration({ ...configuration, login: !configuration.login })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.login && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="login" onClick={() => setConfiguration({ ...configuration, login: !configuration.login })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.login && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-6/12 p-3 ">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Registro? <span className="font-semibold">{configuration.register ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="register" onClick={() => setConfiguration({ ...configuration, register: !configuration.register })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.register && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="register" onClick={() => setConfiguration({ ...configuration, register: !configuration.register })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.register && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-6/12 p-3 ">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Comprar servicios? <span className="font-semibold">{configuration.buyservice ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="buyservice" onClick={() => setConfiguration({ ...configuration, buyservice: !configuration.buyservice })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.buyservice && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="buyservice" onClick={() => setConfiguration({ ...configuration, buyservice: !configuration.buyservice })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.buyservice && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-6/12 p-3 ">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Comprar créditos? <span className="font-semibold">{configuration.buycredits ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="buycredits" onClick={() => setConfiguration({ ...configuration, buycredits: !configuration.buycredits })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.buycredits && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="buycredits" onClick={() => setConfiguration({ ...configuration, buycredits: !configuration.buycredits })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.buycredits && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-6/12 p-3 ">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Cambiar contraseña? <span className="font-semibold">{configuration.changepassword ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="changepassword" onClick={() => setConfiguration({ ...configuration, changepassword: !configuration.changepassword })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.changepassword && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="changepassword" onClick={() => setConfiguration({ ...configuration, changepassword: !configuration.changepassword })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.changepassword && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-6/12 p-3 ">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Cambiar perfil? <span className="font-semibold">{configuration.changeavatar ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="changeavatar" onClick={() => setConfiguration({ ...configuration, changeavatar: !configuration.changeavatar })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.changeavatar && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="changeavatar" onClick={() => setConfiguration({ ...configuration, changeavatar: !configuration.changeavatar })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.changeavatar && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Recuperar contraseña? <span className="font-semibold">{configuration.canrecoverpassword ? <span className="text-green-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span htmlFor="canrecoverpassword" onClick={() => setConfiguration({ ...configuration, canrecoverpassword: !configuration.canrecoverpassword })} className={`rounded-full flex bg-black items-center cursor-pointer w-[84px] justify-start text-center absolute ${configuration.canrecoverpassword && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>

                            <span htmlFor="canrecoverpassword" onClick={() => setConfiguration({ ...configuration, canrecoverpassword: !configuration.canrecoverpassword })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-[84px] text-center justify-end absolute ${!configuration.canrecoverpassword && 'hidden'}`}>
                                <span className="rounded-full w-12 h-12 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                    <div className="w-full flex flex-wrap p-3">

                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                    <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                    <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                </div>
                                <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                            </label>

                        </div>
                        <div class="w-full md:w-6/12 p-3 rounded-full flex inline-flex items-center justify-center">
                            <div className="border-2 w-100 h-100 rounded-full border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                <div className="">
                                    <div className="">
                                        <img src={`/images/logos/${configuration.currentlogo}`} width={250} id='prelogo' className="object-cover rounded-full" />
                                    </div>
                                </div>
                                {preview.logo && <IoChevronForward className="text-neutral-300" size={32} />}
                                <ImagesPreview url={preview.logo} />
                            </div>
                        </div>

                    </div>

                    <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                        <button
                            type="submit"
                            class="flex w-full disabled:opacity-30 justify-center rounded-md bg-blue-600 px-3 py-5 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            disabled={response.isLoading ? true : false}
                        >
                            {response.isLoading ? <Spinner /> : <IoReload size={24} className="mr-2" />}
                            {response.isLoading ? 'Guardando...' : 'ACTUALIZAR CONFIGURACION'}
                        </button>
                    </div>

                </form>


            </div>

        </Wrapper>
    )
}
export default Configuration;