import AccountList from "../../components/home/AccountList";
import { Toaster } from "react-hot-toast";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { IoAlarm, IoBagCheck, IoBan, IoChatboxEllipsesSharp, IoCheckbox, IoChevronDown, IoCloseCircleOutline, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoOpen, IoOpenOutline, IoPerson, IoRefreshSharp, IoReload, IoReloadCircleSharp, IoSearch, IoThumbsDownSharp, IoTime } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import Text from "../../components/skeleton/Text";
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdAvTimer, MdDevices, MdMail, MdNearbyError, MdPhoneIphone, MdReport, MdUpdate } from "react-icons/md";
import { Pagination, PaginationItem, Stack, Button, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FaClipboard, FaShippingFast } from "react-icons/fa";
import { setUserToken } from "../../store/reducers/authReducer";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import 'moment/locale/es';
import Modal from '@mui/material/Modal';
import { useGetAllCuentasQuery } from "../../store/services/cuentaService";
import { useGetAllCategoriesStreamingQuery } from "../../store/services/categoryService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useCreateSocialReportMutation, useGetAllSocialPlataformsQuery, useGetOrdersByUserIdQuery } from "../../store/services/socialService";
import { MuiTelInput } from "mui-tel-input";


const UserSocialOrders = ({
  socket,
  userToken,
  user,
  SECRET_KEY,
  loadinguserbyid,
  userById,
  UpdateUserById,
  configuration,
  loadingconfiguration
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [limit, setLimit] = useState(30);
  const [infopedido, setInfopedido] = useState('')
  const [reportorder, setReportOrder] = useState({
    _id: "",
    open: false,
    message: "",
    orderId: "",
    nameplataform: "",
    plataformlogo: "",
    countrycode: "",
    phonenumber: "",
  })

  const [checkHistoryReport, setCheckHistoryReport] = useState({
    open: false,
    message: "",
    answer: "",
    created_at: "",
    read_at: "",
    orderId: "",
    nameplataform: "",
    logo: ""
  })

  const { data: orders, isFetching: loadingorders, refetch } = useGetOrdersByUserIdQuery({ id: userToken && user?.id, search: searchTerm.trim(), page: currentPage, limit: limit });
  const { data: plataforms, isFetching: loadingplataforms } = useGetAllSocialPlataformsQuery();

  const dispatch = useDispatch();
  const [curTime, SetCurTime] = useState(null)

  useEffect(async () => {
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [searchTerm, currentPage, limit]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    setInterval(async function () {
      await refetch()
      return async () => {
        // Abort the ongoing request if the component unmounts or if the query is refetched
        await refetch.abort();
      };
    }.bind(this), 300000);
  }, [])

  useEffect(() => {
    setInterval(function () {
      SetCurTime(new Date().toLocaleString());
    }.bind(this), 1000);
  }, [])

  const onCopy = (text) => {
    message(text, 'success', 2)
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const handleReportOrder = (_id, orderId, nameplataform, logo) => {
    setReportOrder({ ...reportorder, open: true, _id: _id, orderId: orderId, nameplataform: nameplataform, plataformlogo: logo });
  }

  const onChangePhone = (newValue, info) => {
    setReportOrder({ ...reportorder, ['countrycode']: info.countryCode, ['phonenumber']: newValue });
  };

  const [sendReport, responseReport] = useCreateSocialReportMutation()

  const handleSubmitReportOrder = e => {
    e.preventDefault()
    sendReport({ _id: reportorder._id, message: reportorder.message, phonenumber: reportorder.phonenumber })
  }

  useEffect(() => {
    if (responseReport.isSuccess) {
      message(responseReport?.data?.message, 'success', 6)
      setReportOrder({
        ...reportorder,
        _id: "",
        open: false,
        message: "",
        orderId: "",
        nameplataform: "",
        plataformlogo: "",
        countrycode: "",
        phonenumber: "",
      })
    }
  }, [responseReport.isSuccess])

  useEffect(() => {
    if (responseReport.isError) {
      responseReport?.error?.data?.errors.map((err) => {
        message(err.msg, 'error', 4)
      });
    }
  }, [responseReport?.error?.data])

  const handleReadReport = (message, answer, created_at, read_at, orderId, nameplataform, logo) => {
    setCheckHistoryReport({ ...checkHistoryReport, open: true, message, answer, created_at, read_at, orderId, nameplataform, logo })
  }

  return (
    <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <UserScreenHeader>
        <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
          <IoBagCheck size={32} className="mr-2" /> Mis pedidos redes sociales<span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">{!loadingorders && orders?.totalorders}</span></div>
      </UserScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[106px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pt-8 pb-8">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe el nombre de la plataforma o número de orden"
            required
            onChange={debouncedChangeHandler}
            autoComplete="off"
          />
        </div>
      </div>
      <Modal
        keepMounted
        open={reportorder.open}
        onClose={() => setReportOrder({
          ...reportorder,
          _id: "",
          open: false,
          message: "",
          orderId: "",
          nameplataform: "",
          plataformlogo: "",
          countrycode: "",
          phonenumber: "",
        })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">

          <div className="realtive m-auto p-5 w-full md:w-5/12 bg-neutral-200 dark:bg-neutral-900">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => setReportOrder({
                  ...reportorder,
                  _id: "",
                  open: false,
                  message: "",
                  orderId: "",
                  nameplataform: "",
                  plataformlogo: "",
                  countrycode: "",
                  phonenumber: "",
                })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Reportar servicio</div>
            </div>
            <div className="grid grid-wrap w-full md:w-4/12 mt-5 mb-5">
              <div className="flex inline-flex items-center relative">
                <span className="ml-[0px] text-neutral-900 dark:text-neutral-200 
              text-base rounded-lg bg-neutral-50 dark:bg-neutral-950 p-2 flex inline-flex 
              items-center justify-center">{reportorder.orderId}</span>
                <LazyLoadImage
                  class="w-14 h-14 z-30 rounded-full flex inline-flex object-cover"
                  alt={`No image ${reportorder.nameplataform}`}
                  effect="blur"
                  src={`/images/logos/${reportorder.plataformlogo}`}
                />
                <div className="grid self-center">
                  <div className="ml-2 text-base font-semibold text-neutral-900 dark:text-neutral-200 capitalize">{reportorder.nameplataform}</div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmitReportOrder}>
              <div className="w-full p-3 mt-5">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Mensaje</label>
                <textarea name="message" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Describe el problema que estas presentando"
                  autoComplete="off"
                  required
                  value={reportorder.message}
                  rows={2}
                  onChange={(e) => setReportOrder({ ...reportorder, message: e.target.value })}
                  maxLength={100}
                />
              </div>

              <div className="w-full p-3">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Numero whatsapp</label>
                <MuiTelInput
                  className="bg-neutral-50 dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  helperText={'Ingresa tu numero de whatsapp, nos pondremos en contacto contigo, gracias.'}
                  required
                  inputProps={{ className: "text-neutral-900 dark:text-neutral-200" }}
                  value={reportorder.phonenumber}
                  defaultCountry={'PE'}
                  onChange={onChangePhone}
                />
              </div>

              <div class="relative mt-5">
                <button
                  type="submit"
                  class="flex w-full disabled:opacity-30 justify-center rounded-md bg-blue-600 px-3 py-5 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  disabled={responseReport.isLoading ? true : false}
                >
                  {responseReport.isLoading ? <Spinner /> : <MdReport size={24} className="mr-2" />}
                  {responseReport.isLoading ? 'Enviando...' : 'Reportar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        keepMounted
        open={checkHistoryReport.open}
        onClose={() => setCheckHistoryReport({
          ...checkHistoryReport,
          open: false,
          message: "",
          answer: "",
          created_at: "",
          read_at: "",
          orderId: "",
          nameplataform: "",
          logo: ""
        })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">

          <div className="realtive m-auto p-5 w-full md:w-5/12 bg-neutral-200 dark:bg-neutral-900">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => setCheckHistoryReport({
                  ...checkHistoryReport,
                  open: false,
                  message: "",
                  answer: "",
                  created_at: "",
                  read_at: "",
                  orderId: "",
                  nameplataform: "",
                  logo: ""
                })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Detalles reporte de servicio</div>
            </div>
            <div className="grid grid-wrap w-full md:w-7/12 mt-5 mb-5">
              <div className="flex inline-flex items-center relative">
                <span className="ml-[0px] text-neutral-900 dark:text-neutral-200 
              text-base rounded-lg bg-neutral-50 dark:bg-neutral-950 p-2 flex inline-flex 
              items-center justify-center">{checkHistoryReport.orderId}</span>
                <LazyLoadImage
                  class="w-14 h-14 z-30 rounded-full flex inline-flex object-cover"
                  alt={`No image ${checkHistoryReport.nameplataform}`}
                  effect="blur"
                  src={`/images/logos/${checkHistoryReport.logo}`}
                />
                <div className="grid self-center">
                  <div className="ml-2 text-base font-semibold text-neutral-900 dark:text-neutral-200 capitalize">{checkHistoryReport.nameplataform}</div>
                </div>
              </div>
              <div className="ml-[2px] text-sm text-red-600 dark:text-red-500 font-semibold">Se reportó el {moment(checkHistoryReport.created_at).format("LLLL")}</div>
              <div className="ml-[2px] text-sm text-blue-600 dark:text-blue-500 font-semibold">Se respondió el {moment(checkHistoryReport.read_at).format("LLLL")}</div>
            </div>
            <div className="w-full p-3 mt-5">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Mensaje</label>
              <textarea name="message" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Describe el problema que estas presentando"
                autoComplete="off"
                disabled
                readOnly
                value={checkHistoryReport.message}
                rows={2}
                maxrows={2}
                maxLength={100}
              />
            </div>

            <div className="w-full p-3 mt-5">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Respuesta</label>
              <textarea name="answer" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Escribe una respuesta para este reporte"
                autoComplete="off"
                disabled
                readOnly
                value={checkHistoryReport.answer}
                rows={2}
                maxrows={2}
                maxLength={100}
              />
            </div>
          </div>
        </div>
      </Modal>

      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[211px] pb-5 pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950">
        <div className="w-full flex items-center justify-center md:justify-end text-neutral-800 dark:text-neutral-200 mt-0 h-10">
          {
            !loadingorders ?
              <>
                <div className="hidden md:block">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!loadingorders && Number(orders?.totalordersFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                      sx={{
                        "& .MuiPaginationItem-page": { color: "#e5e5e5", fontSize: 15, border: "2px solid #1d4ed8", fontFamily: 'Jost, sans-serif' },
                        "& .MuiPaginationItem-icon": { color: '#334155' },
                        "& .Mui-selected": { backgroundColor: "#1d4ed8" },
                        "& .MuiPaginationItem-ellipsis": { color: '#e5e5e5', fontSize: 25 },
                      }}
                    />
                  </Stack>
                </div>
                <div className="block md:hidden">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!loadingorders && Number(orders?.totalordersFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                      sx={{
                        "& .MuiPaginationItem-page": { color: "#e5e5e5", fontSize: 15, border: "2px solid #1d4ed8", fontFamily: 'Jost, sans-serif' },
                        "& .MuiPaginationItem-icon": { color: '#334155' },
                        "& .Mui-selected": { backgroundColor: "#1d4ed8" },
                        "& .MuiPaginationItem-ellipsis": { color: '#e5e5e5', fontSize: 25 },
                      }}
                    />
                  </Stack>
                </div>
              </>
              : <Spinner />
          }

        </div>
      </div>

      <div className="w-full mt-[316px]">
        {
          !loadingorders ? orders?.orders.length > 0 ? (
            <div>
              {
                orders?.orders.map((order) => {
                  var sublogo = ""
                  var nameplataform = ""
                  var servicename = ""

                  !loadingplataforms && plataforms?.plataforms.find((plataform) => {
                    if (plataform._id === order.plataformId) {
                      sublogo = plataform.sublogo
                      nameplataform = plataform.name
                      plataform.subcategory.find((service) => {
                        if (service.serviceId === order.serviceId) {
                          servicename = service.name
                        }
                      })
                    }
                  })

                  return (
                    <div className="transition-all text-neutral-900 dark:text-neutral-200 duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer bg-white dark:bg-neutral-900 flex flex-wrap mb-12 shadow-xl rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer animation" key={order._id}>
                      <div className="flex flex-wrap items-center w-full md:w-3/12">
                        <div className="flex inline-flex items-center">
                          <span className="absolute -mt-40 -ml-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-neutral-900 pt-2 pl-2 pr-2 pb-2 flex inline-flex 
                          items-center justify-center"><div className='ml-2 text-xs'>#{order.orderId}</div> </span>
                          <LazyLoadImage
                            className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                            alt={`No image ${nameplataform}`}
                            effect="blur"
                            src={`/images/logos/${sublogo}`}
                          />
                        </div>
                        <div className="w-[calc(100%-56px)]">
                          <div className="ml-2 text-lg capitalize font-semibold">{nameplataform}</div>
                          <div className="ml-2 text-xs truncate ...">{servicename}</div>
                          <div className="ml-2 text-xs mt-2 truncate ...">Se creó hace {CalcularTiempoTranscurrido(order.created_at)}</div>
                        </div>
                      </div>

                      <div className="relative w-full md:w-1/12 text-xs mt-5 md:mt-0 flex items-center justify-center">
                        <div className="w-full grid self-center word">
                          <div className="md:hidden break-all ...">{order.link}</div>
                          <div className="flex items-center justify-center mt-2">
                            <a href={`${order.link}`} target="_blank" className=""><IoOpenOutline size={24} /></a>
                          </div>
                        </div>
                      </div>

                      <div className="relative md:flex grid grid-cols-3 md:grid-cols-1 items-center w-full md:w-[calc(66.666%-80px)] text-sm mt-10 md:mt-0">
                        <div className="md:w-2/12 w-full grid">
                          <div className="flex items-center justify-center">Cantidad</div>
                          <div className="flex items-center justify-center">{order.quantity}</div>
                        </div>
                        <div className="md:w-2/12 w-full grid">
                          <div className="flex items-center justify-center">Precio</div>
                          <div className="flex items-center justify-center">{currencyFormat(order.totalamount)}</div>
                        </div>
                        <div className="md:w-2/12 w-full grid">
                          <div className="flex items-center justify-center">Inicio/Final</div>
                          <div className="flex items-center justify-center">{order.start_count + ' / ' + (order.start_count + order.quantity)}</div>
                        </div>
                        <div className="md:w-2/12 w-full grid -mt-1 md:mt-0">
                          <div className="flex items-center justify-center">Proceso</div>
                          <div class="w-full bg-neutral-900 rounded-full h-4 dark:bg-neutral-200">
                            <div class={`${((100 / (order.quantity / (order.quantity - order.remains))) >= 0 && (100 / (order.quantity / (order.quantity - order.remains))) <= 25) && 'bg-gradient-to-r from-red-600 via-red-500 to-orange-600'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 25 && (100 / (order.quantity / (order.quantity - order.remains))) <= 50) && 'bg-gradient-to-r from-red-500 via-orange-600 to-orange-500'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 50 && (100 / (order.quantity / (order.quantity - order.remains))) <= 75) && 'bg-gradient-to-r from-orange-600 via-orange-500 to-green-600'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 75 && (100 / (order.quantity / (order.quantity - order.remains))) <= 99) && 'bg-gradient-to-r from-orange-500 via-green-600 to-green-500'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 99 && (100 / (order.quantity / (order.quantity - order.remains))) <= 100) && 'bg-gradient-to-r from-green-600 via-green-500 to-green-400'}
                            h-4 rounded-full`} style={{ width: `${100 / (order.quantity / (order.quantity - order.remains))}%` }}></div>
                            <span className="text-xs">{(100 / (order.quantity / (order.quantity - order.remains))).toFixed(2)}%</span>
                          </div>
                        </div>
                        <div className="md:w-2/12 grid">
                          <div className="flex items-center justify-center">Faltan</div>
                          <div className="flex items-center justify-center">{order.remains}</div>
                        </div>
                        <div className="md:w-2/12 mt-5 md:mt-0">
                          <div className="flex items-center justify-center">Estado</div>
                          <div className="flex items-enter justify-center">
                            {
                              (order.observations.length > 0 && !order.observations[0].read) ?
                                <div class="m-auto p-2 bg-yellow-500 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                  <span class="text-center rounded-full bg-yellow-400 bg-opacity-60 px-2 py-1 text-xs font-bold">En revisión</span>
                                </div>
                                :
                                <>
                                  {order.state === "Partial" &&
                                    <div class="m-auto p-2 bg-neutral-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                      <span class="text-center rounded-full bg-neutral-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Parcial</span>
                                    </div>
                                  }
                                  {order.state === "Completed" &&
                                    <div class="m-auto p-2 bg-green-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                      <span class="text-center rounded-full bg-green-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Completado</span>
                                    </div>
                                  }
                                  {order.state === "Processing" &&
                                    <div class="m-auto p-2 bg-orange-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                      <span class="text-center rounded-full bg-orange-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Procesando</span>
                                    </div>}
                                  {order.state === "Pending" &&
                                    <div class="m-auto p-2 bg-yellow-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                      <span class="text-center rounded-full bg-yellow-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Pendiente</span>
                                    </div>}
                                  {order.state === "Refunded" &&
                                    <div class="m-auto p-2 bg-cyan-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                      <span class="text-center rounded-full bg-cyan-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Reembolsado</span>
                                    </div>}
                                  {order.state === "Canceled" &&
                                    <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                      <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Cancelado</span>
                                    </div>}
                                </>

                            }
                          </div>
                          <div className='text-xs'>
                            <div className='w-full text-center'>
                              {order.state === "Completed" && 'se completó'}
                              {(order.state === "Processing" || order.state === "Pending" || order.state === "Partial") && 'última actualización'}
                              {order.state === "Refunded" && 'se reembolsó'}
                              {order.state === "Canceled" && 'se canceló'}
                            </div>
                            <div className='w-full text-center'>{moment(order.last_update).format("DD [de] MMMM [de] YYYY [a las] HH:mm a.")}</div>
                          </div>
                        </div>
                      </div>

                      <div className="relative  items-center w-full md:w-[80px] text-sm mt-3 md:mt-0 hidden md:flex">
                        <Tooltip arrow title="Reportar">
                          <IconButton disabled={(order.observations.length > 0 || order.state === "Completed" || order.state === "Refunded" || order.state === "Canceled") ? true : false} onClick={() => handleReportOrder(order._id, order.orderId, nameplataform, sublogo)} color="primary" className="w-[45px]">
                            <MdReport size={32} className={`${(order.observations.length > 0 || order.state === "Completed" || order.state === "Refunded" || order.state === "Canceled") ? 'text-neutral-400 dark:text-neutral-700' : 'text-red-600'}`} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Ver reporte">
                          <IconButton onClick={() => handleReadReport(order.observations[0].message, order.observations[0].answer, order.observations[0].created_at, order.observations[0].read_at, order.orderId, nameplataform, sublogo)}
                            disabled={(order.observations.length > 0 && order.observations[0].read) ? false : true} color="primary" className="w-[45px]">
                            <IoChatboxEllipsesSharp size={32} className={`${(order.observations.length > 0 && order.observations[0].read) ? 'text-yellow-500 dark:text-yellow-300' : 'text-neutral-400 dark:text-neutral-700'}`} />
                          </IconButton>
                        </Tooltip>
                      </div>

                      <div className="relative flex items-center w-full md:w-[80px] text-sm mt-5 mb-3 md:mt-0 md:hidden">
                        <button disabled={(order.observations.length > 0 || order.state === "Completed" || order.state === "Refunded" || order.state === "Canceled") ? true : false} onClick={() => handleReportOrder(order._id, order.orderId, nameplataform, sublogo)} color="primary" class="flex items-center disabled:opacity-20 justify-center rounded-md bg-red-600 px-2 py-2 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                          <MdReport size={22} className={`${(order.observations.length > 0 || order.state === "Completed" || order.state === "Refunded" || order.state === "Canceled") ? 'opacity-20 text-white mr-1' : 'text-white mr-1'}`} /> Reportar
                        </button>

                        <button onClick={() => handleReadReport(order.observations[0].message, order.observations[0].answer, order.observations[0].created_at, order.observations[0].read_at, order.orderId, nameplataform, sublogo)}
                          disabled={(order.observations.length > 0 && order.observations[0].read) ? false : true} color="primary" class="flex items-center ml-2 disabled:opacity-20 justify-center rounded-md bg-yellow-600 px-2 py-2 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
                          <IoChatboxEllipsesSharp size={22} className={`${(order.observations.length > 0 && order.observations[0].read) ? 'text-white mr-1' : 'opacity-20 text-white mr-1'}`} /> Ver reporte
                        </button>
                      </div>
                    </div>
                  )
                })
              }
            </div>) : (<div className="alert-danger font-semibold mt-[316px]">Sin resultados en la búsqueda! o no existen pedidos registrados</div>) : (<Spinner />)
        }

      </div>

    </AccountList >
  );
};

export default UserSocialOrders;
