import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const whatsappService = createApi({
  reducerPath: "whatsapps",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      headers.set('authorization', userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      sendMessageWhatsappNewService: builder.mutation({
        query: (data) => {
          return {
            url: "/send-whatsapp",
            method: "POST",
            body: data,
          };
        },
      }),
    };
  },
});
export const {
  useSendMessageWhatsappNewServiceMutation,
} = whatsappService;
export default whatsappService;
