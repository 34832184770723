import AccountList from "../../components/home/AccountList";
import { Toaster } from "react-hot-toast";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { IoAlarm, IoBagCheck, IoBan, IoCheckbox, IoCloseCircleOutline, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoPerson, IoRefreshSharp, IoReload, IoReloadCircleSharp, IoSearch, IoThumbsDownSharp, IoTime } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import { CalcularExpiracion, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdAvTimer, MdDevices, MdEventNote, MdNearbyError, MdNote, MdNoteAlt, MdPhoneIphone, MdUpdate, MdVerified } from "react-icons/md";
import { Button, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { FaFrown, FaHistory, FaShippingFast } from "react-icons/fa";
import { useRenewServiceMutation } from "../../store/services/ventasService";
import { setUserToken } from "../../store/reducers/authReducer";
import { useDispatch } from "react-redux";
import { useGetPaymentsByUserIdQuery } from "../../store/services/paymentService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PaymentsHistory = ({
  socket,
  userToken,
  user,
  loadinguserbyid,
  userById,
  configuration,
  loadingconfiguration
}) => {

  const { data: paymentsbyId, isFetching: loadingpaymentsbyId, refetch } = useGetPaymentsByUserIdQuery(userToken && user?.id);
  const dispatch = useDispatch();
  const [searchPayment, SetSearchPayment] = useState("")

  useEffect(async () => {
    window.scrollTo(0, 0);
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [])

  const HandleFilterPayments = !loadingpaymentsbyId && paymentsbyId?.payments.filter(_c => {
    return _c.method.toLowerCase().includes(searchPayment.toLowerCase()) || _c.payment_id.toLowerCase().includes(searchPayment.toLowerCase())
  })

  return (
    <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <UserScreenHeader>
        <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
          <FaHistory size={32} className="mr-2" /> Historial de recargas <span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">{!loadingpaymentsbyId && paymentsbyId?.payments.length}</span></div>
      </UserScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[106px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pt-8 pb-8">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe el nombre de plataforma, correo de cuenta o código de pedido"
            required
            value={searchPayment}
            onChange={(e) => SetSearchPayment(e.target.value)}
            autoComplete="off"
          />
        </div>

      </div>
      <div className="w-full mt-[240px]">

        {
          !loadingpaymentsbyId ? HandleFilterPayments.length > 0 ? (
            <div>
              {
                HandleFilterPayments?.map((payment) => {

                  return (
                    <div className={`transition-all duration-500 hover:scale-x-[101%] hover:contrast-[110%] bg-neutral-200 dark:bg-neutral-900 flex flex-wrap mb-10 shadow-xl rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer animation`} key={payment._id}>
                      <div className="grid grid-wrap w-full md:w-5/12">
                        <div className="flex inline-flex items-center">
                          <span className="absolute -mt-24 -ml-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-lg bg-neutral-200 dark:bg-neutral-900 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center">{payment.payment_id}</span>
                          <LazyLoadImage
                            className="w-14 h-14 z-10 rounded-full flex inline-flex object-cover"
                            alt={`No image ${payment.method}`}
                            effect="blur"
                            src={`/images/paymethods/${payment.method}.webp`}
                          />
                          <div className="grid self-center">
                            <div className="ml-2 text-xl font-semibold capitalize">{payment.method}</div>
                            <div className={`ml-2 text-base font-semibold ${payment.status === 'complete' ? 'text-neutral-400' : payment.status === 'pending' ? 'text-yellow-400' : 'text-red-400'}`}>{currencyFormat(payment.total_recharge)}</div>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-wrap w-full md:w-4/12 mt-3 md:mt-0">
                        <div className="flex inline-flex items-center text-sm"><IoTime size={28} className="mr-2" /> {setUnixToTimeFormat("info_contacto", payment.created_at)}</div>
                      </div>

                      <div className="flex items-center w-full md:w-3/12">

                        <div className="flex items-center justify-start w-full md:w-6/12">
                          <div className="w-[35px] h-[35px] flex items-center"><MdEventNote size={28} className="mr-2 text-yellow-500" /></div>
                          <div className="text-sm">
                            {payment.status === "complete" && <span className="text-neutral-800 dark:text-neutral-200">{payment.observations}</span>}
                            {payment.status === "pending" && <span className="text-yellow-500">{payment.observations}</span>}
                            {payment.status === "rejected" && <span className="text-red-500">{payment.observations}</span>}
                          </div>
                        </div>

                        <div className="flex items-center justify-end mr-2 w-full md:w-6/12 ">
                          {payment.status === "complete" && <div className="flex inline-flex items-center text-green-500 text-sm">
                            <MdVerified size={28} className="mr-2" /> Completado</div>}
                          {payment.status === "pending" && <div className="flex inline-flex items-center text-yellow-500 text-sm">
                            <FaShippingFast size={28} className="mr-2" />Pendiente</div>}
                          {payment.status === "rejected" && <div className="flex inline-flex items-center text-red-500 text-sm">
                            <FaFrown size={28} className="mr-2" />Rechazado</div>}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>) : (<div className="alert-danger font-semibold mt-[240px]">Sin resultados en la búsqueda! o no existen recargas registrados</div>) : (<Spinner w={'35px'} h={'35px'} />)
        }

      </div>

    </AccountList >
  );
};

export default PaymentsHistory;
