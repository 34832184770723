import { useEffect, useMemo, useState } from "react"
import ScreenHeader from "../../components/ScreenHeader";
import { Toaster } from 'react-hot-toast';
import { useDeleteCategoryMutation, useDeleteSubCategoryMutation, useGetAllCategoriesGiftCardQuery, useUpdateCategoryMutation, useUpdateGcCategoryLogoMutation } from "../../store/services/categoryService";
import Spinner from "../../components/Spinner";
import debounce from 'lodash.debounce';
import { MdCategory, MdDelete, MdDevices, MdEdit, MdPhoneIphone } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { IoChevronForward, IoCloseSharp, IoEye, IoPodium, IoSave, IoSearch, IoServer, IoTime } from "react-icons/io5";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { FaCertificate, FaCogs, FaDollarSign, FaEdit, FaShippingFast, FaUserTie } from "react-icons/fa";
import { SiNano } from "react-icons/si";
import UpdateCategory from "./UpdateSubCategorySTR";
import Wrapper from "./Wrapper";
import { FcEditImage, FcImageFile, FcPlus, FcRating } from "react-icons/fc";
import UpdateSubCategoryGC from "./UpdateSubCategoryGC";
import { Link } from "react-router-dom";
import SpinnerData from "../../components/SpinnerData";
import Modal from '@mui/material/Modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useGetAllCuentasQuery } from "../../store/services/cuentaService";
import ImagesPreviewGiftCard from "../../components/home/ImagesPreviewGiftCard";
import ImagesPreview from "../../components/ImagesPreview";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector, useDispatch } from "react-redux";
import { deleteCategory, deleteSubCategory, updateCategory, SET_TO_DEFAULT_RESPONSE } from "../../store/features/categorySlice";
import { HexColorPicker } from "react-colorful";

const CategoriesGC = ({
   socket,
   configuration,
   loadingconfiguration,
   categories,
   categoryIsLoading
}) => {
   const dispatch = useDispatch();
   const {
      responseSuccessDeleteCategory,
      responseSuccessUpdateCategory,
      responseSuccessDeleteSubCategory,
      isLoading,
      isSuccess,
      isError
   } = useSelector((state) => state.categoryReducer);

   const [removeCategory, response] = useDeleteCategoryMutation();
   const [removeSubCategory, responseDeleteSubCategory] = useDeleteSubCategoryMutation();
   const { data: categoryGC, isFetching: loadingcategoryGC } = useGetAllCategoriesGiftCardQuery();
   const { data: cuentas, isFetching: loadingcuentas } = useGetAllCuentasQuery();
   const [searchCategories, SetSearchCategories] = useState("")
   const [openeditcategoryGC, SetOpenEditCategoryGC] = useState({ open: false, categoryId: "", categoryGCData: null })
   const [openVerSubcategorias, SetOpenVerSubcategorias] = useState({ open: false, name: "", categoryId: "", subcategorydata: null })
   const [updateCategoryState, setUpdateCategory] = useState({ _id: "", name: "", themecolor: "", description: "", open: false, currentlogo: "", currentsublogo: "", logo: "", sublogo: "" });
   const [color, setColor] = useState('#ffffff');
   const [preview, setPreview] = useState({
      logo: '',
      sublogo: ''
   })

   const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
   );

   var htmlData = useMemo(
      () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
      [editorState]
   );

   const imageHandle = e => {
      if (e.target.files.length !== 0) {
         setUpdateCategory({ ...updateCategoryState, [e.target.name]: e.target.files[0] });
         const reader = new FileReader();
         reader.onloadend = () => {
            setPreview({ ...preview, [e.target.name]: reader.result })
         }
         reader.readAsDataURL(e.target.files[0]);
      }
   }

   const ondeleteCategory = id => {
      var CategoryIsUsed = false

      !loadingcuentas && cuentas?.cuentas.find((cuenta) => {
         if (cuenta.categoryId === id) {
            CategoryIsUsed = true
         }
      })

      if (CategoryIsUsed) {
         message('Esta categoría ya está en uso y no se puede eliminar', 'error', 4)
      } else {
         alertaConfirmar('Eliminar Categoría', 'Estas seguro que quieres eliminar esta categoría? recuerda que se eliminarán todos los datos relacionados a esta categoría.', 'question', async (res_alert) => {
            if (res_alert === true) {
               await dispatch(deleteCategory(id));
            }
         })
      }
   }

   useEffect(async () => {
      if (isSuccess) {
         message(responseSuccessUpdateCategory.message, 'success', 5)
         onCloseupdateCategory()
      }
      await dispatch(SET_TO_DEFAULT_RESPONSE())
   }, [responseSuccessUpdateCategory])

   useEffect(async () => {
      if (isSuccess) {
         message(responseSuccessDeleteCategory.message, 'success', 5)
      }
      await dispatch(SET_TO_DEFAULT_RESPONSE())
   }, [responseSuccessDeleteCategory])

   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const HandleFilterCategories = !categoryIsLoading && categories.filter(_c => {
      return _c.name.toLowerCase().includes(searchCategories.toLowerCase()) && _c.type === 'GiftCard'
   })

   const HandleOpenEditCategoryGC = (categoryId, data) => {
      SetOpenEditCategoryGC({ ...openeditcategoryGC, open: true, categoryId: categoryId, categoryGCData: data });
   }

   const HandleCloseEditCategoryGC = () => {
      SetOpenEditCategoryGC({ ...openeditcategoryGC, open: false, categoryId: "", categoryGCData: null });
   }

   const HandleupdateCategory = async () => {
      const contentState = editorState.getCurrentContent();
      updateCategoryState.themecolor = color
      if (contentState.hasText()) {
         if (updateCategoryState.name.trim().length < 1) {
            message('nombre de categoría es requerido', 'error', 4)
         } else {
            const formData = new FormData();
            formData.append('data', JSON.stringify(updateCategoryState));
            formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
            formData.append('image1', updateCategoryState.logo)
            formData.append('image2', updateCategoryState.sublogo)
            await dispatch(updateCategory(formData));
         }
      } else {
         message('Descripción y modo de uso es requerido', 'error', 4)
      }
   }

   const onOpenUpdateCategory = (description, name, themecolor, categoryId, currentlogo, currentsublogo) => {
      setColor(themecolor)
      setUpdateCategory({ ...updateCategoryState, description: description, name: name, _id: categoryId, open: !updateCategoryState.open, currentlogo: currentlogo, currentsublogo: currentsublogo })
      const rawContent = description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
   }

   const onCloseupdateCategory = () => {
      setUpdateCategory({ ...updateCategoryState, _id: "", name: "", open: !updateCategoryState.open, description: "", currentlogo: "", currentsublogo: "", logo: "", sublogo: "" })
      setPreview({ ...preview, logo: "", sublogo: "" })
   }

   const handleDeleteSubCategory = (categoryId, subcategoryId) => {
      alertaConfirmar('Eliminar Subcategoría', 'Estas seguro que quieres eliminar esta subcategoría? recuerda que se eliminarán todos los datos relacionados a esta subcategoría.', 'question', async (res_alert) => {
         if (res_alert === true) {
            await dispatch(deleteSubCategory({ categoryId: categoryId, subcategoryId: subcategoryId }))
         }
      })
   }

   useEffect(async () => {
      if (isSuccess) {
         message(responseSuccessDeleteSubCategory.message, 'success', 5)
         SetOpenVerSubcategorias({ ...openVerSubcategorias, subcategorydata: responseSuccessDeleteSubCategory.newStateData.newData })
      }
      await dispatch(SET_TO_DEFAULT_RESPONSE())
   }, [responseSuccessDeleteSubCategory])

   const onOpenVerSubcategorias = (categoryId, name, data) => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, open: !openVerSubcategorias.open, categoryId: categoryId, name: name, subcategorydata: data })
   }

   const onCloseVerSubcategorias = () => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, open: !openVerSubcategorias.open, categoryId: "", name: "", subcategorydata: "" })
   }

   const onChangeColor = (event) => {
      setColor(event)
   };

   const debouncecolor = useMemo(() => debounce(onChangeColor, 1000), []);

   return (
      <Wrapper socket={socket} configuration={configuration}
         loadingconfiguration={loadingconfiguration}>
         <Modal
            open={openeditcategoryGC.open}
            onClose={HandleCloseEditCategoryGC}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-8/12 bg-white dark:bg-black">
                  <UpdateSubCategoryGC
                     socket={socket}
                     categoryId={openeditcategoryGC.categoryId}
                     categoryGC={openeditcategoryGC.categoryGCData}
                     HandleCloseEditCategoryGC={HandleCloseEditCategoryGC}
                     newdata={(data) => SetOpenVerSubcategorias({ ...openVerSubcategorias, subcategorydata: data })}
                  />
               </div>
            </div>
         </Modal>
         <Toaster position="top-right" reverseOrder={true} />
         <ScreenHeader>
            <div className="w-full h-14 text-lg md:text-2xl flex items-center">
               <div className="flex items-center w-[calc(100%-64px)]">
                  <IoPodium size={32} style={{ marginRight: 5 }} />
                  CATEGORIAS GIFTCARD
                  <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
                     {!categoryIsLoading && categories.filter(fil => fil.type === 'GiftCard').length}
                  </div>
               </div>
               <div className="flex items-center w-[64px]">
                  <Link to="/admin/create-category" className="w-full flex justify-end">
                     <Tooltip title="Agregar categoría" arrow={true}>
                        <div className="w-16 h-16 rounded-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-900">
                           <IconButton color="primary" className="w-16 h-16">
                              <FcPlus size={45} />
                           </IconButton>
                        </div>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </ScreenHeader>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
            <div className="relative">
               <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
               </div>
               <input autoFocus type="search" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Buscar Categorías"
                  value={searchCategories}
                  onChange={(e) => SetSearchCategories(e.target.value)}
                  autoComplete="off"
               />
            </div>
         </div>

         <Modal
            open={updateCategoryState.open}
            onClose={onCloseupdateCategory}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-6/12 bg-white dark:bg-black">
                  <div className="flex items-center">
                     <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                        <IconButton color="error" onClick={onCloseupdateCategory}>
                           <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                           />
                        </IconButton>
                     </div>
                     <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Editar categoria GiftCard</div>
                  </div>

                  <div className="flex flex-wrap h-auto">
                     <div className="w-full flex flex-wrap p-3">
                        <div className="w-full mb-5 mt-5">
                           <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la categoría</label>
                           <input autoFocus name="name" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                              placeholder="Nombre de la categoría"
                              autoComplete="off"
                              required
                              value={updateCategoryState.name}
                              type="text"
                              onChange={(e) => setUpdateCategory({ ...updateCategoryState, name: e.target.value })}
                           />
                        </div>

                        <div class="w-full md:w-6/12">

                           <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 dark:hover:bg-neutral-700">
                              <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                 <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                 <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                 <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                              </div>
                              <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                           </label>

                        </div>
                        <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                           <div className="">
                              <LazyLoadImage
                                 className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                                 alt={`No image`}
                                 effect="blur"
                                 src={`/images/logos/${updateCategoryState.currentlogo}`}
                              />
                           </div>
                           {preview.logo && <IoChevronForward className="text-neutral-300" size={32} />}
                           <ImagesPreview url={preview.logo} />
                        </div>
                     </div>

                     <div className="w-full flex flex-wrap p-3">
                        <div class="w-full md:w-6/12">

                           <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 dark:hover:bg-neutral-700">
                              <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                 <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                 <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                                 <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                              </div>
                              <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                           </label>

                        </div>
                        <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                           <div className="">
                              <LazyLoadImage
                                 className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                                 alt={`No image`}
                                 effect="blur"
                                 src={`/images/logos/${updateCategoryState.currentsublogo}`}
                              />
                           </div>
                           {preview.sublogo && <IoChevronForward className="text-neutral-300" size={32} />}
                           <ImagesPreview url={preview.sublogo} />
                        </div>
                     </div>

                     <div className="w-[125px] p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Color tema</label>
                        <HexColorPicker color={color} onChange={debouncecolor} />
                     </div>

                     <div className="w-full p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descripción y modo de uso</label>
                        <Editor
                           editorState={editorState}
                           onEditorStateChange={setEditorState}
                           placeholder="Escribe aquí..."
                        />
                     </div>
                     
                     <div className="w-full mt-10">
                        <Button
                           onClick={HandleupdateCategory}
                           sx={{
                              "&.Mui-disabled": {
                                 background: "#525252",
                                 color: "#a3a3a3"
                              }
                           }}
                           disabled={isLoading ? true : false}
                           type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                           size='large' variant="contained" color="primary">
                           {isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                           {isLoading ? 'Guardando...' : 'ACTUALIZAR CATEGORIA'}
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>



         <Modal
            open={openVerSubcategorias.open}
            onClose={onCloseVerSubcategorias}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-8/12 bg-white dark:bg-black">
                  <div className="flex items-center">
                     <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                        <IconButton color="error" onClick={onCloseVerSubcategorias}>
                           <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                           />
                        </IconButton>
                     </div>
                     <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">SubCategorias de {openVerSubcategorias.name}</div>
                  </div>

                  <div className="flex flex-wrap h-auto mt-10">
                     {
                        openVerSubcategorias.subcategorydata && openVerSubcategorias.subcategorydata.length > 0 ? [...Object.freeze(openVerSubcategorias.subcategorydata)].sort((a, b) => a.created_at - b.created_at).map((cat, index) => {
                           return (
                              <div key={index} className="relative p-3 w-full shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer md:w-12/12 bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-wrap mb-10 rounded-lg animation">
                                 <div className="absolute -top-4 left-0">
                                    <LazyLoadImage
                                       className="object-cover w-8 h-8 z-10 rounded-full flex inline-flex"
                                       alt={`No image ${cat.name}`}
                                       effect="blur"
                                       src={`/images/locations/${cat.location}.svg`}
                                    />
                                 </div>
                                 <div className={`flex items-center absolute -top-3 left-10 ${cat.stock > 0 ? 'text-green-400' : 'text-red-400'}`}>
                                    <IoServer size={24} className="mr-1" /> {cat.stock}
                                 </div>
                                 <div className="flex items-center w-full md:w-[calc(43%-112px)] relative">
                                    <div className="flex items-center">
                                       <LazyLoadImage
                                          className="object-cover w-10 h-16 z-10 flex inline-flex"
                                          alt={`No image ${cat.name}`}
                                          effect="blur"
                                          src={`/images/logos/${cat.logo}`}
                                       />

                                    </div>
                                    <div className="w-[calc(100%-48px)]">
                                       <div className="ml-2 text-base font-bold text-neutral-900 dark:text-neutral-200 truncate ...">{cat.name}</div>
                                       <div className="ml-2 text-neutral-900 dark:text-neutral-200 text-xs mt-2 truncate ...">{setUnixToTimeFormat('info_contacto', cat.created_at)}</div>
                                    </div>

                                 </div>

                                 <div className="md:w-[18.7%] w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200">Costo usuario</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm text-green-600 dark:text-green-500 font-bold">
                                          {
                                             cat.discountuser > 0 ?
                                                <div><span className="line-through text-red-500 mr-2">
                                                   {currencyFormat(cat.costouser)}
                                                </span>{currencyFormat(cat.costouser - ((cat.costouser * cat.discountuser) / 100))}</div>
                                                :
                                                currencyFormat(cat.costouser)
                                          }
                                       </div>
                                    </div>
                                 </div>

                                 <div className="md:w-[18.7%] w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200">Costo reseller</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm text-yellow-600 dark:text-yellow-500 font-bold">
                                          {currencyFormat(cat.costoreseller)}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="md:w-[18.7%] w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200">Tipo de entrega</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm">
                                          {cat.tipoentrega ? <><span className="text-cyan-500 flex items-center"><FaShippingFast size={24} className="mr-1" />Automática</span></>
                                             :
                                             <><span className="text-neutral-500 flex items-center"><FaCogs size={24} className="mr-1" />Manual</span></>}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="w-[118px] flex items-center justify-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                                    <div className="w-[56px] h-[56px] rounded-full">
                                       <Tooltip title="editar" arrow={true}>
                                          <IconButton onClick={() => HandleOpenEditCategoryGC(openVerSubcategorias.categoryId, cat)} className="w-14 h-14" color='primary'>
                                             <MdEdit
                                                className="text-yellow-500 m-auto"
                                                size={25}
                                             />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                    <div className="w-[56px] h-[56px] rounded-full ml-2">
                                       <Tooltip title="eliminar" arrow={true}>
                                          <IconButton onClick={() => handleDeleteSubCategory(openVerSubcategorias.categoryId, cat._id)} className="w-14 h-14" color="primary">
                                             <MdDelete size={25}
                                                className={`text-red-600 m-auto`}
                                             />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 </div>
                              </div>)
                        }) :
                           <div class="flex items-center m-auto p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-neutral-800 dark:text-yellow-500" role="alert">
                              <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                              </svg>
                              <span class="sr-only">Info</span>
                              <div>
                                 <span class="font-bold uppercase">Sin datos!</span> La categoría <b>{openVerSubcategorias.name}</b> aún no tiene datos registrados!
                              </div>
                           </div>
                     }
                  </div>
               </div>
            </div>
         </Modal>

         {!loadingcategoryGC ? HandleFilterCategories.length > 0 ? <>
            <div className="flex flex-wrap mt-[175px]">


               {HandleFilterCategories.map((category) => {
                  var CategoryIsUsed = false
                  !loadingcuentas && cuentas?.cuentas.find((____c) => {
                     if (____c.categoryId === category._id) {
                        CategoryIsUsed = true
                     }
                  })

                  return (
                     <div className={`p-4 w-full shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer md:w-12/12 bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-wrap mb-10 rounded-lg animation`} key={category._id}>

                        <div className="flex flex-wrap items-center w-full md:w-4/12 relative h-24 md:h-auto">
                           <div className="absolute left-0 md:-top-[7px] top-0">

                              <LazyLoadImage
                                 className="object-cover w-16 h-24 z-10 rounded-sm flex inline-flex"
                                 alt={`No image ${category.name}`}
                                 effect="blur"
                                 src={`/images/logos/${category.logo}`}
                              />
                           </div>
                           <div className="flex flex-wrap items-center ml-16 w-[calc(100%-48px)]">
                              <div className="flex inline-flex items-center ml-2">
                                 <LazyLoadImage
                                    className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex"
                                    alt={`No image ${category.name}`}
                                    effect="blur"
                                    src={`/images/logos/${category.sublogo}`}
                                 />
                              </div>
                              <div className="w-[calc(100%-56px)]">
                                 <div className="ml-2 text-base text-neutral-900 dark:text-neutral-200 font-bold uppercase">{category.name}</div>
                                 <div className="ml-2 text-xs mt-2 truncate ...">{setUnixToTimeFormat('info_contacto', category.created_at)}</div>
                              </div>
                           </div>

                        </div>

                        <div className="md:w-2/12 w-full grid mt-8 md:mt-0">
                           <div className="flex items-center justify-center font-bold">Color tema</div>
                           <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center text-2xl font-bold">
                                 <div className="w-8 h-8 rounded-full border-4 border-neutral-400 dark:border-neutral-600" style={{ background: `${category.themecolor}` }}>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="md:w-2/12 w-full grid mt-3 md:mt-0">
                           <div className="flex items-center justify-center font-bold">Subcategorias</div>
                           <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center text-2xl font-bold">
                                 {category.subcategory.length}
                              </div>
                           </div>
                        </div>

                        <div className="flex inline-flex items-center justify-end w-full md:w-4/12">
                           <div className="flex items-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg mt-3 md:mt-0">
                              <div className="w-14 h-14 rounded-full">
                                 <Tooltip title="ver subcategorias" arrow={true}>
                                    <IconButton onClick={() => onOpenVerSubcategorias(category._id, category.name, category.subcategory)} className="w-14 h-14" color="primary" disabled={CategoryIsUsed ? true : false}>
                                       <IoEye size={24} className="cursor-pointer text-neutral-900 dark:text-neutral-100" />
                                    </IconButton>
                                 </Tooltip>
                              </div>

                              <div className="w-14 h-14 rounded-full">
                                 <Tooltip title="editar" arrow={true}>
                                    <IconButton onClick={() => onOpenUpdateCategory(category.description, category.name, category.themecolor, category._id, category.logo, category.sublogo)} className="w-14 h-14" color="primary" disabled={CategoryIsUsed ? true : false}>
                                       <FaEdit size={24} className="cursor-pointer text-yellow-500" />
                                    </IconButton>
                                 </Tooltip>
                              </div>

                              <div className="w-14 h-14 rounded-full">
                                 <Tooltip title="eliminar" arrow={true}>
                                    <IconButton onClick={() => ondeleteCategory(category._id)} className="w-14 h-14" color="primary" disabled={CategoryIsUsed ? true : false}>
                                       <MdDelete size={25}
                                          className={`${CategoryIsUsed ? 'text-neutral-600' : 'text-red-600'} m-auto`}
                                       />
                                    </IconButton>
                                 </Tooltip>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               }
               )}
            </div></> : <div className="alert-danger font-semibold mt-[175px]">Sin resultados en la búsqueda! o no existen categorías registradas</div> : <div className="mt-[175px]"><SpinnerData>Cargando categorías giftcard, por favor espere...</SpinnerData></div>}
      </Wrapper>
   )
}
export default CategoriesGC;