import { useEffect, useState } from "react"
import Sidebar from "./Sidebar";
import Nav from "./Nav";

const AccountList = ({ children, userToken, user, socket, loadinguserbyid, userById, configuration, loadingconfiguration}) => {
  const [side, setSide] = useState('-translate-x-full')

  const openCloseSidebar = () => {
    if (side === "-translate-x-full") {
      setSide("")
    } else {
      setSide("-translate-x-full");
    }
  }
  
  const closeSidebar = () => {
    setSide('-translate-x-full');
  }

  return (
    <>
      <Sidebar side={side} closeSidebar={closeSidebar}/>
      <Nav openCloseSidebar={openCloseSidebar} userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
    loadingconfiguration={loadingconfiguration} />
      <section className="ml-0 sm:ml-64 bg-neutral-50 dark:bg-neutral-950 min-h-screen mt-[128px] overflow-hidden">
        <div className="bg-neutral-50 dark:bg-neutral-950 text-neutral-900 dark:text-neutral-200 px-4 py-6">
          {children}
        </div>
      </section>

    </>
  )
}
export default AccountList;