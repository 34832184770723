import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Toaster } from 'react-hot-toast';
import ScreenHeader from "../../components/ScreenHeader"
import Wrapper from "./Wrapper"
import { useCCuentaMutation } from "../../store/services/cuentaService";
import Spinner from "../../components/Spinner"
import { IoArrowDown, IoChevronDown, IoEye, IoEyeOff, IoPerson, IoSave } from 'react-icons/io5';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { BsArrowDown, BsInfo, BsPlusCircleDotted } from "react-icons/bs";
import { message, PermiteSoloNumeros } from "../../utils/Funciones.js";
import moment from 'moment';
import { useGetAllCategoriesStreamingQuery } from "../../store/services/categoryService";
import Swal from "sweetalert2";
import { FaArrowDown } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
const { v4: uuidv4 } = require("uuid");

const CreateCuenta = ({
    SECRET_KEY,
    socket,
    configuration,
    loadingconfiguration
}) => {

    const { data: category, isFetching: loadingcategory } = useGetAllCategoriesStreamingQuery();

    const [state, setState] = useState({
        correo: '',
        passwordcorreo: '',
        passwordcuenta: '',
        categoryId: '',
        subcategoryId: '',
        expirationdate: '',
        observations: '',
        servicetype: null,
        profilesinfo: [],
        renovable: false
    });

    const [VerPasswords, SetVerPasswords] = useState(
        {
            passwordcorreo: false,
            passwordcuenta: false,
            passwordp1: false,
            passwordp2: false,
            passwordp3: false,
            passwordp4: false,
            passwordp5: false,
            passwordp6: false,
            passwordp7: false,
            passwordp8: false,
            passwordp9: false,
            passwordp10: false,
        });

    const alertaConfirmar = (titulo, texto, icon, callback) => {
        Swal.fire({
            title: titulo,
            html: texto,
            icon: icon,
            showDenyButton: true,
            confirmButtonText: 'Si, continuar',
            denyButtonText: 'No, ir a cuentas',
            confirmButtonColor: '#2C3E50',
        }).then((result) => {
            if (result.isConfirmed) {
                callback(result.isConfirmed)
            } else if (result.isDenied) {
                callback(false)
            }
        })
    }

    const handleInput = e => {

        if (e.target.name === 'categoryId') {
            setState({
                ...state,
                [e.target.name]: e.target.value,
                ['subcategoryId']: "",
                ['profilesinfo']: []
            })
        } else if (e.target.name === "subcategoryId") {
            var val = false
            var maximoperfiles = 0
            !loadingcategory && category?.categories.filter(fil => fil._id === state.categoryId)[0].subcategory.find((cat) => {
                if (cat._id === e.target.value) {
                    val = cat.isprofileblocked
                    maximoperfiles = cat.maximoperfiles
                }
            })
            const datas = []
            if (val) {
                if (state.servicetype) {
                    var perfiles = {
                        _id: uuidv4(),
                        name: "Cliente",
                        password: "",
                        isused: false,
                        byuserid: ""
                    }
                    datas.push(perfiles)
                    setState({
                        ...state,
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                } else {
                    for (var i = 1; i <= maximoperfiles; i++) {
                        var AleatoryPasswords = Math.floor((Math.random() * (9999 - 1001 + 1)) + 1001)

                        var perfiles = {
                            _id: uuidv4(),
                            name: "Cliente " + i,
                            password: AleatoryPasswords,
                            isused: false,
                            byuserid: ""
                        }
                        datas.push(perfiles)
                    }
                    setState({
                        ...state,
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                }
            } else {
                setState({
                    ...state,
                    [e.target.name]: e.target.value,
                    ['profilesinfo']: []
                })
            }
        } else if (e.target.name === "servicetype" && state.categoryId !== "" && state.subcategoryId !== "") {
            var val = false
            var maximoperfiles = 0
            !loadingcategory && category?.categories.filter(fil => fil._id === state.categoryId)[0].subcategory.find((cat) => {
                if (cat._id === state.categoryId) {
                    val = cat.isprofileblocked
                    maximoperfiles = cat.maximoperfiles
                }
            })
            const datas = []
            if (val) {
                if (e.target.value) {
                    var perfiles = {
                        _id: uuidv4(),
                        name: "Cliente",
                        password: "",
                        isused: false,
                        byuserid: ""
                    }
                    datas.push(perfiles)
                    setState({
                        ...state,
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                } else {
                    for (var i = 1; i <= maximoperfiles; i++) {
                        var AleatoryPasswords = Math.floor((Math.random() * (9999 - 1001 + 1)) + 1001)

                        var perfiles = {
                            _id: uuidv4(),
                            name: "Cliente " + i,
                            password: AleatoryPasswords,
                            isused: false,
                            byuserid: ""
                        }
                        datas.push(perfiles)
                    }
                    setState({
                        ...state,
                        categoryId: '',
                        [e.target.name]: e.target.value,
                        ['profilesinfo']: datas
                    })
                }

            } else {
                setState({
                    ...state,
                    categoryId: '',
                    [e.target.name]: e.target.value,
                    ['profilesinfo']: []
                })
            }
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
    }

    const HandleChangeProfileInfo = (value, name, id) => {
        const NewState = state.profilesinfo.map((pro) => {
            if (pro._id === id) {
                return {
                    ...pro,
                    [name]: value
                }
            }
            return pro
        })
        setState({ ...state, profilesinfo: NewState })
    }

    const [crearNuevaCuenta, response] = useCCuentaMutation();
    const createCuenta = e => {
        e.preventDefault();
        crearNuevaCuenta(state);
    }

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {

                message(err.msg, 'error', 4)
            })
        }
    }, [response?.error?.data?.errors])

    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            socket.emit('nuevacuenta', response?.data?.newcuenta, state.correo, state.categoryId, state.servicetype, response?.data?.createdata)
            message(response?.data?.message, 'success', 5)

            alertaConfirmar('Confirmar', 'Quieres seguir creando cuentas?', 'question', async (res_alert) => {
                if (res_alert === true) {
                    setState({
                        correo: '',
                        passwordcorreo: '',
                        passwordcuenta: '',
                        categoryId: '',
                        expirationdate: '',
                        observations: '',
                        servicetype: null,
                        profilesinfo: [],
                        renovable: false
                    })
                } else if (res_alert === false) {
                    navigate('/admin/cuentas');
                }
            })
        }
    }, [response?.isSuccess])

    const OnChangeRenovable = () => {
        setState({ ...state, ['renovable']: !state.renovable })
    }

    return (
        <Wrapper socket={socket} configuration={configuration}
            loadingconfiguration={loadingconfiguration}>
            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <BsPlusCircleDotted size={32} style={{ marginRight: 5 }} />
                        NUEVA CUENTA
                    </div>
                </div>
            </ScreenHeader>
            <div className="flex flex-wrap -mx-3 mt-[70px]">
                <span className="text-yellow-600 dark:text-yellow-400 ml-5 flex items-center bg-neutral-200 dark:bg-neutral-900 p-[8px] rounded-lg mr-5">
                    <span className="w-10 h-10 mr-2 flex items-center justify-center bg-neutral-800 rounded-full"><BsInfo className="w-14 h-14" />
                    </span>
                    Las categorías que tienen como máximo 1 perfil, solamente pueden ser cuentas completas.
                </span>
                <form className="w-full xl:w-8/12 p-3" onSubmit={createCuenta}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Correo o usuario de la cuenta</label>
                            <input autoFocus name="correo" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Correo o usuario de la cuenta"
                                autoComplete="off"
                                required
                                value={state.correo}
                                onChange={handleInput}
                                type="text"
                            />
                        </div>
                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password del correo</label>
                            <input name="passwordcorreo" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Password del correo"
                                autoComplete="off"
                                required
                                value={state.passwordcorreo}
                                onChange={handleInput}
                                type={VerPasswords.passwordcorreo ? 'text' : 'password'}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcorreo']: !VerPasswords.passwordcorreo })}>
                                    {VerPasswords.passwordcorreo ? <IoEyeOff className="text-neutral-900 dark:text-neutral-200" /> : <IoEye className="text-neutral-900 dark:text-neutral-200" />}
                                </IconButton>}
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password de la cuenta</label>
                            <input name="passwordcuenta" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Password de la cuenta"
                                autoComplete="off"
                                required
                                value={state.passwordcuenta}
                                onChange={handleInput}
                                type={VerPasswords.passwordcuenta ? 'text' : 'password'}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcuenta']: !VerPasswords.passwordcuenta })}>
                                    {VerPasswords.passwordcuenta ? <IoEyeOff className="text-neutral-900 dark:text-neutral-200" /> : <IoEye className="text-neutral-900 dark:text-neutral-200" />}
                                </IconButton>}
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Expiración de la cuenta</label>
                            <input name="expirationdate" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Expiración de la cuenta"
                                autoComplete="off"
                                required
                                value={state.expirationdate}
                                onChange={handleInput}
                                type="datetime-local"
                            />
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Tipo de servicio</label>
                            {!loadingcategory ? category?.categories.length > 0 &&
                                <div className="relative">
                                    <FormControl fullWidth>
                                        <Select
                                            required
                                            name="servicetype"
                                            className="flex items-center"
                                            variant="outlined"
                                            value={state.servicetype}
                                            onChange={handleInput}
                                        >
                                            <MenuItem value={true} key={true} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/completa.webp`} className="mr-2 rounded-full" />Cuenta completa</MenuItem>
                                            <MenuItem value={false} key={false} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/pantalla.webp`} className="mr-2 rounded-full" />Cuenta compartida</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div> : <Spinner />}

                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Categoria</label>
                            {
                                state.servicetype !== null ?
                                    <div className="relative">
                                        <FormControl fullWidth>
                                            <Select
                                                required
                                                name="categoryId"
                                                className="flex items-center"
                                                variant="outlined"
                                                value={state.categoryId}
                                                onChange={handleInput}
                                            >
                                                {!loadingcategory && category?.categories.map(category => (
                                                    <MenuItem value={category._id} key={category._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.sublogo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {
                                            /*state.servicetype === 'completa' ?
                                                !loadingcategory && category?.categories.filter(cat => cat.cuentacompleta).map(category => (

                                                    <option value={category._id} key={category._id}>{category.name}</option>

                                                )) :
                                                !loadingcategory && category?.categories.filter(cat => !cat.cuentacompleta).map(category => (

                                                    <option value={category._id} key={category._id}>{category.name}</option>

                                                ))*/

                                        }
                                    </div> : <div className="mt-7 text-neutral-900 dark:text-neutral-200">Seleccionar tipo de servicio</div>}

                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Sub categoría</label>
                            {
                                state.categoryId !== "" ?
                                    <div className="relative">
                                        <FormControl fullWidth>
                                            <Select
                                                required
                                                name="subcategoryId"
                                                className="flex items-center"
                                                variant="outlined"
                                                value={state.subcategoryId}
                                                onChange={handleInput}
                                            >
                                                {!loadingcategory && category?.categories.filter(fil => fil._id === state.categoryId)[0].subcategory.filter(fil2 => fil2.cuentacompleta === state.servicetype).map(category => (
                                                    <MenuItem value={category._id} key={category._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.logo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> : <div className="mt-7 text-neutral-900 dark:text-neutral-200">Seleccionar categoría</div>}
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-3">
                            {
                                state?.profilesinfo?.map((pro, i) => {
                                    var passwordp = `passwordp${i + 1}`
                                    var categoryname = `name`
                                    var password = `password`

                                    return (
                                        <div className="rounded-lg relative text-neutral-900 dark:text-neutral-200 bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-4 cursor-pointer hover:scale-[101%] animation transition-all">

                                            <div className="absolute text-center font-bold rounded-full p-2 left-1 top-1 bg-neutral-400 dark:bg-neutral-800 text-neutral-950 dark:text-neutral-50 bg-opacity-30 text-3xl w-14 h-14 flex items-center justify-center">
                                                {i + 1}
                                            </div>
                                            <IoPerson size={64} className="text-neutral-900 dark:text-neutral-200 rounded-full m-auto" />

                                            <div className="m-auto mb-2">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre</label>
                                                <input key={i} name={categoryname} className="bg-neutral-300 dark:bg-neutral-700 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                    placeholder="Nombre"
                                                    autoComplete="off"
                                                    required
                                                    onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                                                    value={pro[categoryname]}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="m-auto relative">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">PIN</label>
                                                <input key={i} name={password} className="bg-neutral-300 dark:bg-neutral-700 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                                    placeholder="PIN"
                                                    autoComplete="off"
                                                    required
                                                    onKeyPress={PermiteSoloNumeros}
                                                    onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                                                    value={pro[password]}
                                                    type={VerPasswords[passwordp] ? 'text' : 'password'}
                                                    maxLength={5}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    {<IconButton color='primary' onClick={(e) => SetVerPasswords({ ...VerPasswords, [passwordp]: !VerPasswords[passwordp] })}>
                                                        {VerPasswords[passwordp] ? <IoEyeOff className="text-neutral-900 dark:text-neutral-200" /> : <IoEye className="text-neutral-900 dark:text-neutral-200" />}
                                                    </IconButton>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="w-full p-3">
                            <label className="text-neutral-900 dark:text-neutral-200 text-sm">Es renovable ? <span className="font-semibold">{state.renovable ? "Si" : "No"}</span></label>
                            <div className="flex flex-col justify-between h-16 mt-3">

                                <span onClick={OnChangeRenovable} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${state.renovable && 'hidden'}`}>
                                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                </span>

                                <span onClick={OnChangeRenovable} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!state.renovable && 'hidden'}`}>
                                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                </span>
                            </div>
                        </div>

                        <div className="w-full p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Observaciones</label>
                            <textarea name="observations" className="bg-neutral-100 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Observaciones"
                                rows="3"
                                autoComplete="off"
                                required
                                value={state.observations}
                                onChange={handleInput}
                            />
                        </div>
                        <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                            <Button
                                type="submit"
                                disabled={response.isLoading ? true : false}
                                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                size='large' variant="contained"
                            >
                                {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                {response.isLoading ? 'Guardando...' : 'CREAR CUENTA'}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}
export default CreateCuenta