import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const paymentService = createApi({
  reducerPath: "payment",
  tagTypes: "payments",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      headers.set('authorization', userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '');
      return headers;
    },
  }),

  endpoints: (builder) => {
    return {
      sendPayment: builder.mutation({
        query: (recharge) => {
          return {
            url: "/create-checkout-session",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),
      generateQrCodeBinancePay: builder.mutation({
        query: (recharge) => {
          return {
            url: "/generateQrCodeBinancePay",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),
      generateQrCodeBinancePayDonation: builder.mutation({
        query: (recharge) => {
          return {
            url: "/generateQrCodeBinancePayDonation",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),
      createPaymentIziPay: builder.mutation({
        query: (recharge) => {
          return {
            url: "/createPaymentIziPay",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),

      createPaymentIziPayDonation: builder.mutation({
        query: (recharge) => {
          return {
            url: "/createPaymentIziPayDonation",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),

      validatePaymentIziPayDonation: builder.mutation({
        query: (data) => {
          return {
            url: "/validatePaymentIziPayDonation",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ['payments']
      }),

      validatePaymentIziPay: builder.mutation({
        query: (data) => {
          return {
            url: "/validatePaymentIziPay",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ['payments']
      }),

      sendPaymentMercadoPago: builder.mutation({
        query: (recharge) => {
          return {
            url: "/create-order-mercado-pago",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),

      sendPaymentMercadoPagoDonation: builder.mutation({
        query: (recharge) => {
          return {
            url: "/create-order-mercado-pago-donation",
            method: "POST",
            body: recharge,
          };
        },
        invalidatesTags: ['payments']
      }),

      sendYapePayment: builder.mutation({
        query: (data) => {
          return {
            url: "/send-yape-payment",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ['payments']
      }),
      verifyPayment: builder.query({
        query: (id) => {
          return {
            url: `verify-payment/${id}`,
            method: "GET",
          };
        },
      }),
      verifyMercadoPagoPayment: builder.query({
        query: (payment_id) => {
          return {
            url: `verify-mercadopago-payment/${payment_id}`,
            method: "GET",
          };
        },
      }),
      getAllPayments: builder.query({
        query: () => {
          return {
            url: `/get-payments`,
            method: 'GET'
          }
        },
        providesTags: ['payments']
      }),

      getAllPaymentsByPage: builder.query({
        query: (params) => {
          var finalquery = ''
          if(params.search === ''){
            finalquery = 'emptyquery'
          }else{
            finalquery = params.search
          }
          return {
            url: `get-payments-bypage/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["payments"],
      }),

      countPaymentsByState: builder.query({
        query: () => {
          return {
            url: `countpaymentsbystate`,
            method: "GET",
          };
        },
        providesTags: ["payments"],
      }),

      deletePayment: builder.mutation({
        query: id => {
            return {
                url: `/delete-payment/${id}`,
                method: 'DELETE'
            }
        },
        invalidatesTags: ['payments']
    }),
      verifyPaymentYape: builder.mutation({
        query: data => {
          return {
            url: `verify-payment-yape`,
            method: 'PUT',
            body: data
          }
        },
        invalidatesTags: ['payments']
      }),
      getPaymentsByUserId: builder.query({
        query: (userId) => {
          return {
            url: `get-payments/${userId}`,
            method: "GET",
          };
        },
        providesTags: ["payments"],
      }),
    };
  },
});
export const {
  useSendPaymentMutation,
  useCreatePaymentIziPayMutation,
  useCreatePaymentIziPayDonationMutation,
  useValidatePaymentIziPayDonationMutation,
  useValidatePaymentIziPayMutation,
  useGenerateQrCodeBinancePayMutation,
  useGenerateQrCodeBinancePayDonationMutation,
  useSendPaymentMercadoPagoMutation,
  useSendPaymentMercadoPagoDonationMutation,
  useVerifyPaymentQuery,
  useVerifyMercadoPagoPaymentQuery,
  useSendYapePaymentMutation,
  useVerifyPaymentYapeMutation,
  useDeletePaymentMutation,
  useGetAllPaymentsQuery,
  useCountPaymentsByStateQuery,
  useGetAllPaymentsByPageQuery,
  useGetPaymentsByUserIdQuery
} = paymentService;
export default paymentService;
