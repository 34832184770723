import axios from "axios";

const API_URL = `https://cinedar.com:8443/api/category/`;

// Get all categories

const createCategory = async (formData) => {
    const response = await axios.post(API_URL, formData);
    return response.data;
};

const getCategories = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

const deleteCategory = async (id) => {
    const response = await axios.delete(API_URL + id);
    return response.data;
};

const deleteSubCategory = async (formData) => {
    const response = await axios.patch(API_URL + '/deleteSubCategory', formData);
    return response.data;
};

const updateCategory = async (formData) => {
    const response = await axios.patch(API_URL + '/category', formData);
    return response.data;
};

const updateSubCategory = async (formData) => {
    const response = await axios.patch(API_URL + '/subcategory', formData);
    return response.data;
};

const categoryService = {
    createCategory,
    getCategories,
    updateCategory,
    updateSubCategory,
    deleteCategory,
    deleteSubCategory
};

export default categoryService;
