import { useEffect, useMemo, useState } from "react"
import ScreenHeader from "../../components/ScreenHeader";
import { Toaster } from 'react-hot-toast';
import { useDeleteUserMutation, useUpdateLockedUserMutation, useUpdateCashMutation, useGetAllUsersQuery, useGetAllUsersByPageQuery, useCountAllUsersFilterQuery } from "../../store/services/userService";
import Spinner from "../../components/Spinner";
import { Button, FormControl, IconButton, MenuItem, Pagination, PaginationItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import { IoAdd, IoAddCircleSharp, IoAddSharp, IoCash, IoChevronDown, IoCloseSharp, IoLockClosed, IoLockOpen, IoSearch, IoTime } from "react-icons/io5";
import Swal from 'sweetalert2'
import { currencyFormat, message, PermiteSoloNumeros, setUnixToTimeFormat, sleep } from "../../utils/Funciones.js";
import { MdDelete, MdEdit, MdSupervisorAccount, MdVerifiedUser } from "react-icons/md";
import { FaChessKing, FaClipboard, FaCoins, FaDollarSign, FaUser, FaUserTie, FaWallet } from 'react-icons/fa';
import Wrapper from "./Wrapper";
import { FcPlus } from "react-icons/fc";
import { Link } from "react-router-dom";
import UpdateUser from "./UpdateUser";
import { BsInfo } from "react-icons/bs";
import debounce from 'lodash.debounce';
import usePagination from "@mui/material/usePagination/usePagination";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from '@mui/material/Modal';
import SpinnerData from "../../components/SpinnerData";
import Spinner2 from "../../components/Spinner2";
import CopyToClipboard from "react-copy-to-clipboard";
const moment = require("moment");
require("moment/locale/es");

const Users = ({
   socket,
   adminToken,
   admin,
   configuration,
   loadingconfiguration
}) => {

   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [limit, setLimit] = useState(30);

   const { data: users, isFetching: loadingusers, refetch } = useGetAllUsersByPageQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
   const { data: countallusersfilter, isFetching: fechingcountallusersfilter, refetch: refetchcountallusersfilter } = useCountAllUsersFilterQuery();

   const [removeUser, response] = useDeleteUserMutation();
   const [updateCash, responseCash] = useUpdateCashMutation();
   const [UpdateLocedkUser, responseUpdateLocedkUser] = useUpdateLockedUserMutation();
   const [openedituser, SetOpenEditUser] = useState({ open: false, userData: null })
   const [usersFilter, setUsersFilter] = useState('')
   const [addcredits, Setaddcredits] = useState({
      open: false,
      _id: "",
      credits: 0,
      email: "",
      currentcredits: 0,
      OperationTypeAdd: true,
      payment_id: "",
      payment_type: "",
   })

   useEffect(async () => {
      await refetch()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetch.abort();
      };
   }, [searchTerm, currentPage, limit]);

   useEffect(async () => {
      await refetchcountallusersfilter()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetchcountallusersfilter.abort();
      };
   }, [countallusersfilter])

   const deleteUser = id => {
      alertaConfirmar('Eliminar Usuario', 'Estas seguro que quieres eliminar este usuario? recuerda que se eliminarán todos los datos relacionados a este usuario.', 'question', (res_alert) => {
         if (res_alert === true) {
            removeUser(id);
         }
      })
   }

   const HandleUpdateCash = e => {
      e.preventDefault();
      if (addcredits.credits !== 0) {
         if (addcredits.payment_type !== "" || addcredits.credits < 0) {
            if (addcredits.payment_type === 'yape') {
               if (addcredits.payment_id.trim().length > 7) {
                  updateCash(addcredits);
               } else {
                  message(`N° de operación debe ser mayor o igual a 8 caracteres!`, 'error', 4)
               }
            } else {
               updateCash(addcredits);
            }
         } else {
            message(`Selecciona como recibiste el pago`, 'error', 4)
         }
      } else {
         message(`no puedes agregar ${addcredits.credits} créditos`, 'error', 4)
      }

   }

   const lockUnlockUser = (id, locked) => {
      var state = ""
      if (locked) {
         state = "Desbloquear"
      } else {
         state = "Bloquear"
      }

      alertaConfirmar(`${state} Usuario`, `Estas seguro que quieres ${state} este usuario?`, 'question', (res_alert) => {
         if (res_alert === true) {
            UpdateLocedkUser({ _id: id, action: !locked });
         }
      })
   }

   useEffect(() => {
      if (responseUpdateLocedkUser.isSuccess) {

         socket.emit('lockandunlockuser', responseUpdateLocedkUser?.data?.id, responseUpdateLocedkUser?.data?.token, responseUpdateLocedkUser?.data?.state)
         message(responseUpdateLocedkUser?.data?.msg, 'success', 5)
      }
   }, [responseUpdateLocedkUser?.data?.msg])

   useEffect(() => {
      if (responseCash.isSuccess) {
         if (socket.connected) {
            socket.emit("updatecash", responseCash?.data?.ResData)
         }
         message(responseCash?.data?.msg, 'success', 5)
         Setaddcredits({ ...addcredits, ['open']: false, ['_id']: "", ['credits']: 0, payment_type: "", email: "", currentcredits: 0, payment_id: "" });
      }
   }, [responseCash?.data?.msg])

   useEffect(() => {
      if (!responseCash.isSuccess) {
         responseCash?.error?.data?.errors.map(err => {
            message(err.msg, 'error', 4)
         })
      }
   }, [!responseCash?.error?.data?.errors])

   useEffect(() => {
      if (response.isSuccess) {
         message(response?.data?.message, 'success', 5)
      }
   }, [response?.data?.message])

   useEffect(() => {
      if (!response.isSuccess) {
         response?.error?.data?.errors.map(err => {
            message(err?.msg, 'error', 5)
         })
      }
   }, [response?.error?.data?.errors])

   const OpenCloseAddCredits = (_id, email, currentcredits) => {
      Setaddcredits({ ...addcredits, ['open']: !addcredits.open, ['_id']: _id, ['credits']: 0, payment_type: "", email: email, currentcredits: currentcredits, payment_id: "" });
   }


   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const changeHandleSearch = (event) => {
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);



   const HandleOpenEditUser = (id) => {
      if (!loadingusers) {
         const useredit = users?.users.find((u__) => {
            if (u__._id === id) {
               return u__
            }
         })
         SetOpenEditUser({ ...openedituser, open: true, userData: useredit });
      }
   }

   const HandleCloseEditUser = () => {
      SetOpenEditUser({ ...openedituser, open: false, userData: null });
   }

   const handleChange = (e, p) => {
      setCurrentPage(p);
   };

   const HandleChangeFilterUsers = async (state) => {
      setSearchTerm(state)
      setUsersFilter(state)
      setCurrentPage(1);
   }

   const onCopy = (text) => {
      message(text, 'success', 2)
   }

   return (
      <Wrapper socket={socket} configuration={configuration}
         loadingconfiguration={loadingconfiguration}>
         <Toaster position="top-right" reverseOrder={true} />
         <ScreenHeader>
            <div className="w-full h-14 text-lg md:text-2xl flex items-center">
               <div className="flex items-center w-9/12 md:w-6/12">
                  <MdSupervisorAccount size={32} style={{ marginRight: 5 }} />
                  USUARIOS
                  <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
                     {!loadingusers && users?.totalusers}
                  </div>
               </div>
               <div className="flex items-center justify-end w-3/12 md:w-6/12">
                  <Link to="/admin/create-user" className="w-16 h-16 flex justify-end">
                     <Tooltip title="Agregar cuenta" arrow={true}>
                        <div className="w-16 h-16 rounded-full flex items-center justify-center bg-neutral-100 dark:bg-neutral-800">
                           <IconButton color="primary" className="w-16 h-16">
                              <FcPlus size={45} />
                           </IconButton>
                        </div>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </ScreenHeader>
         <Modal
            open={openedituser.open}
            onClose={HandleCloseEditUser}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-y-auto fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-8/12 bg-white dark:bg-black">
                  <UpdateUser
                     socket={socket}
                     user={openedituser.userData}
                     HandleCloseEditUser={HandleCloseEditUser}
                  />
               </div>
            </div>
         </Modal>
         <Modal
            keepMounted
            open={addcredits.open}
            onClose={() => Setaddcredits({ ...addcredits, open: !addcredits.open, _id: "", credits: 0, payment_type: "", email: "", currentcredits: 0, payment_id: "" })}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="w-full h-screen overflow-auto bg-neutral-50 bg-opacity-30">

               <div className="realtive m-auto p-5 w-full md:w-3/12 bg-white dark:bg-black">
                  <div className="flex items-center mb-3">
                     <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-600">
                        <IconButton color="error" onClick={() => Setaddcredits({ ...addcredits, open: !addcredits.open, _id: "", credits: 0, payment_type: "", email: "", currentcredits: 0, payment_id: "" })}>
                           <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                           />
                        </IconButton>
                     </div>
                     <div className="ml-3 text-2xl text-neutral-900 dark:text-neutral-200 uppercase">Recargar créditos</div>
                  </div>
                  <form onSubmit={HandleUpdateCash}>
                     {/*<div className="text-blue-600 dark:text-blue-400 ml-5 flex items-center bg-neutral-100 dark:bg-neutral-900 p-[5px] rounded-lg mr-5">
                        <div className="w-[40px] h-10 mr-2 flex items-center justify-center bg-neutral-800 rounded-full">
                           <BsInfo size={28} />
                        </div>
                        <div className="w-[calc(100%-40px)]">
                           Puedes agregar o quitar créditos, para quitar créditos puedes agregar el signo menos(-) adelante del numero.
                        </div>
                     </div>*/}
                     <div class="w-full p-5">
                        <div className="mb-6 bg-neutral-100 dark:bg-neutral-900 rounded-lg p-3 space-y-3">
                           <label for="first_name" class="block uppercase mb-2 text-sm font-medium text-neutral-900 dark:text-white">Usuario</label>
                           <label className="flex items-center font-bold text-sm text-blue-400"><FaUser size={24} className="mr-2"/> {addcredits.email}</label>
                           <label for="first_name" class="block uppercase mb-2 text-sm font-medium text-neutral-900 dark:text-white">Créditos actuales</label>
                           <label className={`flex items-center font-bold text-sm text-yellow-500`}><FaWallet size={24} className="mr-2"/> {currencyFormat(addcredits.currentcredits)}</label>
                           <label for="first_name" class="block uppercase mb-2 text-sm font-medium text-neutral-900 dark:text-white">Créditos después de recargar</label>
                           <label className={`flex items-center font-bold text-sm transition-all duration-500 ${addcredits.credits >= 0 ? 'text-blue-500':'text-red-400'}`}><FaCoins size={24} className="mr-2"/> { addcredits.credits != 0 ? currencyFormat(addcredits.currentcredits + Number(addcredits.credits)):' ------ ' } </label>
                        </div>

                        <div className="mb-6">
                           <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Cantidad de créditos</label>
                           <input autoFocus name="credits" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-100 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                              placeholder="Cantidad de créditos"
                              autoComplete="off"
                              required
                              value={addcredits.credits}
                              onChange={e => Setaddcredits({ ...addcredits, ['credits']: e.target.value })}
                              type="number"
                           />
                        </div>

                        {
                           addcredits.credits > 0 &&
                           <>
                              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Como recibiste el pago?</label>
                              <div className="relative mb-6">
                                 <FormControl fullWidth>
                                    <Select
                                       required
                                       name="payment_type"
                                       className="flex items-center"
                                       variant="outlined"
                                       value={addcredits.payment_type}
                                       onChange={e => Setaddcredits({ ...addcredits, ['payment_type']: e.target.value })}
                                    >
                                       <MenuItem value={'yape'} key={'yape'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/yape.webp`} className="mr-2 rounded-full" />Yape</MenuItem>
                                       <MenuItem value={'stripe'} key={'stripe'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/stripe.webp`} className="mr-2 rounded-full" />Stripe</MenuItem>
                                       <MenuItem value={'izipay'} key={'izipay'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/izipay.webp`} className="mr-2 rounded-full" />IziPay</MenuItem>
                                       <MenuItem value={'mercadopago'} key={'mercadopago'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/mercadopago.webp`} className="mr-2 rounded-full" />Mercadopago</MenuItem>
                                       <MenuItem value={'gift'} key={'gift'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/gift.webp`} className="mr-2 rounded-full" />Fué un regalo</MenuItem>
                                       <MenuItem value={'binance'} key={'binance'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/binance.webp`} className="mr-2 rounded-full" />Binance</MenuItem>
                                       <MenuItem value={'paypal'} key={'paypal'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/paypal.webp`} className="mr-2 rounded-full" />Paypal</MenuItem>
                                    </Select>
                                 </FormControl>
                              </div>
                           </>
                        }

                        {
                           addcredits.payment_type === 'yape' &&
                           <div className="mb-2">
                              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Número de operación de yape</label>
                              <input autoFocus name="payment_id" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-100 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                 placeholder="N° de operación de yape"
                                 autoComplete="off"
                                 maxLength={16}
                                 value={addcredits.payment_id}
                                 onChange={e => Setaddcredits({ ...addcredits, ['payment_id']: e.target.value })}
                                 type="text"
                              />
                           </div>
                        }


                     </div>
                     <div className="w-full p-5">
                        <Button
                           type="submit"
                           style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                           size='large' variant="contained" color={`${addcredits.credits >= 0 ? 'primary' : 'error'}`}
                           disabled={(responseCash.isLoading || addcredits.credits == 0 || (addcredits.currentcredits + Number(addcredits.credits) < 0)) ? true : false}
                        >
                           {responseCash.isLoading ? <Spinner /> : <IoCash size={24} className="mr-2" />}
                           {responseCash.isLoading ? 'Procesando...' : `${addcredits.credits >= 0 ? 'Agregar' : 'Quitar'} Creditos`}
                        </Button>
                     </div>
                  </form>

               </div>
            </div>
         </Modal >
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
            <div className="relative">
               <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
               </div>
               <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Escribe nombre, correo..."
                  required
                  onChange={debouncedChangeHandler}
                  autoComplete="off"
               />
            </div>
         </div>

         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[128px] pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
            <div className="w-full flex items-center justify-center md:justify-end text-neutral-200 mt-0 h-10">
               {
                  !loadingusers ?
                     <>
                        <div className="hidden md:block">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!loadingusers && Number(users?.totalusersFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChange}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="large"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-neutral-200 dark:bg-neutral-900"
                              />
                           </Stack>
                        </div>

                        <div className="block md:hidden">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!loadingusers && Number(users?.totalusersFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChange}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="small"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-neutral-200 dark:bg-neutral-900"
                              />
                           </Stack>
                        </div>
                     </> : <Spinner />
               }
            </div>

            <div className="mt-8">

               {
                  <Tooltip title="Click aquí para filtrar usuarios bloqueados" arrow={true}>
                     <div onClick={() => HandleChangeFilterUsers("locked")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "locked" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-600 p-3 rounded-lg`}>
                        <div className="flex inline-flex items-center"><IoLockClosed className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!fechingcountallusersfilter ? countallusersfilter?.totaluserslocked : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

               {
                  <Tooltip title="Click aquí para filtrar usuarios resellers" arrow={true}>
                     <div onClick={() => HandleChangeFilterUsers("resellers")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "resellers" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-yellow-600 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><FaDollarSign className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-600"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!fechingcountallusersfilter ? countallusersfilter?.totalusersreseller : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

               {
                  <Tooltip title="Click aquí para filtrar todos los usuarios" arrow={true}>
                     <div onClick={() => HandleChangeFilterUsers("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-green-500 p-3 rounded-lg ml-3 md:ml-5`}>
                        <div className="flex inline-flex items-center"><MdSupervisorAccount className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                        <div className="-mt-12 -mr-4">
                           <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                              <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                                 {!loadingusers ? users?.totalusers : <Spinner2 />}
                              </span>
                           </span>
                        </div>
                     </div>
                  </Tooltip>
               }

            </div>

         </div>

         {
            !loadingusers ? users?.users.length > 0 ? <>
               <div className="flex flex-wrap mt-[325px]">

                  {!loadingusers && users?.users.map((user) => {
                     var userIsused = false
                     /*!loadingventas && ventas?.ventas.find((ven) => {
                        if (ven.userId === user._id && ven.state === 'complete') {
                           userIsused = true
                        }
                     })*/

                     return (
                        <div className={`relative transition-all shadow-xl duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer hover:cursor-pointer p-4 w-full md:w-12/12 bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-wrap mb-6 rounded-lg animation`} key={user._id}>
                           <div className="grid grid-wrap w-full md:w-3/12">
                              <div className="flex inline-flex items-center">
                                 <div className="rounded-full w-14 h-14 cursor-pointer">
                                    <LazyLoadImage
                                       className="w-14 h-14 rounded-full object-cover"
                                       alt={`No image user`}
                                       effect="blur"
                                       src={`/images/photos/${user.photo ? user.photo : 'unknowphoto.png'}`}
                                    />
                                 </div>
                                 <div className="grid self-center">
                                    <div className="ml-2 text-base font-semibold">{user.email}</div>
                                    <div className="ml-2 text-sm opacity-80">{user.name}</div>
                                    <div className="ml-2 text-sm flex items-center mt-1 opacity-80"><IoTime size={24} className="mr-1" />{setUnixToTimeFormat("info_contacto", user.created_at)}</div>
                                 </div>
                              </div>
                           </div>


                           <div className="flex inline-flex items-center w-full md:w-[26.6666%] mt-2 md:mt-0 mb-2 md:mb-0">
                              {
                                 user.reseller ?
                                    <div className="flex items-center mr-3">
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 text-yellow-500 rounded-full"><FaDollarSign size={20} /></div>
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 ml-1 text-yellow-500 rounded-lg">RESELLER</div>
                                    </div>
                                    :
                                    <div className="flex items-center mr-3">
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 text-blue-500 rounded-full"><FaUserTie size={20} /></div>
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 ml-1 text-blue-500 rounded-lg">CONSUMIDOR</div>
                                    </div>
                              }
                              {
                                 user.admin ?
                                    <div className="flex items-center">
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 text-red-600 rounded-full"><FaChessKing size={20} /></div>
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 ml-1 text-red-600 rounded-lg">ADMIN</div>
                                    </div>
                                    :
                                    <div className="flex items-center">
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 text-green-600 dark:text-green-400 rounded-full"><MdVerifiedUser size={20} /></div>
                                       <div className="bg-neutral-50 dark:bg-neutral-950 p-2 ml-1 text-green-600 dark:text-green-400 rounded-lg">REGISTRADO</div>
                                    </div>
                              }

                           </div>

                           <div className="flex inline-flex items-center w-full md:w-3/12 mt-2 md:mt-0 mb-2 md:mb-0">
                              {
                                 user.reseller &&
                                 <div className="flex items-center mr-3">
                                    <div className="bg-neutral-50 dark:bg-neutral-950 p-2 text-green-600 dark:text-green-400 rounded-full"><FaDollarSign size={20} /></div>
                                    <div className="bg-neutral-50 dark:bg-neutral-950 p-2 ml-1 text-green-600 dark:text-green-400 rounded-lg">VENTAS TOTALES : {currencyFormat(Number(user.credits_used))}</div>
                                 </div>
                              }

                           </div>

                           <div className="flex flex-wrap self-center items-center justify-center w-[150px] md:w-[160px] absolute md:left-0 left-4 bottom-4 md:bottom-0 md:relative">
                              <div className="w-full flex items-center justify-center self-center text-sm">
                                 <FaWallet className="mr-2" size={16} /> BALANCE
                              </div>
                              <div className="w-full flex items-center justify-center md:justify-start self-center text-center bg-neutral-50 dark:bg-neutral-950 text-neutral-900 dark:text-neutral-200 rounded-lg p-1">
                                 <div className="mr-2 text-sm md:text-base w-[calc(150px-56px)] md:w-[calc(160px-56px)]">{currencyFormat(user.credits_purchased - user.credits_used)}</div>
                                 <Tooltip title="agregar o quitar créditos" arrow={true}>
                                    <div className="w-14 h-14 rounded-full flex items-center justify-end">
                                       <IconButton onClick={() => OpenCloseAddCredits(user._id, user.email, (user.credits_purchased - user.credits_used))} className="w-14 h-14" color='primary'>
                                          <IoAddCircleSharp
                                             className="text-green-600 dark:text-green-400 m-auto"
                                             size={36}
                                          />
                                       </IconButton>
                                    </div>
                                 </Tooltip>
                              </div>
                           </div>

                           <div className="flex inline-flex items-center justify-end w-full md:w-[12.666%] mt-3 md:mt-0">
                              <div className="flex inline-flex items-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                                 <div className="w-14 h-14 rounded-full mr-2">
                                    {
                                       user.locked ?
                                          <Tooltip title="desbloquear" arrow={true}>
                                             <IconButton onClick={() => lockUnlockUser(user._id, user.locked)} className="w-14 h-14" color='primary'>
                                                <IoLockOpen
                                                   className="text-green-400 m-auto"
                                                   size={25}
                                                />
                                             </IconButton>
                                          </Tooltip>
                                          :
                                          <Tooltip title="bloquear" arrow={true}>
                                             <IconButton disabled={user._id === (adminToken && admin.id) ? true : false}
                                                onClick={() => lockUnlockUser(user._id, user.locked)}
                                                className="w-14 h-14" color='primary'
                                             >
                                                <IoLockClosed
                                                   className={`${user._id === (adminToken && admin.id) ? 'text-neutral-400 dark:text-neutral-700' : 'text-red-600'} m-auto`}
                                                   size={25}
                                                />
                                             </IconButton>
                                          </Tooltip>
                                    }

                                 </div>

                                 <div className="w-14 h-14 rounded-full mr-2">
                                    <Tooltip title="editar" arrow={true}>
                                       <IconButton onClick={() => HandleOpenEditUser(user._id)} className="w-14 h-14" color='primary'>
                                          <MdEdit
                                             className="text-yellow-600 m-auto"
                                             size={25}
                                          />
                                       </IconButton>
                                    </Tooltip>
                                 </div>

                                 <div className="w-14 h-14 rounded-full">
                                    <Tooltip title="eliminar" arrow={true}>
                                       <IconButton onClick={() => deleteUser(user._id)} className="w-14 h-14" color="primary" disabled={(userIsused || user._id === (adminToken && admin.id)) ? true : false}>
                                          <MdDelete size={25}
                                             className={`${(userIsused || user._id === (adminToken && admin.id)) ? 'text-neutral-400 dark:text-neutral-700' : 'text-red-600'} m-auto`}
                                          />
                                       </IconButton>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                        </div>

                     )
                  }
                  )
                  }
               </div>
            </> : <div className="alert-danger font-semibold mt-[325px]">Sin resultados en la búsqueda! o no existen usuarios registradas</div> : <div className="mt-[325px]"><SpinnerData>Cargando usuarios, por favor espere...</SpinnerData></div>
         }
      </Wrapper >
   )
}
export default Users;