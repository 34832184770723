import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import ScreenHeader from "../../components/ScreenHeader";
import toast, { Toaster } from 'react-hot-toast';
import Wrapper from "./Wrapper"
import { Button, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { BsCalendar3, BsPlusCircleDotted } from "react-icons/bs";
import { IoAdd, IoChevronDown, IoCloseSharp, IoSave } from "react-icons/io5";
import ImagesPreview from "../../components/ImagesPreview";
import { message, PermiteSoloNumeros } from "../../utils/Funciones.js";
import Spinner from "../../components/Spinner";
import { MdAddCircle } from "react-icons/md";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import debounce from 'lodash.debounce';
import Modal from '@mui/material/Modal';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useCreateSocialPlataformMutation, useGetAllSocialPlataformsQuery } from "../../store/services/socialService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HexColorPicker } from "react-colorful";
import ImagesPreviewBanner from "../../components/ImagesPreviewBanner";
import ImagesPreview2 from "../../components/ImagesPreview2";

const CreateServiceSocial = ({
    socket,
    configuration,
    loadingconfiguration
}) => {
    const { data: plataforms, isFetching } = useGetAllSocialPlataformsQuery();

    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [cuentacompleta, SetCuentaCompleta] = useState(false)
    const [renovable, SetRenovable] = useState(false)
    const [profileblocked, SetProfileBlocked] = useState(false)
    const [color, setColor] = useState('#ffffff');
    const [enabled, SetEnabled] = useState(true)
    const [preview, setPreview] = useState({
        logo: '',
        sublogo: '',
        banner: ''
    })

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [editorState2, setEditorState2] = useState(
        () => EditorState.createEmpty(),
    );

    const [state, setState] = useState({
        nameplataform: "",
        servicename: "",
        serviceId: "",
        servicetype: "",
        tipoentrega: false,
        preciofijouser: 0,
        preciofijoreseller: 0,
        porcentajeadicional: 0,
        enabled: true,
        speedseconds: 0,
        policy: "",
        themecolor: "",
        urlexamplephone: "",
        urlexampledesktop: "",
        logo: "",
        sublogo: "",
        banner: "",
        type: "",
        openNewSocialPlataform: false
    });

    const HandleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value, type: 'NewSocialService' })
    }

    const HandleChangeType = (value) => {
        var openNewSocialPlataform = false
        if (value === 'NewSocialPlataform') {
            openNewSocialPlataform = true
        }

        setState({
            ...state,
            nameplataform: "",
            servicename: "",
            serviceId: "",
            servicetype: "",
            preciofijouser: 0,
            preciofijoreseller: 0,
            porcentajeadicional: 0,
            enabled: true,
            speedseconds: 0,
            policy: "",
            tipoentrega: false,
            themecolor: "",
            urlexamplephone: "",
            urlexampledesktop: "",
            type: value,
            openNewSocialPlataform: openNewSocialPlataform
        })

        setPreview({
            ...preview,
            logo: '',
            sublogo: '',
            banner: ''
        })
    }

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const [savePlataformService, response] = useCreateSocialPlataformMutation();

    const submitNewPlataform = e => {
        e.preventDefault();
        if (state.nameplataform.trim().length > 0 && state.logo !== "") {
            const contentState = editorState.getCurrentContent();
            if (contentState.hasText()) {
                const formData = new FormData();
                state.themecolor = color
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
                formData.append('image1', state.logo)
                formData.append('image2', state.sublogo)
                formData.append('image3', state.banner)
                savePlataformService(formData)
            } else {
                message('Descripción es requerido', 'error', 4)
            }
        } else {
            message('Todos los campos son requeridos!', 'error', 4)
        }
    }

    const submitNewService = e => {
        e.preventDefault()
        if (state.servicename.trim().length > 0 &&
            state.serviceId.trim().length > 0 &&
            state.nameplataform.trim().length > 0 &&
            state.urlexampledesktop.trim().length > 0 &&
            state.urlexamplephone.trim().length
        ) {
            const contentState = editorState2.getCurrentContent();
            if (contentState.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
                savePlataformService(formData);
            } else {
                message('Términos y condiciones es requerido', 'error', 4)
            }
        } else {
            message('Todos los campos son requeridos!', 'error', 4)
        }
    }

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                message(err.msg, 'error', 4)
            })
        }
    }, [response?.error?.data?.errors])


    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            message(response?.data?.message, 'success', 5)
            if (state.type === 'NewSocialPlataform') {
                setState({
                    ...state,
                    nameplataform: "",
                    servicename: "",
                    serviceId: "",
                    servicetype: "",
                    preciofijouser: 0,
                    preciofijoreseller: 0,
                    porcentajeadicional: 0,
                    enabled: true,
                    speedseconds: 0,
                    policy: "",
                    tipoentrega: false,
                    themecolor: "",
                    urlexamplephone: "",
                    urlexampledesktop: "",
                    type: "",
                    openNewSocialPlataform: false
                })
            } else if (state.type === 'NewSocialService') {
                navigate('/admin/socialplataforms')
            }
        }
    }, [response?.isSuccess])

    const OnChangeTipoEntrega = () => {
        SetTipoEntrega(!tipoentrega)
        setState({ ...state, ['tipoentrega']: !tipoentrega })
    }

    const onChangeColor = (event) => {
        setColor(event)
    };

    const debouncecolor = useMemo(() => debounce(onChangeColor, 1000), []);

    const OnChangeEnabled = () => {
        SetEnabled(!enabled)
        setState({ ...state, enabled: !enabled })
    }

    return (
        <Wrapper socket={socket} configuration={configuration}
            loadingconfiguration={loadingconfiguration}>
            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <BsPlusCircleDotted size={32} style={{ marginRight: 5 }} />
                        NUEVO SERVICIO
                    </div>
                </div>
            </ScreenHeader>

            <div className="flex flex-wrap -mx-3 mt-[70px]">
                <Modal
                    open={state.openNewSocialPlataform}
                    onClose={() => setState({
                        ...state,
                        nameplataform: "",
                        servicename: "",
                        serviceId: "",
                        servicetype: "",
                        preciofijouser: 0,
                        preciofijoreseller: 0,
                        porcentajeadicional: 0,
                        enabled: true,
                        speedseconds: 0,
                        policy: "",
                        tipoentrega: false,
                        themecolor: "",
                        urlexamplephone: "",
                        urlexampledesktop: "",
                        logo: "",
                        type: "",
                        openNewSocialPlataform: false
                    })}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                    sx={{ zIndex: 50 }}
                >
                    <div id="authentication-modal" class="w-full h-full overflow-auto bg-neutral-50 bg-opacity-30">
                        <div className="m-auto p-5 w-full md:w-7/12 bg-white dark:bg-black">
                            <div className="flex items-center">
                                <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                                    <IconButton color="error" onClick={() => setState({
                                        ...state,
                                        nameplataform: "",
                                        servicename: "",
                                        serviceId: "",
                                        servicetype: "",
                                        preciofijouser: 0,
                                        preciofijoreseller: 0,
                                        porcentajeadicional: 0,
                                        enabled: true,
                                        speedseconds: 0,
                                        policy: "",
                                        tipoentrega: false,
                                        themecolor: "",
                                        urlexamplephone: "",
                                        urlexampledesktop: "",
                                        logo: "",
                                        type: "",
                                        openNewSocialPlataform: false
                                    })}>
                                        <IoCloseSharp
                                            className="text-neutral-200"
                                            size={28}
                                        />
                                    </IconButton>
                                </div>
                                <div className="w-[calc(100%-48px)] text-neutral-900 dark:text-neutral-200 text-center text-2xl">
                                    CREAR PLATAFORMA
                                </div>
                            </div>
                            <div className="w-full md:w-12/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la plataforma</label>
                                <input autoFocus name="nameplataform" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Nombre de la plataforma"
                                    autoComplete="off"
                                    required
                                    type="text"
                                    value={state.nameplataform}
                                    onChange={(e) => setState({ ...state, nameplataform: e.target.value })}
                                />
                            </div>

                            <div className="w-[125px] p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Color tema</label>
                                <HexColorPicker color={color} onChange={debouncecolor} />
                            </div>
                            <div className="w-full flex flex-wrap p-3">
                                <div class="w-full md:w-6/12">
                                    <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                    <ImagesPreview2 url={preview.logo} />
                                </div>
                            </div>

                            <div className="w-full flex flex-wrap p-3">
                                <div class="w-full md:w-6/12">

                                    <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                    <ImagesPreview url={preview.sublogo} />
                                </div>
                            </div>

                            <div className="w-full flex flex-wrap p-3">
                                <div class="w-full md:w-6/12">

                                    <label htmlFor="banner" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen banner </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="banner" accept="image/png,image/jpeg,image/webp" type="file" name="banner" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                    <ImagesPreviewBanner url={preview.banner} />
                                </div>
                            </div>

                            <div className="w-full p-3 relative">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Estado de la plataforma <span className="font-semibold">{enabled ? "habilitado" : "deshabilitado"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeEnabled} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${enabled && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeEnabled} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!enabled && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descripción y modo de uso</label>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    placeholder="Escribe aquí..."
                                />
                            </div>

                            <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                                <Button
                                    type="submit"
                                    onClick={submitNewPlataform}
                                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                    size='large' variant="contained"
                                    disabled={response.isLoading ? true : false}
                                >
                                    {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                    {response.isLoading ? 'Cargando...' : 'Crear Plataforma'}
                                </Button>
                            </div>

                        </div>
                    </div>
                </Modal>

                <form className="w-full xl:w-8/12 p-3" onSubmit={submitNewService}>
                    <div className="flex flex-wrap">
                        <div className="flex items-center w-full md:w-6/12">
                            <div className="w-[calc(100%-85px)] p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Plataforma</label>
                                {!isFetching ?
                                    <div className="relative">
                                        <FormControl fullWidth>
                                            <Select
                                                required
                                                name="nameplataform"
                                                className="flex items-center"
                                                variant="outlined"
                                                value={state.nameplataform}
                                                onChange={HandleChange}
                                            >
                                                {!isFetching && plataforms?.plataforms.map(plataform => (
                                                    <MenuItem value={plataform.name} key={plataform.name}><LazyLoadImage width={25} height={25} src={`/images/logos/${plataform.sublogo}`} className="mr-2 rounded-full h-[25px] w-[25px]" />{plataform.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> : <Spinner />}
                            </div>
                            <div className="flex items-center mt-7 justify-center w-[55px] h-[55px] ml-[15px] mr-[15px] bg-gradient-blue-custom rounded-full">
                                <Tooltip title="Agregar nueva plataforma" arrow={true}>
                                    <IconButton onClick={() => HandleChangeType('NewSocialPlataform')}
                                        className="flex items-center justify-center w-[55px] h-[55px]"
                                        color="primary"
                                    >
                                        <MdAddCircle size={45} className="text-neutral-200" />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de servicio</label>
                            <input autoFocus name="servicename" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Nombre de servicio"
                                autoComplete="off"
                                required
                                value={state.servicename}
                                type="text"
                                onChange={HandleChange}
                            />
                        </div>

                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Servicio ID</label>
                            <input name="serviceId" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Servicio ID"
                                autoComplete="off"
                                required
                                value={state.serviceId}
                                onChange={HandleChange}
                                type="text"
                                onKeyPress={PermiteSoloNumeros}
                                min={0}
                            />
                        </div>

                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_preciofijouser" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Precio fijo usuario</label>
                            <input name="preciofijouser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Precio fijo usuario"
                                autoComplete="off"
                                required
                                min={0}
                                step={0.01}
                                onWheel={(e) => e.target.blur()}
                                value={state.preciofijouser}
                                type="number"
                                onChange={HandleChange}
                            />
                            <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                S/
                            </div>
                        </div>
                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_preciofijoreseller" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Precio fijo reseller</label>
                            <input name="preciofijoreseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Precio fijo reseller"
                                autoComplete="off"
                                required
                                min={0}
                                step={0.01}
                                onWheel={(e) => e.target.blur()}
                                value={state.preciofijoreseller}
                                type="number"
                                onChange={HandleChange}
                            />
                            <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                S/
                            </div>
                        </div>
                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_porcentajeadicional" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Porcentaje adicional aplicado</label>
                            <input name="porcentajeadicional" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Porcentaje adicional aplicado"
                                autoComplete="off"
                                required
                                min={0}
                                max={100}
                                value={state.porcentajeadicional}
                                type="number"
                                onChange={HandleChange}
                            />
                            <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                %
                            </div>
                        </div>
                        <div className="w-full md:w-6/12 p-3 relative">
                            <label for="first_speedseconds" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Tiempo estimado para completar (segundos)</label>
                            <input name="speedseconds" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Tiempo estimado para completar"
                                autoComplete="off"
                                required
                                min={0}
                                value={state.speedseconds}
                                type="number"
                                onChange={HandleChange}
                            />
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Tipo de servicio</label>
                            <FormControl fullWidth>
                                <Select
                                    required
                                    name="servicetype"
                                    className="flex items-center"
                                    variant="outlined"
                                    value={state.servicetype}
                                    onChange={HandleChange}
                                >
                                    <MenuItem value={'default'} key={'default'} className="flex items-center">Default</MenuItem>
                                    <MenuItem value={'customcomments'} key={'customcomments'} className="flex items-center">Custom comments</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="w-full p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Ejemplo url en celular</label>
                            <input name="urlexamplephone" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Ejemplo url para celular"
                                autoComplete="off"
                                required
                                value={state.urlexamplephone}
                                type="text"
                                onChange={HandleChange}
                            />
                        </div>

                        <div className="w-full p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Ejemplo url en computadora</label>
                            <input name="urlexampledesktop" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                placeholder="Ejemplo url en computadora"
                                autoComplete="off"
                                required
                                value={state.urlexampledesktop}
                                type="text"
                                onChange={HandleChange}
                            />
                        </div>

                        <div className="w-full p-3">
                            <label className="text-neutral-900 dark:text-neutral-200 text-sm">Tipo de servicio <span className="font-semibold">{tipoentrega ? "automático" : "manual"}</span></label>
                            <div className="flex flex-col justify-between h-16 mt-3">

                                <span onClick={OnChangeTipoEntrega} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${tipoentrega && 'hidden'}`}>
                                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                </span>

                                <span onClick={OnChangeTipoEntrega} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!tipoentrega && 'hidden'}`}>
                                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                </span>
                            </div>
                        </div>

                        <div className="w-full p-3">
                            <label className="text-neutral-900 dark:text-neutral-200 text-sm">Estado del servicio <span className="font-semibold">{enabled ? "habilitado" : "deshabilitado"}</span></label>
                            <div className="flex flex-col justify-between h-16 mt-3">

                                <span onClick={OnChangeEnabled} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${enabled && 'hidden'}`}>
                                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                </span>

                                <span onClick={OnChangeEnabled} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!enabled && 'hidden'}`}>
                                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                </span>
                            </div>
                        </div>

                        <div className="w-full p-3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Términos y condiciones</label>
                            <Editor
                                editorState={editorState2}
                                onEditorStateChange={setEditorState2}
                                placeholder="Escribe aquí..."
                            />
                        </div>

                        <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                            <Button
                                type="submit"
                                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                size='large' variant="contained"
                                disabled={response.isLoading ? true : false}
                            >
                                {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                {response.isLoading ? 'Guardando...' : 'CREAR SERVICIO'}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Wrapper>
    )
}

export default CreateServiceSocial;