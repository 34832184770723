import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "../screens/auth/AdminLogin";
import Categories from "../screens/admin/Categories";
import CreateCategory from "../screens/admin/CreateCategory";
import Cuentas from "../screens/admin/Cuentas";
import CreateCuenta from "../screens/admin/CreateCuenta";
import Private from "./Private.js";
import Public from "./Public";
import Home from "../screens/home/Home";
import Donacion from "../screens/home/Donacion";
import Login from "../screens/home/auth/Login";
import Register from "../screens/home/auth/Register";
import Profile from "../screens/users/Profile";
import UserRoute from "./UserRoute";
import UserAuthRoute from "./UserAuthRoute";
import Recharge from "../screens/users/Recharge";
import StreamingOrders from "../screens/admin/StreamingOrders";
import GiftCardsOrders from "../screens/admin/GiftCardsOrders";
import History from "../screens/admin/History";
import CreateServiceSocial from "../screens/admin/CreateServiceSocial";
import UserOrders from "../screens/users/UserOrders";
import UserSocialOrders from "../screens/users/UserSocialOrders";
import Users from "../screens/admin/Users";
import UpdateUser from "../screens/admin/UpdateUser";
import CreateUser from "../screens/admin/CreateUser";
import Dashboard from "../screens/admin/Dashboard";
import ChangePassword from "../screens/users/ChangePassword";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useGetAllCategoriesQuery, useGetAllCategoriesStreamingByRatingQuery, useGetAllCategoriesStreamingQuery } from "../store/services/categoryService";
import { useGetAllUsersQuery, useGetUserByIdQuery } from "../store/services/userService";
import { useGetAllVentasByUserIdQuery, useGetAllVentasByPageQuery, useGetLastVentasQuery } from "../store/services/ventasService";
import { logout, setAdminToken, setOfflineUserToken, setUserToken } from "../store/reducers/authReducer";
import { currencyFormat, message, sleep } from "../utils/Funciones.js";
import toast, { Toaster } from "react-hot-toast";
import { MdClose, MdInfo, MdWarning } from "react-icons/md";
import CategoriesGC from "../screens/admin/CategoriesGC";
import CreateGiftCard from "../screens/admin/CreateGiftCard";
import GiftCards from "../screens/admin/GiftCards";
import { useGetAllGiftCardsQuery, useGetAllGiftCardsSellsByUserIdQuery } from "../store/services/giftcardService";
import { useCreateOfflineUserTokenMutation } from "../store/services/tokenService";
import ForgotPasswordReset from "../components/home/ForgotPasswordReset";
import { useGetAllPaymentsQuery, useGetPaymentsByUserIdQuery } from "../store/services/paymentService";
import PaymentsHistory from "../screens/users/PaymentsHistory";
import Payments from "../screens/admin/Payments";
import { FaCheck } from "react-icons/fa";
import { FcLock } from "react-icons/fc";
import MyGiftCards from "../screens/users/MyGiftCards";
import Configuration from "../screens/admin/Configuration";
import { useGetConfigurationQuery } from "../store/services/authService";
import SocialPlataforms from "../screens/admin/SocialPlataforms";
import SocialOrders from "../screens/admin/SocialOrders";
import {
  UPDATE_CATEGORY_FROM_SOCKET
} from "../store/features/categorySlice";
import Gracias from "../screens/home/Gracias";

const Routing = ({ socket }) => {
  const dispatch = useDispatch();
  const { userToken, user, offlineUserToken, admin, adminToken } = useSelector((state) => state.authReducer);
  const { categories, isLoading: categoryIsLoading, isError, isSuccess } = useSelector((state) => state.categoryReducer);
  //const { categories } = useSelector((state) => state.categoryReducer);
  const { data: userByIdFromdb, isFetching: fechingUseById } = useGetUserByIdQuery(userToken && user?.id);
  const { data: configurationfromdb, isFetchingConfiguration } = useGetConfigurationQuery();

  const [loadingconfiguration, setLoadingConfiguration] = useState(true)
  const [configuration, setConfiguration] = useState(null)

  const [userById, SetUserById] = useState(null)
  const [loadinguserbyid, SetLoadingUserByID] = useState(true)

  const [SECRET_KEY, SetSecretKey] = useState('')


  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        if (userToken) {
          socket.emit("registrarse", user?.id, user?.admin);
        } else if (adminToken && admin?.admin) {
          socket.emit("registrarse", admin?.id, admin?.admin);
        } else {
          socket.emit("registrarse", null, false);
        }
      });
    }
  }, [socket])

  useEffect(() => {
    // Maneja la reconexión cuando el socket se desconecta
    if (socket) {

      socket.on('disconnect', () => {
        // Aquí puedes realizar cualquier acción que necesites al desconectarse el socket
        // Intenta reconectar después de un breve retraso
        setTimeout(() => {
          console.log('Socket desconectado. Intentando reconectar...');
          socket.connect();
          if (userToken) {
            socket.emit("registrarse", user?.id, user?.admin);
          } else if (adminToken && admin?.admin) {
            socket.emit("registrarse", admin?.id, admin?.admin);
          } else {
            socket.emit("registrarse", null, false);
          }
        }, 1000); // Cambia el tiempo de espera según tus necesidades
      });
    }
  }, [socket]);

  useEffect(() => {
    async function onUpdateCash(data) {
      if (userToken) {
        SetUserById(data.token)
        if (data.creditsadd >= 0) {
          message(`Se te ah recargado ${currencyFormat(Number(data.creditsadd))}.`, 'success', 5)
        } else {
          message(`Se te ah penalizado con ${currencyFormat(Number(data.creditsadd))}`, 'error', 5)
        }
      }
    };

    async function onNuevoPedidoAdmin(datacategoria, status, username) {
      if (adminToken) {
        if (status === 'pending') {
          toast((t) => (
            <div className="flex inline-flex items-center">
              <div className="mr-3">
                <MdWarning size={24} color={'#fde047'} className="mr-2" />
              </div>
              <div className="">
                Tienes un nuevo pedido del usuario <b>{username}</b>, este pedido está pendiente de activación!
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });
        } else if (status === 'complete') {
          toast((t) => (
            <div className="flex inline-flex items-center">
              <div className="mr-3">
                <MdInfo size={24} color={'#60a5fa'} className="mr-2" />
              </div>
              <div className="">
                El usuario <b>{username}</b> ah realizado una nueva compra!
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });
        }
      }
    }


    async function onUpdateCategory(datacategoria, olddata, newdata) {
      if (userToken) {
        if ((Number(newdata.costoperfiluser) !== olddata.costoperfiluser) && !user.reseller) {
          toast((t) => (
            <div className="flex items-center">
              <div className="h-full w-[35px] flex items-center justify-center">
                <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                  <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                </div>
              </div>
              <div className="ml-3">
                El precio de 1 pantalla en la categoría {newdata.name} ah sido actualizado a {currencyFormat(Number(newdata.costoperfiluser))}
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });

        }
        if ((Number(newdata.costocuentauser) !== olddata.costocuentauser) && !user.reseller) {
          toast((t) => (
            <div className="flex items-center">
              <div className="h-full w-[35px] flex items-center justify-center">
                <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                  <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                </div>
              </div>
              <div className="ml-3">
                El precio de 1 cuenta completa en la categoría {newdata.name} ah sido actualizado a {currencyFormat(Number(newdata.costocuentauser))}
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });
        }
        if ((Number(newdata.costoperfilreseller) !== olddata.costoperfilreseller) && user.reseller) {
          toast((t) => (
            <div className="flex items-center">
              <div className="h-full w-[35px] flex items-center justify-center">
                <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                  <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                </div>
              </div>
              <div className="ml-3">
                El precio de 1 pantalla en la categoría {newdata.name} ah sido actualizado a {currencyFormat(Number(newdata.costoperfilreseller))}
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });
        }
        if ((Number(newdata.costocuentareseller) !== olddata.costocuentareseller) && user.reseller) {
          toast((t) => (
            <div className="flex items-center">
              <div className="h-full w-[35px] flex items-center justify-center">
                <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                  <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                </div>
              </div>
              <div className="ml-3">
                El precio de 1 cuenta completa en la categoría {newdata.name} ah sido actualizado a {currencyFormat(Number(newdata.costocuentareseller))}
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });
        }
        if ((Number(newdata.discountperfiluser) !== olddata.discountperfiluser) && !user.reseller) {
          toast((t) => (
            <div className="flex items-center">
              <div className="h-full w-[35px] flex items-center justify-center">
                <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                  <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                </div>
              </div>
              <div className="ml-3">
                El descuento de 1 pantalla en la categoría {newdata.name} ah sido actualizado a {Number(newdata.discountperfiluser)}% de descuento
              </div>
              <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                <div className="w-8 h-8">
                  <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                    <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                  </button>
                </div>
              </div>

            </div>
          ),
            {
              position: 'top-center',
              style: {
                borderRadius: '10px',
                background: '#0a0a0a',
                color: '#e5e5e5',
              }, duration: Infinity
            });
        }
        //SetCategory(await datacategoria)
      } else {
        //SetCategory(await datacategoria)
      }
    }

    async function onSecretKey(key) {
      SetSecretKey(key)
    }

    async function onLockOrRejectedVenta(data) {
      if (userToken) {
        if (user.id === data.userId) {
          if (data.type === 'rejected') {
            //SetVentasById(await data.ventasByUserId)
            message(`Se ah rechazado tu pedido : ${data.ventaId}, revisa tus pedidos para más detalles.`, 'error', 10)
            message(`Se te ah reembolsado : ${currencyFormat(data.totalamount)} de cash.`, 'success', 5)
            SetUserById(data.token)
          } else if (data.type === 'locked') {
            //SetVentasById(await data.ventasByUserId)
            //SetCategory(await data.datacategoria.filter((cat) => cat.type === 'Streaming'))
            message(`Se ah bloqueado tu servicio con código : ${data.ventaId}, porque ah pasado el tiempo máximo para su renovación.`, 'error', 10)
          }
        }

        if (data.type === 'locked') {
          //SetCategory(await data.datacategoria.filter((cat) => cat.type === 'Streaming'))
        }
      }
    }

    async function onRenewService(username, ventaId, quantitymonths) {
      if (adminToken) {
        message(`El usuario ${username} ah renovado su servicio con CÓDIGO : ${ventaId} por ${quantitymonths < 2 ? quantitymonths + ' mes' : quantitymonths + ' meses'} más.`, 'success', 5)
      }
    }

    async function onUpdatePinPerfil(datatarget) {
      if (userToken) {
        message(`El PIN de tu perfil de ${datatarget.categoria} en la cuenta ${datatarget.correo} ah sido modificado, revisa tus pedidos para mas detalles.`, 'success', 5)
      }
    }

    async function onUpdatePasswordCuenta(datatarget) {
      if (userToken) {
        message(`La contraseña de la cuenta ${datatarget.correo} en tu servicio de ${datatarget.categoria} ah sido modificada, revisa tus pedidos para mas detalles.`, 'success', 5)
      }
    }

    async function onVerifyPaymentYape(userId, token, dataPaymentsByUserId, amount, action) {
      if (userToken) {
        if (user.id === userId) {
          if (action === 'complete') {
            message(`Se ah completado un pago de YAPE, se te ah recargado ${currencyFormat(Number(amount))} a tu cuenta.`, 'success', 5)
            SetUserById(token)
          } else if (action === 'rejected') {
            message(`Tu recarga de YAPE ah sido rechazada, revise su historial para más detalles.`, 'error', 5)
          }
        }
      }
    }

    async function onYapePayment(callback) {
      if (adminToken) {
        toast((t) => (
          <div className="flex items-center">
            <div className="w-[128px] h-128px] h-full flex items-center justify-center">
              <img class="z-10 rounded-full scale"
                src={`/images/paymethods/yape.png`} alt={`No image yape`} />
            </div>
            <div className="ml-2">
              Tienes un nuevo pago de YAPE, el usuario <b>{callback.username}</b> ah recargado {currencyFormat(Number(callback.amount))}, verifica y completa la recarga.
            </div>
            <div className="w-8 h-full ml-[10px] flex items-center justify-center">
              <div className="w-8 h-8">
                <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                  <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                </button>
              </div>
            </div>

          </div>
        ),
          {
            position: 'top-center',
            style: {
              borderRadius: '10px',
              background: '#0a0a0a',
              color: '#e5e5e5',
            }, duration: Infinity
          });
      }
    }

    async function onLockAndUnlockUser(userId, token, state) {
      if (adminToken && admin.id === userId) {
        if (state === 'bloqueado') {
          message('Tu usuario ah sido bloqueado.', 'error', 5)
        } else if (state === 'desbloqueado') {
          message('Tu usuario ah sido desbloqueado.', 'success', 5)
        }
        localStorage.setItem('adminToken', token);
        dispatch(setAdminToken(token))

      } else if (userToken && user.id === userId) {
        if (state === 'bloqueado') {
          message('Tu usuario ah sido bloqueado.', 'error', 5)
        } else if (state === 'desbloqueado') {
          message('Tu usuario ah sido desbloqueado.', 'success', 5)
        }
        SetUserById(token)
      }
    }

    async function onUpdateUser(useredited, userold, passwordchange) {
      if (userToken) {
        if (user.id === userold._id) {
          if (useredited.name !== userold.name) {
            toast((t) => (
              <div className="flex items-center">
                <div className="h-full w-[35px] flex items-center justify-center">
                  <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                    <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                  </div>
                </div>
                <div className="ml-2">
                  El ADMINISTRADOR ah cambiado tu nombre a <b>{useredited.name}</b>.
                </div>
                <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                  <div className="w-8 h-8">
                    <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                      <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                    </button>
                  </div>
                </div>

              </div>
            ),
              {
                position: 'top-center',
                style: {
                  borderRadius: '10px',
                  background: '#0a0a0a',
                  color: '#e5e5e5',
                }, duration: Infinity
              });
          }
          if (useredited.email !== userold.email) {
            toast((t) => (
              <div className="flex items-center">
                <div className="h-full w-[35px] flex items-center justify-center">
                  <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                    <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                  </div>
                </div>
                <div className="ml-2">
                  El ADMINISTRADOR ah cambiado tu email a <b className="font-semibold">{useredited.email}</b>.
                </div>
                <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                  <div className="w-8 h-8">
                    <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                      <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                    </button>
                  </div>
                </div>

              </div>
            ),
              {
                position: 'top-center',
                style: {
                  borderRadius: '10px',
                  background: '#0a0a0a',
                  color: '#e5e5e5',
                }, duration: Infinity
              });
          }

          if (useredited.phonenumber !== userold.phonenumber) {
            toast((t) => (
              <div className="flex items-center">
                <div className="h-full w-[35px] flex items-center justify-center">
                  <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                    <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                  </div>
                </div>
                <div className="ml-2">
                  El ADMINISTRADOR ah cambiado tu numero de celular a <b>{useredited.phonenumber}</b>.
                </div>
                <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                  <div className="w-8 h-8">
                    <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                      <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                    </button>
                  </div>
                </div>

              </div>
            ),
              {
                position: 'top-center',
                style: {
                  borderRadius: '10px',
                  background: '#0a0a0a',
                  color: '#e5e5e5',
                }, duration: Infinity
              });
          }
          if (passwordchange) {
            toast((t) => (
              <div className="flex items-center">
                <div className="h-full w-[35px] flex items-center justify-center">
                  <div style={{ background: '#53C522' }} className="rounded-full w-[22px] h-[22px] flex items-center justify-center">
                    <FaCheck className="text-neutral-100 w-[12px] h-[12px]" />
                  </div>
                </div>
                <div className="ml-2">
                  El ADMINISTRADOR ah cambiado tu contraseña.
                </div>
                <div className="w-8 h-full ml-[10px] flex items-center justify-center">
                  <div className="w-8 h-8">
                    <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                      <MdClose size={22} className="text-neutral-800 dark:text-neutral-200" />
                    </button>
                  </div>
                </div>

              </div>
            ),
              {
                position: 'top-center',
                style: {
                  borderRadius: '10px',
                  background: '#0a0a0a',
                  color: '#e5e5e5',
                }, duration: Infinity
              });
          }
          SetUserById(useredited)
        }
      }
    }

    async function onUpdateConfiguration(config) {
      setLoadingConfiguration(true)
      setConfiguration(await config)
      setLoadingConfiguration(false)
    }

    async function onDisconectUsers() {
      if (userToken) {
        socket.emit('logout')
        dispatch(logout('userToken'))
        sleep(500)
        window.location.href = '/'
      }
    }

    async function onNuevoPedidoStreaming(socketdata) {
      socketdata.action = 'nuevopedido_streaming'
      dispatch(UPDATE_CATEGORY_FROM_SOCKET(socketdata))
    }

    async function onNuevoPedidoGiftCard(socketdata) {
      socketdata.action = 'nuevopedido_giftcard'
      dispatch(UPDATE_CATEGORY_FROM_SOCKET(socketdata))
    }

    async function onNuevaCuenta(createdata) {
      createdata.action = 'nuevacuenta'
      dispatch(UPDATE_CATEGORY_FROM_SOCKET(createdata))
    }

    async function onDeleteCuenta(deletedata) {
      deletedata.action = 'deletecuenta'
      dispatch(UPDATE_CATEGORY_FROM_SOCKET(deletedata))
    }

    /**INICIA SOCKETS FUNCTIONS */

    socket.on('yapepayment', onYapePayment)
    socket.on('verifypaymentyape', onVerifyPaymentYape)
    socket.on('updatepinperfil', onUpdatePinPerfil)
    socket.on('updatepasswordcuenta', onUpdatePasswordCuenta)
    socket.on('renewservice', onRenewService)
    socket.on('lockedorrejectedventa', onLockOrRejectedVenta)
    socket.on('updatecash', onUpdateCash)
    socket.on('nuevopedidoadmin', onNuevoPedidoAdmin)
    socket.on('nuevopedido_streaming', onNuevoPedidoStreaming)
    socket.on('nuevopedidoGC', onNuevoPedidoGiftCard)
    socket.on('nuevacuenta', onNuevaCuenta)
    socket.on('deletecuenta', onDeleteCuenta)
    //socket.on('updatecategory', onUpdateCategory)
    socket.on('updateuser', onUpdateUser)
    socket.on('lockandunlockuser', onLockAndUnlockUser)
    socket.on('secretkey', onSecretKey)
    socket.on('updateconfiguration', onUpdateConfiguration)
    socket.on('disconectusers', onDisconectUsers)

    /** TERMINA SOCKET FUNCTIONS */
    return () => {
      // Cleanup: Disconnect the socket when the component unmounts.
      socket.disconnect();
    }
  }, [])

  useEffect(async () => {
    if (!isFetchingConfiguration) {
      setLoadingConfiguration(true)
      setConfiguration(await configurationfromdb?.configuration[0])
      setLoadingConfiguration(false)
    }
  }, [configurationfromdb?.configuration])

  useEffect(async () => {
    if (!fechingUseById && userToken) {
      SetUserById(await userByIdFromdb?.user)
      SetLoadingUserByID(false)
    }
  }, [userByIdFromdb?.user])

  const [side, setSide] = useState('-translate-x-full')

  const openCloseSidebar = () => {
    if (side === "-translate-x-full") {
      setSide("")
    } else {
      setSide("-translate-x-full");
    }
  }

  const UpdateUserById = async (newData) => {
    SetLoadingUserByID(true)
    SetUserById(await newData)
    SetLoadingUserByID(false)
  }

  return (
    <>
      {
        (!loadinguserbyid && userById.locked) ?
          <div className="w-full h-screen flex items-center justify-center">
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex-wrap text-center text-red-500 text-2xl">
              <FcLock size={128} className="m-auto" />
              BLOQUEADO
              <p className="w-full md:w-4/12 m-auto text-neutral-800 dark:text-neutral-200 text-sm mt-0 p-5">Su usuario ah sido bloqueado
                por inclumplir algunas reglas, para mayor información
                comuniquese con atención al cliente, haz click en el icono de whatsapp que esta abajo a la derecha.</p>
            </div>
          </div>
          :
          <BrowserRouter>
            <Routes>
              <Route path="/" element={
                <Home
                  socket={socket}
                  userToken={userToken}
                  user={user}
                  userById={userById}
                  loadinguserbyid={loadinguserbyid}
                  UpdateUserById={UpdateUserById}
                  configuration={configuration}
                  loadingconfiguration={loadingconfiguration}
                  categories={categories}
                  CategoryIsLoading={categoryIsLoading}
                />} />


              <Route path="/donacion" element={
                <Donacion />} />

              <Route path="/gracias" element={
                <Gracias />} />


              <Route element={<UserAuthRoute socket={socket} />}>
                <Route path="forgotpasswordreset/:key" element={
                  <ForgotPasswordReset
                    userToken={userToken}
                    user={user}
                    openCloseSidebar={openCloseSidebar}
                    loadinguserbyid={loadinguserbyid}
                    userById={userById}
                    configuration={configuration}
                    loadingconfiguration={loadingconfiguration}
                  />} />
                <Route path="login" element={<Login
                  socket={socket}
                  UpdateUserById={UpdateUserById}
                  configuration={configuration}
                  loadingconfiguration={loadingconfiguration}
                />} />
                <Route path="register" element={<Register
                  socket={socket}
                  UpdateUserById={UpdateUserById}
                  configuration={configuration}
                  loadingconfiguration={loadingconfiguration}
                />} />
              </Route>
              <Route element={<UserRoute />}>
                <Route path="manage-account">
                  <Route path="profile" element={
                    <Profile
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      UpdateUserById={UpdateUserById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />
                  <Route path="changepassword" element={
                    <ChangePassword
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />
                  <Route path="userorders" element={
                    <UserOrders
                      SECRET_KEY={SECRET_KEY}
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      UpdateUserById={UpdateUserById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />

                  <Route path="usersocialorders" element={
                    <UserSocialOrders
                      SECRET_KEY={SECRET_KEY}
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      UpdateUserById={UpdateUserById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />

                  <Route path="mygiftcards" element={
                    <MyGiftCards
                      SECRET_KEY={SECRET_KEY}
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />
                  <Route path="paymentshistory" element={
                    <PaymentsHistory
                      SECRET_KEY={SECRET_KEY}
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />
                  <Route path="recharge" element={
                    <Recharge
                      socket={socket}
                      userToken={userToken}
                      user={user}
                      UpdateUserById={UpdateUserById}
                      loadinguserbyid={loadinguserbyid}
                      userById={userById}
                      configuration={configuration}
                      loadingconfiguration={loadingconfiguration}
                    />} />
                </Route>
              </Route>
              <Route path="auth">
                <Route
                  path="admin-login"
                  element={
                    <Public>
                      <AdminLogin
                        socket={socket} />
                    </Public>
                  }
                />
              </Route>

              <Route path="admin">
                <Route
                  path="dashboard"
                  element={
                    <Private>
                      <Dashboard
                        socket={socket}
                        adminToken={adminToken}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                        categories={categories}
                        categoryIsLoading={categoryIsLoading}
                      />
                    </Private>
                  }
                />

                <Route
                  path="users"
                  element={
                    <Private>
                      <Users
                        socket={socket}
                        adminToken={adminToken}
                        admin={admin}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="update-user/:id"
                  element={
                    <Private>
                      <UpdateUser
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="create-user"
                  element={
                    <Private>
                      <CreateUser
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="cuentas"
                  element={
                    <Private>
                      <Cuentas
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="create-cuenta"
                  element={
                    <Private>
                      <CreateCuenta
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="categories"
                  element={
                    <Private>
                      <Categories
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                        categories={categories}
                        categoryIsLoading={categoryIsLoading}
                      />
                    </Private>
                  }
                />

                <Route
                  path="categoriesGC"
                  element={
                    <Private>
                      <CategoriesGC
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                        categories={categories}
                        categoryIsLoading={categoryIsLoading}
                      />
                    </Private>
                  }
                />

                <Route
                  path="create-category"
                  element={
                    <Private>
                      <CreateCategory
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                        categories={categories}
                        CategoryIsLoading={categoryIsLoading}
                      />
                    </Private>
                  }
                />

                <Route
                  path="create-giftcard"
                  element={
                    <Private>
                      <CreateGiftCard
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                        categories={categories}
                        categoryIsLoading={categoryIsLoading}
                      />
                    </Private>
                  }
                />

                <Route
                  path="giftcards"
                  element={
                    <Private>
                      <GiftCards
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="streamingorders"
                  element={
                    <Private>
                      <StreamingOrders
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        user={user}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="giftcardsorders"
                  element={
                    <Private>
                      <GiftCardsOrders
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        user={user}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="socialorders"
                  element={
                    <Private>
                      <SocialOrders
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        user={user}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="payments"
                  element={
                    <Private>
                      <Payments
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />


                <Route
                  path="history"
                  element={
                    <Private>
                      <History
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="create-social-service"
                  element={
                    <Private>
                      <CreateServiceSocial
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="socialplataforms"
                  element={
                    <Private>
                      <SocialPlataforms
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        userToken={userToken}
                        configuration={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />

                <Route
                  path="configuration"
                  element={
                    <Private>
                      <Configuration
                        SECRET_KEY={SECRET_KEY}
                        socket={socket}
                        configurations={configuration}
                        loadingconfiguration={loadingconfiguration}
                      />
                    </Private>
                  }
                />
              </Route>
            </Routes>
          </BrowserRouter>
      }
    </>
  );
};
export default Routing;
