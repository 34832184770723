import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const gcventasService = createApi({
  reducerPath: "gcventa",
  tagTypes: "gcventa",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      headers.set('authorization', userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createGcVenta: builder.mutation({
        query: (dataventa) => {
          return {
            url: "create-gcventa",
            method: "POST",
            body: dataventa,
          };
        },
        invalidatesTags: ["gcventa"],
      }),

      getAllGcVentasByUserId: builder.query({
        query: (params) => {
          var finalquery = ''
          if(params.search === ''){
            finalquery = 'emptyquery'
          }else{
            finalquery = params.search
          }
          return {
            url: `get-gcventas-userid/${params.id}/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["gcventa"],
      }),

      getAllGcVentasByPage: builder.query({
        query: (params) => {
            var finalquery = ''
            if (params.search === '') {
                finalquery = 'emptyquery'
            } else {
                finalquery = params.search
            }
            return {
                url: `getallgcventasbypage/${finalquery}/${params.page}/${params.limit}`,
                method: "GET",
            };
        },
        providesTags: ["gcventa"],
    }),
    };
  },
});
export const {
  useCreateGcVentaMutation,
  useGetAllGcVentasByPageQuery,
  useGetAllGcVentasByUserIdQuery,
} = gcventasService;
export default gcventasService;
