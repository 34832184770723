import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode"
const customerToken = localStorage.getItem('userToken')
const administratorToken = localStorage.getItem('admin-token')
const purchaseToken = localStorage.getItem('purchaseToken')

function verifyToken(keyName) {
  const storage = localStorage.getItem(keyName);
  if (storage) {
    const decodeToken = jwtDecode(storage);
    const expiresIn = new Date(decodeToken.exp * 1000);
    if (new Date() > expiresIn) {
      localStorage.removeItem(keyName);
      return null;
    } else {
      return storage;
    }
  } else {
    return null;
  }
}
const authReducer = createSlice({
  name: 'authReducer',
  initialState: {
    adminToken: verifyToken('admin-token'),
    admin: administratorToken ? jwtDecode(administratorToken) : null,
    userToken: verifyToken('userToken'),
    user: customerToken ? jwtDecode(customerToken) : null,
    purchaseToken: verifyToken('purchaseToken'),
    purchase: purchaseToken ? jwtDecode(purchaseToken) : null,
    offlineUserToken: verifyToken('offlineUserToken'),
  },
  reducers: {
    setAdminToken: (state, action) => {
      state.adminToken = action.payload;
      state.admin = jwtDecode(action.payload);
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
      state.user = jwtDecode(action.payload);
    },
    setOfflineUserToken: (state, action) => {
      state.offlineUserToken = action.payload;
    },
    setPurchaseToken: (state, action) => {
      state.purchaseToken = action.payload;
      state.purchase = jwtDecode(action.payload);
    },
    deletePurchaseToken: (state, { payload }) => {
      localStorage.removeItem(payload)
      state.purchaseToken = null;
      state.purchase = null;
    },
    deleteOfflineUserToken: (state, { payload }) => {
      localStorage.removeItem(payload)
      state.offlineUserToken = null;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem(payload)
      if (payload === 'admin-token') {
        state.adminToken = null;
        state.admin = null;
      } else if (payload === 'userToken') {
        state.userToken = null;
        state.user = null;
      }
    }
  }
})
export const { setAdminToken, setUserToken, setOfflineUserToken, setPurchaseToken, deletePurchaseToken, deleteOfflineUserToken, logout } = authReducer.actions;
export default authReducer.reducer;