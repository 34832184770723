import { useEffect, useMemo, useState } from "react"
import { Toaster } from 'react-hot-toast';
import { useCountAllCuentasQuery, useDeleteCuentaMutation, useGetAllCuentasByPageQuery } from "../../store/services/cuentaService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import { MdAlarmOn, MdAllInbox, MdContactMail, MdDelete, MdDeviceHub, MdEdit, MdTimer, MdTimerOff, MdTimeToLeave } from 'react-icons/md';
import { IconButton, PaginationItem, Stack, TextField, Tooltip } from "@mui/material";
import { CalcularExpiracion, GenerarAlertasSistemaPerfiles, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { IoAdd, IoAlarm, IoCloseSharp, IoLockClosed, IoLogoWhatsapp, IoMail, IoPerson, IoSearch, IoTime, IoTimerSharp } from "react-icons/io5";
import UpdateCuenta from "./UpdateCuenta";
import Wrapper from "./Wrapper";
import debounce from 'lodash.debounce';
import { useNavigate, Link } from "react-router-dom";
import { FcPlus } from "react-icons/fc";
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SpinnerData from "../../components/SpinnerData";
import Modal from '@mui/material/Modal';
import { useGetAllCategoriesStreamingQuery } from "../../store/services/categoryService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Spinner2 from "../../components/Spinner2";

const Cuentas = ({
   SECRET_KEY,
   socket,
   configuration,
   loadingconfiguration
}) => {
   const navigate = useNavigate();
   const [curTime, SetCurTime] = useState(null)
   const [delCuenta, response] = useDeleteCuentaMutation();
   const [PasswordEdited, SetPasswordEdited] = useState({ open: false, data: null })
   const [openeditcuenta, SetOpenEditCuenta] = useState({ _id: "", open: false, cuentaData: null })
   const [query, setQuery] = useState("");
   const [verFilterCuentas, SetFilterCuentas] = useState("")

   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [limit, setLimit] = useState(30);

   const { data: cuentas, isFetching: fechingCuentas, refetch: refetchCuentas } = useGetAllCuentasByPageQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
   const { data: countcuentas, isFetching: isFetchingcountcuentas, refetch: refetchcoutcuentas } = useCountAllCuentasQuery();
   const { data: category, isFetching: loadingcategory } = useGetAllCategoriesStreamingQuery();

   useEffect(() => {
      const interval = setInterval(async () => {
         SetCurTime(new Date().toLocaleString());
      }, 1000);
      return () => clearInterval(interval);
   }, [])


   useEffect(async () => {
      await refetchCuentas()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetchCuentas.abort();
      };
   }, [searchTerm, currentPage, limit]);

   useEffect(async () => {
      await refetchcoutcuentas()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetchcoutcuentas.abort();
      };
   }, [countcuentas])

   useEffect(() => {
      if (response.isSuccess) {
         message(response?.data?.msg, 'success', 5)
         socket.emit('deletecuenta', response?.data?.deletedata)
      }
   }, [response?.data?.msg])

   useEffect(() => {
      if (!response.isSuccess) {
         response?.error?.data?.errors.map(err => {
            message(err?.msg, 'error', 5)
         })
      }
   }, [response?.error?.data?.errors])

   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const deleteCuenta = id => {
      var cuentaIsUsed = 0
      !fechingCuentas && cuentas?.cuentas.find((cuenta) => {
         if (cuenta._id === id) {
            cuentaIsUsed = cuenta.estadouso
         }
      })

      if (cuentaIsUsed > 0) {
         message('Esta cuenta ya está en uso y no se puede eliminar', 'error', 4)
      } else {
         alertaConfirmar('Eliminar Cuenta', 'Estas seguro que quieres eliminar esta cuenta? recuerda que se eliminarán todos los datos relacionados a esta cuenta.', 'question', (res_alert) => {
            if (res_alert === true) {
               delCuenta(id);
            }
         })
      }
   }

   const handleChangePage = (e, p) => {
      setCurrentPage(p);
   };

   const changeHandleSearch = (event) => {
      SetFilterCuentas('')
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 300), []);

   const HandleOpenEditCuenta = (id) => {
      if (!fechingCuentas) {
         const cuenta = cuentas?.cuentas.find((c) => {
            if (c._id === id) {
               return c
            }
         })
         SetOpenEditCuenta({ ...openeditcuenta, _id: id, open: true, cuentaData: cuenta });
      }
   }

   const HandleCloseEditCuenta = () => {
      SetOpenEditCuenta({ ...openeditcuenta, _id: "", open: false, cuentaData: null });
   }

   const isPasswordEdited = (data) => {
      SetPasswordEdited({ ...PasswordEdited, open: true, data: data })
   }

   const HandleChangeFilterCuentas = async (state) => {
      setSearchTerm(state)
      SetFilterCuentas(state)
      setCurrentPage(1)
   }

   const refetchcuentas = async () => {
      await refetchCuentas()
      await refetchcoutcuentas()
      return async () => {
         // Abort the ongoing request if the component unmounts or if the query is refetched
         await refetchCuentas.abort();
         await refetchcoutcuentas.abort();
      };
   }

   return (
      <Wrapper socket={socket} configuration={configuration}
         loadingconfiguration={loadingconfiguration}>
         <Modal
            open={openeditcuenta.open}
            onClose={HandleCloseEditCuenta}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-y-auto fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-8/12 bg-white dark:bg-black">
                  <UpdateCuenta
                     socket={socket}
                     SECRET_KEY={SECRET_KEY}
                     category={category?.categories}
                     loadingcategory={loadingcategory}
                     cuenta={openeditcuenta.cuentaData}
                     HandleCloseEditCuenta={HandleCloseEditCuenta}
                     isPasswordEdited={isPasswordEdited}
                     refetchcuentas={refetchcuentas}
                  />
               </div>
            </div>
         </Modal>
         <Toaster position="top-right" reverseOrder={true} />
         <ScreenHeader>
            <div className="w-full h-14 text-lg md:text-2xl flex items-center">
               <div className="flex items-center w-9/12 md:w-6/12">
                  <MdContactMail size={32} style={{ marginRight: 5 }} />
                  CUENTAS
                  <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
                     {!fechingCuentas && cuentas?.totalcuentas}
                  </div>
               </div>
               <div className="flex items-center justify-end w-3/12 md:w-6/12">
                  <Link to="/admin/create-cuenta" className="w-16 h-16 flex justify-end">
                     <Tooltip title="Agregar cuenta" arrow={true}>
                        <div className="w-16 h-16 rounded-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-900">
                           <IconButton color="primary" className="w-16 h-16">
                              <FcPlus size={45} />
                           </IconButton>
                        </div>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </ScreenHeader>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[48px] fixed z-20 p-4 bg-neutral-50 dark:bg-neutral-950">
            <div className="relative">
               <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
               </div>
               <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Escribe el nombre de categoría o correo"
                  required
                  onChange={debouncedChangeHandler}
                  autoComplete="off"
               />
            </div>

            <div className="w-full flex items-center justify-center md:justify-end text-neutral-200 mt-4">
               <div className="hidden md:block">
                  <Stack spacing={2}>
                     <Pagination
                        count={Math.ceil(!fechingCuentas && Number(cuentas?.totalcuentasFilter) / limit)}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="text"
                        color="primary"
                        shape="rounded"
                        size="large"
                        renderItem={(item) => (
                           <PaginationItem
                              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                              {...item}
                           />
                        )}
                        className="bg-neutral-200 dark:bg-neutral-900"
                     />
                  </Stack>
               </div>
               <div className="block md:hidden">
                  <Stack spacing={2}>
                     <Pagination
                        count={Math.ceil(!fechingCuentas && Number(cuentas?.totalcuentasFilter) / limit)}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="text"
                        color="primary"
                        shape="rounded"
                        size="small"
                        renderItem={(item) => (
                           <PaginationItem
                              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                              {...item}
                           />
                        )}
                        className="bg-neutral-200 dark:bg-neutral-900"
                     />
                  </Stack>
               </div>
            </div>
         </div>

         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[160px] md:mt-[180px] fixed z-20 pt-9 pl-4 bg-neutral-50 dark:bg-neutral-950">
            {
               <Tooltip title="Click aquí para filtrar cuentas expiradas" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("expired")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "expired" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-400 p-3 rounded-lg`}>
                     <div className="flex inline-flex items-center"><MdTimerOff className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                              {!isFetchingcountcuentas ? countcuentas?.totalcuentasExpired : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            }

            {
               <Tooltip title="Click aquí para filtrar cuentas próximas a expirar" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("proxim")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "proxim" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-orange-400 p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdTimer className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                              {!isFetchingcountcuentas ? countcuentas?.totalcuentasProxExpired : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            }

            {
               <Tooltip title="Click aquí para filtrar cuentas que tienen todos los perfiles expirados" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("allprofilesexpired")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "allprofilesexpired" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-400 p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdAlarmOn className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                              {!isFetchingcountcuentas ? countcuentas?.totalcuentaswhitallprofilesexpired : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            }

            {
               <Tooltip title="Click aquí para filtrar cuentas bloqueadas" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("locked")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "locked" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-red-500 p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><IoLockClosed className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                              {!isFetchingcountcuentas ? countcuentas?.totalcuentasLocked : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            }

            {
               <Tooltip title="Click aquí para filtrar todas las cuentas" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "" && "border-dashed border-2 border-neutral-500"} bg-neutral-200 dark:bg-neutral-900 text-green-400 p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdContactMail className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-neutral-200 dark:bg-neutral-900 items-center justify-center text-neutral-900 dark:text-neutral-200 font-semibold text-base md:text-xl">
                              {!fechingCuentas ? cuentas?.totalcuentas : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            }
         </div>

         {!fechingCuentas ? cuentas?.cuentas.length > 0 ? (
            <div className="flex flex-wrap mt-[310px]">
               {
                  cuentas?.cuentas.map((cuenta) => {
                     var CategoryName = ""
                     var CategoryLogo = ""
                     var MaximoPerfiles = 0

                     !loadingcategory && category?.categories.find((category) => {
                        if (cuenta.categoryId === category._id) {
                           category.subcategory.find((sub) => {
                              if (cuenta.subcategoryId === sub._id) {
                                 CategoryName = sub.name
                                 MaximoPerfiles = sub.maximoperfiles
                                 CategoryLogo = sub.logo
                              }
                           })
                        }
                     })

                     var i = 1
                     var ii = 1
                     const p_Availables = []
                     const p_Used = []
                     for (i = 0; i < MaximoPerfiles - cuenta.estadouso; i++) {
                        p_Availables.push(<div key={i} className="rounded-full ml-2 mt-1"><IoPerson size={15} className="text-green-600 rounded-full" /></div>)
                     }

                     for (ii = 0; ii < cuenta.estadouso; ii++) {
                        p_Used.push(<div key={ii} className="rounded-full ml-2 mt-1"><IoPerson size={15} className="text-red-600 rounded-full" /></div>)
                     }

                     return (
                        <div className={`bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 shadow-xl transition-all duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer hover:cursor-pointer p-4 w-full md:w-12/12 flex flex-wrap mb-6 rounded-lg animation`} key={cuenta._id}>
                           <div className="grid grid-wrap w-full md:w-5/12">
                              <div className="flex inline-flex items-center">
                                 <LazyLoadImage
                                    className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex"
                                    alt={`No image ${CategoryName}`}
                                    effect="blur"
                                    src={`/images/logos/${CategoryLogo}`}
                                 />
                                 <div className="grid self-center">
                                    <div className="ml-2 text-base font-semibold truncate ...">{cuenta.correo}</div>
                                    <div className="ml-2 text-sm">{CategoryName}</div>
                                 </div>
                              </div>
                           </div>

                           <div className="grid grid-wrap w-full md:w-3/12 mt-2 md:mt-0">
                              <div className="flex inline-flex items-center text-sm"><IoTime size={28} className="mr-2" /> {setUnixToTimeFormat("info_contacto", cuenta.created_at)}</div>
                              <div className="flex inline-flex items-center text-sm"><IoAlarm size={28} className="mr-2" />
                                 {
                                    <span>{CalcularExpiracion(cuenta.expirationdate) === "Caducado" ?
                                       <span className="text-red-400">
                                          <span class="relative flex">
                                             <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                             <span class="relative inline-flex rounded-full items-center justify-center text-red-400 font-semibold">
                                                Esta cuenta ah expirado
                                             </span>
                                          </span>
                                       </span> : <div>{(CalcularExpiracion(cuenta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(cuenta.expirationdate).substring(0, 1)) >= 0 &&
                                          Number(CalcularExpiracion(cuenta.expirationdate).substring(0, 1)) < 3) ? <span className="text-orange-400">{CalcularExpiracion(cuenta.expirationdate)}</span> : CalcularExpiracion(cuenta.expirationdate)}</div>}</span>}</div>
                           </div>

                           <div className="flex inline-flex items-center justify-end w-full md:w-4/12 mt-3 md:mt-0">
                              <div className="mr-5">
                                 {cuenta.locked &&
                                    <Tooltip title="cuenta bloqueada" arrow={true}>
                                       <IoLockClosed size={45} className="text-red-500" />
                                    </Tooltip>
                                 }
                              </div>

                              <div className="flex inline-flex items-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">

                                 <div className="w-14 h-14 text-xl text-center grid grid-wrap items-center justify-center rounded-full mr-2">
                                    <Tooltip title="perfiles usados" arrow={true}>
                                       <div>
                                          <IoPerson
                                             className="text-red-600"
                                             size={20}
                                          />
                                          {p_Used.length}
                                       </div>
                                    </Tooltip>
                                 </div>
                                 <div className="w-14 h-14 text-xl text-center grid grid-wrap items-center justify-center rounded-full mr-2">
                                    <Tooltip title="perfiles disponibles" arrow={true}>
                                       <div>
                                          <IoPerson
                                             className="text-green-600"
                                             size={20}
                                          />
                                          {p_Availables.length}
                                       </div>
                                    </Tooltip>
                                 </div>

                                 <div className="w-14 h-14 rounded-full mr-2">
                                    <Tooltip title="editar" arrow={true}>
                                       <IconButton onClick={() => HandleOpenEditCuenta(cuenta._id)} className="w-14 h-14" color='primary'>
                                          <MdEdit
                                             className="text-yellow-600"
                                             size={25}
                                          />
                                       </IconButton>
                                    </Tooltip>
                                 </div>

                                 <div className="w-14 h-14 rounded-full">
                                    <Tooltip title="eliminar" arrow={true}>
                                       <IconButton onClick={() => deleteCuenta(cuenta._id)} className="w-14 h-14" color="primary" disabled={cuenta.estadouso > 0 ? true : false}>
                                          <MdDelete
                                             size={25}
                                             className={`${cuenta.estadouso > 0 ? 'text-neutral-400 dark:text-neutral-700' : 'text-red-600'}`}
                                          />
                                       </IconButton>
                                    </Tooltip>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )
                  })
               }
            </div>) : (<div className="alert-danger font-semibold mt-[310px]">Sin resultados en la búsqueda! o no existen cuentas registradas</div>) : (<div className="mt-[310px]"><SpinnerData>Cargando cuentas, por favor espere...</SpinnerData></div>)}

      </Wrapper>
   )
}
export default Cuentas;