// App.js
import { Pagination, PaginationItem, Stack, TextField, Link, Tooltip, IconButton, Button } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast, { Toaster } from "react-hot-toast";
import { FaArrowDown, FaArrowUp, FaClipboard, FaShippingFast, FaUser } from 'react-icons/fa';
import { IoAlarm, IoBagCheck, IoBan, IoBuild, IoCheckbox, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoReload, IoReloadCircleSharp, IoRocket, IoSave, IoSearch, IoThumbsDownSharp, IoTime } from 'react-icons/io5';
import ScreenHeader from '../../components/ScreenHeader';
import SpinnerData from '../../components/SpinnerData';
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat } from '../../utils/Funciones';
import Swal from "sweetalert2";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import Modal from '@mui/material/Modal';
import Wrapper from './Wrapper';
import moment from 'moment';
import { MdAdminPanelSettings, MdAvTimer, MdClose, MdDelete, MdDevices, MdMail, MdOutgoingMail, MdPhoneIphone, MdTimer, MdTimerOff, MdVerified, MdWarning } from 'react-icons/md';
import Spinner from '../../components/Spinner';
import Spinner2 from '../../components/Spinner2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useDeleteAllHistoryMutation, useGetHistoryPageQuery } from '../../store/services/historyService';
import { useGetAllUsersQuery } from '../../store/services/userService';
import { useGetAllCategoriesQuery, useGetAllCategoriesStreamingQuery } from '../../store/services/categoryService';

const History = ({
  socket,
  SECRET_KEY,
  configuration,
  loadingconfiguration
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(30);
  const { data: history, isFetching, refetch } = useGetHistoryPageQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
  const { data: users, isFetching: loadingusers } = useGetAllUsersQuery();
  const { data: category, isFetching: loadingcategory } = useGetAllCategoriesQuery();

  useEffect(async () => {
    await refetch()
    return async () => {
      // Abort the ongoing request if the component unmounts or if the query is refetched
      await refetch.abort();
    };
  }, [searchTerm, currentPage, limit]);

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar Historial',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }


  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const [sendDeleteHistory, responseDel] = useDeleteAllHistoryMutation();
  const handleDeleteHistory = () => {

    alertaConfirmar('Eliminar Historial', 'Esta acción eliminará todo el historial, deseas continuar?.', 'question', (res_alert) => {
      if (res_alert === true) {
        sendDeleteHistory()
      }
    })
  }

  useEffect(() => {
    if (responseDel.isSuccess) {
      message(responseDel?.data?.message, 'success', 4)
    }
  }, [responseDel?.data])

  useEffect(() => {
    if (!responseDel.isSuccess) {
      responseDel?.error?.data?.errors.map(err => {
        message(err?.msg, 'error', 4)
      })
    }
  }, [responseDel?.error?.data?.errors])

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  return (

    <Wrapper socket={socket} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <ScreenHeader>
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-9/12 md:w-6/12">
            <IoBagCheck size={32} className="mr-2" /> HISTORIAL
            <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
              {!isFetching && history?.totalhistories}
            </div>
          </div>
        </div>
      </ScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pt-4 pb-3">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe correo de usuario..."
            required
            onChange={debouncedChangeHandler}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] pb-5 mt-[128px] pt-[15px] fixed z-20 bg-neutral-50 dark:bg-neutral-950">
        <div className="w-full flex items-center justify-center md:justify-end text-neutral-200 mt-0 h-10">
          {
            !isFetching ?
              <>
                <div className="hidden md:block">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!isFetching && Number(history?.totalhitoriesFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                      sx={{
                        "& .MuiPaginationItem-page": { color: "#e5e5e5", fontSize: 15, border: "2px solid #1d4ed8", fontFamily: 'Jost, sans-serif' },
                        "& .MuiPaginationItem-icon": { color: '#334155' },
                        "& .Mui-selected": { backgroundColor: "#1d4ed8" },
                        "& .MuiPaginationItem-ellipsis": { color: '#e5e5e5', fontSize: 25 },
                      }}
                    />
                  </Stack>
                </div>
                <div className="block md:hidden">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!isFetching && Number(history?.totalhitoriesFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                      sx={{
                        "& .MuiPaginationItem-page": { color: "#e5e5e5", fontSize: 15, border: "2px solid #1d4ed8", fontFamily: 'Jost, sans-serif' },
                        "& .MuiPaginationItem-icon": { color: '#334155' },
                        "& .Mui-selected": { backgroundColor: "#1d4ed8" },
                        "& .MuiPaginationItem-ellipsis": { color: '#e5e5e5', fontSize: 25 },
                      }}
                    />
                  </Stack>
                </div>
              </>
              : <Spinner />
          }

        </div>
        <div className='flex inline-flex items-center justify-end w-full md:w-[4.333337%]'>
          <div className="w-14 h-14 rounded-full bg-neutral-200 dark:bg-neutral-900 mt-3 md:mt-0">
            <Tooltip title="eliminar historial" arrow={true}>
              <IconButton onClick={handleDeleteHistory} className="w-14 h-14" color="primary">
                <MdDelete size={28} color='red' />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="mt-[280px]">
        {!isFetching ? history?.histories.length > 0 ? history?.histories.map((histories) => {
          var username = ""
          var email = ""
          var categoryName = ""
          var categoryName = ""
          var categoryLogo = ""
          var isStreaming = false

          !loadingusers && users?.users.find((_u) => {
            if (_u._id === histories.userId) {
              username = _u.name
              email = _u.email
            }
          })

          !loadingcategory && category?.categories.find((cat) => {
            if (histories.categoryId === cat._id) {
              cat.subcategory.find((sub) => {
                if (histories.subcategoryId === sub._id) {
                  isStreaming = true
                  categoryName = sub.name
                  categoryLogo = <LazyLoadImage
                    className="w-6 h-6 z-10 rounded-full flex inline-flex object-cover mr-2"
                    alt={`No image ${sub.logo}`}
                    effect="blur"
                    src={`/images/logos/${sub.logo}`}
                  />
                }
              })
            }

          })

          if (histories.categoryId !== "" && isStreaming === false) {
            if (histories.categoryId === 'admin') {
              categoryName = histories.categoryId
              categoryLogo = <MdAdminPanelSettings className='mr-2' size={20} />

            } else {
              categoryName = histories.categoryId
              categoryLogo = <LazyLoadImage
                className="w-6 h-6 z-10 rounded-full flex inline-flex object-cover mr-2"
                alt={`No image`}
                effect="blur"
                src={`/images/paymethods/${histories.categoryId}.png`}
              />
            }

          }

          if (histories.categoryId === "") {
            categoryName = "Sin datos"
            categoryLogo = <MdClose className='text-red-500 mr-2' size={20} />
          }

          return (
            <div className="animation transition-all duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer 
            bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 transition-all duration-500 flex flex-wrap shadow-xl mb-9 rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer" key={histories._id}>
              <div className="grid grid-wrap w-full md:w-4/12">
                <div className="flex inline-flex items-center">
                  <span className="absolute -mt-[100px] -ml-4  
                  text-base rounded-lg bg-neutral-200 dark:bg-neutral-900 transition-all duration-500 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                  items-center justify-center">{histories.transaction_id}</span>
                  <FaUser className="object-cover w-10 h-10 z-10 rounded-full flex inline-flex text-neutral-900 dark:text-neutral-200" />
                  <div className="grid self-center">
                    <div className="flex items-center">
                      <div className="ml-2 text-base font-bold">{username === "" ? <Spinner /> : username}</div>
                    </div>
                    <div className="ml-2 text-sm">{email === "" ? <Spinner /> : email}</div>
                    <div className="flex inline-flex items-center text-sm ml-2">{setUnixToTimeFormat("info_contacto", histories.created_at)}</div>
                  </div>
                </div>
              </div>

              <div className="flex inline-flex items-center justify-center w-full md:w-4/12">
                <div class="p-2 bg-neutral-50 dark:bg-neutral-950 items-center leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                  <span class="flex rounded-full bg-neutral-200 dark:bg-neutral-900 uppercase px-4 py-2 text-sm font-bold">{currencyFormat(histories.old_credits)}</span>
                </div>
                <span class="flex rounded-full bg-neutral-50 dark:bg-neutral-950 uppercase px-4 py-2 text-base font-bold">
                  {(histories.new_credits - histories.old_credits) < 0 ?
                    <span className='text-red-500 flex inline-flex items-center'><FaArrowDown className='mr-1' /> {currencyFormat(histories.new_credits - histories.old_credits)}</span> :
                    <span className='text-green-500 flex inline-flex items-center'><FaArrowUp className='mr-1' /> +{currencyFormat(histories.new_credits - histories.old_credits)}</span>
                  }
                </span>
                <div class="p-2 bg-neutral-50 dark:bg-neutral-950 items-center leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                  <span class="flex rounded-full bg-neutral-200 dark:bg-neutral-900 uppercase px-4 py-2 text-sm font-bold">{currencyFormat(histories.new_credits)}</span>
                </div>
              </div>

              <div className="flex inline-flex items-center justify-end w-full md:w-[calc(33.33%-200px)]">
                <div class="p-2 bg-blue-900 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                  <span class="flex items-center rounded-full bg-blue-700 capitalize px-4 py-2 text-xs font-bold">{categoryLogo}{categoryName}</span>
                </div>
              </div>

              <div className="flex inline-flex items-center justify-end w-full md:w-[200px]">
                {
                  histories.action === 'New Streaming' &&
                  <div class="p-2 bg-green-900 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-green-700 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

                {
                  histories.action === 'New GiftCard' &&
                  <div class="p-2 bg-green-900 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-green-700 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

                {
                  histories.action === 'Add Recharge' &&
                  <div class="p-2 bg-yellow-700 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-yellow-500 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

                {
                  histories.action === 'Penalty Recharge' &&
                  <div class="p-2 bg-red-700 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-red-500 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

                {
                  histories.action === 'Rejected Streaming' &&
                  <div class="p-2 bg-red-700 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-red-500 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

                {
                  histories.action === 'Rejected GiftCard' &&
                  <div class="p-2 bg-red-700 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-red-500 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

                {
                  histories.action === 'Renew Streaming' &&
                  <div class="p-2 bg-purple-700 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                    <span class="flex rounded-full bg-purple-500 px-4 py-2 text-xs font-bold">{histories.action}</span>
                  </div>
                }

              </div>
            </div>
          )
        }) : (<div className="alert-danger font-semibold mt-[280px]">Sin resultados en la búsqueda! o no existe historial registrado.</div>) : (<div className="mt-[280px]"><SpinnerData>Cargando historial, por favor espere...</SpinnerData></div>)}
      </div>
    </Wrapper>
  );
};

export default History;
