import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const giftcardService = createApi({
    reducerPath: 'giftcard',
    tagTypes: 'giftcards',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://cinedar.com:8443/api/',
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            const userToken = reducers?.authReducer?.userToken;
            const offlineUserToken = reducers?.authReducer?.offlineUserToken
            headers.set('authorization', (userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '' || offlineUserToken ? `Bearer ${offlineUserToken}` : ''));
            return headers;
        }
    }),

    endpoints: (builder) => {
        return {
            
            createGiftCard: builder.mutation({
                query: (data) => {
                    return {
                        url: '/create-giftcard',
                        method: 'POST',
                        body: data
                    }
                },
                invalidatesTags: ['giftcards']
            }),

            getAllGiftCards: builder.query({
                query: () => {
                    return {
                        url: `/all-giftcards`,
                        method: 'GET'
                    }
                },
                providesTags: ['giftcards']
            }),

            getAllGiftCardsSellsByUserId: builder.query({
                query: (id) => {
                    return {
                        url: `get-giftcards-sells-userid/${id}`,
                        method: "GET",
                    };
                },
                providesTags: ["giftcards"],
            }),

            getAllGiftCardsByPage: builder.query({
                query: (params) => {
                  var finalquery = ''
                  if(params.search === ''){
                    finalquery = 'emptyquery'
                  }else{
                    finalquery = params.search
                  }
                  return {
                    url: `getallgiftcardsbypage/${finalquery}/${params.page}/${params.limit}`,
                    method: "GET",
                  };
                },
                providesTags: ["giftcards"],
              }),

            deleteGiftCard: builder.mutation({
                query: id => {
                    return {
                        url: `/delete-giftcard/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['giftcards']
            }),
        }
    }
})

export const {
    useCreateGiftCardMutation,
    useGetAllGiftCardsQuery,
    useGetAllGiftCardsByPageQuery,
    useGetAllGiftCardsSellsByUserIdQuery,
    useDeleteGiftCardMutation
} = giftcardService;
export default giftcardService