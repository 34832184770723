import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { IoAlarm, IoBan, IoCheckmark, IoCloseSharp, IoEye, IoEyeOff, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoSave, IoTime } from 'react-icons/io5';
import { MdClose, MdDevices, MdEdit, MdEditOff, MdPhoneIphone, MdTimerOff, MdVerified, MdWarning } from 'react-icons/md';
import UpdateScreenHeader from "../../components/UpdateScreenHeader";
import { Button, IconButton, MenuItem, TextField, Tooltip, Link, FormControl, Select } from "@mui/material";
import {
  useGetCuentaByIdQuery,
  useUpdateCuentaMutation
} from "../../store/services/cuentaService";
import Spinner from "../../components/Spinner";
import moment from 'moment';
import { CalcularExpiracion, CalcularTiempoTranscurrido, message, PermiteSoloNumeros, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { FaShippingFast, FaUserCheck, FaUserLock } from "react-icons/fa";
import { useGetVentasByCorreoIdQuery, useLockedVentasByCorreoIdMutation, useLockOrRejectedVentaMutation } from "../../store/services/ventasService";
import { useGetAllUsersQuery } from "../../store/services/userService";
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";

const UpdateCuenta = ({
  socket,
  SECRET_KEY,
  cuenta,
  category,
  loadingcategory,
  HandleCloseEditCuenta,
  refetchcuentas
}) => {
  const { data: ventas, isFetching: loadingventas, refetch } = useGetVentasByCorreoIdQuery({ correoid: cuenta._id, categoryId: cuenta.categoryId, servicetype: cuenta.servicetype });
  const { data: ventasid, isFetching: loadingventasid } = useGetCuentaByIdQuery(cuenta._id);
  const { data: users, isFetching: loadingusers } = useGetAllUsersQuery();
  const [VerPerfiles, SetVerPerfiles] = useState(false)
  const [IsProfilesBlocked, SetIsProfilesBlocked] = useState(false)
  const [viewcurrentperfiles, SetViewcurrentperfiles] = useState(false)
  const [allexpired, setAllExpired] = useState(true)
  const [state, setState] = useState({
    _id: '',
    correo: '',
    passwordcorreo: '',
    passwordcuenta: '',
    categoryId: '',
    subcategoryId: '',
    expirationdate: '',
    observations: '',
    servicetype: '',
    profilesinfo: [],
    renovable: false,
    locked: false,
    currentrenovable: false
  });

  const [VerPasswords, SetVerPasswords] = useState(
    {
      passwordcorreo: false,
      passwordcuenta: false,
      passwordp1: false,
      passwordp2: false,
      passwordp3: false,
      passwordp4: false,
      passwordp5: false,
      passwordp6: false,
      passwordp7: false,
      passwordp8: false,
      passwordp9: false,
      passwordp10: false,
    })

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const HandleChangeProfileInfo = (value, name, id) => {
    const NewState = state.profilesinfo.map((pro) => {
      if (pro._id === id) {
        return {
          ...pro,
          [name]: value
        }
      }
      return pro
    })
    setState({ ...state, profilesinfo: NewState })
  }

  const HandleChangeProfileInfoFromLockedVenta = (id) => {
    const NewState = state.profilesinfo.map((pro) => {
      if (pro._id === id) {
        return {
          ...pro,
          isused: false,
          byuserid: ""
        }
      }
      return pro
    })
    setState({ ...state, profilesinfo: NewState })
  }

  const HandleChangeLockedAllProfileInfoFromLockedVenta = () => {
    const NewState = state.profilesinfo.map((pro) => {
      return {
        ...pro,
        isused: false,
        byuserid: ""
      }
      return pro
    })
    setState({ ...state, profilesinfo: NewState })
  }

  const [updateCuenta, response] = useUpdateCuentaMutation();
  const UpdateCuenta = (e) => {
    e.preventDefault();
    updateCuenta(state);
  };

  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors.map((err) => {
        message(err.msg, 'error', 4)
      });
    }
  }, [response?.error?.data?.errors]);

  useEffect(() => {
    if (response?.isSuccess) {

      message(response?.data?.msg, 'success', 5)
      if (response?.data?.isPerfilEdited.length > 0) {
        socket.emit('updatepinperfil', response?.data?.isPerfilEdited, response?.data?.datatarget)
      }
      if (response?.data?.isPasswordEdited.length > 0) {
        message('La nueva contraseña se ah enviado por correo', 'success', 5)
        socket.emit('updatepasswordcuenta', response?.data?.isPasswordEdited, response?.data?.datatarget)
      }
      if (state.currentrenovable !== state.renovable || response?.data?.locked) {
        socket.emit('updatecuenta', state.correo)
      }
      HandleCloseEditCuenta()
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!loadingcategory) {
      const cuentaforedit = {
        _id: cuenta._id,
        correo: cuenta.correo,
        passwordcorreo: cuenta.passwordcorreo,
        passwordcuenta: cuenta.passwordcuenta,
        categoryId: cuenta.categoryId,
        subcategoryId: cuenta.subcategoryId,
        expirationdate: moment(cuenta.expirationdate).format("yyyy-MM-DDTHH:mm"),
        observations: cuenta.observations,
        created_at: cuenta.created_at,
        servicetype: cuenta.servicetype,
        edtadouso: cuenta.estadouso,
        profilesinfo: cuenta.profilesinfo,
        locked: cuenta.locked,
        last_update: cuenta.last_update,
        renovable: cuenta.renovable ? cuenta.renovable : false,
        currentrenovable: cuenta.renovable ? cuenta.renovable : false
      }
      category.map((category) => {
        if (category._id === cuenta.categoryId) {
          category.subcategory.find((subcat) => {
            if (subcat._id === cuenta.subcategoryId) {
              SetIsProfilesBlocked(subcat.isprofileblocked)
            }
          })
        }
      })
      setState(cuentaforedit)
    }

  }, []);

  const OnChangeRenovable = () => {
    setState({ ...state, ['renovable']: !state.renovable })
  }

  const OnChangeLocked = () => {
    setState({ ...state, ['locked']: !state.locked })
  }

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const [rejectedorlocked, responseLock] = useLockOrRejectedVentaMutation();
  const [lockedventas, responseLockVentas] = useLockedVentasByCorreoIdMutation();

  const HandleLockOrRejectedVenta = (id, actiontype, state, isExpired) => {

    if (actiontype === 'locked') {
      if (state === 'complete' && isExpired !== 'Caducado') {
        alertaConfirmar('Bloquear pedido - No expirado', 'Este pedido aún no ah expirado, deseas forzar el bloqueo ?', 'question', (res_alert) => {
          if (res_alert === true) {
            rejectedorlocked({ _id: id, actiontype: actiontype, message: '', force: true });
          }
        })
      } else {
        alertaConfirmar('Bloquear pedido', 'Al bloquear este pedido el usuario ya no podrá renovarlo ni tener ningún acceso.', 'warning', (res_alert) => {
          if (res_alert === true) {
            rejectedorlocked({ _id: id, actiontype: actiontype, message: '', force: false });
          }
        })
      }
    }
  }

  const HandleLockPerfilesVencidos = () => {
    const newarray = []
    !loadingventas && ventas?.ventas.map((venta) => {
      if (Date.now() > new Date(venta.expirationdate).getTime() && venta.state === 'complete') {
        if (venta.isprofileblocked) {
          if (venta.type === '1 pantalla') {
            /**/
            newarray.push(venta.dataperfiles[0]._id)

          } else if (venta.type === 'cuenta completa') {

            const NewState = state.profilesinfo.map((pro) => {
              if (venta.userId === pro.byuserid) {
                return {
                  ...pro,
                  isused: false,
                  byuserid: ""
                }
              }
              return pro
            })
            setState({ ...state, profilesinfo: NewState })
          }
        }
      }
    })

    console.log(newarray)

    const NewState = state.profilesinfo.map((pro) => {
      if (newarray.includes(pro._id)) {
        return {
          ...pro,
          isused: false,
          byuserid: ""
        }
      }
      return pro
    })
    setState({ ...state, profilesinfo: NewState })

    lockedventas({ _id: state._id })
  }

  useEffect(() => {
    if (responseLockVentas.isSuccess) {
      refetchcuentas()
      //setState({ ...state, profilesinfo: responseLockVentas?.data?.callback?.newstateprofiles })
      //console.log(responseLockVentas?.data?.callback?.newstateprofiles)
      //console.log(!loadingventasid && ventasid?.cuenta)

      message(responseLockVentas?.data?.msg, 'success', 3)
      toast((t) => (
        <div className="flex inline-flex items-center">
          <div className="mr-3">
            <MdWarning size={24} className="mr-2 text-yellow-600" />
          </div>
          <div className="">
            Recuerda que debes cambiar la contraseña de la cuenta.
          </div>
          <div className="w-8 h-full ml-[10px] flex items-center justify-center">
            <div className="w-8 h-8">
              <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                <MdClose size={22} className="text-neutral-200" />
              </button>
            </div>
          </div>

        </div>
      ),
        {
          position: 'top-center',
          style: {
            borderRadius: '10px',
            background: '#0a0a0a',
            color: '#e5e5e5',
          }, duration: 3
        })
    }
  }, [responseLockVentas?.data?.msg])


  useEffect(() => {
    if (responseLock.isSuccess) {
      setAllExpired(false)
      refetchcuentas()
      if (IsProfilesBlocked) {
        HandleChangeProfileInfoFromLockedVenta(responseLock?.data?.callback?._id)
      }

      socket.emit("lockedorrejectedventa", responseLock?.data?.callback)
      message(responseLock?.data?.msg, 'success', 3)
      toast((t) => (
        <div className="flex inline-flex items-center">
          <div className="mr-3">
            <MdWarning size={24} className="mr-2 text-yellow-600" />
          </div>
          <div className="">
            Recuerda que debes cambiar la contraseña de la cuenta.
          </div>
          <div className="w-8 h-full ml-[10px] flex items-center justify-center">
            <div className="w-8 h-8">
              <button className="rounded-full w-8 h-8 bg-red-600 flex inline-flex items-center justify-center hover:bg-red-700" onClick={() => toast.dismiss(t.id)}>
                <MdClose size={22} className="text-neutral-200" />
              </button>
            </div>
          </div>

        </div>
      ),
        {
          position: 'top-center',
          style: {
            borderRadius: '10px',
            background: '#0a0a0a',
            color: '#e5e5e5',
          }, duration: Infinity
        })
    }
  }, [responseLock?.data?.msg])

  const HandleLinkWhatsapp = (Identificador) => {
    setTimeout(() => {
      document.getElementById(Identificador).click()
    }, 100)
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <UpdateScreenHeader>
        <div className="flex items-center">
          <div className="flex inline-flex items-center">
            <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
              <IconButton color="error" onClick={HandleCloseEditCuenta}>
                <IoCloseSharp
                  className="text-neutral-200"
                  size={28}
                />
              </IconButton>
            </div>
          </div>
          <div className="w-full text-neutral-900 dark:text-neutral-200 text-2xl ml-5 flex inline-flex items-center">EDITAR CUENTA</div>
        </div>
      </UpdateScreenHeader>
      {
        <div className="flex flex-wrap -mx-3 mt-[125px]">
          <form className="w-full xl:w-12/12 p-3" onSubmit={UpdateCuenta}>
            <div className="flex flex-wrap relative">
              <div className="w-full md:w-6/12 p-3">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Correo de la cuenta</label>
                <input autoFocus type="text" name="correo" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-700 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Correo de la cuenta"
                  autoComplete="off"
                  required
                  value={state.correo}
                  onChange={handleInput}
                />
              </div>

              <div className="w-full md:w-6/12 p-3 relative">

                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password del correo</label>
                <input name="passwordcorreo" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-700 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Password del correo"
                  autoComplete="off"
                  required
                  value={state.passwordcorreo}
                  onChange={handleInput}
                  type={VerPasswords.passwordcorreo ? 'text' : 'password'}
                />
                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                  {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcorreo']: !VerPasswords.passwordcorreo })}>
                    {VerPasswords.passwordcorreo ? <IoEyeOff className="text-neutral-900 dark:text-neutral-200" /> : <IoEye className="text-neutral-900 dark:text-neutral-200" />}
                  </IconButton>}
                </div>
              </div>

              <div className="w-full md:w-6/12 p-3 relative">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password de la cuenta</label>
                <input name="passwordcuenta" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-700 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Password de la cuenta"
                  autoComplete="off"
                  required
                  value={state.passwordcuenta}
                  onChange={handleInput}
                  type={VerPasswords.passwordcuenta ? 'text' : 'password'}
                />
                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                  {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcuenta']: !VerPasswords.passwordcuenta })}>
                    {VerPasswords.passwordcuenta ? <IoEyeOff className="text-neutral-900 dark:text-neutral-200" /> : <IoEye className="text-neutral-900 dark:text-neutral-200" />}
                  </IconButton>}
                </div>

              </div>

              <div className="w-full md:w-6/12 p-3">

                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Expiración de la cuenta</label>
                <input name="expirationdate" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-700 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Expiración de la cuenta"
                  autoComplete="off"
                  required
                  value={state.expirationdate}
                  onChange={handleInput}
                  type="datetime-local"
                />
              </div>

              <div className="w-full md:w-6/12 p-3">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Tipo de servicio</label>
                <Tooltip title={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MdEditOff size={18} style={{ marginRight: 5 }} />
                    Tipo de servicio no se puede editar
                  </div>}

                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: 12.5,
                      }
                    }
                  }}
                  arrow>
                  <FormControl fullWidth>
                    <Select
                      required
                      name="servicetype"
                      className="flex items-center"
                      variant="outlined"
                      value={state.servicetype}
                    >
                      {
                        state.servicetype === 'completa' ?
                          <MenuItem value={'completa'} key={'completa'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/completa.webp`} className="mr-2 rounded-full" />Cuenta completa</MenuItem>
                          :
                          <MenuItem value={'compartida'} key={'compartida'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/pantalla.webp`} className="mr-2 rounded-full" />Cuenta compartida</MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Tooltip>
              </div>

              <div className="w-full md:w-6/12 p-3">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Categoría</label>
                {!loadingcategory ? category.length > 0 && <Tooltip title={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MdEditOff size={18} style={{ marginRight: 5 }} />
                    Categoría no se puede editar
                  </div>}

                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: 12.5,
                      }
                    }
                  }}
                  arrow>
                  <FormControl fullWidth>
                    <Select
                      name="servicetype"
                      className="flex items-center"
                      variant="outlined"
                      value={state.categoryId}
                    >
                      {category.map(category => {
                        if (category._id === state.categoryId)
                          return <MenuItem value={category._id} key={category._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.sublogo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Tooltip> : <Spinner w={'35px'} h={'35px'} />}
              </div>

              <div className="w-full md:w-6/12 p-3">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Subcategoría</label>
                {!loadingcategory ? category.length > 0 && <Tooltip title={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MdEditOff size={18} style={{ marginRight: 5 }} />
                    Subcategoría no se puede editar
                  </div>}

                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: 12.5,
                      }
                    }
                  }}
                  arrow>
                  <FormControl fullWidth>
                    <Select
                      name="servicetype"
                      className="flex items-center"
                      variant="outlined"
                      value={state.subcategoryId}
                    >
                      {category.map(category => {
                        if (category._id === state.categoryId)
                          return category.subcategory.map((subcat) => {
                            if (subcat._id === state.subcategoryId)
                              return <MenuItem value={subcat._id} key={subcat._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcat.logo}`} className="mr-2 rounded-full" />{subcat.name}</MenuItem>
                          })
                      })}
                    </Select>
                  </FormControl>
                </Tooltip> : <Spinner w={'35px'} h={'35px'} />}
              </div>

              <div className="w-full p-3">
                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Bloqueada ? <span className="font-semibold">{state.locked ? "Si" : "No"}</span></label>
                <div className="flex flex-col justify-between h-16 mt-3">

                  <span onClick={OnChangeLocked} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${state.locked && 'hidden'}`}>
                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                  </span>

                  <span onClick={OnChangeLocked} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!state.locked && 'hidden'}`}>
                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                  </span>
                </div>
              </div>

              <div className="w-full p-3">
                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Es renovable ? <span className="font-semibold">{state.renovable ? "Si" : "No"}</span></label>
                <div className="flex flex-col justify-between h-16 mt-3">

                  <span onClick={OnChangeRenovable} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${state.renovable && 'hidden'}`}>
                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                  </span>

                  <span onClick={OnChangeRenovable} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!state.renovable && 'hidden'}`}>
                    <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                  </span>
                </div>
              </div>

              <div className="w-full p-3">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Observaciones</label>
                <textarea name="observations" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-700 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Observaciones"
                  rows="3"
                  autoComplete="off"
                  required
                  value={state.observations}
                  onChange={handleInput}
                />
              </div>
              <div className="p-3">
                {IsProfilesBlocked &&

                  <div onClick={() => SetVerPerfiles(!VerPerfiles)}
                    className="bg-indigo-500 hover:bg-indigo-600 p-4 w-full text-center cursor-pointer rounded-lg transition">
                    {VerPerfiles ? 'OCULTAR' : 'VER'} PERFILES
                  </div>
                }
              </div>

              {
                VerPerfiles &&

                <div className="grid grid-cols-2 md:grid-cols-5 gap-4 p-3">

                  {
                    state.profilesinfo.map((pro, i) => {
                      var passwordp = `passwordp${i + 1}`
                      var categoryname = `name`
                      var password = `password`
                      var username = ""
                      !loadingusers && users?.users.find((_u) => {
                        if (pro.byuserid === _u._id) {
                          username = _u.name
                        }
                      })

                      return (
                        <div className={`relative ${pro.isused ? 'bg-gradient-to-r from-red-400 via-red-500 to-red-600' : 'bg-gradient-to-r from-green-400 via-green-500 to-green-600'} rounded-lg text-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-4 cursor-pointer hover:scale-[101%] animation transition-all`}>
                          <div className="absolute text-center text-white font-bold rounded-full p-2 left-1 top-1 bg-white bg-opacity-30 text-3xl w-14 h-14 flex items-center justify-center">
                            {i + 1}
                          </div>
                          <IoPerson size={64} className="text-neutral-100 rounded-full m-auto" />
                          <div className="h-12 text-center font-semibold text-neutral-100">
                            {username}
                          </div>

                          <div className="m-auto mb-2">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-100 font-semibold">Nombre</label>
                            <input key={i} name={categoryname} className="bg-neutral-950 bg-opacity-20 p-[15px] text-neutral-100 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                              placeholder="Nombre"
                              autoComplete="off"
                              required
                              onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                              value={pro[categoryname]}
                              type="text"
                            />
                          </div>
                          <div className="m-auto relative">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-100 font-semibold">PIN</label>
                            <input key={i} name={password} className="bg-neutral-950 bg-opacity-20 p-[15px] text-neutral-100 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                              placeholder="PIN"
                              autoComplete="off"
                              required
                              onKeyPress={PermiteSoloNumeros}
                              onChange={(e) => HandleChangeProfileInfo(e.target.value, e.target.name, pro._id)}
                              value={pro[password]}
                              type={VerPasswords[passwordp] ? 'text' : 'password'}
                              maxLength={5}
                            />
                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                              {<IconButton color='primary' onClick={(e) => SetVerPasswords({ ...VerPasswords, [passwordp]: !VerPasswords[passwordp] })}>
                                {VerPasswords[passwordp] ? <IoEyeOff className="text-neutral-300" /> : <IoEye className="text-neutral-300" />}
                              </IconButton>}
                            </div>
                          </div>
                        </div>

                      )
                    })
                  }
                </div>
              }

              <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                <Button
                  type="submit"
                  disabled={response.isLoading ? true : false}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained"
                >
                  {response.isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                  {response.isLoading ? 'Actualizando...' : 'Actualizar cuenta'}
                </Button>
              </div>
              <div className="w-full mt-5 p-3">
                {
                  ventas?.ventas.length > 0 &&
                  <>
                    <Button
                      type="button"
                      onClick={() => SetViewcurrentperfiles(!viewcurrentperfiles)}
                      style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                      size='large' variant="contained" color="success"
                    >
                      {viewcurrentperfiles ? 'OCULTAR' : 'VER'} USUARIOS QUE USAN ESTA CUENTA
                    </Button>
                    <div className={`${viewcurrentperfiles ? '' : 'hidden'} w-full mt-10 mb-5 p-0`}>
                      <div className="flex w-full inline-flex">

                        <div>
                          <Tooltip title="Bloquear perfiles vencidos" arrow={true}>
                            <IconButton onClick={HandleLockPerfilesVencidos} color="primary" className="bg-neutral-100 dark:bg-neutral-800">
                              <FaUserLock size={45} className="text-red-600" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      {
                        !loadingventas && ventas?.ventas.map((venta, index) => {
                          var CategoryName = ""
                          var CategoryLogo = ""
                          var UserName = ""
                          var ContactNumber = ""
                          var msgtype = ""
                          var msgtype1 = ""
                          var maxscreems = 0
                          var email = ""
                          if ((cuenta._id === venta.correoId) &&
                            venta.state === 'complete' &&
                            venta.ventatype === 'Streaming') {

                            !loadingusers && users?.users.find((user) => {
                              if (user._id === venta.userId) {
                                UserName = user.name
                                ContactNumber = user.phonenumber
                                email = user.email
                              }
                            })

                            !loadingcategory && category.find((cat) => {
                              if (cat._id === venta.categoryId) {
                                cat.subcategory.find((sub) => {
                                  if (venta.subcategoryId === sub._id) {
                                    CategoryName = sub.name
                                    CategoryLogo = sub.logo
                                    maxscreems = sub.maximoperfiles
                                  }
                                })
                              }
                            })
                            if ((CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                              Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3)) {
                              if (venta.type === "cuenta completa") {
                                msgtype = " Somos CINEDAR, le informamos que su cuenta de "
                                msgtype1 = ` con código ${venta.ventaId}  expira en menos de 3 días. Si desea continuar con el servicio puede renovar directamente mediante nuestra página web https://cinedar.com, en caso que NO desea el servicio, ignore el mensaje.%0ASi tiene alguna duda con respecto al servicio, comuníquese con nuestro centro de atención al cliente.`
                              } else {
                                msgtype = " Somos CINEDAR, le informamos que su perfil de "
                                msgtype1 = ` con código ${venta.ventaId}  expira en menos de 3 días. Si desea continuar con el servicio puede renovar directamente mediante nuestra página web https://cinedar.com, en caso que NO desea el servicio, ignore el mensaje.%0ASi tiene alguna duda con respecto al servicio, comuníquese con nuestro centro de atención al cliente.`
                              }
                            } else {
                              if (venta.type === "cuenta completa") {
                                msgtype = " Somos CINEDAR, le informamos que su cuenta de "
                                msgtype1 = ` con código ${venta.ventaId} ah expirado. Tiene 4 horas como plazo máximo para renovar; En caso desee continuar puede renovar directamente desde nuestra página https://cinedar.com, en caso que NO desea el servicio, ignore el mensaje.%0ASi tiene alguna duda con respecto al servicio, comuníquese con nuestro centro de atención al cliente.`
                              } else {
                                msgtype = " Somos CINEDAR, le informamos que su perfil de "
                                msgtype1 = ` con código ${venta.ventaId} ah expirado. Tiene 4 horas como plazo máximo para renovar; En caso desee continuar puede renovar directamente desde nuestra página https://cinedar.com, en caso que NO desea el servicio, ignore el mensaje.%0ASi tiene alguna duda con respecto al servicio, comuníquese con nuestro centro de atención al cliente.`
                              }
                            }
                            return (
                              <div className={`mt-10 animation ${(venta.state === 'complete' || venta.state === 'pending') && 'transition-all duration-500 hover:scale-x-[101%] hover:contrast-[105%]'} 
                    ${(venta.state === "locked" || venta.state === "rejected") && 'opacity-40'} text-neutral-900 dark:text-neutral-200 bg-neutral-100 dark:bg-neutral-800 flex flex-wrap mb-9 rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer`}
                                key={venta._id}>
                                <div className="grid grid-wrap w-full md:w-5/12">
                                  <div className="flex inline-flex items-center">
                                    <span className="absolute -mt-28 -ml-4 
                          text-base rounded-lg bg-neutral-100 dark:bg-neutral-800 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center">{venta.ventaId}</span>
                                    <img class="w-12 h-12 z-10 scale rounded-full flex inline-flex"
                                      src={`/images/logos/${CategoryLogo}`} alt={`No image ${CategoryName}`} />
                                    <div className="grid self-center">
                                      <div className="ml-2 text-base font-semibold">{email}</div>
                                      <div className="ml-2 text-sm">{UserName}</div>
                                      <div className="ml-2 text-sm">{CategoryName}</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="grid grid-wrap w-full md:w-3/12">
                                  <div className="flex inline-flex items-center text-sm"><IoTime size={28} className="mr-2" /> {setUnixToTimeFormat("info_contacto", venta.created_at)}</div>
                                  <div className="flex inline-flex items-center text-sm"><IoAlarm size={28} className="mr-2" />
                                    {venta.expirationdate === null ?
                                      <span className="text-yellow-500">Expiración aún no extablecida</span> :
                                      <span className="text-green-500">{(CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") ?
                                        <span className="text-red-500">
                                          <span class="relative flex">
                                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                            <span class="relative inline-flex rounded-full items-center justify-center text-red-500 font-semibold">
                                              Este servicio ah expirado
                                            </span>
                                          </span>
                                        </span> : <div>{(CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                                          Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3) ? <span className="text-orange-400">{CalcularExpiracion(venta.expirationdate)}</span> : (venta.state === 'rejected' || venta.state === 'locked') ? <span className="text-red-500">{CalcularExpiracion(venta.expirationdate)}</span> : <span className="text-green-500">{CalcularExpiracion(venta.expirationdate)}</span>}</div>}</span>}
                                  </div>
                                  <span className="flex items-center text-red-500">{(venta.expirationdate !== null && CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") && <><MdTimerOff size={28} className="mr-2" />Expiró hace {CalcularTiempoTranscurrido(venta.expirationdate)}</>}</span>
                                </div>

                                <div className="grid grid-wrap w-full md:w-2/12">
                                  <div className="flex inline-flex items-center">
                                    {venta.state === "complete" && <div className="flex inline-flex items-center text-green-500 text-sm">
                                      <MdVerified size={28} className="mr-2" /> Completado</div>}
                                    {venta.state === "pending" && <div className="flex inline-flex items-center text-yellow-500 text-sm">
                                      <FaShippingFast size={28} className="mr-2" />Pendiente</div>}
                                    {venta.state === "rejected" && <div className="flex inline-flex items-center text-red-500 text-sm">
                                      <IoBan size={28} className="mr-2" /> Rechazado ({venta.observations})</div>}
                                    {venta.state === "locked" && <div className="flex inline-flex items-center text-red-500 text-sm">
                                      <IoLockClosed size={28} className="mr-2" /> Bloqueado</div>}
                                  </div>
                                  <div className="flex inline-flex items-center text-sm">{venta.type === "cuenta completa" ? <MdDevices size={28} className="mr-2" /> : <MdPhoneIphone size={28} className="mr-2" />}{venta.type}</div>
                                </div>

                                <div className="flex inline-flex items-center justify-end w-full md:w-2/12">

                                  <div className="w-12 h-12 rounded-full bg-neutral-200 dark:bg-neutral-900 mt-3 md:mt-0 ">
                                    {
                                      <Tooltip title="Bloquear" arrow={true}>
                                        <IconButton onClick={() => HandleLockOrRejectedVenta(venta._id, 'locked', venta.state, CalcularExpiracion(venta.expirationdate))} className="w-12 h-12" color="primary" disabled={(venta.state === 'rejected' || venta.state === 'pending') ? true : false}>
                                          <IoLockClosed size={24} className={`${(venta.state === 'rejected' || venta.state === 'pending') ? 'text-neutral-900 dark:text-neutral-200 cursor-not-allowed font-medium' : 'text-red-600'}`} />
                                        </IconButton>
                                      </Tooltip>
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  </>
                }

              </div>
            </div>
          </form>
        </div>
      }
    </>
  );
};
export default UpdateCuenta;
