import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const authService = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://cinedar.com:8443/api/',
    }),
    endpoints: (builder) => {
       return {
           authLogin: builder.mutation({
               query: (loginData) => {
                   return {
                       url: '/admin-login',
                       method: 'POST',
                       body: loginData
                   }
               }
           }),
           userRegister: builder.mutation({
            query: data => {
                return {
                    url: '/register',
                    method: 'POST',
                    body: data
                }
            }
           }),
           userLogin: builder.mutation({
            query: loginData => {
                return {
                    url: '/login',
                    method: 'POST',
                    body: loginData
                }
            }
           }),
           forgotpassword: builder.mutation({
            query: data => {
                return {
                    url: '/forgot-password',
                    method: 'PUT',
                    body: data
                }
            }
           }),
           getConfiguration: builder.query({
            query: () => {
                return {
                    url: '/get-configuration',
                    method: 'GET',
                }
            }
           }),
           forgotPasswordResetCheck: builder.query({
            query: key => {
                return {
                    url: `/forgotpasswordresetcheck/${key}`,
                    method: 'GET'
                };
            }
           }),
           updateForgotPassword: builder.mutation({
            query: data => {
                return {
                    url: `/update-forgot-password`,
                    method: 'PUT',
                    body: data
                };
            }
           })
       }
    }
});
export const {useAuthLoginMutation, useGetConfigurationQuery, useUpdateForgotPasswordMutation, useForgotPasswordResetCheckQuery, useForgotpasswordMutation, useUserRegisterMutation, useUserLoginMutation} = authService
export default authService