import { useState } from "react";
import { FaCoins, FaDesktop, FaGifts } from "react-icons/fa";
import { IoBag, IoBagCheck, IoCash, IoChevronDown, IoChevronUp, IoFingerPrint, IoGitNetwork, IoHome, IoPerson } from "react-icons/io5";
import { MdDashboard, MdHistory, MdManageAccounts } from "react-icons/md";
import { SiGnusocial } from "react-icons/si";
import { Link } from "react-router-dom";
const Sidebar = ({ side, closeSidebar }) => {
  const [OpenCloseManageProfile, SetOpenCloseManageProfile] = useState('hidden')
  const [OpenCloseManageOrders, SetOpenCloseManageOrders] = useState('hidden')
  const [OpenCloseManageRecharge, SetOpenCloseManageRecharge] = useState('hidden')

  const HandleOpenCloseManageProfile = (e) => {
    if (OpenCloseManageProfile === "hidden") {
      SetOpenCloseManageProfile("")
    } else {
      SetOpenCloseManageProfile("hidden")
    }
  }

  const HandleOpenCloseManageOrders = (e) => {
    if (OpenCloseManageOrders === "hidden") {
      SetOpenCloseManageOrders("")
    } else {
      SetOpenCloseManageOrders("hidden")
    }
  }

  const HandleOpenCloseManageRecharge = (e) => {
    if (OpenCloseManageRecharge === "hidden") {
      SetOpenCloseManageRecharge("")
    } else {
      SetOpenCloseManageRecharge("hidden")
    }
  }

  return (
    <div>
      {
        side === "" && <div className="bg-neutral-50 bg-opacity-30 fixed inset-0 z-40" onClick={closeSidebar}></div>
      }

      <aside id="logo-sidebar"
        class={`fixed left-0 transition-all duration-500 z-40 w-64 h-screen pt-10 transition-transform ${side} bg-neutral-200 dark:bg-neutral-900 border-r border-neutral-800 sm:translate-x-0`} aria-label="Sidebar">
        <div class="h-full px-3 pb-4 overflow-y-auto bg-neutral-200 dark:bg-neutral-900">
          <ul class="space-y-2">
            {/*
              <li className="hs-accordion">
              <Link to={'/manage-account/dashboard'} class="bg-neutral-200 dark:bg-neutral-900 flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
              transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  pt-4 pb-4">
                <MdDashboard className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 
                group-hover:text-neutral-800 dark:text-neutral-200"/>
                <span class="flex-1 ml-3 text-left whitespace-nowrap">Dashboard</span>
              </Link>
            </li>*/
            }


            <li className="hs-accordion">
              <button onClick={HandleOpenCloseManageProfile} type="button" class="flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
               transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800 bg-neutral-200 dark:bg-neutral-900 pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <MdManageAccounts className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar mi perfil</span>
                {
                  OpenCloseManageProfile === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageProfile} py-1 space-y-1`}>
                <li>
                  <Link to="/manage-account/profile" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoPerson className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Mi perfil</span>
                  </Link>
                </li>
                <li>
                  <Link to="/manage-account/changepassword" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoFingerPrint className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Cambiar contraseña</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManageOrders} type="button" class=" bg-neutral-200 dark:bg-neutral-900 flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
              transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoBag className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 transition duration-75" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar pedidos</span>
                {
                  OpenCloseManageOrders === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageOrders} py-1 space-y-1`}>
                <li>
                  <Link to="/manage-account/userorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <FaDesktop className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Streaming</span>
                  </Link>
                </li>
                <li>
                  <Link to="/manage-account/mygiftcards" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <FaGifts className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Gift cards</span>
                  </Link>
                </li>

                <li>
                  <Link to="/manage-account/usersocialorders" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <IoGitNetwork className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Redes sociales</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button onClick={HandleOpenCloseManageRecharge} type="button" class=" bg-neutral-200 dark:bg-neutral-900 flex items-center w-full p-2 text-sm font-bold text-neutral-800 dark:text-neutral-200 rounded-lg group 
              transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800  pt-4 pb-4" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <IoCash className="flex-shrink-0 w-6 h-6 text-neutral-700 dark:text-neutral-200 transition duration-75" />
                <span class="flex-1 ml-3 text-left whitespace-nowrap group-hover:font-semibold" sidebar-toggle-item>Gestionar recargas</span>
                {
                  OpenCloseManageRecharge === "hidden" ? <IoChevronDown className="w-6 h-6" /> : <IoChevronUp className="w-6 h-6" />
                }

              </button>
              <ul id="dropdown-example" class={`${OpenCloseManageRecharge} py-1 space-y-1`}>
                <li>
                  <Link to="/manage-account/recharge" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <FaCoins className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Recargar</span>
                  </Link>
                </li>
                <li>
                  <Link to="/manage-account/paymentshistory" className="capitalize flex items-center w-full p-2 text-sm font-bold 
                  text-neutral-800 dark:text-neutral-200 rounded-lg pl-4 pt-4 pb-4 group transition duration-700 ease-in-out hover:bg-neutral-300 dark:hover:bg-neutral-800">
                    <MdHistory className="flex-shrink-0 w-6 h-6 text-neutral-800 dark:text-neutral-200 mr-2" /><span className="group-hover:font-semibold">Historial</span>
                  </Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </aside >
    </div >
  );
};
export default Sidebar;
