import { Tooltip } from "@mui/material";
import { AiOutlineLogout } from "react-icons/ai";
import { IoMenu } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom";
import { logout, setOfflineUserToken } from "../store/reducers/authReducer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useCreateOfflineUserTokenMutation } from "../store/services/tokenService";
import { useEffect, useState } from "react";
import Spinner2 from "./Spinner2";
import { FaMoon, FaSun } from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";

const AdminNav = ({ openCloseSidebar, socket, configuration, loadingconfiguration }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [crearOfflineUserToken, ResponseCrearOfflineUserToken] = useCreateOfflineUserTokenMutation()
  const { adminToken, admin } = useSelector((state) => state.authReducer);
  const [theme, setTheme] = useState(localStorage.getItem('color-theme') ? localStorage.getItem('color-theme') : 'dark');

  const changeTheme = () => {
    if (localStorage.getItem('color-theme') === 'light') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      localStorage.setItem('color-theme', 'dark');
      setTheme('dark')
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
      setTheme('light')
    }
  }

  const adminLogout = async () => {
    if (adminToken) {
      await crearOfflineUserToken({ socket: socket.id })
    }
  }

  useEffect(async () => {
    if (ResponseCrearOfflineUserToken.isSuccess) {
      localStorage.setItem('offlineUserToken', ResponseCrearOfflineUserToken?.data?.token);
      dispatch(setOfflineUserToken(ResponseCrearOfflineUserToken?.data?.token))
      dispatch(logout('admin-token'));
      socket.emit('logout')
      navigate('/auth/admin-login')
    }
  }, [ResponseCrearOfflineUserToken.isSuccess])
  return (
    <nav className="fixed top-0 z-40 w-full bg-gradient-blue-custom h-20 pr-3 shadow-lg shadow-neutral-950">
      <div className="flex items-center justify-between max-w-screen-xl mx-auto my-auto h-20">
        <div className="flex items-center w-6/12 h-20">
          <button onClick={openCloseSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button"
            class="z-40 p-2 md:hidden">
            <span class="sr-only">Open sidebar</span>
            <HiMenuAlt2 size={32} className={'text-neutral-100'} />
          </button>
          <div className="flex items-center cursor-pointer w-[165px] h-20">
            {
              !loadingconfiguration ? <Link to="/admin/dashboard" className="">
                <LazyLoadImage
                  className="w-[140px] h-[60px]"
                  alt={'No image logo'}
                  effect="blur"
                  src={`/images/logos/${configuration && configuration.logo}`}
                />
              </Link> : <Spinner2 w={'35px'} h={'35px'} />
            }
          </div>
        </div>

        <div class="w-6/12 flex items-center justify-end h-20">
          <div className="flex items-center justify-end h-20 mr-10">
            <label class="inline-flex items-center cursor-pointer">
              <input onClick={changeTheme} checked={theme === 'light' ? true : false} type="checkbox" value="" className="sr-only peer focus:outline-none focus:ring-none" />
              <div class="relative w-14 h-7 bg-neutral-200 peer-focus:outline-none peer-focus:ring-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-neutral-900 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-neutral-600 peer-checked:bg-blue-500"></div>
              <span class="ms-3 text-sm font-medium text-neutral-900 dark:text-neutral-300">
                {
                  theme === 'dark' ?
                    <FaMoon size={20} onClick={changeTheme} className="cursor-pointer" color="white" /> :
                    <FaSun size={20} onClick={changeTheme} className="cursor-pointer" color="yellow" />
                }
              </span>
            </label>
          </div>
          <div class="flex justify-end">
            <button onClick={adminLogout} type="button" class="flex text-sm rounded-full" aria-expanded="false" data-dropdown-toggle="dropdown-user">
              <span class="sr-only">Open user menu</span>
              <Tooltip title="Salir" arrow={true}>
                <div className="rounded-full transition-all duration-500 bg-red-700 hover:bg-red-600 active:bg-red-800 p-[8px]"><AiOutlineLogout size={24} className="text-neutral-100" /></div>
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}
export default AdminNav;