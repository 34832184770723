// App.js
import { Pagination, PaginationItem, Stack, TextField, Link, Tooltip, IconButton, Button } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast, { Toaster } from "react-hot-toast";
import { FaClipboard, FaShippingFast, FaUser } from 'react-icons/fa';
import { IoAlarm, IoArrowRedo, IoBagCheck, IoBan, IoBuild, IoChatboxEllipsesSharp, IoCheckbox, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoOpenOutline, IoPerson, IoReload, IoReloadCircleSharp, IoRocket, IoSave, IoSearch, IoThumbsDownSharp, IoTime } from 'react-icons/io5';
import ScreenHeader from '../../components/ScreenHeader';
import SpinnerData from '../../components/SpinnerData';
import { useCreateVentaMutation, useGetAllVentasByPageQuery, useCountAllVentasByStateQuery, useLockOrRejectedVentaMutation, useDeleteVentaMutation, useUpdateServiceMutation } from '../../store/services/ventasService';
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat } from '../../utils/Funciones';
import { useSendEmailWhitInformationServiceMutation } from "../../store/services/emailService";
import draftToHtml from "draftjs-to-html";
import Swal from "sweetalert2";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import Modal from '@mui/material/Modal';
import Wrapper from './Wrapper';
import moment from 'moment';
import 'moment/locale/es';
import { MdAvTimer, MdClose, MdDelete, MdDevices, MdMail, MdOutgoingMail, MdOutlineTimerOff, MdPhoneIphone, MdReport, MdTimer, MdTimerOff, MdVerified, MdWarning } from 'react-icons/md';
import Spinner from '../../components/Spinner';
import { useGetAllCuentasQuery } from '../../store/services/cuentaService';
import { useGetAllCategoriesStreamingQuery } from '../../store/services/categoryService';
import { useGetAllUsersQuery } from '../../store/services/userService';
import Spinner2 from '../../components/Spinner2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useCreateSocialAnswerReportMutation, useGetAllSocialOrdersQuery, useGetAllSocialPlataformsQuery } from '../../store/services/socialService';

const SocialOrders = ({
  socket,
  SECRET_KEY,
  configuration,
  loadingconfiguration
}) => {

  const [curTime, SetCurTime] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [infopedido, setInfopedido] = useState('')
  const [limit, setLimit] = useState(30);

  const [reportanswerorder, setReportAnswerOrder] = useState({
    _id: "",
    open: false,
    message: "",
    phonenumber: "",
    created_at: "",
    orderId: "",
    nameplataform: "",
    plataformlogo: "",
    answer: ""
  })

  const [checkHistoryReport, setCheckHistoryReport] = useState({
    open: false,
    message: "",
    answer: "",
    created_at: "",
    read_at: "",
    orderId: "",
    nameplataform: "",
    logo: ""
  })

  const { data: orders, isFetching: loadingorders, refetch } = useGetAllSocialOrdersQuery({ search: searchTerm.trim(), page: currentPage, limit: limit });
  const { data: plataforms, isFetching: loadingplataforms } = useGetAllSocialPlataformsQuery();
  const { data: users, isFetching: fechingusers } = useGetAllUsersQuery();

  useEffect(() => {
    setInterval(function () {
      SetCurTime(new Date().toLocaleString());
    }.bind(this), 1000);
  }, [])

  const onCopy = (text) => {
    message(text, 'success', 2)
  }

  const handleAnswerReportOrder = (_id, message, phonenumber, created_at, orderId, nameplataform, logo) => {
    setReportAnswerOrder({ ...reportanswerorder, open: true, message: message, phonenumber, created_at: created_at, _id: _id, orderId: orderId, nameplataform: nameplataform, plataformlogo: logo });
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const [sendAnswerReport, responseAnswerReport] = useCreateSocialAnswerReportMutation()

  const handleSubmitAnswerReportOrder = e => {
    e.preventDefault()
    sendAnswerReport({ _id: reportanswerorder._id, answer: reportanswerorder.answer })
  }

  useEffect(() => {
    if (responseAnswerReport.isSuccess) {
      message(responseAnswerReport?.data?.message, 'success', 6)
      setReportAnswerOrder({
        ...reportanswerorder,
        open: false,
        _id: "",
        message: "",
        phonenumber: "",
        created_at: "",
        orderId: "",
        nameplataform: "",
        plataformlogo: "",
        answer: ""
      })
    }
  }, [responseAnswerReport.isSuccess])

  useEffect(() => {
    if (responseAnswerReport.isError) {
      responseAnswerReport?.error?.data?.errors.map((err) => {
        message(err.msg, 'error', 4)
      });
    }
  }, [responseAnswerReport?.error?.data])

  const handleReadReport = (message, answer, created_at, read_at, orderId, nameplataform, logo) => {
    setCheckHistoryReport({ ...checkHistoryReport, open: true, message, answer, created_at, read_at, orderId, nameplataform, logo })
  }

  return (

    <Wrapper socket={socket} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <ScreenHeader>
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-full">
            <IoBagCheck size={32} className="mr-2" /> PEDIDOS REDES SOCIALES
            <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
              {!loadingorders && orders?.totalorders}
            </div>
          </div>
        </div>
      </ScreenHeader>
      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 p-4">
        <div className="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input autoFocus type="text" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-500 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
            placeholder="Escribe el nombre de la plataforma, usuario o número de orden"
            required
            onChange={debouncedChangeHandler}
            autoComplete="off"
          />
        </div>
      </div>

      <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-0 mt-[128px] p-4 fixed z-20 bg-neutral-50 dark:bg-neutral-950">
        <div className="w-full flex items-center justify-center md:justify-end text-neutral-800 dark:text-neutral-200 mt-0 h-10">
          {
            !loadingorders ?
              <>
                <div className="hidden md:block">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!loadingorders && Number(orders?.totalordersFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                    />
                  </Stack>
                </div>
                <div className="block md:hidden">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(!loadingorders && Number(orders?.totalordersFilter) / limit)}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="text"
                      color="primary"
                      shape="rounded"
                      size="small"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                          {...item}
                        />
                      )}
                      className="bg-neutral-200 dark:bg-neutral-900"
                    />
                  </Stack>
                </div>
              </>
              : <Spinner />
          }

        </div>
      </div>

      <Modal
        keepMounted
        open={checkHistoryReport.open}
        onClose={() => setCheckHistoryReport({
          ...checkHistoryReport,
          open: false,
          message: "",
          answer: "",
          created_at: "",
          read_at: "",
          orderId: "",
          nameplataform: "",
          logo: ""
        })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">

          <div className="realtive m-auto p-5 w-full md:w-5/12 bg-neutral-200 dark:bg-neutral-900">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => setCheckHistoryReport({
                  ...checkHistoryReport,
                  open: false,
                  message: "",
                  answer: "",
                  created_at: "",
                  read_at: "",
                  orderId: "",
                  nameplataform: "",
                  logo: ""
                })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Detalles reporte de servicio</div>
            </div>
            <div className="grid grid-wrap w-full md:w-7/12 mt-5 mb-5">
              <div className="flex inline-flex items-center relative">
                <span className="ml-[0px] text-neutral-900 dark:text-neutral-200 
              text-base rounded-lg bg-neutral-50 dark:bg-neutral-950 p-2 flex inline-flex 
              items-center justify-center">{checkHistoryReport.orderId}</span>
                <LazyLoadImage
                  class="w-14 h-14 z-30 rounded-full flex inline-flex object-cover"
                  alt={`No image ${checkHistoryReport.nameplataform}`}
                  effect="blur"
                  src={`/images/logos/${checkHistoryReport.logo}`}
                />
                <div className="grid self-center">
                  <div className="ml-2 text-base font-semibold text-neutral-900 dark:text-neutral-200 capitalize">{checkHistoryReport.nameplataform}</div>
                </div>
              </div>
              <div className="ml-[2px] text-sm text-red-600 dark:text-red-500 font-semibold">Se reportó el {moment(checkHistoryReport.created_at).format("LLLL")}</div>
              <div className="ml-[2px] text-sm text-blue-600 dark:text-blue-500 font-semibold">Se respondió el {moment(checkHistoryReport.read_at).format("LLLL")}</div>
            </div>
            <div className="w-full p-3 mt-5">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Mensaje</label>
              <textarea name="message" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Describe el problema que estas presentando"
                autoComplete="off"
                disabled
                readOnly
                value={checkHistoryReport.message}
                rows={2}
                maxrows={2}
                maxLength={100}
              />
            </div>

            <div className="w-full p-3 mt-5">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Respuesta</label>
              <textarea name="answer" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Escribe una respuesta para este reporte"
                autoComplete="off"
                disabled
                readOnly
                value={checkHistoryReport.answer}
                rows={2}
                maxrows={2}
                maxLength={100}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        keepMounted
        open={reportanswerorder.open}
        onClose={() => setReportAnswerOrder({
          ...reportanswerorder,
          _id: "",
          open: false,
          message: "",
          phonenumber: "",
          created_at: "",
          orderId: "",
          nameplataform: "",
          plataformlogo: "",
          answer: ""
        })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        sx={{ zIndex: 50 }}
      >
        <div id="authentication-modal" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-neutral-50 bg-opacity-30">

          <div className="realtive m-auto p-5 w-full md:w-5/12 bg-neutral-200 dark:bg-neutral-900">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                <IconButton color="error" onClick={() => setReportAnswerOrder({
                  ...reportanswerorder,
                  _id: "",
                  open: false,
                  message: "",
                  phonenumber: "",
                  created_at: "",
                  orderId: "",
                  nameplataform: "",
                  plataformlogo: "",
                  answer: ""
                })}>
                  <IoCloseSharp
                    className="text-neutral-200"
                    size={28}
                  />
                </IconButton>
              </div>
              <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Responder reporte de servicio</div>
            </div>
            <div className="grid grid-wrap w-full md:w-4/12 mt-5 mb-5">
              <div className="flex inline-flex items-center relative">
                <span className="ml-[0px] text-neutral-900 dark:text-neutral-200 
              text-base rounded-lg bg-neutral-50 dark:bg-neutral-950 p-2 flex inline-flex 
              items-center justify-center">{reportanswerorder.orderId}</span>
                <LazyLoadImage
                  class="w-14 h-14 z-30 rounded-full flex inline-flex object-cover"
                  alt={`No image ${reportanswerorder.nameplataform}`}
                  effect="blur"
                  src={`/images/logos/${reportanswerorder.plataformlogo}`}
                />
                <div className="grid self-center">
                  <div className="ml-2 text-base font-semibold text-neutral-900 dark:text-neutral-200 capitalize">{reportanswerorder.nameplataform}</div>
                </div>
              </div>
              <div className="ml-[2px] text-sm text-red-600 dark:text-red-500 font-semibold">Se reportó hace {CalcularTiempoTranscurrido(reportanswerorder.created_at)}</div>
            </div>

            <form onSubmit={handleSubmitAnswerReportOrder}>
              <div className="w-full p-3 mt-5">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Mensaje</label>
                <textarea name="message" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Describe el problema que estas presentando"
                  autoComplete="off"
                  disabled
                  value={reportanswerorder.message}
                  rows={2}
                  maxLength={100}
                />
              </div>

              <div className="w-full p-3 mt-5 relative">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Número whatsapp</label>
                <input autoFocus name="phonenumber" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Número whatsapp"
                  autoComplete="off"
                  readOnly
                  disabled
                  value={reportanswerorder.phonenumber}
                  type="text"
                />
                <div class="absolute cursor-pointer text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 right-4 flex items-center text-lg leading-5">
                  <a href={`https://wa.me/${((((reportanswerorder.phonenumber.replace(" ", "")).replace(" ", "")).replace(" ", "")).replace(" ", "")).replace("+", "")}?text=Hola,%20le%20escribo%20sobre%20el%20reporte%20${`*${reportanswerorder.orderId}*`}%20de%20servicios%20de%20redes%20sociales`} target='_blank'>
                    <IoOpenOutline size={20} />
                  </a>
                </div>
              </div>

              <div className="w-full p-3 mt-5">
                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Respuesta</label>
                <textarea name="answer" className="bg-neutral-50 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-950 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Escribe una respuesta para este reporte"
                  autoComplete="off"
                  required
                  value={reportanswerorder.answer}
                  rows={2}
                  onChange={(e) => setReportAnswerOrder({ ...reportanswerorder, answer: e.target.value })}
                  maxLength={100}
                />
              </div>

              <div class="relative mt-5">
                <button
                  type="submit"
                  class="flex w-full disabled:opacity-30 justify-center rounded-md bg-blue-600 px-3 py-5 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  disabled={responseAnswerReport.isLoading ? true : false}
                >
                  {responseAnswerReport.isLoading ? <Spinner /> : <IoArrowRedo size={24} className="mr-2" />}
                  {responseAnswerReport.isLoading ? 'Enviando...' : 'Responder'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <div className="w-full mt-[245px]">
        {
          (!loadingorders && !fechingusers && !loadingplataforms) ? orders?.orders.length > 0 ? orders?.orders.map((order) => {
            var logo = ""
            var nameplataform = ""
            var servicename = ""
            var email = ""

            !loadingplataforms && plataforms?.plataforms.find((plataform) => {
              if (plataform._id === order.plataformId) {
                logo = plataform.sublogo
                nameplataform = plataform.name
                plataform.subcategory.find((service) => {
                  if (service.serviceId === order.serviceId) {
                    servicename = service.name
                  }
                })
              }
            })

            !fechingusers && users?.users.find((user) => {
              if (user._id === order.userId) {
                email = user.email
              }
            })

            return (
              <div className="transition-all text-neutral-900 dark:text-neutral-200 duration-500 shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer bg-white dark:bg-neutral-900 flex flex-wrap mb-12 shadow-xl rounded-lg p-4 w-full md:w-12/12 hover:cursor-pointer animation" key={order._id}>
                <div className="flex flex-wrap items-center w-full md:w-3/12">
                  <div className="flex inline-flex items-center">
                    <span className="absolute -mt-28 -ml-4 text-neutral-800 dark:text-neutral-200 
                          text-base rounded-lg bg-white dark:bg-neutral-900 pt-1 pl-2 pr-2 pb-1 flex inline-flex 
                          items-center justify-center"><div className="flex items-center ml-2 text-xs truncate ..."><FaUser size={16} className='mr-2' /><div className='mr-2 flex items-center'>{email}<CopyToClipboard text={email} onCopy={() => onCopy('Correo copiado')}>
                        <FaClipboard size={20} className="text-neutral-900 dark:text-neutral-200 cursor-pointer ml-2" />
                      </CopyToClipboard></div> </div>-<div className='ml-2 text-xs'>{order.orderId}</div> </span>
                    <LazyLoadImage
                      className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                      alt={`No image ${nameplataform}`}
                      effect="blur"
                      src={`/images/logos/${logo}`}
                    />
                  </div>
                  <div className="w-[calc(100%-56px)]">
                    <div className="ml-2 text-lg capitalize font-semibold">{nameplataform}</div>
                    <div className="ml-2 text-xs truncate ...">{servicename}</div>
                    <div className="ml-2 text-xs mt-2">{setUnixToTimeFormat("info_contacto", order.created_at)}</div>
                  </div>
                </div>

                <div className="relative w-full md:w-1/12 text-xs mt-5 md:mt-0 flex items-center justify-center">
                  <div className="w-full grid self-center word">
                    <div className="md:hidden break-all ...">{order.link}</div>
                    <div className="flex items-center justify-center mt-2">
                      <a href={`${order.link}`} target="_blank" className=""><IoOpenOutline size={24} /></a>
                    </div>
                  </div>
                </div>

                <div className="relative md:flex grid grid-cols-3 md:grid-cols-1 items-center w-full md:w-[calc(66.666%-80px)] text-sm mt-10 md:mt-0">
                  <div className="md:w-2/12 w-full grid">
                    <div className="flex items-center justify-center">Cantidad</div>
                    <div className="flex items-center justify-center">{order.quantity}</div>
                  </div>
                  <div className="md:w-2/12 w-full grid">
                    <div className="flex items-center justify-center">Precio</div>
                    <div className="flex items-center justify-center">{currencyFormat(order.totalamount)}</div>
                  </div>
                  <div className="md:w-2/12 w-full grid">
                    <div className="flex items-center justify-center">Inicio/Final</div>
                    <div className="flex items-center justify-center">{order.start_count + ' / ' + (order.start_count + order.quantity)}</div>
                  </div>
                  <div className="md:w-2/12 w-full grid -mt-1 md:mt-0">
                    <div className="flex items-center justify-center">Progreso</div>
                    <div class="w-full bg-neutral-900 rounded-full h-4 dark:bg-neutral-200">
                      <div class={`${((100 / (order.quantity / (order.quantity - order.remains))) >= 0 && (100 / (order.quantity / (order.quantity - order.remains))) <= 25) && 'bg-gradient-to-r from-red-600 via-red-500 to-orange-600'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 25 && (100 / (order.quantity / (order.quantity - order.remains))) <= 50) && 'bg-gradient-to-r from-red-500 via-orange-600 to-orange-500'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 50 && (100 / (order.quantity / (order.quantity - order.remains))) <= 75) && 'bg-gradient-to-r from-orange-600 via-orange-500 to-green-600'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 75 && (100 / (order.quantity / (order.quantity - order.remains))) <= 99) && 'bg-gradient-to-r from-orange-500 via-green-600 to-green-500'} 
                            ${((100 / (order.quantity / (order.quantity - order.remains))) > 99 && (100 / (order.quantity / (order.quantity - order.remains))) <= 100) && 'bg-gradient-to-r from-green-600 via-green-500 to-green-400'}
                            h-4 rounded-full`} style={{ width: `${100 / (order.quantity / (order.quantity - order.remains))}%` }}></div>
                      <span className="text-xs">{(100 / (order.quantity / (order.quantity - order.remains))).toFixed(2)}%</span>
                    </div>
                  </div>
                  <div className="md:w-2/12 grid">
                    <div className="flex items-center justify-center">Faltan</div>
                    <div className="flex items-center justify-center">{order.remains}</div>
                  </div>
                  <div className="md:w-2/12 mt-5 md:mt-0">
                    <div className="flex items-center justify-center">Estado</div>
                    <div className="flex items-enter justify-center">
                      {
                        (order.observations.length > 0 && !order.observations[0].read) ?
                          <div class="m-auto p-2 bg-red-500 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                            <span class="text-center rounded-full bg-red-400 bg-opacity-60 px-2 py-1 text-xs font-bold">Reportaron</span>
                          </div>
                          :
                          <>
                            {order.state === "Partial" &&
                              <div class="m-auto p-2 bg-neutral-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-neutral-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Parcial</span>
                              </div>
                            }
                            {order.state === "Completed" &&
                              <div class="m-auto p-2 bg-green-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-green-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Completado</span>
                              </div>
                            }
                            {order.state === "Processing" &&
                              <div class="m-auto p-2 bg-orange-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-orange-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Procesando</span>
                              </div>}
                            {order.state === "Pending" &&
                              <div class="m-auto p-2 bg-yellow-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-yellow-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Pendiente</span>
                              </div>}
                            {order.state === "Refunded" &&
                              <div class="m-auto p-2 bg-cyan-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-cyan-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Reembolsado</span>
                              </div>}
                            {order.state === "Canceled" &&
                              <div class="m-auto p-2 bg-red-600 bg-opacity-60 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                <span class="text-center rounded-full bg-red-500 bg-opacity-60 px-2 py-1 text-xs font-bold">Cancelado</span>
                              </div>}
                          </>

                      }
                    </div>
                    <div className='text-xs'>
                      <div className='w-full text-center'>
                        {order.state === "Completed" && 'se completó'}
                        {(order.state === "Processing" || order.state === "Pending" || order.state === "Partial") && 'última actualización'}
                        {order.state === "Refunded" && 'se reembolsó'}
                        {order.state === "Canceled" && 'se canceló'}
                        </div>
                      <div className='w-full text-center'>{moment(order.last_update).format("DD [de] MMMM [de] YYYY [a las] HH:mm a.")}</div>
                    </div>
                  </div>
                </div>

                <div className="relative flex items-center w-full md:w-[80px] text-sm mt-3 md:mt-0">
                  <Tooltip arrow title="Ver reporte">
                    <IconButton onClick={() => handleAnswerReportOrder(order._id, order.observations[0].message, order.observations[0].phonenumber, order.observations[0].created_at, order.orderId, nameplataform, logo)}
                      disabled={(
                        (order.observations.length > 0 && order.observations[0].read && order.state === "Completed") ||
                        (order.observations.length > 0 && order.observations[0].read) ||
                        (order.observations.length === 0 && (order.state !== "Completed" || order.state === "Canceled" || order.state === "Refunded")) ||
                        (order.state === "Canceled" || order.state === "Refunded") ||
                        (order.observations.length === 0 && order.state === "Completed")
                      ) ? true : false} color="primary" className="w-[45px]">
                      <IoArrowRedo size={32} className={`${(
                        (order.observations.length > 0 && order.observations[0].read && order.state === "Completed") ||
                        (order.observations.length > 0 && order.observations[0].read) ||
                        (order.observations.length === 0 && (order.state !== "Completed" || order.state === "Canceled" || order.state === "Refunded")) ||
                        (order.state === "Canceled" || order.state === "Refunded") ||
                        (order.observations.length === 0 && order.state === "Completed")
                      ) ? 'text-neutral-400 dark:text-neutral-700' : 'text-yellow-500'}`} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Ver reporte">
                    <IconButton onClick={() => handleReadReport(order.observations[0].message, order.observations[0].answer, order.observations[0].created_at, order.observations[0].read_at, order.orderId, nameplataform, logo)}
                      disabled={(order.observations.length > 0 && order.observations[0].read) ? false : true} color="primary" className="w-[45px]">
                      <IoChatboxEllipsesSharp size={32} className={`${(order.observations.length > 0 && order.observations[0].read) ? 'text-yellow-500 dark:text-yellow-300' : 'text-neutral-400 dark:text-neutral-700'}`} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )
          }) : (<div className="alert-danger font-semibold mt-[305px]">Sin resultados en la búsqueda! o no existen pedidos de redes sociales registrados</div>) : (<div className="mt-[305px]"><SpinnerData>Cargando pedidos de redes sociales, por favor espere...</SpinnerData></div>)}
      </div>
    </Wrapper>
  );
};

export default SocialOrders;
