import { useEffect, useState } from "react"
import ScreenHeader from "../../components/ScreenHeader";
import { Toaster } from 'react-hot-toast';
import Spinner from "../../components/Spinner";
import { MdCategory, MdDelete, MdDevices, MdEdit, MdPhoneIphone } from "react-icons/md";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { IoAt, IoChevronForward, IoCloseSharp, IoEye, IoPodium, IoSave, IoSearch, IoTime } from "react-icons/io5";
import { convertirsegundos, currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { FaCertificate, FaCogs, FaCoins, FaDollarSign, FaEdit, FaShippingFast, FaUser, FaUserTie } from "react-icons/fa";
import { SiNano } from "react-icons/si";
import Wrapper from "./Wrapper";
import { FcBullish, FcBusinessman, FcClock, FcDebt, FcFlashAuto, FcMoneyTransfer, FcPlus, FcRating, FcReading, FcServices } from "react-icons/fc";
import { Link } from "react-router-dom";
import SpinnerData from "../../components/SpinnerData";
import Modal from '@mui/material/Modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useGetAllSocialPlataformsQuery, useUpdatePlataformMutation } from "../../store/services/socialService";
import ImagesPreview from "../../components/ImagesPreview";
import ImagesPreview2 from "../../components/ImagesPreview2";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { BsLightningFill } from "react-icons/bs";
import UpdateSubCategorySocial from "./UpdateSubCategorySocial";
import ImagesPreviewBanner from "../../components/ImagesPreviewBanner";

const SocialPlataforms = ({
   socket,
   configuration,
   loadingconfiguration
}) => {

   const [searchSocialPlataforms, SetSearchSocialPlataforms] = useState("")

   const { data: plataforms, isFetching } = useGetAllSocialPlataformsQuery();
   const [updatePlataform, setUpdatePlataform] = useState({ _id: "", nameplataform: "", enabled: false, description: "", open: false, currentlogo: "", currentsublogo: "", currentbanner: "", logo: "", sublogo: "", banner: "" });
   const [openVerSubcategorias, SetOpenVerSubcategorias] = useState({ open: false, plataformIsUsed: false, name: "", plataformId: "", subcategorydata: null, sublogo: "" })
   const [openeditsubcategory, SetOpenEditSubCategory] = useState({ open: false, plataformId: "", serviceData: null })
   const [enabled, SetEnabled] = useState(true)
   const [preview, setPreview] = useState({
      logo: '',
      sublogo: '',
      banner: ''
   })

   const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
   );

   const imageHandle = e => {
      if (e.target.files.length !== 0) {
         setUpdatePlataform({ ...updatePlataform, [e.target.name]: e.target.files[0] });
         const reader = new FileReader();
         reader.onloadend = () => {
            setPreview({ ...preview, [e.target.name]: reader.result })
         }
         reader.readAsDataURL(e.target.files[0]);
      }
   }


   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const HandleFilterCategories = !isFetching && plataforms?.plataforms.filter(_c => {
      return _c.name.toLowerCase().includes(searchSocialPlataforms.toLowerCase())

   })

   const onOpenUpdatePlataform = (description, nameplataform, _id, currentlogo, currentsublogo, currentbanner, enabled) => {
      setUpdatePlataform({ ...updatePlataform, description: description, enabled: enabled, nameplataform: nameplataform, _id: _id, open: !updatePlataform.open, currentlogo: currentlogo, currentsublogo: currentsublogo, currentbanner: currentbanner })
      SetEnabled(enabled)
      const rawContent = description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
   }

   const onCloseupdatePlataform = () => {
      setUpdatePlataform({ ...updatePlataform, _id: "", enabled: false, nameplataform: "", description: "", open: false, currentlogo: "", currentsublogo: "", currentbanner: "", logo: "", sublogo: "", banner: "" })
      setPreview({ ...preview, logo: "", sublogo: "", banner: "" })
   }

   const [onUpdateSocialPlataform, responseUpdateSocialPlataform] = useUpdatePlataformMutation();

   const updateSocialPlataform = () => {
      const contentState = editorState.getCurrentContent();
      if (contentState.hasText()) {
         if (updatePlataform.nameplataform.trim().length < 1) {
            message('nombre de plataform es requerido', 'error', 4)
         } else {
            const formData = new FormData();
            formData.append('data', JSON.stringify(updatePlataform));
            formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
            formData.append('image1', updatePlataform.logo)
            formData.append('image2', updatePlataform.sublogo)
            formData.append('image3', updatePlataform.banner)
            onUpdateSocialPlataform(formData);
         }
      } else {
         message('Descripción y modo de uso es requerido', 'error', 4)
      }
   }

   useEffect(() => {
      if (responseUpdateSocialPlataform?.isSuccess) {
         message(responseUpdateSocialPlataform?.data?.message, 'success', 5)
         onCloseupdatePlataform()
      }
   }, [responseUpdateSocialPlataform?.isSuccess])

   useEffect(() => {
      if (!responseUpdateSocialPlataform.isSuccess) {
         responseUpdateSocialPlataform?.error?.data?.errors.map(err => {
            message(err.msg, 'error', 4)
         })
      }
   }, [responseUpdateSocialPlataform?.error?.data?.errors])


   const onOpenVerSubcategorias = (plataformId, plataformIsUsed, name, data, sublogo) => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, open: !openVerSubcategorias.open, plataformIsUsed: plataformIsUsed, plataformId: plataformId, name: name, subcategorydata: data, sublogo: sublogo })
   }

   const onCloseVerSubcategorias = () => {
      SetOpenVerSubcategorias({ ...openVerSubcategorias, plataformIsUsed: false, open: !openVerSubcategorias.open, plataformId: "", name: "", subcategorydata: "", sublogo: "" })
   }

   const HandleOpenEditSubCategory = (plataformId, data) => {
      SetOpenEditSubCategory({ ...openeditsubcategory, open: true, plataformId: plataformId, serviceData: data });
   }

   const HandleCloseEditSubCategorySocial = () => {
      SetOpenEditSubCategory({ ...openeditsubcategory, open: false, plataformId: "", serviceData: null });
   }

   const handleDeleteSubCategory = (plataformId, serviceId) => {
      alertaConfirmar('Eliminar Subcategoría', 'Estas seguro que quieres eliminar esta subcategoría? recuerda que se eliminarán todos los datos relacionados a esta subcategoría.', 'question', async (res_alert) => {
         if (res_alert === true) {
            //dispatch(deleteSubCategory({ categoryId: categoryId, subcategoryId: subcategoryId }))
         }
      })
   }

   const OnChangeEnabled = () => {
      SetEnabled(!enabled)
      setUpdatePlataform({ ...updatePlataform, ['enabled']: !enabled })
  }

   return (
      <Wrapper socket={socket} configuration={configuration}
         loadingconfiguration={loadingconfiguration}>
         <Toaster position="top-right" reverseOrder={true} />
         <ScreenHeader>
            <div className="w-full h-14 text-lg md:text-2xl flex items-center">
               <div className="flex items-center w-9/12 md:w-6/12">
                  <IoAt size={32} style={{ marginRight: 5 }} />
                  PLATAFORMAS SOCIALES
                  <div class="flex items-center justify-center px-3 ml-3 text-2xl font-medium bg-neutral-200 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-full">
                     {!isFetching && plataforms?.plataforms.length}
                  </div>
               </div>
               <div className="flex items-center justify-end w-3/12 md:w-6/12">
                  <Link to="/admin/create-social-service" className="w-16 h-16 flex justify-end">
                     <Tooltip title="Agregar categoría" arrow={true}>
                        <div className="w-16 h-16 rounded-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-900">
                           <IconButton color="primary" className="w-16 h-16">
                              <FcPlus size={45} />
                           </IconButton>
                        </div>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </ScreenHeader>
         <Modal
            open={openeditsubcategory.open}
            onClose={HandleCloseEditSubCategorySocial}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-8/12 bg-white dark:bg-black">
                  <UpdateSubCategorySocial
                     socket={socket}
                     plataformId={openeditsubcategory.plataformId}
                     serviceData={openeditsubcategory.serviceData}
                     newData={(data) => SetOpenVerSubcategorias({ ...openVerSubcategorias, subcategorydata: data })}
                     HandleCloseEditSubCategorySocial={HandleCloseEditSubCategorySocial}
                  />
               </div>
            </div>
         </Modal>

         <Modal
            open={openVerSubcategorias.open}
            onClose={onCloseVerSubcategorias}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-8/12 bg-white dark:bg-black">
                  <div className="flex items-center">
                     <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                        <IconButton color="error" onClick={onCloseVerSubcategorias}>
                           <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                           />
                        </IconButton>
                     </div>
                     <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Servicios de {openVerSubcategorias.name}</div>
                  </div>

                  <div className="flex flex-wrap h-auto mt-10">
                     {
                        openVerSubcategorias.subcategorydata && openVerSubcategorias.subcategorydata.length > 0 ? openVerSubcategorias.subcategorydata.map((service, index) => {


                           return (
                              <div key={index} className="relative p-3 w-full shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer md:w-12/12 bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-wrap mb-12 rounded-lg animation">
                                 <div className="absolute p-2 bg-white dark:bg-neutral-900 rounded-tl-xl rounded-tr-xl w-full md:w-auto text-sm left-0 -top-7 font-bold text-neutral-900 dark:text-neutral-200 truncate ...">{service.name}</div>
                                 
                                 <div className="flex items-center w-full md:w-[calc(33.333%-118px)] relative">
                                    <div className="w-[calc(55px)]">
                                       <LazyLoadImage
                                          className="object-cover w-[52px] h-[52px] z-24 rounded-full flex inline-flex"
                                          alt={`No image`}
                                          effect="blur"
                                          id='prelogo'
                                          src={`/images/logos/${openVerSubcategorias.sublogo}`}
                                       />
                                    </div>
                                    <div className="w-[calc(100%-55px)]">
                                    <div className="ml-2 w-full text-neutral-900 dark:text-neutral-200 text-sm mt-2 flex items-center font-bold truncate ..."><FcServices size={26} className="mr-1" />{service.serviceId}</div>
                                    <div className="relative ml-2 w-full text-neutral-900 dark:text-neutral-200 text-sm mt-2">{service.enabled ? <span className="bg-green-600 text-white font-bold rounded-lg p-1">Habilitado</span>:<span className="bg-red-600 text-white font-bold rounded-lg p-1">Deshabilitado</span>}</div>
                                       <div className="ml-2 w-full text-neutral-900 dark:text-neutral-200 text-xs mt-2 truncate ...">{setUnixToTimeFormat('info_contacto', service.created_at)}</div>
                                    </div>
                                 </div>

                                 <div className="md:w-2/12 w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200 text-sm">Precios fijos</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm">
                                          <span className="text-neutral-900 dark:text-neutral-100 flex items-center text-sm mr-3"><FcBusinessman size={32} className="mr-1" />{currencyFormat(service.preciofijouser)}</span>
                                          <span className="text-neutral-900 dark:text-neutral-100 flex items-center text-sm"><FcDebt size={32} className="mr-1" />{currencyFormat(service.preciofijoreseller)}</span>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="md:w-2/12 w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200 text-sm">% adicional aplicado</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm">
                                          <span className="text-neutral-900 dark:text-neutral-100 flex items-center text-sm"><FcBullish size={32} className="mr-1" />+ {service.porcentajeadicional}%</span>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="md:w-2/12 w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200 text-sm">Tiempo estimado</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm">
                                          <span className="text-neutral-900 dark:text-neutral-100 flex items-center text-sm mr-3"><FcClock size={32} className="mr-1" />{convertirsegundos(service.speedseconds)}</span>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="md:w-2/12 w-full grid mt-3 md:mt-0">
                                    <div className="flex items-center justify-center font-bold text-neutral-900 dark:text-neutral-200 text-sm">Tipo de entrega</div>
                                    <div className="flex items-center justify-center">
                                       <div className="flex inline-flex items-center text-sm">
                                          {service.tipoentrega ? <><span className="text-yellow-500 dark:text-yellow-400 flex items-center text-sm"><FcFlashAuto size={32} className="mr-1" />Automática</span></>
                                             :
                                             <><span className="text-neutral-600 dark:text-neutral-400 flex items-center text-sm"><FcReading size={32} className="mr-1" />Manual</span></>}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="w-[118px] flex items-center justify-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg">
                                    <div className="w-[56px] h-[56px] rounded-full">
                                       <Tooltip title="editar" arrow={true}>
                                          <IconButton onClick={() => HandleOpenEditSubCategory(openVerSubcategorias.plataformId, service)} className="w-14 h-14" color='primary'>
                                             <MdEdit
                                                className="text-yellow-500 m-auto"
                                                size={25}
                                             />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                    <div className="w-[56px] h-[56px] rounded-full ml-2">
                                       <Tooltip title="eliminar" arrow={true}>
                                          <IconButton onClick={() => handleDeleteSubCategory(openVerSubcategorias.plataformId, service._id)} className="w-14 h-14" color="primary">
                                             <MdDelete size={25}
                                                className="text-red-600 m-auto"
                                             />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 </div>
                              </div>)
                        }) :
                           <div class="flex items-center m-auto p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-neutral-800 dark:text-yellow-500" role="alert">
                              <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                              </svg>
                              <span class="sr-only">Info</span>
                              <div>
                                 <span class="font-bold uppercase">Sin datos!</span> La categoría <b>{openVerSubcategorias.name}</b> aún no tiene datos registrados!
                              </div>
                           </div>
                     }
                  </div>
               </div>
            </div>
         </Modal>
         <div className="-ml-[1px] w-[calc(100%-29px)] md:w-[calc(100%-269px)] mb-5 mt-[52px] fixed z-20 bg-neutral-50 dark:bg-neutral-950 pt-5 pb-5">
            <div className="relative ml-4">
               <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-5 h-5 text-neutral-500 dark:text-neutral-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
               </div>
               <input autoFocus type="search" id="monto" className="bg-neutral-200 dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] ps-10 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full md:w-5/12 placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                  placeholder="Buscar Categorías"
                  value={searchSocialPlataforms}
                  onChange={(e) => SetSearchSocialPlataforms(e.target.value)}
                  autoComplete="off"
               />
            </div>
         </div>

         <Modal
            open={updatePlataform.open}
            onClose={onCloseupdatePlataform}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ zIndex: 50 }}
         >
            <div id="authentication-modal" class="overflow-auto w-full h-full bg-neutral-50 bg-opacity-30">
               <div className="relative m-auto p-5 w-full md:w-6/12 bg-white dark:bg-black">
                  <div className="flex items-center">
                     <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                        <IconButton color="error" onClick={onCloseupdatePlataform}>
                           <IoCloseSharp
                              className="text-neutral-200"
                              size={28}
                           />
                        </IconButton>
                     </div>
                     <div className="text-2xl ml-3 uppercase text-neutral-900 dark:text-neutral-200">Editar Plataforma</div>
                  </div>


                  <div className="flex flex-wrap h-auto">
                     <div className="w-full flex flex-wrap p-3">
                        <div className="w-full mb-5 mt-5">
                           <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la plataforma</label>
                           <input autoFocus name="nameplataform" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                              placeholder="Nombre de la plataforma"
                              autoComplete="off"
                              required
                              value={updatePlataform.nameplataform}
                              type="text"
                              onChange={(e) => setUpdatePlataform({ ...updatePlataform, nameplataform: e.target.value })}
                           />
                        </div>

                        <div class="w-full md:w-6/12">

                           <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                              <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                 <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                 <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                 <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                              </div>
                              <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                           </label>

                        </div>
                        <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                           <div className="">
                              <LazyLoadImage
                                 className="object-cover w-[150px] h-[220px] z-24 rounded-lg flex inline-flex"
                                 alt={`No image`}
                                 effect="blur"
                                 src={`/images/logos/${updatePlataform.currentlogo}`}
                              />
                           </div>
                           {preview.logo && <IoChevronForward className="text-neutral-300" size={32} />}
                           <ImagesPreview2 url={preview.logo} />
                        </div>
                     </div>

                     <div className="w-full flex flex-wrap p-3">
                        <div class="w-full md:w-6/12">

                           <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                              <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                 <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                 <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                                 <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                              </div>
                              <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                           </label>

                        </div>
                        <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                           <div className="">
                              <LazyLoadImage
                                 className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                                 alt={`No image`}
                                 effect="blur"
                                 src={`/images/logos/${updatePlataform.currentsublogo}`}
                              />
                           </div>
                           {preview.sublogo && <IoChevronForward className="text-neutral-300" size={32} />}
                           <ImagesPreview url={preview.sublogo} />
                        </div>
                     </div>

                     <div className="w-full flex flex-wrap p-3">
                        <div class="w-full md:w-6/12">

                           <label htmlFor="banner" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800">
                              <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                                 <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                 <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200"><span class="font-semibold">Click para subir imagen banner </span> o arrastrar y soltar</p>
                                 <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                              </div>
                              <input id="banner" accept="image/png,image/jpeg,image/webp" type="file" name="banner" class="hidden" onChange={imageHandle} />

                           </label>

                        </div>
                        <div className="border-2 w-full md:w-6/12 h-[250px] rounded-lg border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                           <div className="">
                              <LazyLoadImage
                                 className="object-cover w-[180px] h-[110px] z-24 rounded-full flex inline-flex"
                                 alt={`No image`}
                                 effect="blur"
                                 src={`/images/logos/${updatePlataform.currentbanner}`}
                              />
                           </div>
                           {preview.banner && <IoChevronForward className="text-neutral-300" size={32} />}
                           <ImagesPreviewBanner url={preview.banner} />
                        </div>
                     </div>

                     <div className="w-full p-3">
                        <label className="text-neutral-900 dark:text-neutral-200 text-sm">Estado del servicio <span className="font-semibold">{enabled ? "habilitado" : "deshabilitado"}</span></label>
                        <div className="flex flex-col justify-between h-16 mt-3">

                            <span onClick={OnChangeEnabled} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${enabled && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>

                            <span onClick={OnChangeEnabled} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!enabled && 'hidden'}`}>
                                <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                            </span>
                        </div>
                    </div>

                     <div className="w-full p-3">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descripción y modo de uso</label>
                        <Editor
                           editorState={editorState}
                           onEditorStateChange={setEditorState}
                           placeholder="Escribe aquí..."
                        />
                     </div>
                     <div className="w-full mt-10">
                        <Button
                           disabled={responseUpdateSocialPlataform.isLoading ? true : false}
                           onClick={updateSocialPlataform}
                           sx={{
                              "&.Mui-disabled": {
                                 background: "#525252",
                                 color: "#a3a3a3"
                              }
                           }}
                           type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                           size='large' variant="contained" color="primary">{responseUpdateSocialPlataform.isLoading ? <><Spinner w={'35px'} h={'35px'} /> PROCESANDO... </> : <><IoSave size={24} className="mr-2" /> ACTUALIZAR PLATAFORMA</>}</Button>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>

         {!isFetching ? HandleFilterCategories.length > 0 ? <>
            <div className="flex flex-wrap mt-[175px]">


               {HandleFilterCategories.map((plataform) => {

                  var plataformIsUsed = false

                  return (
                     <div className={`p-4 w-full shadow-lg dark:shadow-black hover:bg-gradient-to-t from-neutral-300 via-neutral-100 to-white dark:from-neutral-700 dark:via-neutral-800 dark:to-neutral-900 cursor-pointer md:w-12/12 bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-wrap mb-10 rounded-lg animation`} key={plataform._id}>
                        <div className="flex flex-wrap items-center w-full md:w-4/12 relative h-24 md:h-auto">
                           <div className="absolute left-0 md:-top-[7px] top-0">

                              <LazyLoadImage
                                 className="object-cover w-16 h-24 z-10 rounded-sm flex inline-flex"
                                 alt={`No image ${plataform.name}`}
                                 effect="blur"
                                 src={`/images/logos/${plataform.logo}`}
                              />
                           </div>
                           <div className="flex flex-wrap items-center ml-16 w-[calc(100%-48px)]">
                              <div className="flex inline-flex items-center ml-2">
                                 <LazyLoadImage
                                    className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex"
                                    alt={`No image ${plataform.name}`}
                                    effect="blur"
                                    src={`/images/logos/${plataform.sublogo}`}
                                 />
                              </div>
                              <div className="w-[calc(100%-56px)]">
                                 <div className="ml-2 text-base text-neutral-900 dark:text-neutral-200 font-bold uppercase">{plataform.name}</div>
                                 <div className="ml-2 text-xs mt-2 truncate ...">{setUnixToTimeFormat('info_contacto', plataform.created_at)}</div>
                              </div>
                           </div>

                        </div>

                        <div className="md:w-2/12 w-full grid mt-8 md:mt-0">
                           <div className="flex items-center justify-center font-bold">Color tema</div>
                           <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center text-2xl font-bold">
                                 <div className="w-8 h-8 rounded-full border-4 border-neutral-400 dark:border-neutral-600" style={{ background: `${plataform.themecolor}` }}>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="md:w-2/12 w-full grid mt-3 md:mt-0">
                           <div className="flex items-center justify-center font-bold">Subcategorias</div>
                           <div className="flex items-center justify-center">
                              <div className="flex inline-flex items-center text-2xl font-bold">
                                 {plataform.subcategory.length}
                              </div>
                           </div>
                        </div>

                        <div className="flex inline-flex items-center justify-end w-full md:w-4/12">
                           <div className="flex items-center p-3 bg-neutral-50 dark:bg-neutral-950 rounded-lg mt-3 md:mt-0">
                              <div className="w-14 h-14 rounded-full">
                                 <Tooltip title="ver subcategorias" arrow={true}>
                                    <IconButton onClick={() => onOpenVerSubcategorias(plataform._id, plataformIsUsed, plataform.name, plataform.subcategory, plataform.sublogo)} className="w-14 h-14" color="primary">
                                       <IoEye size={24} className="cursor-pointer text-neutral-900 dark:text-neutral-100" />
                                    </IconButton>
                                 </Tooltip>
                              </div>
                              <div className="w-14 h-14 rounded-full mt-3 md:mt-0">
                                 <Tooltip title="Editar" arrow={true}>
                                    <IconButton onClick={() => onOpenUpdatePlataform(plataform.description, plataform.name, plataform._id, plataform.logo, plataform.sublogo, plataform.banner, plataform.enabled)} className="w-14 h-14" color="primary">
                                       <FaEdit size={24} className="cursor-pointer ml-2 text-yellow-500" />
                                    </IconButton>
                                 </Tooltip>
                              </div>

                              <div className="w-14 h-14 rounded-full mt-3 md:mt-0 ">
                                 <Tooltip title="Eliminar" arrow={true}>
                                    <IconButton className="w-14 h-14" color="primary">
                                       <MdDelete size={25} className="text-red-600 m-auto" />
                                    </IconButton>
                                 </Tooltip>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               }
               )}
            </div></> : <div className="alert-danger font-semibold mt-[175px]">Sin resultados en la búsqueda! o no existen plataformas registradas</div> : <div className="mt-[175px]"><SpinnerData>Cargando plataformas, por favor espere...</SpinnerData></div>}
      </Wrapper>
   )
}
export default SocialPlataforms;