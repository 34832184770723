import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import ScreenHeader from "../../components/ScreenHeader";
import toast, { Toaster } from 'react-hot-toast';
import Wrapper from "./Wrapper"
import { useCreateMutation, useGetAllCategoriesGiftCardQuery, useGetAllCategoriesStreamingQuery } from "../../store/services/categoryService";
import { Button, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { BsCalendar3, BsPlusCircleDotted } from "react-icons/bs";
import { IoAdd, IoChevronDown, IoCloseSharp, IoSave } from "react-icons/io5";
import ImagesPreview from "../../components/ImagesPreview";
import { message, PermiteSoloNumeros } from "../../utils/Funciones.js";
import Spinner from "../../components/Spinner";
import { MdAddCircle } from "react-icons/md";
import ImagesPreviewGiftCard from "../../components/home/ImagesPreviewGiftCard";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import Modal from '@mui/material/Modal';
import { HexColorPicker } from 'react-colorful';
import debounce from 'lodash.debounce';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ImagesPreview2 from "../../components/ImagesPreview2";
import { SET_TO_DEFAULT_RESPONSE, createCategory } from "../../store/features/categorySlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CreateCategory = ({
    socket,
    configuration,
    loadingconfiguration,
    categories,
    CategoryIsLoading
}) => {
    const dispatch = useDispatch();
    const { responseSuccessCreate, isLoading, isSuccess, isError } = useSelector((state) => state.categoryReducer);

    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [requiredemail, SetRequiredEmail] = useState(false)
    const [cuentacompleta, SetCuentaCompleta] = useState(false)
    const [renovable, SetRenovable] = useState(false)
    const [profileblocked, SetProfileBlocked] = useState(false)

    const [editorStateSTR, setEditorStateSTR] = useState(
        () => EditorState.createEmpty(),
    );

    const [editorStateGC, setEditorStateGC] = useState(
        () => EditorState.createEmpty(),
    );

    const [editorState2STR, setEditorState2STR] = useState(
        () => EditorState.createEmpty(),
    );

    const [editorState2GC, setEditorState2GC] = useState(
        () => EditorState.createEmpty(),
    );

    const [color, setColor] = useState('#ffffff');
    const [state, setState] = useState({
        name: '',
        subcategory: "",
        discountuser: "",
        costouser: "",
        costoreseller: "",
        maximoperfiles: "",
        costoperfiluser: "",
        discountperfiluser: 0,
        costocuentauser: "",
        costoperfilreseller: "",
        costocuentareseller: "",
        tipoentrega: false,
        requiredemail: false,
        cuentacompleta: false,
        renovable: false,
        isprofileblocked: false,
        url: "",
        themecolor: "#aabbcc",
        logo: "",
        sublogo: "",
        type: "",
        location: "",
        opennewcategorygiftcard: false,
        opennewcategorystreaming: false,
        minmonths: "",
        logosubcategory: ""
    });
    const HandleChange = (e) => {
        if (e.target.name === 'maximoperfiles' && e.target.value > 1) {
            SetCuentaCompleta(false)
            setState({ ...state, ['cuentacompleta']: false, [e.target.name]: e.target.value })
        } else if (e.target.name === 'maximoperfiles' && e.target.value < 2) {
            SetCuentaCompleta(true)
            setState({ ...state, ['cuentacompleta']: true, [e.target.name]: e.target.value })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
    }

    const HandleChangeType = (value) => {
        var opennewcategorygiftcard = false
        var opennewcategorystreaming = false
        if (value === 'NewCategoryGiftCard') {
            opennewcategorygiftcard = true
        }

        if (value === 'NewCategoryStreaming') {
            opennewcategorystreaming = true
        }

        setState({
            ...state, name: '',
            subcategory: "",
            costouser: "",
            costoreseller: "",
            maximoperfiles: "",
            costoperfiluser: "",
            discountperfiluser: "",
            costocuentauser: "",
            costoperfilreseller: "",
            costocuentareseller: "",
            tipoentrega: false,
            requiredemail: false,
            cuentacompleta: false,
            isprofileblocked: false,
            url: "",
            themecolor: "#ffffff",
            logo: "",
            sublogo: "",
            location: "",
            type: value,
            opennewcategorygiftcard: opennewcategorygiftcard,
            opennewcategorystreaming: opennewcategorystreaming
        })

        setPreview({
            ...preview,
            logo: '',
            sublogo: '',
            logosubcategory: ''
        })

        setEditorState2GC(() => EditorState.createEmpty())
        setEditorState2STR(() => EditorState.createEmpty())
        setEditorStateGC(() => EditorState.createEmpty())
        setEditorStateSTR(() => EditorState.createEmpty())
    }

    const [preview, setPreview] = useState({
        logo: '',
        sublogo: '',
        logosubcategory: ''
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const submitCategory = async e => {
        e.preventDefault();
        const policySTR = editorStateSTR.getCurrentContent();
        const descriptionSTR = editorState2STR.getCurrentContent();
        const policyGC = editorStateGC.getCurrentContent();
        const descriptionGC = editorState2GC.getCurrentContent();
        state.themecolor = color

        if (state.type === 'Streaming') {
            if (policySTR.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(policySTR)));
                formData.append('rawData2', JSON.stringify(convertToRaw(descriptionSTR)));
                formData.append('image1', state.logo)
                formData.append('image2', state.sublogo)
                formData.append('image3', state.logosubcategory)
                //saveCategory(formData);
                await dispatch(createCategory(formData));

            } else {
                message('Términos y condiciones del servicio', 'error', 4)
            }

        } else if (state.type === 'GiftCard') {
            console.log('entro aquiii')
            if (policyGC.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(policyGC)));
                formData.append('rawData2', JSON.stringify(convertToRaw(descriptionGC)));
                formData.append('image1', state.logo)
                formData.append('image2', state.sublogo)
                formData.append('image3', state.logosubcategory)
                await dispatch(createCategory(formData));
            } else {
                message('Términos y condiciones del servicio', 'error', 4)
            }
        } else if (state.type === 'NewCategoryGiftCard') {
            if (descriptionGC.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(policyGC)));
                formData.append('rawData2', JSON.stringify(convertToRaw(descriptionGC)));
                formData.append('image1', state.logo)
                formData.append('image2', state.sublogo)
                formData.append('image3', state.logosubcategory)
                await dispatch(createCategory(formData));
            } else {
                message('Términos y condiciones del servicio', 'error', 4)
            }
        } else if (state.type === 'NewCategoryStreaming') {
            if (descriptionSTR.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(policySTR)));
                formData.append('rawData2', JSON.stringify(convertToRaw(descriptionSTR)));
                formData.append('image1', state.logo)
                formData.append('image2', state.sublogo)
                formData.append('image3', state.logosubcategory)
                await dispatch(createCategory(formData));
            } else {
                message('Términos y condiciones del servicio', 'error', 4)
            }
        }
    }

    const navigate = useNavigate();


    useEffect(async () => {
        if (isSuccess) {
            message(responseSuccessCreate.message, 'success', 5)
            if (responseSuccessCreate.type === 'Streaming') {
                navigate('/admin/categories')
            } else if (responseSuccessCreate.type === 'GiftCard') {
                navigate('/admin/categoriesGC')
            } else if (responseSuccessCreate.type === 'NewCategoryGiftCard') {
                setState({ ...state, opennewcategorygiftcard: false, type: 'GiftCard', name: '', logosubcategory: '' })
            } else if (responseSuccessCreate.type === 'NewCategoryStreaming') {
                setState({ ...state, opennewcategorystreaming: false, type: 'Streaming', name: '', logosubcategory: '' })
            }
        }
        await dispatch(SET_TO_DEFAULT_RESPONSE())
    }, [responseSuccessCreate])

    const OnChangeTipoEntrega = () => {
        if (state.requiredemail) {
            SetRequiredEmail(false)
            SetTipoEntrega(!tipoentrega)
            setState({ ...state, ['requiredemail']: false, ['tipoentrega']: !tipoentrega })
        } else {
            SetTipoEntrega(!tipoentrega)
            setState({ ...state, ['tipoentrega']: !tipoentrega })
        }
    }

    const OnChangeRequiredEmail = () => {

        if (state.tipoentrega) {
            SetTipoEntrega(false)
            SetRequiredEmail(!requiredemail)
            setState({ ...state, ['tipoentrega']: false, ['requiredemail']: !requiredemail })
        } else {
            SetRequiredEmail(!requiredemail)
            setState({ ...state, ['requiredemail']: !requiredemail })
        }
    }

    const OnChangeCuentaCompleta = () => {
        if (state.maximoperfiles > 1) {
            message('No se puede editar cuando máximo de perfiles es mayor a 1', 'error')
        } else {
            if (!cuentacompleta) {
                SetCuentaCompleta(!cuentacompleta)
                setState({ ...state, ['cuentacompleta']: !cuentacompleta, maximoperfiles: 1 })
            } else {
                SetCuentaCompleta(!cuentacompleta)
                setState({ ...state, ['cuentacompleta']: !cuentacompleta })
            }

        }
    }

    const OnChangeRenovable = () => {
        SetRenovable(!renovable)
        setState({ ...state, ['renovable']: !renovable })
    }

    const OnChangeProfileBlocked = () => {
        SetProfileBlocked(!profileblocked)
        setState({ ...state, ['isprofileblocked']: !profileblocked })
    }

    const onChangeColor = (event) => {
        setColor(event)
    };

    const debouncecolor = useMemo(() => debounce(onChangeColor, 1000), []);

    return (
        <Wrapper socket={socket} configuration={configuration}
            loadingconfiguration={loadingconfiguration}>
            <Toaster position="top-right" reverseOrder={true} />
            <ScreenHeader>
                <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                    <div className="flex items-center w-9/12 md:w-6/12">
                        <BsPlusCircleDotted size={32} style={{ marginRight: 5 }} />
                        NUEVA CATEGORIA
                    </div>
                </div>
            </ScreenHeader>

            <div className="flex flex-wrap -mx-3 mt-[70px]">
                <Modal
                    open={state.opennewcategorygiftcard}
                    onClose={() => setState({ ...state, opennewcategorygiftcard: false, name: '', type: 'GiftCard', logosubcategory: '' })}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                    sx={{ zIndex: 50 }}
                >
                    <div id="authentication-modal" class="w-full h-full overflow-auto bg-neutral-50 bg-opacity-30">
                        <div className="m-auto p-5 w-full md:w-7/12 bg-white dark:bg-black">
                            <div className="flex items-center">
                                <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                                    <IconButton color="error" onClick={() => setState({ ...state, opennewcategorygiftcard: false, name: '', type: 'GiftCard', logosubcategory: '' })}>
                                        <IoCloseSharp
                                            className="text-neutral-200"
                                            size={28}
                                        />
                                    </IconButton>
                                </div>
                                <div className="w-[calc(100%-48px)] text-neutral-900 dark:text-neutral-200 text-center text-2xl">
                                    CREAR CATEGORIA GIFTCARD
                                </div>
                            </div>
                            <div className="w-full md:w-12/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la categoria giftcard</label>
                                <input autoFocus name="name" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Nombre de la categoria giftcard"
                                    autoComplete="off"
                                    required
                                    type="text"
                                    value={state.name}
                                    onChange={(e) => setState({ ...state, name: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-5/12 p-3">
                                    <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black hover:bg-neutral-200 dark:hover:bg-neutral-700">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200 p-3"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div class="w-full md:w-6/12 p-3 rounded-sm flex inline-flex items-center justify-center">
                                    <div className="w-100 h-100 rounded-sm border-neutral-500 flex inline-flex items-center justify-center">
                                        <ImagesPreview2 url={preview.logo} />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="w-full md:w-5/12 p-3">
                                    <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black hover:bg-neutral-200 dark:hover:bg-neutral-700">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200 p-3"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div class="w-full md:w-6/12 p-3 rounded-full flex inline-flex items-center justify-center">
                                    <div className="border-2 w-100 h-100 rounded-full border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                        <ImagesPreview url={preview.sublogo} />
                                    </div>
                                </div>
                            </div>

                            <div className="w-[125px] p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Color tema</label>
                                <HexColorPicker color={color} onChange={debouncecolor} />
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descripción y modo de uso</label>
                                <Editor
                                    editorState={editorState2GC}
                                    onEditorStateChange={setEditorState2GC}
                                    placeholder="Escribe aquí..."
                                />
                            </div>

                            <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                                <Button
                                    type="submit"
                                    onClick={submitCategory}
                                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                    size='large' variant="contained"
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                    {isLoading ? 'Guardando...' : 'Crear categoría'}
                                </Button>
                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal
                    open={state.opennewcategorystreaming}
                    onClose={() => setState({ ...state, opennewcategorystreaming: false, name: '', type: 'Streaming', logosubcategory: '', themecolor: "" })}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                    sx={{ zIndex: 50 }}
                >
                    <div id="authentication-modal" class="w-full h-full overflow-auto bg-neutral-50 bg-opacity-30">
                        <div className="m-auto p-5 w-full md:w-7/12 bg-white dark:bg-black">
                            <div className="flex items-center">
                                <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                                    <IconButton color="error" onClick={() => setState({ ...state, opennewcategorystreaming: false, name: '', type: 'Streaming', logosubcategory: '', themecolor: "" })}>
                                        <IoCloseSharp
                                            className="text-neutral-200"
                                            size={28}
                                        />
                                    </IconButton>
                                </div>
                                <div className="w-[calc(100%-48px)] text-neutral-900 dark:text-neutral-200 text-center text-2xl">
                                    CREAR CATEGORIA STREAMING
                                </div>
                            </div>
                            <div className="w-full md:w-12/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de la categoria streaming</label>
                                <input autoFocus name="name" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Nombre de la categoria streaming"
                                    autoComplete="off"
                                    required
                                    type="text"
                                    value={state.name}
                                    onChange={(e) => setState({ ...state, name: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-5/12 p-3">
                                    <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black hover:bg-neutral-200 dark:hover:bg-neutral-700">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200 p-3"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div class="w-full md:w-6/12 p-3 rounded-sm flex inline-flex items-center justify-center">
                                    <div className="w-100 h-100 rounded-sm border-neutral-500 flex inline-flex items-center justify-center">
                                        <ImagesPreview2 url={preview.logo} />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="w-full md:w-5/12 p-3">
                                    <label htmlFor="sublogo" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black hover:bg-neutral-200 dark:hover:bg-neutral-700">
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200 p-3"><span class="font-semibold">Click para subir imagen sublogo </span> o arrastrar y soltar</p>
                                            <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                        </div>
                                        <input id="sublogo" accept="image/png,image/jpeg,image/webp" type="file" name="sublogo" class="hidden" onChange={imageHandle} />

                                    </label>

                                </div>
                                <div class="w-full md:w-6/12 p-3 rounded-full flex inline-flex items-center justify-center">
                                    <div className="border-2 w-100 h-100 rounded-full border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                        <ImagesPreview url={preview.sublogo} />
                                    </div>
                                </div>
                            </div>

                            <div className="w-[125px] p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Color tema</label>
                                <HexColorPicker color={color} onChange={debouncecolor} />
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descripción y modo de uso</label>
                                <Editor
                                    editorState={editorState2STR}
                                    onEditorStateChange={setEditorState2STR}
                                    placeholder="Escribe aquí..."
                                />
                            </div>

                            <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                                <button
                                    type="submit"
                                    onClick={submitCategory}
                                    class="flex w-full disabled:opacity-30 justify-center rounded-md bg-blue-600 px-3 py-5 uppercase text-base font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                    {isLoading ? 'Cargando...' : 'Crear categoría'}
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal>

                <div className="w-full flex flex-wrap items-center p-3">
                    <div onClick={() => HandleChangeType('Streaming')}
                        className={`flex text-center bg-neutral-300 dark:bg-neutral-50 p-5 rounded-lg 
                    cursor-pointer active:bg-neutral-200 dark:active:bg-white text-neutral-900 font-bold ${(state.type === "Streaming" || state.type === "NewCategoryStreaming") && "bg-gradient-red-custom text-white"}`}>
                        STREAMING
                    </div>
                    <div onClick={() => HandleChangeType('GiftCard')}
                        className={`flex text-center bg-neutral-300 dark:bg-neutral-50 p-5 rounded-lg 
                        cursor-pointer active:bg-neutral-200 dark:active:bg-white text-neutral-900 font-bold ml-3 ${(state.type === "GiftCard" || state.type === "NewCategoryGiftCard") && "bg-gradient-blue-custom text-white"}`}>
                        TARJETA DE REGALO
                    </div>
                </div>
                {
                    (state.type === "Streaming" || state.type === "NewCategoryStreaming") &&
                    <form className="w-full xl:w-8/12 p-3" onSubmit={submitCategory}>
                        <div className="flex flex-wrap">
                            <div className="flex items-center w-full md:w-6/12">
                                <div className="w-[calc(100%-85px)] p-3">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Categoría Streaming</label>
                                    {!CategoryIsLoading ?
                                        <div className="relative">
                                            <FormControl fullWidth>
                                                <Select
                                                    required
                                                    name="name"
                                                    className="flex items-center"
                                                    variant="outlined"
                                                    value={state.name}
                                                    onChange={HandleChange}
                                                >
                                                    {categories.filter(fil => fil.type === 'Streaming').map(category => (
                                                        <MenuItem value={category.name} key={category.name} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.sublogo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div> : <Spinner />}
                                </div>
                                <div className="flex items-center mt-7 justify-center w-[55px] h-[55px] ml-[15px] mr-[15px] bg-gradient-red-custom rounded-full">
                                    <Tooltip title="Agregar nueva categoría Streaming" arrow={true}>
                                        <IconButton onClick={() => HandleChangeType('NewCategoryStreaming')}
                                            className="flex items-center justify-center w-[55px] h-[55px]"
                                            color="primary"
                                        >
                                            <MdAddCircle size={45} className="text-neutral-200" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de sub categoria</label>
                                <input autoFocus name="subcategory" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Nombre de la sub categoría"
                                    autoComplete="off"
                                    required
                                    value={state.subcategory}
                                    type="text"
                                    onChange={HandleChange}
                                />
                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Maximo de perfiles de la cuenta</label>
                                <input name="maximoperfiles" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Maximo de perfiles de la cuenta"
                                    autoComplete="off"
                                    type="number"
                                    onKeyPress={PermiteSoloNumeros}
                                    value={state.maximoperfiles}
                                    onChange={HandleChange}
                                    min={1}
                                />
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo perfil usuario * 30 dias</label>
                                <input name="costoperfiluser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Costo perfil usuario * 30 dias"
                                    autoComplete="off"
                                    required
                                    value={state.costoperfiluser}
                                    type="number"
                                    onChange={HandleChange}
                                    onWheel={(e) => e.target.blur()}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    S/
                                </div>
                            </div>


                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descuento por perfil de usuario (%)</label>
                                <input name="discountperfiluser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Descuento por perfil de usuario (%)"
                                    autoComplete="off"
                                    required
                                    value={state.discountperfiluser}
                                    type="number"
                                    onChange={HandleChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyPress={PermiteSoloNumeros}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    %
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo cuenta completa usuario * 30 dias</label>
                                <input name="costocuentauser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Costo cuenta completa usuario * 30 dias"
                                    autoComplete="off"
                                    required
                                    value={state.costocuentauser}
                                    type="number"
                                    onChange={HandleChange}
                                    onWheel={(e) => e.target.blur()}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    S/
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo perfil reseller * 30 dias</label>
                                <input name="costoperfilreseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Costo cuenta completa usuario * 30 dias"
                                    autoComplete="off"
                                    required
                                    value={state.costoperfilreseller}
                                    type="number"
                                    onChange={HandleChange}
                                    onWheel={(e) => e.target.blur()}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    S/
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo cuenta completa reseller * 30 dias</label>
                                <input name="costocuentareseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Costo cuenta completa reseller * 30 dias"
                                    autoComplete="off"
                                    required
                                    value={state.costocuentareseller}
                                    type="number"
                                    onChange={HandleChange}
                                    onWheel={(e) => e.target.blur()}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    S/
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Cantidad mínima de meses</label>
                                <input name="minmonths" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Cantidad mínima de meses"
                                    autoComplete="off"
                                    required
                                    value={state.minmonths}
                                    type="number"
                                    onChange={HandleChange}
                                    onWheel={(e) => e.target.blur()}
                                    min={1}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    <BsCalendar3 size={20} className="text-neutral-900 dark:text-neutral-200" />
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Localización</label>
                                <div className="relative">
                                    <FormControl fullWidth>
                                        <Select
                                            required
                                            name="location"
                                            className="flex items-center"
                                            variant="outlined"
                                            value={state.location}
                                            onChange={HandleChange}
                                        >
                                            <MenuItem value={'Global'} key={'Global'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Global.svg`} className="mr-2 rounded-full" />Global</MenuItem>
                                            <MenuItem value={'Latam'} key={'Latam'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Latam.svg`} className="mr-2 rounded-full" />Latam</MenuItem>
                                            <MenuItem value={'Perú'} key={'Perú'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Perú.svg`} className="mr-2 rounded-full" />Perú</MenuItem>
                                            <MenuItem value={'Brasil'} key={'Brasil'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Brasil.svg`} className="mr-2 rounded-full" />Brasil</MenuItem>
                                            <MenuItem value={'Colombia'} key={'Colombia'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Colombia.svg`} className="mr-2 rounded-full" />Colombia</MenuItem>
                                            <MenuItem value={'Turquía'} key={'Turquía'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Turquía.svg`} className="mr-2 rounded-full" />Turquía</MenuItem>
                                            <MenuItem value={'Estados Unidos'} key={'Estados Unidos'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Estados Unidos.svg`} className="mr-2 rounded-full" />Estados Unidos</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Términos y condiciones del servicio</label>
                                <Editor
                                    editorState={editorStateSTR}
                                    onEditorStateChange={setEditorStateSTR}
                                    placeholder="Escribe aquí..."
                                />
                            </div>

                            <div className="w-full p-3">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeTipoEntrega} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${tipoentrega && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeTipoEntrega} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!tipoentrega && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Se requiere email para completar pedido ? <span className="font-semibold">{requiredemail ? "Si" : "No"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeRequiredEmail} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${requiredemail && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeRequiredEmail} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!requiredemail && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Cuenta completa ? <span className="font-semibold">{cuentacompleta ? "Si" : "No"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeCuentaCompleta} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${cuentacompleta && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeCuentaCompleta} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!cuentacompleta && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Es renovable ? <span className="font-semibold">{renovable ? "Si" : "No"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeRenovable} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${renovable && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeRenovable} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!renovable && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Se bloquea los perfiles ? <span className="font-semibold">{profileblocked ? "Si" : "No"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeProfileBlocked} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${profileblocked && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeProfileBlocked} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!profileblocked && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label htmlFor="logosubcategory" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-200 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-700">
                                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200 p-3"><span class="font-semibold">Click para subir imagen logo de subcategoría </span> o arrastrar y soltar</p>
                                        <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                    </div>
                                    <input id="logosubcategory" accept="image/png,image/jpeg,image/webp" type="file" name="logosubcategory" class="hidden" onChange={imageHandle} />

                                </label>

                            </div>
                            <div class="w-full md:w-6/12 p-3 rounded-full flex inline-flex items-center justify-center">
                                <div className="w-100 h-100 rounded-full border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                    <ImagesPreview url={preview.logosubcategory} />
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Sitio web</label>
                                <input name="url" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Sitio web"
                                    autoComplete="off"
                                    required
                                    value={state.url}
                                    type="text"
                                    onChange={HandleChange}
                                />
                            </div>

                            <div className="flex m-auto w-full  md:w-12/12 p-3 w-full p-3 mt-5">
                                <Button
                                    type="submit"
                                    disabled={isLoading ? true : false}
                                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                    size='large' variant="contained"
                                >
                                    {isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                    {isLoading ? 'Guardando...' : 'CREAR CATEGORIA'}
                                </Button>
                            </div>
                        </div>
                    </form>
                }

                {
                    (state.type === "GiftCard" || state.type === "NewCategoryGiftCard") &&
                    <form className="w-full xl:w-8/12 p-3" onSubmit={submitCategory}>
                        <div className="flex flex-wrap">
                            <div className="flex items-center w-full md:w-6/12">
                                <div className="w-[calc(100%-85px)] p-3">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Categoría GiftCard</label>
                                    {!CategoryIsLoading ?
                                        <div className="relative">
                                            <FormControl fullWidth>
                                                <Select
                                                    required
                                                    name="name"
                                                    className="flex items-center"
                                                    variant="outlined"
                                                    value={state.name}
                                                    onChange={HandleChange}
                                                >
                                                    {categories.filter(fil => fil.type === 'GiftCard').map(category => (
                                                        <MenuItem value={category.name} key={category.name} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${category.sublogo}`} className="mr-2 rounded-full" />{category.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div> : <Spinner />}
                                </div>
                                <div className="flex items-center mt-7 justify-center w-[55px] h-[55px] ml-[15px] mr-[15px] bg-gradient-blue-custom rounded-full">
                                    <Tooltip title="Agregar nueva categoría GiftCard" arrow={true}>
                                        <IconButton onClick={() => HandleChangeType('NewCategoryGiftCard')}
                                            className="flex items-center justify-center w-[55px] h-[55px]"
                                            color="primary"
                                        >
                                            <MdAddCircle size={45} className="text-neutral-200" />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Nombre de sub categoria</label>
                                <input autoFocus name="subcategory" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Nombre de la sub categoría"
                                    autoComplete="off"
                                    required
                                    value={state.subcategory}
                                    type="text"
                                    onChange={HandleChange}
                                />
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Descuento usuario %</label>
                                <input name="discountuser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Descuento usuario %"
                                    autoComplete="off"
                                    required
                                    value={state.discountuser}
                                    onChange={HandleChange}
                                    type="number"
                                    onKeyPress={PermiteSoloNumeros}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    %
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo usuario</label>
                                <input name="costouser" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Costo usuario"
                                    autoComplete="off"
                                    required
                                    value={state.costouser}
                                    onChange={HandleChange}
                                    type="number"
                                    onKeyPress={PermiteSoloNumeros}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    S/
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3 relative">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Costo reseller</label>
                                <input name="costoreseller" className="bg-neutral-100 p-[15px] pl-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Costo reseller"
                                    autoComplete="off"
                                    required
                                    value={state.costoreseller}
                                    onChange={HandleChange}
                                    type="number"
                                    onKeyPress={PermiteSoloNumeros}
                                    min={0}
                                />
                                <div class="absolute text-neutral-900 dark:text-neutral-200 inset-y-4 mt-7 left-0 pl-5 flex items-center text-lg leading-5">
                                    S/
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Localización</label>
                                <div className="relative">
                                    <FormControl fullWidth>
                                        <Select
                                            required
                                            name="location"
                                            className="flex items-center"
                                            variant="outlined"
                                            value={state.location}
                                            onChange={HandleChange}
                                        >
                                            <MenuItem value={'Global'} key={'Global'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Global.svg`} className="mr-2 rounded-full" />Global</MenuItem>
                                            <MenuItem value={'Latam'} key={'Latam'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Latam.svg`} className="mr-2 rounded-full" />Latam</MenuItem>
                                            <MenuItem value={'Perú'} key={'Perú'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Perú.svg`} className="mr-2 rounded-full" />Perú</MenuItem>
                                            <MenuItem value={'Brasil'} key={'Brasil'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Brasil.svg`} className="mr-2 rounded-full" />Brasil</MenuItem>
                                            <MenuItem value={'Colombia'} key={'Colombia'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Colombia.svg`} className="mr-2 rounded-full" />Colombia</MenuItem>
                                            <MenuItem value={'Turquía'} key={'Turquía'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Turquía.svg`} className="mr-2 rounded-full" />Turquía</MenuItem>
                                            <MenuItem value={'Estados Unidos'} key={'Estados Unidos'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Estados Unidos.svg`} className="mr-2 rounded-full" />Estados Unidos</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Términos y condiciones del servicio</label>
                                <Editor
                                    editorState={editorStateGC}
                                    onEditorStateChange={setEditorStateGC}
                                    placeholder="Escribe aquí..."
                                />
                            </div>

                            <div className="w-full p-3">
                                <label className="text-neutral-900 dark:text-neutral-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                                <div className="flex flex-col justify-between h-16 mt-3">

                                    <span onClick={OnChangeTipoEntrega} className={`rounded-full flex bg-neutral-600 items-center cursor-pointer w-14 justify-start text-center absolute ${tipoentrega && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>

                                    <span onClick={OnChangeTipoEntrega} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!tipoentrega && 'hidden'}`}>
                                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                                    </span>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 p-3">
                                <label htmlFor="logosubcategory" class="flex flex-col items-center justify-center w-full h-64 border-2 border-neutral-500 border-dashed rounded-lg cursor-pointer bg-neutral-200 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-700">
                                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-900 dark:text-neutral-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                        <p class="mb-2 text-sm text-neutral-900 dark:text-neutral-200 p-3"><span class="font-semibold">Click para subir imagen logo de subcategoría </span> o arrastrar y soltar</p>
                                        <p class="text-xs text-neutral-900 dark:text-neutral-200">PNG o JPG (MAX. 800x400px)</p>
                                    </div>
                                    <input id="logosubcategory" accept="image/png,image/jpeg,image/webp" type="file" name="logosubcategory" class="hidden" onChange={imageHandle} />
                                </label>

                            </div>

                            <div class="w-full md:w-6/12 p-3 rounded-full flex inline-flex items-center justify-center">
                                <div className="w-100 h-100 rounded-full border-neutral-500 border-dashed flex inline-flex items-center justify-center">
                                    <ImagesPreview url={preview.logosubcategory} />
                                </div>
                            </div>

                            <div className="w-full p-3">
                                <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Sitio web</label>
                                <input name="url" className="bg-neutral-100 p-[15px] text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-neutral-900 shadow-md shadow-neutral-500 dark:shadow-black placeholder-neutral-300 dark:placeholder-neutral-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                                    placeholder="Sitio web"
                                    autoComplete="off"
                                    required
                                    value={state.url}
                                    type="text"
                                    onChange={HandleChange}
                                />
                            </div>

                            <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
                                <Button
                                    type="submit"
                                    disabled={isLoading ? true : false}
                                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                    size='large' variant="contained"
                                >
                                    {isLoading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                                    {isLoading ? 'Guardando...' : 'CREAR CATEGORIA'}
                                </Button>
                            </div>
                        </div>
                    </form>
                }

            </div>
        </Wrapper>
    )
}
export default CreateCategory;