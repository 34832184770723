import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AccountList from "../../components/home/AccountList";
import UserScreenHeader from "../../components/home/UserScreenHeader";
import { useUpdatePasswordMutation } from "../../store/services/userService";
import { IoEye, IoEyeOff, IoFingerPrint, IoSave } from "react-icons/io5";
import { Button, IconButton, TextField } from "@mui/material";
import { message } from "../../utils/Funciones.js";
import Spinner from "../../components/Spinner";
const ChangePassword = ({ socket, userToken, user, loadinguserbyid, userById, configuration, loadingconfiguration }) => {

  const [state, setState] = useState({
    _id: user?.id,
    passwordcurrent: "",
    passwordnew: "",
    passwordrepeatnew: ""
  });

  const [VerPasswords, SetVerPasswords] = useState(
    {
      passwordcurrent: false,
      passwordnew: false,
      passwordrepeatnew: false
    });

  const HandleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const [updatePassword, response] = useUpdatePasswordMutation();

  const UpdatePassword = (e) => {
    e.preventDefault()
    if (configuration && configuration.changepassword) {
      if (state.passwordnew.length >= 5) {
        if (state.passwordrepeatnew.length >= 5) {
          if (state.passwordrepeatnew === state.passwordnew) {
            updatePassword(state)
          } else {
            message("Password nueva & repetir password nueva no coinciden", 'error', 4)
          }

        } else {
          message("Password nueva debe tener por lo menos 5 caracteres", 'error', 4)
        }
      } else {
        message("Repetir password nueva debe tener por lo menos 5 caracteres", 'error', 4)
      }
    } else {
      message("Cambiar contraseña está temporalmente bloqueado, intentalo denuevo mas tarde.", 'error', 4)
    }

  }

  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors.map((err) => {
        message(err.msg, 'error', 4)
      });
    }
  }, [response?.error?.data?.errors]);

  const navigate = useNavigate();

  useEffect(() => {
    if (response?.isSuccess) {
      message(response?.data?.msg, 'success', 5)
      navigate("/manage-account/profile");
    }
  }, [response?.isSuccess]);

  return (
    <AccountList userToken={userToken} user={user} socket={socket} loadinguserbyid={loadinguserbyid} userById={userById} configuration={configuration}
      loadingconfiguration={loadingconfiguration}>
      <Toaster position="top-right" reverseOrder={true} />
      <UserScreenHeader>
        <div className="w-full text-neutral-800 dark:text-neutral-200 text-lg md:text-2xl flex inline-flex items-center">
          <IoFingerPrint size={32} className="mr-2" />CAMBIAR CONTRASEÑA <span class="inline-flex items-center justify-center px-3 ml-3 text-2xl font-medium 
            text-neutral-800 bg-neutral-200 rounded-full"></span></div>
      </UserScreenHeader>
      <div className="flex flex-wrap mt-[106px] bg-neutral-200 dark:bg-neutral-900 p-3">
        <form className="w-full md:w-6/12 p-3" onSubmit={UpdatePassword}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-12/12 p-3 relative">
              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password actual</label>
              <input autoFocus id="monto" name="passwordcurrent" className="bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] pr-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Password actual"
                required
                autoComplete="off"
                value={state.passwordcurrent}
                onChange={HandleChange}
                type={VerPasswords.passwordcurrent ? 'text' : 'password'}
              />
              <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordcurrent']: !VerPasswords.passwordcurrent })}>
                  {VerPasswords.passwordcurrent ? <IoEyeOff className="text-neutral-800 dark:text-neutral-200" /> : <IoEye className="text-neutral-800 dark:text-neutral-200" />}
                </IconButton>}
              </div>
            </div>

            <div className="w-full md:w-6/12 p-3 relative">

              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Password nueva</label>
              <input id="monto" name="passwordnew" className="bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] pr-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Password nueva"
                required
                autoComplete="off"
                value={state.passwordnew}
                onChange={HandleChange}
                type={VerPasswords.passwordnew ? 'text' : 'password'}
              />
              <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordnew']: !VerPasswords.passwordnew })}>
                  {VerPasswords.passwordnew ? <IoEyeOff className="text-neutral-800 dark:text-neutral-200" /> : <IoEye className="text-neutral-800 dark:text-neutral-200" />}
                </IconButton>}
              </div>
            </div>
            <div className="w-full md:w-6/12 p-3 relative">

              <label for="first_name" class="block mb-2 text-sm font-medium text-neutral-900 dark:text-white">Repetir password nueva</label>
              <input id="monto" name="passwordrepeatnew" className="bg-neutral-100 dark:bg-neutral-800 shadow-md shadow-neutral-500 dark:shadow-black p-[15px] pr-9 text-neutral-900 dark:text-neutral-200 text-base rounded-lg focus:ring-blue-600 block w-full placeholder-neutral-300 dark:placeholder-neutral-500 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                placeholder="Repetir password nueva"
                required
                autoComplete="off"
                value={state.passwordrepeatnew}
                onChange={HandleChange}
                type={VerPasswords.passwordrepeatnew ? 'text' : 'password'}
              />
              <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['passwordrepeatnew']: !VerPasswords.passwordrepeatnew })}>
                  {VerPasswords.passwordrepeatnew ? <IoEyeOff className="text-neutral-800 dark:text-neutral-200" /> : <IoEye className="text-neutral-800 dark:text-neutral-200" />}
                </IconButton>}
              </div>
            </div>
            <div className="flex m-auto md:w-12/12 p-3 w-full p-3 mt-5">
              <Button
                type="submit"
                disabled={response.isLoading ? true : false}
                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained"
              >
                {response.isLoading ? <Spinner /> : <IoSave className="mr-2" size={24} />}
                {response.isLoading ? 'Guardando...' : 'Cambiar contraseña'}
              </Button>
            </div>
          </div>
        </form>
      </div>

    </AccountList>
  );
};

export default ChangePassword;
