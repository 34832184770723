import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ventasService = createApi({
  reducerPath: "venta",
  tagTypes: "ventas",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cinedar.com:8443/api/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      const userToken = reducers?.authReducer?.userToken;
      headers.set('authorization', userToken ? `Bearer ${userToken}` : '' || token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createVenta: builder.mutation({
        query: (dataventa) => {
          return {
            url: "create-venta",
            method: "POST",
            body: dataventa,
          };
        },
        invalidatesTags: ["ventas"],
      }),

      updateventa: builder.mutation({
        query: (dataventa) => {
          return {
            url: `update-venta/${dataventa._id}`,
            method: "PATCH",
            body: dataventa,
          };
        },
        invalidatesTags: ["ventas"],
      }),
      lockOrRejectedVenta: builder.mutation({
        query: dataventa => {
          return {
            url: `locked-rejected-venta`,
            method: "PATCH",
            body: dataventa,
          };
        },
        invalidatesTags: ["ventas"],
      }),

      lockedVentasByCorreoId: builder.mutation({
        query: dataventa => {
          return {
            url: `lockedventas-bycorreoid`,
            method: "PATCH",
            body: dataventa,
          };
        },
        invalidatesTags: ["ventas"],
      }),

      getventaById: builder.query({
        query: (id) => {
          return {
            url: `get-venta/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      getAllVentasByUserId: builder.query({
        query: (params) => {
          var finalquery = ''
          if(params.search === ''){
            finalquery = 'emptyquery'
          }else{
            finalquery = params.search
          }
          return {
            url: `get-ventas-userid/${params.id}/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      getAllVentasByPage: builder.query({
        query: (params) => {
          var finalquery = ''
          if(params.search === ''){
            finalquery = 'emptyquery'
          }else{
            finalquery = params.search
          }
          return {
            url: `getallventasbypage/${finalquery}/${params.page}/${params.limit}`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      getAllVentas: builder.query({
        query: () => {
          return {
            url: `getallventas`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      countAllVentasByState: builder.query({
        query: () => {
          return {
            url: `countallventasbystate`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      getVentasByCorreoId: builder.query({
        query: (field) => {
          return {
            url: `ventasbycorreoid/${field.correoid}/${field.categoryId}/${field.servicetype}`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      LockedAllVentasByCorreoId: builder.mutation({
        query: (dataventa) => {
          return {
            url: `lockedallventasbycorreoid`,
            method: "PATCH",
            body: dataventa,
          };
        },
        providesTags: ["ventas"],
      }),

      getLastVentas: builder.query({
        query: () => {
          return {
            url: `getlastventas`,
            method: "GET",
          };
        },
        providesTags: ["ventas"],
      }),

      getDashboard: builder.query({
        query: () => {
          return {
            url: `getdashboard`,
            method: "GET",
          };
        }
      }),

      getVentasPage: builder.query({
        query: (page, query) => {
          return {
            url: `/ventas/${page}/${query}`,
            method: 'GET'
          }
        },
        providesTags: ['ventas']
      }),
      renewService: builder.mutation({
        query: data => {
          return {
            url: `renew-service`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["ventas"],
      }),
      updateService: builder.mutation({
        query: data => {
          return {
            url: `update-service`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["ventas"],
      }),

      deleteVenta: builder.mutation({
        query: (field) => {
            return {
                url: `/delete-venta/${field.id}/${field.type}`,
                method: 'DELETE'
            }
        },
        invalidatesTags: ['ventas']
    }),
    };
  },
});
export const {
  useCreateVentaMutation,
  useLockedAllVentasByCorreoIdMutation,
  useUpdateVentaMutation,
  useLockOrRejectedVentaMutation,
  useLockedVentasByCorreoIdMutation,
  useUpdateServiceMutation,
  useRenewServiceMutation,
  useGetVentaByIdQuery,
  useCountAllVentasByStateQuery,
  useGetVentasByCorreoIdQuery,
  useGetVentasPageQuery,
  useGetAllVentasByPageQuery,
  useGetAllVentasQuery,
  useGetLastVentasQuery,
  useGetDashboardQuery,
  useGetAllVentasByUserIdQuery,
  useDeleteVentaMutation
} = ventasService;
export default ventasService;
